"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "WFSite", {
  enumerable: true,
  get: function get() {
    return _WFSite.WFSite;
  }
});
Object.defineProperty(exports, "WFPortfolio", {
  enumerable: true,
  get: function get() {
    return _WFPortfolio.WFPortfolio;
  }
});

var _WFSite = require("./WFSite");

var _WFPortfolio = require("./WFPortfolio");