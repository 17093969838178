"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.loadIssuesFlow = loadIssuesFlow;
exports.issuesFlow = issuesFlow;
exports.getAppSetup = exports.getBetaSelections = exports.getUserSites = exports.getBetaIssues = exports.getAuthUser = exports.ACTION_STATUSES = exports.loadClosedIssues = exports.loadIssues = exports.updateBetaIssue = exports.loadIssuesRequest = exports.UPDATE_BETA_ISSUE = exports.LOAD_BETA_ISSUES_REQUEST_FAIL = exports.LOAD_BETA_ISSUES_REQUEST_SUCCESS = exports.LOAD_BETA_ISSUES_REQUEST = exports.LOAD_BETA_ISSUES = exports.CLOSED_ACTIONS_START_DATE = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _effects = require("redux-saga/effects");

var _moment = _interopRequireDefault(require("moment"));

var _reduxPersist = require("redux-persist");

var _requestSchedule = require("./requestSchedule");

var _actionTypes = require("../auth/actionTypes");

var _actionTypes2 = require("../appSetup/actionTypes");

var _regex = require("../../../utils/regex");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const initialState = {
  showClosed: false,
  loading: false,
  loaded: false,
  siteHasChanged: true,
  data: []
}; // Utils

function stripSpecialChars() {
  let str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  // NOTE: flexsearch doesn't like excessive special characters, like those found in SS links
  const stripped = str // Remove markdown link urls
  .replace(_regex.matchMDLinks, "$1") // Remove all non-alpha numeric chars (allows underscore)
  .replace(_regex.matchUnSearchable, "");
  return stripped;
}

function composeSearchString() {
  let fields = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return fields.reduce((searchable, field) => field !== undefined ? "".concat(searchable, " ").concat(field) : searchable, "");
}

function flattenEquipFields() {
  let equips = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let equipFields = "";

  if (equips.length > 0) {
    equipFields = equips.reduce((fields, equip) => "".concat(fields, " ").concat(equip.targetName, " ").concat(equip.ruleName, " ").concat(equip.description), "");
  }

  return equipFields;
}

function composeIssueData() {
  let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return data.map((issue, i) => {
    const searchable = composeSearchString([issue.components, issue.insite_assignee, issue.bonfire_status, issue.context, issue.description, issue.priority, issue.responsible, issue.spark_type, issue.summary, issue.key, issue.project_name, issue.transition_reason, flattenEquipFields(issue.associatedEquips)]);
    const valueString = stripSpecialChars(searchable);
    return _objectSpread(_objectSpread({}, issue), {}, {
      searchId: i,
      valueString
    });
  });
}

const CLOSED_ACTIONS_START_DATE = {
  LAST_12_MONTHS: 12,
  LAST_24_MONTHS: 24,
  ALL_TIME: -1
};
exports.CLOSED_ACTIONS_START_DATE = CLOSED_ACTIONS_START_DATE;

const getClosedActionStartDate = function getClosedActionStartDate() {
  let closedActionsStartDate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : CLOSED_ACTIONS_START_DATE.LAST_12_MONTHS;

  if (closedActionsStartDate === -1) {
    return (0, _moment.default)("2016-01-01").toJSON();
  }

  return (0, _moment.default)().subtract(closedActionsStartDate, "months").toJSON();
};
/**
 * Update the `updated` property of an issue, by
 * jira id.
 *
 * @param issues array of actions/issues
 * @param id issue jira key
 * @param updated UTC timestamp
 */


function refreshUpdatedIssue(issues, id, updated) {
  return issues.map(i => {
    if (i.key === id) {
      return _objectSpread(_objectSpread({}, i), {}, {
        updated
      });
    }

    return i;
  });
} // Loading the list of issues


const LOAD_BETA_ISSUES = "bonfire/issues/LOAD_BETA_ISSUES";
exports.LOAD_BETA_ISSUES = LOAD_BETA_ISSUES;
const LOAD_BETA_ISSUES_REQUEST = "bonfire/issues/LOAD_BETA_ISSUES_REQUEST";
exports.LOAD_BETA_ISSUES_REQUEST = LOAD_BETA_ISSUES_REQUEST;
const LOAD_BETA_ISSUES_REQUEST_SUCCESS = "bonfire/issues/LOAD_BETA_ISSUES_REQUEST_SUCCESS";
exports.LOAD_BETA_ISSUES_REQUEST_SUCCESS = LOAD_BETA_ISSUES_REQUEST_SUCCESS;
const LOAD_BETA_ISSUES_REQUEST_FAIL = "bonfire/issues/LOAD_BETA_ISSUES_REQUEST_FAIL";
exports.LOAD_BETA_ISSUES_REQUEST_FAIL = LOAD_BETA_ISSUES_REQUEST_FAIL;
const UPDATE_BETA_ISSUE = "bonfire/issues/UPDATE_BETA_ISSUE";
/**
 * ACTION CREATORS: CLIENT PROMISES
 */

exports.UPDATE_BETA_ISSUE = UPDATE_BETA_ISSUE;

const loadIssuesRequest = function loadIssuesRequest(status) {
  let closedActionsStartDate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 12;
  return {
    type: LOAD_BETA_ISSUES,
    status,
    closedActionsStartDate
  };
};

exports.loadIssuesRequest = loadIssuesRequest;

const updateBetaIssue = (id, updated) => ({
  type: UPDATE_BETA_ISSUE,
  id,
  updated
});

exports.updateBetaIssue = updateBetaIssue;

const loadIssues = function loadIssues(user, site, project) {
  let status = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "pending";
  return {
    types: [LOAD_BETA_ISSUES_REQUEST, LOAD_BETA_ISSUES_REQUEST_SUCCESS, LOAD_BETA_ISSUES_REQUEST_FAIL],
    promise: client => client.post("/issues", {
      headers: {
        Authorization: "Bearer ".concat(user.token)
      },
      data: {
        site,
        project,
        status
      }
    })
  };
};

exports.loadIssues = loadIssues;

const loadClosedIssues = (user, site, project, from) => ({
  types: [LOAD_BETA_ISSUES_REQUEST, LOAD_BETA_ISSUES_REQUEST_SUCCESS, LOAD_BETA_ISSUES_REQUEST_FAIL],
  promise: client => client.post("/issues", {
    headers: {
      Authorization: "Bearer ".concat(user.token)
    },
    data: {
      target: "",
      site,
      project,
      status: "closed",
      closedOnStartDate: from,
      closedOnEndDate: (0, _moment.default)().toJSON()
    }
  })
});

exports.loadClosedIssues = loadClosedIssues;

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case LOAD_BETA_ISSUES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        loaded: false
      });

    case LOAD_BETA_ISSUES_REQUEST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: true,
        data: composeIssueData(action.result),
        siteHasChanged: false,
        error: undefined
      });

    case LOAD_BETA_ISSUES_REQUEST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        data: [],
        loaded: false,
        loading: false,
        siteHasChanged: false,
        error: action.error
      });

    case UPDATE_BETA_ISSUE:
      return _objectSpread(_objectSpread({}, state), {}, {
        data: refreshUpdatedIssue(state.data, action.id, action.updated)
      });

    default:
      return state;
  }
}

const ACTION_STATUSES = {
  open: "open",
  closed: "closed"
};
exports.ACTION_STATUSES = ACTION_STATUSES;

const getAuthUser = state => state.auth.user;

exports.getAuthUser = getAuthUser;

const getBetaIssues = state => state.betaIssues;

exports.getBetaIssues = getBetaIssues;

const getUserSites = state => state.userSites;

exports.getUserSites = getUserSites;

const getBetaSelections = state => state.betaSelections;

exports.getBetaSelections = getBetaSelections;

const getAppSetup = state => state.appSetup;

exports.getAppSetup = getAppSetup;

function* loadIssuesFlow() {
  while (true) {
    // Check global requestHanlder flag to see if we should load issues
    const {
      status,
      closedActionsStartDate
    } = yield (0, _effects.take)(LOAD_BETA_ISSUES);
    const shouldRequestIssues = yield (0, _effects.select)(_requestSchedule.getShouldRequestIssues);
    const {
      loading
    } = yield (0, _effects.select)(getBetaIssues); // If app isn't ready, wait for it to be.

    const {
      appSetupComplete
    } = yield (0, _effects.select)(getAppSetup);

    if (!appSetupComplete) {
      yield (0, _effects.take)(_actionTypes2.APP_SETUP_COMPLETE);
    }

    if (shouldRequestIssues && !loading) {
      try {
        const showClosed = status === ACTION_STATUSES.closed;
        const user = yield (0, _effects.select)(getAuthUser);
        const {
          sites: userSites,
          projects: userProjects
        } = yield (0, _effects.select)(getUserSites);
        const {
          selectedSites: syncoIds
        } = yield (0, _effects.select)(getBetaSelections);
        const payload = syncoIds.reduce((siteProjectPayload, syncoId) => {
          const site = userSites[syncoId];
          const project = userProjects[site.projectId];
          siteProjectPayload.sites.add(site.id);
          siteProjectPayload.projects.add(project.jira_key);
          return siteProjectPayload;
        }, {
          sites: new Set(),
          projects: new Set()
        });
        if (!user.rolesRestriction) throw Error("Unable to authenticate user"); // Request closed issues

        if (showClosed) {
          const from = getClosedActionStartDate(closedActionsStartDate);
          yield (0, _effects.put)(loadClosedIssues(user, Array.from(payload.sites), Array.from(payload.projects), from));
        } else {
          // 'open' status will request all not-closed issues, meaning it'll include 'open' Jira issues
          // 'pending' is the default user status, which excludes 'open' jira issues (as these aren't ready for clients yet)
          // NOTE: Captain validates access if `open` status is requested
          const {
            includeOpenStatus
          } = yield (0, _effects.select)(state => state.betaActionsUI);
          const statusFilter = user.rolesRestriction.includes("Project Admins") && includeOpenStatus ? "open" : "pending"; // Fire off the load issues request

          yield (0, _effects.put)(loadIssues(user, Array.from(payload.sites), Array.from(payload.projects), statusFilter));
        }
      } catch (err) {
        console.error("Something went wrong attempting to load issues: ".concat(err));
      }
    }

    yield (0, _effects.race)({
      fail: (0, _effects.take)(LOAD_BETA_ISSUES_REQUEST_FAIL),
      success: (0, _effects.take)(LOAD_BETA_ISSUES_REQUEST_SUCCESS)
    });
    yield (0, _effects.put)((0, _requestSchedule.setShouldRequestIssues)(false));
  }
} // TODO: Refactor this flow to be shared, as it's mostly duplicated across rules.js and issues.js


function* issuesFlow() {
  yield (0, _effects.take)(_reduxPersist.REHYDRATE);

  while (true) {
    const loadIssuesTask = yield (0, _effects.fork)(loadIssuesFlow);
    yield (0, _effects.take)(_actionTypes.LOGOUT_SUCCESS);
    yield loadIssuesTask.cancel();
  }
}