"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateChangePasswordForm = validateChangePasswordForm;
exports.executeUserAction = executeUserAction;
exports.changePasswordFlow = changePasswordFlow;

var _effects = require("redux-saga/effects");

var _actionTypes = require("./actionTypes");

const ERROR_MESSAGE_KEYS = ["currentPassword", "newPassword", "confirmNewPassword", "passwordMismatch"];

function validateChangePasswordForm(formValues) {
  // Test validate return ok always
  const error = [];
  let ok = true;

  if (!formValues.current_user_password) {
    error.push(ERROR_MESSAGE_KEYS[0]);
  }

  if (!formValues.new_user_password) {
    error.push(ERROR_MESSAGE_KEYS[1]);
  }

  if (!formValues.new_user_password_confirm) {
    error.push(ERROR_MESSAGE_KEYS[2]);
  }

  if (formValues.new_user_password !== formValues.new_user_password_confirm) {
    error.push(ERROR_MESSAGE_KEYS[3]);
  }

  if (error.length > 0) {
    ok = false;
  }

  return {
    ok,
    error
  };
} // SELECTORS


const getAuthToken = state => state.auth.user.token;

const getChangePasswordFormValues = state => state.profile.profile_form_data;

const getCurrentUsername = state => state.auth.user.username;

function* changePassword(row, token) {
  // TODO: Saga doesn't expect our weird middleware, so `any` is required
  const action = {
    types: [_actionTypes.CHANGE_PASSWORD, _actionTypes.CHANGE_PASSWORD_SUCCESS, _actionTypes.CHANGE_PASSWORD_FAIL],
    // TODO: ApiClient needs typing... or maybe just delete the middleware/apiclient and use axios
    promise: client => client.post("/user/".concat(row.current_username, "/update-password"), {
      headers: {
        Authorization: "Bearer ".concat(token)
      },
      data: {
        current_password: row.current_user_password,
        new_password: row.new_user_password
      }
    })
  };
  yield (0, _effects.put)(action);
}
/**
 * @param response I have no idea what this is
 * @param func This is always the changePassword function, why is this necessary?
 * @param user TODO: Requires types
 * @param auth TODO: Requires types
 */


function* executeUserAction(response, func, user, auth) {
  if (response.ok) {
    // call add user asynchronously
    yield (0, _effects.fork)(func, user, auth);
  } else {
    // Return errors in form - Better to use redux form
    yield (0, _effects.put)({
      type: _actionTypes.VALIDATE_FORM_ERROR,
      error: response.error
    });
  }
}

function* changePasswordFlow() {
  while (true) {
    // Wait for start of cycle action
    yield (0, _effects.take)(_actionTypes.CHANGE_PASSWORD_REQUEST); // Get values from form - Better to use redux form

    const passwordFormValues = yield (0, _effects.select)(getChangePasswordFormValues);
    const token = yield (0, _effects.select)(getAuthToken);
    const currentUsername = yield (0, _effects.select)(getCurrentUsername); // add current_username to values sent to changePassword

    passwordFormValues.current_username = currentUsername; // Validate - Better to do this with redux form

    const response = yield (0, _effects.call)(validateChangePasswordForm, passwordFormValues, "ChangePassword"); // Change Password task

    yield (0, _effects.fork)(executeUserAction, response, changePassword, passwordFormValues, token); // Wait for end of cycle action

    yield (0, _effects.take)([_actionTypes.CHANGE_PASSWORD_SUCCESS, _actionTypes.CHANGE_PASSWORD_FAIL, _actionTypes.END_ACTION, _actionTypes.VALIDATE_FORM_ERROR]);
  }
}