"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateActionsUrlHistory = exports.updateMonitoringUrlHistory = exports.updateInsightsUrlHistory = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _actionTypes = require("./actionTypes");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function generateAction(actionType) {
  return (hist, updateType) => {
    const location = _objectSpread(_objectSpread({}, hist), {}, {
      path: "".concat(hist.pathname).concat(hist.search)
    });

    return {
      type: actionType,
      location,
      updateType
    };
  };
}

const updateInsightsUrlHistory = generateAction(_actionTypes.UPDATE_INSIGHTS_URL_HISTORY);
exports.updateInsightsUrlHistory = updateInsightsUrlHistory;
const updateMonitoringUrlHistory = generateAction(_actionTypes.UPDATE_MONITORING_URL_HISTORY);
exports.updateMonitoringUrlHistory = updateMonitoringUrlHistory;
const updateActionsUrlHistory = generateAction(_actionTypes.UPDATE_ACTIONS_URL_HISTORY);
exports.updateActionsUrlHistory = updateActionsUrlHistory;