"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Description = exports.Metric = exports.MetricContainer = exports.SubLabel = exports.Label = exports.Tile = exports.TileContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

const TileContainer = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  flex-wrap: wrap;\n  flex-basis: 800px;\n  background: ", ";\n  box-shadow: ", ";\n"])), _blocks.color.pureWhite, _blocks.elevation.content);

exports.TileContainer = TileContainer;

const Tile = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  flex-basis: 50%;\n  padding: ", ";\n  border-bottom: 1px solid ", ";\n  background: ", ";\n\n  &:nth-child(2n - 1) {\n    border-right: 1px solid ", ";\n  }\n\n  &:last-child {\n    border-bottom: none;\n  }\n"])), _blocks.spacing.default, _blocks.color.theme.divider.solid, _blocks.color.pureWhite, _blocks.color.theme.divider.solid);

exports.Tile = Tile;

const Label = _styledComponents.default.h4(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 0 0 ", ";\n  font-size: 12px;\n  line-height: 12px;\n  font-weight: ", ";\n  color: ", ";\n  text-transform: uppercase;\n"])), _blocks.spacing.extraTight, _blocks.fontWeight.bold, _blocks.color.theme.typography.ink);

exports.Label = Label;

const SubLabel = _styledComponents.default.p(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 0 0 ", ";\n  font-size: 12px;\n  line-height: 12px;\n  color: ", ";\n  letter-spacing: -0.15px;\n"])), _blocks.spacing.default, _blocks.color.theme.typography.ink);

exports.SubLabel = SubLabel;

const MetricContainer = _styledComponents.default.div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  margin: 0 0 ", ";\n"])), _blocks.spacing.loose);

exports.MetricContainer = MetricContainer;

const Metric = _styledComponents.default.span(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n  display: inline-block;\n  margin: 0 ", " 0 0;\n  font-size: 42px;\n  line-height: 42px;\n  font-weight: ", ";\n"])), _blocks.spacing.tight, _blocks.fontWeight.bold);

exports.Metric = Metric;

const Description = _styledComponents.default.p(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 0;\n  font-size: 12px;\n  line-height: 16px;\n  color: ", ";\n"])), _blocks.palette.neutral7);

exports.Description = Description;