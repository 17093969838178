"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TRACKING_EVENT_CHART_CLICK = exports.TRACKING_EVENT_ORDER_BY = exports.TRACKING_EVENT_GROUP_BY = exports.TRACKING_EVENT_SORT_BY = exports.TRACKING_EVENT_TIME_FRAME = exports.METRICS_TYPE_PERSIST_PROP = exports.WORKFLOW_PERSIST_KEY = void 0;
const WORKFLOW_PERSIST_KEY = "Reports-workflow";
exports.WORKFLOW_PERSIST_KEY = WORKFLOW_PERSIST_KEY;
const METRICS_TYPE_PERSIST_PROP = "metricsType"; // Event tracking names

exports.METRICS_TYPE_PERSIST_PROP = METRICS_TYPE_PERSIST_PROP;
const TRACKING_EVENT_TIME_FRAME = "Selected Time Frame (Performance Reports)";
exports.TRACKING_EVENT_TIME_FRAME = TRACKING_EVENT_TIME_FRAME;
const TRACKING_EVENT_SORT_BY = "Chart - Sorted DataGrid";
exports.TRACKING_EVENT_SORT_BY = TRACKING_EVENT_SORT_BY;
const TRACKING_EVENT_GROUP_BY = "Chart - Clicked GroupBy";
exports.TRACKING_EVENT_GROUP_BY = TRACKING_EVENT_GROUP_BY;
const TRACKING_EVENT_ORDER_BY = "Chart - Clicked OrderBy";
exports.TRACKING_EVENT_ORDER_BY = TRACKING_EVENT_ORDER_BY;
const TRACKING_EVENT_CHART_CLICK = "Chart - Clicked Chart element";
exports.TRACKING_EVENT_CHART_CLICK = TRACKING_EVENT_CHART_CLICK;