"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stripSitesQuery = exports.parseAssociateInsightWithActionEventPayload = exports.parseActionForAssociateEvent = exports.parseAddedActionEventPayload = exports.parseAssociateSuccessResult = exports.parseSnoozeEquipRuleEventPayload = exports.parseActionSavedEventPayload = exports.parsePostCommentEventPayload = exports.parseReportViewedEventPayload = exports.parseRuleViewedEventPayload = exports.parseDateToISOString = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _moment = _interopRequireDefault(require("moment"));

var _queryStringForAll = _interopRequireDefault(require("query-string-for-all"));

var _utils = require("../redux/modules/insights/utils");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * FYI: Mixpanel expects date type as ISOString
 */
const parseDateToISOString = dateInput => {
  if (dateInput === null || dateInput === undefined || dateInput === "") {
    return null;
  }

  const dateToParse = typeof dateInput === "number" ? new Date(dateInput * 1000) : dateInput;
  return (0, _moment.default)(dateToParse).toISOString();
};
/** Parse action to eliminate duplication of code */


exports.parseDateToISOString = parseDateToISOString;

const parseAction = action => ({
  actionKey: action.key || null,
  actionSummary: action.summary || null,
  siteName: action.components || null,
  actionPriority: action.priority || null,
  actionResponsible: action.responsible || null,
  actionContext: action.context || null,
  actionStatus: action.bonfire_status || null
});
/** Event: When user view a rule in Insights page */


const parseRuleViewedEventPayload = ruleGroup => {
  const rule = (0, _utils.composeInsights)({
    0: ruleGroup
  })[0];
  return {
    ruleId: rule.ruleId,
    ruleName: rule.ruleName,
    siteName: rule.site.label,
    severity: rule.severity,
    totalDuration: rule.duration,
    snoozedDuration: rule.duration - rule.activeDuration,
    activeDuration: rule.activeDuration,
    ruleType: rule.service,
    actionsCount: rule.actionsCount,
    totalHits: rule.hits,
    activeHits: rule.activeHits,
    snoozedHits: rule.hits - rule.activeHits,
    totalEquips: rule.targetCount,
    activeEquips: rule.activeTargetCount,
    snoozedEquips: rule.targetCount - rule.activeTargetCount,
    latest: rule.latestLabel
  };
};
/** Event: When user view a report in Insights page by clicking reportURL for SS */


exports.parseRuleViewedEventPayload = parseRuleViewedEventPayload;

const parseReportViewedEventPayload = (asset, ruleName, isAssociated) => ({
  ruleId: asset.ruleId,
  ruleName,
  siteName: asset.site.label,
  ruleType: asset.ruleType,
  equipName: asset.equipName || null,
  equipDescription: asset.description,
  numberOfHits: asset.times.length,
  totalDuration: asset.duration,
  averageDuration: asset.averageDur,
  associatedActionKey: asset.issueKey,
  isSnoozed: asset.isSnoozed,
  snoozeEnd: parseDateToISOString(asset.snoozeEnd),
  isAssociated,
  reportURL: asset.reportURL
});
/** Event: When user post a comment under actions, an event triggered to track interactions */


exports.parseReportViewedEventPayload = parseReportViewedEventPayload;

const parsePostCommentEventPayload = (issue, commentResult) => {
  let equips = [];

  if (Array.isArray(issue.equipRules) && issue.equipRules.length > 0) {
    equips = issue.equipRules.map(equipRule => ({
      targetName: equipRule.target.name,
      ruleName: equipRule.rule.name,
      ruleType: equipRule.rule.type,
      severity: equipRule.rule.severity,
      hitOccurredOn: parseDateToISOString(equipRule.hit.occurredOn)
    }));
  }

  return _objectSpread({
    actionCreatedOn: parseDateToISOString(issue.created),
    equipRules: equips,
    comment: commentResult.result && commentResult.result.body ? commentResult.result.body : null
  }, parseAction(issue));
};
/** Event: When user save changes for an action, a event triggered to track interactions */


exports.parsePostCommentEventPayload = parsePostCommentEventPayload;

const parseActionSavedEventPayload = (action, actionId, updatedAction) => ({
  actionKey: actionId,
  actionContext: action.context.value,
  actionSummary: action.summary,
  actionStatus: action.status.value,
  actionPriority: action.priority.value,
  actionResponsible: action.responsible.value,
  actionCreatedOn: parseDateToISOString(updatedAction.created),
  siteName: updatedAction.components,
  ruleName: updatedAction.rule
});
/**
 * helper method used to minimise duplicate mapping
 */


exports.parseActionSavedEventPayload = parseActionSavedEventPayload;

const parseRule = rule => ({
  ruleId: rule.id,
  ruleName: rule.name,
  ruleType: rule.type,
  severity: rule.severity
});
/** Event: When user snooze and equip rule by clicking snooze icon in Insights page */


const parseSnoozeEquipRuleEventPayload = (siteName, duration, equipRuleId, successResult) => _objectSpread({
  hitOccurredOn: parseDateToISOString(successResult.hit.occurredOn),
  associatedActionKey: successResult.issueKey,
  equipName: successResult.target.name,
  snoozeEnd: parseDateToISOString(successResult.snoozeEnd),
  siteName,
  equipRuleId,
  duration
}, parseRule(successResult.rule));
/**
 * Parse function used to parse associate result for both Added Action event and associate action event
 */


exports.parseSnoozeEquipRuleEventPayload = parseSnoozeEquipRuleEventPayload;

const parseAssociateSuccessResult = successResult => successResult === null || successResult === undefined ? [] : successResult.map(equipRule => _objectSpread({
  equipRuleId: equipRule.id,
  actionKey: equipRule.issueKey,
  snoozeEnd: equipRule.snoozeEnd,
  targetId: equipRule.target.id,
  targetName: equipRule.target.name
}, parseRule(equipRule.rule)));
/** Event: When user Add/Create an action, a event triggered to track interactions */


exports.parseAssociateSuccessResult = parseAssociateSuccessResult;

const parseAddedActionEventPayload = function parseAddedActionEventPayload(issueKey, issue) {
  let site = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  let associateResult = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  let associateError = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  return {
    actionContext: issue.issueContext,
    actionKey: issueKey,
    actionPriority: issue.priority,
    actionResponsible: issue.responsible,
    actionStatus: issue.status.value,
    actionSummary: issue.summary,
    equipRuleIds: issue.target !== "" ? issue.target : null,
    ruleName: issue.sparkType,
    siteName: site ? site.label : issue.components[0].name,
    equipRules: parseAssociateSuccessResult(associateResult),
    associateError: associateError || null
  };
};

exports.parseAddedActionEventPayload = parseAddedActionEventPayload;

const parseActionForAssociateEvent = (betaIssues, successResult) => {
  const issueKeys = new Set(successResult.map(equipRule => equipRule.issueKey));
  const issueKey = [...issueKeys.values()][0];
  const issue = (betaIssues.length > 0 ? betaIssues.find(action => action.key === issueKey) : {}) || {};
  return _objectSpread({}, parseAction(issue));
};
/** Event: When user associate an equip rule to an existing issue in Insights */


exports.parseActionForAssociateEvent = parseActionForAssociateEvent;

const parseAssociateInsightWithActionEventPayload = (betaIssues, successResult) => _objectSpread({
  equipRules: parseAssociateSuccessResult(successResult)
}, parseActionForAssociateEvent(betaIssues, successResult));

exports.parseAssociateInsightWithActionEventPayload = parseAssociateInsightWithActionEventPayload;

const stripSitesQuery = search => {
  const qs = _queryStringForAll.default.parse(search);

  return _objectSpread(_objectSpread({}, qs), {}, {
    sites: undefined
  });
};

exports.stripSitesQuery = stripSitesQuery;