"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useRuleSettings = useRuleSettings;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var React = _interopRequireWildcard(require("react"));

var _config = require("../../../context/settings/config");

var _calcActiveConfig = require("../utils/calcActiveConfig");

var _useActions = require("../../../context/settings/config/useActions");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function useRuleSettings() {
  const configState = (0, _config.useConfigState)();
  const {
    updateConfigRules,
    updateRuleConfig,
    bulkUpdateRuleConfig
  } = (0, _useActions.useActions)();
  const [propagated, setPropagated] = React.useState({});
  const handleCheck = React.useCallback(function handleCheck(rule) {
    return () => {
      void updateConfigRules(rule);
    };
  }, []);
  const updatePriority = React.useCallback(function updatePriority(updated) {
    void updateRuleConfig(updated);
  }, []);
  const resetRules = React.useCallback(function resetRules() {
    setPropagated(_objectSpread({}, configState.saved));
  }, [configState.shouldReset]);
  const updateActive = React.useCallback(function updateActive() {
    setTimeout(() => {
      setPropagated(configState.active);
    }, 300);
  }, [configState.active]);
  const clearAll = React.useCallback(function clearAll(visible) {
    return () => {
      const active = (0, _calcActiveConfig.calcRemoveAllConfig)(visible, configState);
      setPropagated(active);
      bulkUpdateRuleConfig(active);
    };
  }, [configState.active]);
  const selectAll = React.useCallback(function selectAll(visible) {
    return () => {
      const active = (0, _calcActiveConfig.calcSelectAllConfig)(visible, configState);
      setPropagated(active);
      bulkUpdateRuleConfig(active);
    };
  }, [configState.active]);
  React.useEffect(() => {
    setPropagated(_objectSpread({}, configState.saved));
  }, [configState.saved]);
  React.useEffect(() => {
    if (configState.shouldReset) {
      setPropagated(_objectSpread({}, configState.saved));
    }
  }, [configState.shouldReset]);
  return React.useMemo(() => ({
    checked: configState.active,
    propagated,
    clearAll,
    selectAll,
    updateActive,
    handleCheck,
    resetRules,
    updatePriority,
    insightType: configState.insightType
  }), [configState.active, propagated, configState.insightType]);
}