"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.enableForm = enableForm;
exports.updateSelectedProject = updateSelectedProject;
exports.generateReport = generateReport;
exports.getAuthUser = getAuthUser;
exports.pollForReportStatus = pollForReportStatus;
exports.sagaGenerateReport = sagaGenerateReport;
exports.watchGenerateReport = watchGenerateReport;
exports.default = exports.jobStates = exports.actions = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _effects = require("redux-saga/effects");

var _segment = _interopRequireDefault(require("../../utils/segment"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const actions = {
  ENABLE_FORM: "bonfire/reportGenerator/ENABLE_FORM",
  UPDATE_SELECTED_PROJECT: "bonfire/reportGenerator/UPDATE_SELECTED_PROJECT",
  GENERATE_REPORT: "bonfire/reportGenerator/GENERATE_REPORT",
  GENERATE_REPORT_REQUEST: "bonfire/reportGenerator/GENERATE_REPORT_REQUEST",
  GENERATE_REPORT_SUCCESS: "bonfire/reportGenerator/GENERATE_REPORT_SUCCESS",
  GENERATE_REPORT_FAILURE: "bonfire/reportGenerator/GENERATE_REPORT_FAILURE",
  GET_REPORT_STATUS_REQUEST: "bonfire/reportGenerator/GET_REPORT_STATUS_REQUEST",
  GET_REPORT_STATUS_SUCCESS: "bonfire/reportGenerator/GET_REPORT_STATUS_SUCCESS",
  GET_REPORT_STATUS_FAILURE: "bonfire/reportGenerator/GET_REPORT_STATUS_FAILURE"
};
exports.actions = actions;
const jobStates = {
  RUNNING: "running",
  DONE_OK: "doneOk",
  DONE_ERROR: "doneErr",
  CANCELLING: "cancelling",
  DONE_CANCEL: "doneCancel"
};
exports.jobStates = jobStates;
const initialState = {
  isFormDisabled: false,
  selectedProject: null,
  showJobStatusPanel: false,
  currentJobStatus: {}
};

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actions.ENABLE_FORM:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFormDisabled: false
      });

    case actions.UPDATE_SELECTED_PROJECT:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedProject: action.payload.project
      });

    case actions.GENERATE_REPORT:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFormDisabled: true,
        showJobStatusPanel: true,
        currentJobStatus: {
          state: jobStates.RUNNING
        }
      });

    case actions.GENERATE_REPORT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentJobStatus: _objectSpread(_objectSpread({}, state.currentJobStatus), {}, {
          id: action.result.jobId
        })
      });

    case actions.GENERATE_REPORT_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFormDisabled: false,
        currentJobStatus: {
          state: jobStates.DONE_ERROR,
          msg: action.error.message || "An unknown error occurred"
        }
      });

    case actions.GET_REPORT_STATUS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentJobStatus: _objectSpread(_objectSpread({}, state.currentJobStatus), {}, {
          start: action.result.start,
          state: action.result.state,
          msg: action.result.progressMsg,
          progress: action.result.progress
        })
      });

    case actions.GET_REPORT_STATUS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFormDisabled: false,
        currentJobStatus: {
          state: jobStates.DONE_ERROR,
          msg: action.error.message || "An unknown error occurred"
        }
      });

    default:
      return state;
  }
}

function enableForm() {
  return {
    type: actions.ENABLE_FORM,
    payload: {}
  };
}

function updateSelectedProject(project) {
  return {
    type: actions.UPDATE_SELECTED_PROJECT,
    payload: {
      project
    }
  };
}

function generateReport(reportId, reportOptions) {
  return {
    type: actions.GENERATE_REPORT,
    payload: {
      reportId,
      reportOptions
    }
  };
}

function getAuthUser(state) {
  return state.auth.user;
}

function* pollForReportStatus(jobId, reportOptions) {
  const user = yield (0, _effects.select)(getAuthUser);
  const headers = {
    Authorization: "Bearer ".concat(user.token)
  };
  const requestAction = {
    types: [actions.GET_REPORT_STATUS_REQUEST, actions.GET_REPORT_STATUS_SUCCESS, actions.GET_REPORT_STATUS_FAILURE],
    promise: client => client.get("/report/job/".concat(jobId), {
      headers
    })
  };
  yield (0, _effects.put)(requestAction);
  const {
    success
  } = yield (0, _effects.race)({
    success: (0, _effects.take)(actions.GET_REPORT_STATUS_SUCCESS),
    failure: (0, _effects.take)(actions.GET_REPORT_STATUS_FAILURE)
  });

  if (success) {
    switch (success.result.state) {
      case jobStates.RUNNING:
      case jobStates.CANCELLING:
        yield new Promise(resolve => {
          setTimeout(() => resolve(), 500);
        });
        yield pollForReportStatus(jobId, reportOptions);
        break;

      case jobStates.DONE_OK:
        _segment.default.track("Report generated", _objectSpread({}, reportOptions));

        yield (0, _effects.put)(enableForm());
        break;

      case jobStates.DONE_CANCEL:
        _segment.default.track("Report cancelled", _objectSpread({}, reportOptions));

        yield (0, _effects.put)(enableForm());
        break;

      case jobStates.DONE_ERROR:
        _segment.default.track("Report errored", _objectSpread({}, reportOptions));

        yield (0, _effects.put)(enableForm());
        break;

      default:
        break;
    }
  }
}

function* sagaGenerateReport(action) {
  const {
    reportId,
    reportOptions
  } = action.payload;
  const user = yield (0, _effects.select)(getAuthUser);
  const headers = {
    Authorization: "Bearer ".concat(user.token)
  };
  const requestAction = {
    types: [actions.GENERATE_REPORT_REQUEST, actions.GENERATE_REPORT_SUCCESS, actions.GENERATE_REPORT_FAILURE],
    promise: client => client.post("/report/".concat(reportId), {
      data: reportOptions,
      headers
    })
  };
  yield (0, _effects.put)(requestAction);
  const {
    success
  } = yield (0, _effects.race)({
    success: (0, _effects.take)(actions.GENERATE_REPORT_SUCCESS),
    failure: (0, _effects.take)(actions.GENERATE_REPORT_FAILURE)
  });
  if (success) yield pollForReportStatus(success.result.jobId, reportOptions);
}

function* watchGenerateReport() {
  yield (0, _effects.takeLatest)(actions.GENERATE_REPORT, sagaGenerateReport);
}

var _default = reducer;
exports.default = _default;