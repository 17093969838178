"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$Setting = exports.$List = exports.$Label = exports.$IconBubble = exports.$Wrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _App = require("../../components/AppChrome/App.constants");

var _Menu = require("../../components/Menu/Menu.styles");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

const $Wrapper = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  position: relative;\n  padding-top: 4px;\n"])));

exports.$Wrapper = $Wrapper;

function showCount(_ref) {
  let {
    count
  } = _ref;
  return count > 0 ? "inherit" : "none";
}

const $IconBubble = (0, _styledComponents.default)(_blocks.Badge)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  display: ", ";\n  position: absolute;\n  top: 2px;\n  left: 50%;\n  min-width: ", "px;\n  background: ", ";\n  height: ", "px;\n  z-index: ", ";\n"])), showCount, _App.BASE_UNIT * 3, _blocks.palette.orange5, _App.BASE_UNIT * 2, _App.LAYERS.FIRST);
exports.$IconBubble = $IconBubble;

const $Label = _styledComponents.default.button.attrs({
  type: "button"
})(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  display: inline-flex;\n  align-items: center;\n  border: none;\n  font-size: 14px;\n  background: none;\n\n  &:focus {\n    outline: 0;\n  }\n"])));

exports.$Label = $Label;

function showList(_ref2) {
  let {
    isOpen
  } = _ref2;
  return isOpen ? "block" : "none";
}

const $List = (0, _styledComponents.default)(_Menu.$Menu)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  display: ", ";\n  left: auto;\n  right: 0;\n"])), showList);
exports.$List = $List;
const $Setting = (0, _styledComponents.default)(_Menu.$ButtonItem)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  span {\n    margin-right: ", ";\n  }\n\n  &:disabled {\n    opacity: ", ";\n\n    span {\n      cursor: default;\n    }\n  }\n"])), _App.SPACING.TIGHT, _App.OPACITY.SUBDUED);
exports.$Setting = $Setting;