"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.startUploadAttachment = startUploadAttachment;
exports.endUploadAttachment = endUploadAttachment;
exports.resetAttachmentState = resetAttachmentState;
exports.handleAddAttachment = handleAddAttachment;
exports.handleRemoveAttachment = handleRemoveAttachment;
exports.getSignedUrl = getSignedUrl;
exports.uploadToS3 = uploadToS3;

var _actionTypes = require("./actionTypes");

var _regex = require("../../../utils/regex");

// Toggles the upload form popup
function startUploadAttachment() {
  return {
    type: _actionTypes.START_UPLOAD_ATTACHMENT
  };
}

function endUploadAttachment() {
  return {
    type: _actionTypes.END_UPLOAD_ATTACHMENT
  };
} // Resets attachments store to initial empty state


function resetAttachmentState() {
  return {
    type: _actionTypes.INITIALIZE_ATTACHMENTS
  };
} // Handles preparing attachment for upload; includes retrieve pre-signed url for upload


function handleAddAttachment(attachment) {
  return {
    type: _actionTypes.ADD_ATTACHMENT_REQUEST,
    attachment
  };
} // Remove attachment from list of attachments to upload


function handleRemoveAttachment(attachment) {
  return {
    type: _actionTypes.REMOVE_ATTACHMENT,
    attachment
  };
}

function getSignedUrl(user, issueId) {
  let fileName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
  let fileType = arguments.length > 3 ? arguments[3] : undefined;
  // Replace unsafe filename characters with `-`
  const safeFileName = fileName.replace(_regex.matchUnsafeS3Chars, "-");
  return {
    types: [_actionTypes.GET_SIGNED_URL, _actionTypes.GET_SIGNED_URL_SUCCESS, _actionTypes.GET_SIGNED_URL_FAIL],
    promise: client => client.get("/upload/".concat(issueId, "/").concat(safeFileName), {
      headers: {
        Authorization: "Bearer ".concat(user.token)
      },
      params: {
        content_type: fileType
      }
    })
  };
}

function uploadToS3(signedUrl, file) {
  return {
    types: [_actionTypes.UPLOAD_TO_S3, _actionTypes.UPLOAD_TO_S3_SUCCESS, _actionTypes.UPLOAD_TO_S3_FAIL],
    promise: client => client.put(signedUrl, {
      data: file
    }, {
      externalPath: true
    })
  };
}