"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateForm = validateForm;
exports.saveUser = saveUser;
exports.executeUserAction = executeUserAction;
exports.createUserFlow = createUserFlow;
exports.saveUserFlow = saveUserFlow;

var _effects = require("redux-saga/effects");

var _address = require("@hapi/address");

var _actionTypes = require("./actionTypes");

var _actionCreators = require("./actionCreators");

var _actionCreators2 = require("../auth/actionCreators");

var _selections = require("../_BETA/selections");

var _actionTypes2 = require("../auth/actionTypes");

function validateForm(newUser, formType) {
  // Test validate return ok always
  const error = [];
  let ok = true;

  if (!newUser.user_email) {
    error.push("Email is required.");
  } else if (!_address.email.isValid(newUser.user_email)) {
    error.push("Email is not valid.");
  }

  if (!newUser.user_fullname || newUser.user_fullname.trim().length === 0) {
    error.push("Full Name is required.");
  }

  if (!newUser.user_username || newUser.user_username.trim().length === 0) {
    error.push("Username is required.");
  }

  if (!newUser.user_password && formType === "CreateUser") {
    error.push("Password is required.");
  }

  if (!newUser.user_password_confirm && formType === "CreateUser") {
    error.push("Confirm Password is required.");
  }

  if (formType === "CreateUser" && newUser.user_password !== newUser.user_password_confirm) {
    error.push("Password and Confirm Password do not match. Try re-entering both.");
  }

  if (!newUser.user_role) {
    error.push("Role is required.");
  }

  if (!newUser.user_group) {
    error.push("Group is required.");
  }

  if (error.length > 0) {
    ok = false;
  }

  return {
    ok,
    error
  };
}

function* addUser(row, user) {
  const action = {
    types: [_actionTypes.ADD_USER, _actionTypes.ADD_USER_SUCCESS, _actionTypes.ADD_USER_FAIL],
    promise: client => client.post("/auth/register", {
      headers: {
        Authorization: "Bearer ".concat(user.token)
      },
      params: {
        admin_role: user.role
      },
      data: {
        username: row.user_username,
        email: row.user_email,
        password: row.user_password,
        display_name: row.user_fullname,
        group: row.user_group.split(","),
        // Captain expects this list as an array
        role: row.user_role,
        updatedBy: user.id
      }
    })
  };
  yield (0, _effects.put)(action);
}

function* saveUser(row, user) {
  const action = yield {
    types: [_actionTypes.SAVE_USER, _actionTypes.SAVE_USER_SUCCESS, _actionTypes.SAVE_USER_FAIL],
    promise: client => client.post("/user/save/".concat(row.user_id), {
      headers: {
        Authorization: "Bearer ".concat(user.token)
      },
      params: {
        role: user.role,
        username: row.user_username
      },
      data: {
        display_name: row.user_fullname,
        role: row.user_role,
        group: row.user_group.split(","),
        updatedBy: user.id,
        email: row.user_email
      }
    })
  };
  yield (0, _effects.put)(action);
}

function* executeUserAction(response, func, user, auth) {
  if (response.ok) {
    // call add user asynchronously
    yield (0, _effects.fork)(func, user, auth);
  } else {
    // Return errors in form - Better to use redux form
    yield (0, _effects.put)({
      type: _actionTypes.VALIDATE_FORM_ERROR,
      error: response.error
    });
  }
} // Our watcher Saga:
// const getCreateUserFormValues = state => state.form.createUser.values


const getCreateUserFormValues = state => state.users.user_form_data;

const getAuthUser = state => state.auth.user;

function* createUserFlow() {
  while (true) {
    // Wait for start of cycle action
    yield (0, _effects.take)(_actionTypes.ADD_USER_REQUEST); // Get values from form - Better to use redux form

    const newUser = yield (0, _effects.select)(getCreateUserFormValues);
    const authUser = yield (0, _effects.select)(getAuthUser); // Validate - Better to do this with redux form

    const response = yield (0, _effects.call)(validateForm, newUser, "CreateUser", authUser); // Add User task

    yield (0, _effects.fork)(executeUserAction, response, addUser, newUser, authUser); // Wait for end of cycle action

    yield (0, _effects.take)([_actionTypes.ADD_USER_SUCCESS, _actionTypes.ADD_USER_FAIL, _actionTypes.END_ACTION, _actionTypes.VALIDATE_FORM_ERROR]);
  }
}

const getSaveUserFormValues = state => state.users.user_form_data;

function* saveUserFlow() {
  while (true) {
    // Wait for start of cycle action
    yield (0, _effects.take)(_actionTypes.SAVE_USER_REQUEST); // Get values from form - Better to use redux form

    const editedUser = yield (0, _effects.select)(getSaveUserFormValues);
    const authUser = yield (0, _effects.select)(getAuthUser); // Validate - Better to do this with redux form

    const response = yield (0, _effects.call)(validateForm, editedUser, "EditUser", authUser); // Save User task

    yield (0, _effects.fork)(executeUserAction, response, saveUser, editedUser, authUser); // Wait for end of cycle action

    const result = yield (0, _effects.take)([_actionTypes.SAVE_USER_SUCCESS, _actionTypes.SAVE_USER_FAIL, _actionTypes.VALIDATE_FORM_ERROR]); // If the user update is successful:

    if (result.type === _actionTypes.SAVE_USER_SUCCESS) {
      // Reauthenticate user to refresh portfolio
      yield (0, _effects.put)((0, _actionCreators2.load)(authUser));
      yield (0, _effects.take)(_actionTypes2.LOAD_AUTH_USER_SUCCESS); // Once reauthenticated, select updated user from store

      const updatedUser = yield (0, _effects.select)(getAuthUser); // Refresh user list

      yield (0, _effects.put)((0, _actionCreators.loadUsers)(updatedUser)); // Retrieve current active project and check that it's valid
      // against the updated user portfolio

      const {
        activeProject
      } = yield (0, _effects.select)(state => state.betaSelections); // If current active project is no longer valid, replace with a valid project
      // from updated user portfolio

      if (updatedUser.portfolio.projects[activeProject.project_pk] === undefined) {
        yield (0, _effects.put)({
          type: _selections.SELECT_BETA_PROJECT,
          projectId: Object.keys(updatedUser.portfolio.projects)[0]
        });
      }
    }
  }
}