"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadUserSitesFlow = loadUserSitesFlow;
exports.userSitesFlow = userSitesFlow;

var _effects = require("redux-saga/effects");

var _actionTypes = require("../auth/actionTypes");

var _selections = require("../_BETA/selections");

var _utils = require("../_BETA/utils");

var _actionTypes2 = require("./actionTypes");

var _actionCreators = require("./actionCreators");

function* setUserSites(projects, sites) {
  const firstSite = sites[Object.keys(sites)[0]]; // Cleanse selectedSites of invalid selections
  // I.e., if a selected site is from a project that the user no
  // longer has access to, remove it.

  const {
    selectedSites = []
  } = yield (0, _effects.select)(state => state.betaSelections);
  const validSelectedSites = selectedSites.length > 0 ? selectedSites.filter(syncoId => sites[syncoId] !== undefined) : []; // If none of the selected sites are valid,
  // set selected sites to be the first valid site

  if (validSelectedSites.length < 1) {
    yield (0, _effects.put)((0, _selections.updateSelectedSites)([firstSite.syncoId]));
  } else {
    // Otherwise, update selected sites to be all valid sites
    yield (0, _effects.put)((0, _selections.updateSelectedSites)(validSelectedSites));
  }

  const projectGroups = (0, _utils.groupByProject)(projects, sites);
  yield (0, _effects.put)((0, _actionCreators.updateUserSites)({
    projects,
    sites,
    projectGroups
  }));
  yield (0, _effects.put)({
    type: _actionTypes2.LOAD_USER_SITES_COMPLETE
  });
}

function* loadUserSitesFlow() {
  while (true) {
    try {
      const {
        result
      } = yield (0, _effects.take)(_actionTypes.LOAD_AUTH_USER_SUCCESS); // If request was successful, update projects, sites, and projectGroups in store

      const {
        projects,
        sites
      } = result.portfolio;
      yield (0, _effects.call)(setUserSites, projects, sites);
    } catch (err) {
      console.error("Something went wrong setting user projects: ".concat(err));
    }
  }
}

function* userSitesFlow() {
  while (true) {
    const loadUserSitesTask = yield (0, _effects.fork)(loadUserSitesFlow);
    yield (0, _effects.take)(_actionTypes2.LOAD_USER_SITES_COMPLETE);
    yield (0, _effects.take)(_actionTypes.LOGOUT_SUCCESS);
    yield loadUserSitesTask.cancel();
  }
}