"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.appSetupFlow = appSetupFlow;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _effects = require("redux-saga/effects");

var _reduxPersist = require("redux-persist");

var _axios = _interopRequireDefault(require("axios"));

var _queryStringForAll = _interopRequireDefault(require("query-string-for-all"));

var _browserHistory = require("../../../browserHistory");

var _actionCreators = require("../auth/actionCreators");

var _actionTypes = require("../auth/actionTypes");

var _actionTypes2 = require("./actionTypes");

var _actionTypes3 = require("../userSites/actionTypes");

var _selections = require("../_BETA/selections");

var _options = require("../../../components/Analysis/utils/options");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * Flow for parsing out:
 * ?dateRange=
 * ?sites=
 * (monitoring|insights)/:siteId/
 * Captures requested params from url and appends valid
 * params to app state during app setup
 */
function* handleUrlParamsFlow() {
  // If no sites in the url, grab em from the store and append the siteId to the end of URL
  const qs = _queryStringForAll.default.parse(_browserHistory.history.location.search, {
    parseNumbers: true,
    sort: false
  }); // HACK: Have to account for equip view, where siteId is a url parameter


  const equipViewRE = /\/analysis\/(:?insights|monitoring)\/(.+)\/\d+/;
  const urlSites = new Set();
  const siteParam = equipViewRE.exec(window.location.pathname); // if daysRange in search, and value is valid, update state
  // otherwise use default

  if ("daysRange" in qs && _options.TIME_RANGE_OPTIONS.monitoring.find(daysRangeOption => daysRangeOption.value === Number(qs.daysRange))) {
    yield (0, _effects.put)((0, _selections.updateDaysRange)(Number(qs.daysRange)));
  } else {
    const defaultDaysRange = yield (0, _effects.select)(state => state.betaSelections.daysRange);

    const parsedDefaultDaysRangeURL = _queryStringForAll.default.stringify(_objectSpread(_objectSpread({}, qs), {}, {
      daysRange: defaultDaysRange
    }), {
      arrayFormat: "comma",
      sort: false
    });

    _browserHistory.history.replace({
      pathname: _browserHistory.history.location.pathname,
      search: parsedDefaultDaysRangeURL
    });
  } // Check for siteId param (equp view url)


  if (siteParam !== null) {
    urlSites.add(siteParam[2]);
  } // Check for sites qs parameter


  if ("sites" in qs) {
    const arr = qs.sites.split(",");
    arr.forEach(s => urlSites.add(s));
  } // Validate sites from URL (params + search), and append to selected sites in store


  if (urlSites.size === 0) {
    const {
      selectedSites
    } = yield (0, _effects.select)(state => state.betaSelections);

    const parsedDefaultSitesURL = _queryStringForAll.default.stringify(_objectSpread(_objectSpread({}, qs), {}, {
      sites: selectedSites
    }), {
      arrayFormat: "comma",
      sort: false
    });

    _browserHistory.history.replace({
      pathname: _browserHistory.history.location.pathname,
      search: parsedDefaultSitesURL
    });

    yield (0, _effects.put)((0, _selections.selectActiveSite)(selectedSites[0]));
  } else {
    const {
      sites
    } = yield (0, _effects.select)(state => state.auth.user.portfolio);
    const {
      selectedSites
    } = yield (0, _effects.select)(state => state.betaSelections);
    const userSelectedSites = new Set([...Array.from(urlSites), ...selectedSites]);
    const siteIdsFromUrl = Array.from(userSelectedSites); // Validate requested sites against user access
    // Filter out invalid sites and log error (if invalid sites present)

    const userSiteIds = Object.keys(sites);
    const validatedSiteIds = siteIdsFromUrl.filter(siteIdFromUrl => userSiteIds.includes(siteIdFromUrl));
    const userCanAccessSitesFromUrl = validatedSiteIds.length === siteIdsFromUrl.length;
    yield (0, _effects.put)((0, _selections.updateSelectedSites)(validatedSiteIds));

    if (siteParam !== null && validatedSiteIds.includes(siteParam[2])) {
      yield (0, _effects.put)((0, _selections.selectActiveSite)(siteParam[2]));
    } else {
      yield (0, _effects.put)((0, _selections.selectActiveSite)(validatedSiteIds[0]));
    }

    try {
      if (!userCanAccessSitesFromUrl) {
        const validatedSearchParams = _queryStringForAll.default.stringify(_objectSpread(_objectSpread({}, qs), {}, {
          sites: validatedSiteIds
        }), {
          arrayFormat: "comma",
          sort: false
        });

        _browserHistory.history.replace({
          pathname: _browserHistory.history.location.pathname,
          search: validatedSearchParams
        });

        throw new Error("User doesn't have permission");
      }
    } catch (e) {
      console.error(e);
    }
  }
}

function* appSetupFlow() {
  yield (0, _effects.take)(_reduxPersist.REHYDRATE);

  while (true) {
    const {
      loading,
      loaded,
      user
    } = yield (0, _effects.select)(state => state.auth);

    if (user && user.token && !loaded && !loading) {
      yield (0, _effects.put)((0, _actionCreators.load)(user));
    } else {
      // Integrate the LOGIN_KEYCLOAK action in the saga
      // which will be followed by setting the state to the authenticated user
      yield (0, _effects.take)(_actionTypes.LOGIN_KEYCLOAK);
      const {
        user: loggedInUser
      } = yield (0, _effects.select)(state => state.auth);
      yield (0, _effects.put)((0, _actionCreators.load)(loggedInUser));
    }

    const {
      authUserSuccess,
      authUserFailure
    } = yield (0, _effects.race)({
      authUserSuccess: (0, _effects.take)(_actionTypes.LOAD_AUTH_USER_SUCCESS),
      authUserFailure: (0, _effects.take)(_actionTypes.LOAD_AUTH_USER_FAIL)
    });

    if (authUserSuccess) {
      const {
        user: authenticatedUser
      } = yield (0, _effects.select)(state => state.auth); // Append auth header to axios after user validation

      _axios.default.defaults.headers.common["Authorization"] = "Bearer ".concat(authenticatedUser.token); // Wait for user sites to be loaded

      yield (0, _effects.take)(_actionTypes3.LOAD_USER_SITES_COMPLETE); // Grab any url sites/date ranges and apply to app state

      yield (0, _effects.call)(handleUrlParamsFlow);
      yield (0, _effects.put)({
        type: _actionTypes2.APP_SETUP_COMPLETE
      });
      yield (0, _effects.take)(_actionTypes.LOGOUT_SUCCESS);
      yield (0, _effects.put)({
        type: _actionTypes2.APP_SETUP_RESET
      }); // Redirect to login after logout
      // NOTE: We're intentionally doing a hard redirect to reset all sagas
      // If another flow has already handled the redirect, just refresh the page.

      if (window.location.pathname === "/login") {
        window.location.reload();
      } else {
        const redirect = "/login"; // @ts-ignore

        window.location = redirect;
      }
    }

    if (authUserFailure) {
      yield (0, _effects.put)({
        type: _actionTypes2.APP_SETUP_ERROR,
        error: "Failed to authenticate logged in user"
      });
    }
  }
}