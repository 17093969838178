"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortByAlpha = sortByAlpha;
exports.sortByInsights = exports.sortByEquips = exports.sortByHits = void 0;

var _lodash = _interopRequireDefault(require("lodash.orderby"));

var _types = require("./types");

const sortByHits = groups => {
  return (0, _lodash.default)(groups, g => g.reduce((hits, rule) => hits + rule.activeHits, 0), "desc");
};

exports.sortByHits = sortByHits;

const sortByEquips = groups => {
  return (0, _lodash.default)(groups, g => g.reduce((total, rule) => total + rule.targetCount, 0), "desc");
};

exports.sortByEquips = sortByEquips;

const sortByInsights = (groups, direction) => {
  return (0, _lodash.default)(groups, g => g.length, direction);
};

exports.sortByInsights = sortByInsights;

function sortByAlpha(groups, groupBy, direction) {
  if (groupBy === _types.GroupBy.Rule) {
    return (0, _lodash.default)(groups, g => "".concat(g[0].ruleName).toLowerCase(), direction);
  }

  if (groupBy === _types.GroupBy.Site) {
    return (0, _lodash.default)(groups, g => "".concat(g[0].site.label).toLowerCase(), direction);
  }

  if (groupBy === _types.GroupBy.Priority) {
    return (0, _lodash.default)(groups, g => "".concat(g[0].priority).toLowerCase(), direction);
  }

  return groups;
}