"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FieldLabel = exports.CheckboxWrap = exports.RuleName = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3;

const RuleName = (0, _styledComponents.default)(_blocks.DisplaySmall)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  margin-bottom: ", ";\n"])), _blocks.spacing.default);
exports.RuleName = RuleName;

const CheckboxWrap = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  margin-bottom: ", ";\n"])), _blocks.spacing.default);

exports.CheckboxWrap = CheckboxWrap;
const FieldLabel = (0, _styledComponents.default)(_blocks.Body)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  margin-bottom: ", ";\n  font-weight: 700;\n"])), _blocks.spacing.tight);
exports.FieldLabel = FieldLabel;