"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createStateHook = void 0;

var _react = require("react");

/**
 * Create a state hook
 */
const createStateHook = stateContext => {
  return () => {
    const state = (0, _react.useContext)(stateContext);

    if (state === undefined) {
      throw new Error("useState must be used within a Provider");
    }

    return state;
  };
};

exports.createStateHook = createStateHook;