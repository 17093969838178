"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Clear = exports.Building = exports.IconWrapper = exports.TitleSite = exports.GlobalSite = exports.Triangle = exports.ColumnContainer = exports.MenuContainer = exports.Search = exports.SearchWrapper = exports.MenuWrapper = exports.LoadingContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _App = require("../../components/AppChrome/App.constants");

var _Search = _interopRequireDefault(require("./components/Search"));

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;

const LoadingContainer = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  justify-content: space-around;\n  align-items: center;\n  height: calc(100% - 45px);\n"])));

exports.LoadingContainer = LoadingContainer;

const MenuWrapper = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  position: relative;\n  margin-left: ", ";\n"])), _blocks.spacing.extraLoose);

exports.MenuWrapper = MenuWrapper;

const SearchWrapper = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  padding: ", " ", ";\n  border-bottom: 1px solid ", ";\n  background: ", ";\n"])), _blocks.spacing.snug, _blocks.spacing.default, _blocks.color.theme.divider.solid, _blocks.palette.neutral2);

exports.SearchWrapper = SearchWrapper;
const Search = (0, _styledComponents.default)(_Search.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  flex-basis: calc(100% / 1.5);\n\n  input {\n    height: 32px;\n  }\n"])));
exports.Search = Search;

const MenuContainer = _styledComponents.default.div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  display: ", ";\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 760px;\n  min-height: 400px;\n  border-radius: ", ";\n  border: 1px solid ", ";\n  box-shadow: ", ";\n  z-index: ", ";\n  background: ", ";\n"])), _ref => {
  let {
    isOpen
  } = _ref;
  return isOpen ? "block" : "none";
}, _blocks.borderRadius.default, _blocks.color.theme.divider.solid, _blocks.elevation.contentNavigation, _blocks.layer.contextNavigation, _blocks.color.pureWhite);

exports.MenuContainer = MenuContainer;

const ColumnContainer = _styledComponents.default.div(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  height: 344px;\n  border-bottom: 1px solid ", ";\n\n  .Column {\n    display: inline-flex;\n    flex-direction: column;\n    width: ", "%;\n\n    border-right: solid 1px ", ";\n    overflow: hidden;\n\n    &:last-child {\n      border-right: none;\n    }\n  }\n\n  .MenuSection-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin: 0;\n    padding: ", " ", " ", ";\n  }\n\n  h2 {\n    margin: 0;\n    font-size: 12px;\n    font-weight: ", ";\n    text-transform: uppercase;\n    color: ", ";\n  }\n\n  .MenuSection-list {\n    height: 100%;\n    width: 100%;\n    padding: 0;\n    overflow-y: scroll;\n  }\n\n  .MenuSection-inner {\n    height: 100%;\n  }\n"])), _blocks.color.theme.divider.solid, 100 / 3, _blocks.color.theme.divider.solid, _blocks.spacing.default, _blocks.spacing.default, _blocks.spacing.snug, _App.TYPOGRAPHY.WEIGHTS.BOLD, _blocks.palette.neutral8);

exports.ColumnContainer = ColumnContainer;
const Triangle = (0, _styledComponents.default)(_blocks.ArrowDropdownDown)(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n  display: inline-block;\n  position: relative;\n  margin-left: ", ";\n  fill: ", ";\n  width: 10px;\n"])), _blocks.spacing.tight, _blocks.color.theme.white);
exports.Triangle = Triangle;

const GlobalSite = _styledComponents.default.button(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\n  display: inline-flex;\n  justify-content: flex-start;\n  align-items: center;\n  height: ", ";\n  padding: 0;\n  border: none;\n  outline: 0;\n  background: transparent;\n"])), _App.AVATARS.BASE);

exports.GlobalSite = GlobalSite;

const TitleSite = _styledComponents.default.div(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\n  display: inline-block;\n  position: relative;\n  background: none;\n  ", "\n\n  .ActiveSite-site {\n    display: block;\n    color: ", ";\n  }\n"])), _blocks.typography.displaySmall, _blocks.color.theme.white);

exports.TitleSite = TitleSite;

const IconWrapper = _styledComponents.default.div(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2.default)(["\n  display: inline-flex;\n  justify-content: space-around;\n  align-items: center;\n  margin-right: ", ";\n  height: ", ";\n  width: ", ";\n  border-radius: 50%;\n  background: rgba(", ", 0.1);\n"])), _blocks.spacing.tight, _App.AVATARS.BASE, _App.AVATARS.BASE, _blocks.color.pureWhite);

exports.IconWrapper = IconWrapper;
const Building = (0, _styledComponents.default)(_blocks.Site)(_templateObject11 || (_templateObject11 = (0, _taggedTemplateLiteral2.default)(["\n  display: inline-block;\n  fill: ", ";\n  background: transparent;\n"])), _blocks.color.theme.white);
exports.Building = Building;
const Clear = (0, _styledComponents.default)(_blocks.Button)(_templateObject12 || (_templateObject12 = (0, _taggedTemplateLiteral2.default)(["\n  text-transform: uppercase;\n  font-size: 12px;\n  color: ", ";\n"])), _blocks.palette.neutral8);
exports.Clear = Clear;