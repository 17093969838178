"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.siteSelectEvent = siteSelectEvent;
exports.standardRangeClickEvent = exports.zoneChartClickEvent = exports.chartClickEvent = exports.orderByEvent = exports.groupByEvent = exports.sortEvent = exports.timeFrameEvent = exports.routeChangeEvent = exports.expandAllEvent = exports.messagePostedEvent = exports.associatedInsightWithActionEvent = exports.savedActionEvent = exports.addedActionEvent = exports.searchedEvent = exports.snoozeEquipEvent = exports.clickedTrendGraphEvent = exports.viewedInsightEvent = exports.userLogin = void 0;

var _segment = _interopRequireDefault(require("./segment"));

var _eventTrackingHelper = require("./eventTrackingHelper");

/**
 * User access events
 */
const userLogin = () => {
  try {
    _segment.default.track("User - Login");
  } catch (err) {
    console.error("Segment Error: userLogin - ".concat(err));
  }
};
/**
 * Segment.io tracking calls for action events
 */


exports.userLogin = userLogin;

const viewedInsightEvent = ruleGroup => {
  try {
    _segment.default.track("Viewed Insight", (0, _eventTrackingHelper.parseRuleViewedEventPayload)(ruleGroup));
  } catch (err) {
    console.error("Segment Error: viewedInsightEvent - ".concat(err));
  }
};

exports.viewedInsightEvent = viewedInsightEvent;

const clickedTrendGraphEvent = (asset, ruleName, isAssociated) => {
  try {
    _segment.default.track("Clicked Link To Trend Graph", (0, _eventTrackingHelper.parseReportViewedEventPayload)(asset, ruleName, isAssociated));
  } catch (err) {
    console.error("Segment Error: clickedTrendGraphEvent - ".concat(err));
  }
};

exports.clickedTrendGraphEvent = clickedTrendGraphEvent;

const snoozeEquipEvent = (siteName, duration, equipRuleId, successResult) => {
  try {
    _segment.default.track("Snoozed Equip", (0, _eventTrackingHelper.parseSnoozeEquipRuleEventPayload)(siteName, duration, equipRuleId, successResult));
  } catch (err) {
    console.error("Segment Error: snoozeEquipEvent - ".concat(err));
  }
};

exports.snoozeEquipEvent = snoozeEquipEvent;

const searchedEvent = () =>
/* searchName, searchString */
{// TODO: Reinstate once search tracking behaviour has been finalised.
  // try {
  //   if (searchString) {
  //     segment.track(`Searched ${searchName}`, {
  //       query: searchString
  //     })
  //   }
  // } catch (err) {
  //   console.error(`Segment Error: searchedEvent - ${err}`)
  // }
};

exports.searchedEvent = searchedEvent;

function siteSelectEvent(selected) {
  let userSites = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  let userProjects = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

  try {
    const siteCount = selected.length;
    const projectIds = selected.map(id => userSites[id].projectId); // Unique project names from selected sites

    const projects = Array.from(new Set(projectIds.map(id => userProjects[id].project_name)));
    const projectCount = projects.length;

    _segment.default.track("Updated Site Select", {
      projects,
      projectCount,
      siteCount
    });
  } catch (err) {
    console.error("Segment Error: siteSelectEvent - ".concat(err));
  }
}
/**
 * FYI: This event currently used for create action in Insights and Add action in Actions scene
 */


const addedActionEvent = function addedActionEvent(issueKey, issue) {
  let site = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  let associateResult = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  let associateError = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;

  try {
    _segment.default.track("Added Action", (0, _eventTrackingHelper.parseAddedActionEventPayload)(issueKey, issue, site, associateResult, associateError));
  } catch (err) {
    console.error("Segment Error: addedActionEvent - ".concat(err));
  }
};

exports.addedActionEvent = addedActionEvent;

const savedActionEvent = (action, actionId, updatedAction) => {
  try {
    _segment.default.track("Action Saved", (0, _eventTrackingHelper.parseActionSavedEventPayload)(action, actionId, updatedAction));
  } catch (err) {
    console.error("Segment Error: savedActionEvent - ".concat(err));
  }
};

exports.savedActionEvent = savedActionEvent;

const associatedInsightWithActionEvent = (betaIssues, successResult) => {
  try {
    _segment.default.track("Associated Insight With Action", (0, _eventTrackingHelper.parseAssociateInsightWithActionEventPayload)(betaIssues, successResult));
  } catch (err) {
    console.error("Segment Error: associatedInsightWithActionEvent - ".concat(err));
  }
};

exports.associatedInsightWithActionEvent = associatedInsightWithActionEvent;

const messagePostedEvent = (issue, commentResult) => {
  try {
    _segment.default.track("Message Posted", (0, _eventTrackingHelper.parsePostCommentEventPayload)(issue, commentResult));
  } catch (err) {
    console.error("Segment Error: messagePostedEvent - ".concat(err));
  }
};

exports.messagePostedEvent = messagePostedEvent;

const expandAllEvent = expanded => {
  try {
    _segment.default.track("Expanded/Collapsed Monitoring List", {
      intent: expanded ? "expand" : "collapse"
    });
  } catch (err) {
    console.error("Segment Error: expandAllEvent - ".concat(err));
  }
};

exports.expandAllEvent = expandAllEvent;

const routeChangeEvent = _ref => {
  let {
    search: rawSearch,
    pathname
  } = _ref;

  try {
    const search = (0, _eventTrackingHelper.stripSitesQuery)(rawSearch);

    _segment.default.track("Page Viewed", {
      search,
      pathname
    });
  } catch (err) {
    console.error("Segment Error: expandAllEvent - ".concat(err));
  }
};
/**
 * * PERFORMANCE REPORT TRACKING
 */
// * Track when the user selects a new time frame for workflow and comfort reports


exports.routeChangeEvent = routeChangeEvent;

const timeFrameEvent = (_ref2, eventName) => {
  let {
    timeFrameOption
  } = _ref2;
  const timeFrame = timeFrameOption === 0 ? "current month" : "last ".concat(timeFrameOption, " month(s)");

  try {
    _segment.default.track(eventName, {
      timeFrame
    });
  } catch (err) {
    console.error("Segment Error: timeFrameEvent - ".concat(err));
  }
}; // * Track when the user changes datagrid sort


exports.timeFrameEvent = timeFrameEvent;

const sortEvent = (_ref3, eventName) => {
  let {
    chartTitle,
    columnTitle,
    direction
  } = _ref3;

  try {
    _segment.default.track(eventName, {
      columnTitle: columnTitle.toLowerCase(),
      direction: direction.toLowerCase(),
      chartTitle: chartTitle.toLowerCase()
    });
  } catch (err) {
    console.error("Segment Error: sortEvent - ".concat(err));
  }
}; // * Track when user changes chart Group By option


exports.sortEvent = sortEvent;

const groupByEvent = (_ref4, eventName) => {
  let {
    chartTitle,
    chartGrouping
  } = _ref4;

  try {
    _segment.default.track(eventName, {
      chartTitle: chartTitle.toLowerCase(),
      chartGrouping: chartGrouping.toLowerCase()
    });
  } catch (err) {
    console.error("Segment Error: groupByEvent - ".concat(err));
  }
}; // * Track when user changes chart Order By option


exports.groupByEvent = groupByEvent;

const orderByEvent = (_ref5, eventName) => {
  let {
    chartTitle,
    chartOrder
  } = _ref5;

  try {
    _segment.default.track(eventName, {
      chartTitle: chartTitle.toLowerCase(),
      chartOrder: chartOrder.toLowerCase()
    });
  } catch (err) {
    console.error("Segment Error: orderByEvent - ".concat(err));
  }
};
/**
 * @param {any} d
 * @param {SingleBar[]} bars
 * @param {Sorting} sortDir
 */


exports.orderByEvent = orderByEvent;

const chartClickEvent = (_ref6, eventName) => {
  let {
    chartTitle,
    d,
    bars,
    sortDir
  } = _ref6;
  const bar = bars[d.index];
  const barsCount = bars.length;
  const chartOrder = sortDir === "asc" ? "least to most" : "most to least";
  const barRankPosition = d.index + 1;
  const first = bars[0].value;
  const last = bars[barsCount - 1].value;

  try {
    _segment.default.track(eventName, {
      chartTitle: chartTitle.toLowerCase(),
      chartOrder,
      barId: bar.id.toLowerCase(),
      barLabel: bar.label.toLowerCase(),
      barValue: bar.value,
      barRankPosition,
      barsCount,
      barsValuesMin: sortDir === "asc" ? first : last,
      barsValuesMax: sortDir === "asc" ? last : first
    });
  } catch (err) {
    console.error("Segment Error: chartClickEvent - ".concat(err));
  }
};
/**
 * @param {any} d
 * @param {SingleBar[]} bars
 * @param {Sorting} sortDir
 */


exports.chartClickEvent = chartClickEvent;

const zoneChartClickEvent = (_ref7, eventName) => {
  let {
    chartTitle,
    d,
    bars,
    sortDir
  } = _ref7;
  const bar = bars[d.index];
  const barsCount = bars.length;
  const chartOrder = sortDir === "worst" ? "least to most" : "most to least";
  const barRankPosition = d.index + 1;
  const first = bars[0].value;
  const last = bars[barsCount - 1].value;

  try {
    _segment.default.track(eventName, {
      chartTitle: chartTitle.toLowerCase(),
      chartOrder,
      barId: d.categoryLabel.toLowerCase(),
      barLabel: d.categoryLabel.toLowerCase(),
      barValue: bar.value,
      barRankPosition,
      barsCount,
      barsValuesMin: sortDir === "asc" ? first : last,
      barsValuesMax: sortDir === "asc" ? last : first
    });
  } catch (err) {
    console.error("Segment Error: zoneChartClickEvent - ".concat(err));
  }
};

exports.zoneChartClickEvent = zoneChartClickEvent;

const standardRangeClickEvent = (_ref8, eventName) => {
  let {
    chartTitle,
    chartDefinedRange
  } = _ref8;

  try {
    _segment.default.track(eventName, {
      chartTitle: chartTitle.toLowerCase(),
      chartDefinedRange: chartDefinedRange.toLowerCase()
    });
  } catch (err) {
    console.error("Segment Error: standardRangeClickEvent - ".concat(err));
  }
};

exports.standardRangeClickEvent = standardRangeClickEvent;