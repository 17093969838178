"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOAD_GROUPS_FAIL = exports.LOAD_GROUPS_SUCCESS = exports.LOAD_GROUPS = void 0;
const LOAD_GROUPS = "bonfire/lookups/LOAD_GROUPS";
exports.LOAD_GROUPS = LOAD_GROUPS;
const LOAD_GROUPS_SUCCESS = "bonfire/lookups/LOAD_GROUPS_SUCCESS";
exports.LOAD_GROUPS_SUCCESS = LOAD_GROUPS_SUCCESS;
const LOAD_GROUPS_FAIL = "bonfire/lookups/LOAD_GROUPS_FAIL";
exports.LOAD_GROUPS_FAIL = LOAD_GROUPS_FAIL;