"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWorkflowEffects = useWorkflowEffects;

var React = _interopRequireWildcard(require("react"));

var _workflow = require("../../../../context/reports/workflow");

var _useActions = require("../../../../context/reports/workflow/useActions");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function useWorkflowEffects() {
  const [state // dispatch
  ] = (0, _workflow.useWorkflow)();
  const {
    getSummary,
    getMetrics
  } = (0, _useActions.useActions)();
  const requestSummary = React.useCallback(() => {
    void getSummary(state.metrics.currentMetricsType, state.sites, state.startDate, state.endDate);
  }, [state.metrics.currentMetricsType, state.sites, state.startDate, state.endDate]);
  const requestMetrics = React.useCallback(() => {
    void getMetrics(state.metrics.currentMetricsType, state.active, state.startDate, state.endDate);
  }, [state.metrics.currentMetricsType, state.active, state.startDate, state.endDate]);
  /**
   * Track initial load state
   * If either metrics or summary are still
   * loading, first load has not yet complete
   */

  const firstLoad = React.useRef(true);
  React.useEffect(() => {
    firstLoad.current = !state.summaryLoaded || !state.metricsLoaded;
  }, [state.summaryLoaded && state.metricsLoaded]);
  /**
   * Handle initial load requests
   * Wait for sites to exist in state and,
   * if summary/metrics aren't loaded, load em
   */

  React.useEffect(() => {
    // If no sites are loaded, skip
    if (state.sites.size === 0) return;

    if (!state.summaryLoaded && !state.summaryLoading) {
      requestSummary();
    }

    if (!state.metricsLoaded && !state.metricsLoading) {
      requestMetrics();
    }
  }, [state.summaryLoaded, state.metricsLoaded, state.sites]);
  /**
   * After initial load, wait for changes to
   * `shouldUpdateSummary` and `shouldUpdateMetrics`
   * and request when appropriate
   */

  React.useEffect(() => {
    if (state.sites.size === 0 || firstLoad.current) return;

    if (state.shouldUpdateSummary && !state.summaryLoading) {
      requestSummary();
    }

    if (state.shouldUpdateMetrics && !state.metricsLoading) {
      requestMetrics();
    }
  }, [state.summaryLoading, state.metricsLoading, state.shouldUpdateSummary, state.shouldUpdateMetrics]);
}