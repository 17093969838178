"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActions = useActions;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var React = _interopRequireWildcard(require("react"));

var _lodash = _interopRequireDefault(require("lodash.isequal"));

var _axios = _interopRequireDefault(require("axios"));

var _ = require(".");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function useActions() {
  const dispatch = (0, _.useConfigDispatch)();
  const updateConfigRules = React.useCallback(function updateConfigRules(rule) {
    dispatch({
      type: "UPDATE_CONFIG_RULES",
      rule
    });
  }, []);
  const updateInsightType = React.useCallback(function updateInsightType(insightType) {
    dispatch({
      type: "UPDATE_INSIGHT_TYPE",
      insightType
    });
  }, []);
  const updateActiveProject = React.useCallback(function updateActiveProject(activeProject) {
    dispatch({
      type: "UPDATE_ACTIVE_PROJECT",
      activeProject
    });
  }, []);
  const setSelectedRule = React.useCallback(function setSelectedRule(selectedRule) {
    dispatch({
      type: "SET_SELECTED_RULE",
      selectedRule
    });
  }, []);
  /**
   * Update single rule
   */

  const updateRuleConfig = React.useCallback(function updateRuleConfig(rule) {
    dispatch({
      type: "UPDATE_RULE_CONFIG",
      rule
    });
  }, []);
  /**
   * Update multiple rules at one
   */

  const bulkUpdateRuleConfig = React.useCallback(function bulkUpdateRuleConfig(rules) {
    dispatch({
      type: "BULK_UPDATE_RULE_CONFIG",
      rules
    });
  }, []);
  /**
   * Discard `active` state and reset `active`
   * to current `saved` state
   */

  const resetConfig = React.useCallback(function resetConfig() {
    dispatch({
      type: "RESET_CONFIG"
    });
  }, []);
  const getProjectConfig = React.useCallback(async function getProjectConfig(projectKey, insightType) {
    dispatch({
      type: "LOAD_CONFIG"
    });

    try {
      const request = await _axios.default.post("/v1/project-rules/search?type=".concat(insightType), {
        projectKeys: [projectKey]
      });

      if (request.status === 200) {
        const saved = Object.entries(request.data).reduce((acc, _ref) => {
          let [key, val] = _ref;
          acc[key] = _objectSpread(_objectSpread({}, val[0]), {}, {
            rule: _objectSpread(_objectSpread({}, val[0].rule), {}, {
              id: Number(val[0].rule.id)
            })
          });
          return acc;
        }, {});
        dispatch({
          type: "LOAD_CONFIG_SUCCESS",
          saved
        });
      } else {
        dispatch({
          type: "LOAD_CONFIG_FAIL"
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, []);
  const saveProjectConfig = React.useCallback(async function saveProjectConfig(projectKey, saved, active, insightType) {
    // * Sets the `isSaving` prop
    dispatch({
      type: "SAVE_CONFIG"
    }); // * If rule exists in `saved` but doesn't in `active`
    // * add the `id` from saved to array of rules to disable

    const disable = Object.entries(saved).reduce((del, _ref2) => {
      let [key, val] = _ref2;

      if (active[Number(key)] === undefined) {
        del.push(val.id);
      }

      return del;
    }, []); // * If rule doesn't match a saved rule (i.e., undefined or different properties)
    // * add rule to config payload

    const enable = Object.values(active).reduce((acc, rule) => {
      if (rule && !(0, _lodash.default)(saved[rule.rule.id], rule)) {
        acc.push(_objectSpread(_objectSpread({}, rule), {}, {
          projectKey: projectKey,
          type: insightType
        }));
        return acc;
      }

      return acc;
    }, []);

    try {
      const response = await _axios.default.post("/v1/project-rules/update", {
        enable,
        disable
      });

      if (response.status === 200) {
        // * Fetch the latest up to date saved config
        void getProjectConfig(projectKey, insightType);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "LOAD_CONFIG_FAIL"
      });
    }
  }, []);
  return {
    updateConfigRules,
    updateInsightType,
    updateActiveProject,
    setSelectedRule,
    updateRuleConfig,
    bulkUpdateRuleConfig,
    resetConfig,
    getProjectConfig,
    saveProjectConfig
  };
}