"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadRulesFlow = loadRulesFlow;
exports.loadActionFlow = loadActionFlow;
exports.loadInitialActionFlow = loadInitialActionFlow;
exports.uploadScreenshot = uploadScreenshot;
exports.saveAction = saveAction;
exports.addAction = addAction;
exports.addActionFlow = addActionFlow;
exports.saveActionFlow = saveActionFlow;

var _effects = require("redux-saga/effects");

var actionTypes = _interopRequireWildcard(require("./actionTypes"));

var attachmentActionTypes = _interopRequireWildcard(require("../attachments/actionTypes"));

var selectors = _interopRequireWildcard(require("./selectors"));

var _actionCreators = require("./actionCreators");

var _serialiseAction = require("./utils/serialiseAction");

var _actionCreators2 = require("../attachments/actionCreators");

var _formatEquipList = require("./utils/formatEquipList");

var _unassociate = require("../_BETA/unassociate");

var _browserHistory = require("../../../browserHistory");

var _eventTracking = require("../../../utils/eventTracking");

var _issues = require("../issues");

var _associate = require("../_BETA/associate");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

/**
 * Saga triggered when loading in an existing action to be edited
 */
function* loadRulesFlow() {
  while (true) {
    yield (0, _effects.take)(actionTypes.LOAD_RULES);
    const token = yield (0, _effects.select)(selectors.getUserToken);
    yield (0, _effects.put)((0, _actionCreators.loadRulesRequestChain)(token));
    const {
      failure
    } = yield (0, _effects.race)({
      success: (0, _effects.take)(actionTypes.LOAD_RULES_REQUEST_SUCCESS),
      failure: (0, _effects.take)(actionTypes.LOAD_RULES_REQUEST_FAIL)
    }); // TODO: Redirect to 404/error?

    if (failure) {
      throw Error(failure.error.message);
    }
  }
}
/**
 * Saga triggered by `LOAD_ACTION` that _only_ loads only the action data
 */


function* loadActionFlow() {
  while (true) {
    const {
      actionId
    } = yield (0, _effects.take)(actionTypes.LOAD_ACTION);
    const token = yield (0, _effects.select)(selectors.getUserToken); // Dispatch all requests at the same time

    yield (0, _effects.put)((0, _actionCreators.loadActionRequestChain)(actionId, token));
    yield (0, _effects.put)((0, _actionCreators.loadCommentsRequestChain)(actionId, token)); // Unblock each step by racing them all

    const [{
      success: action,
      failure: actionFailure
    }, {
      success: comments,
      failure: commentsFailure
    }] = yield (0, _effects.all)([(0, _effects.race)({
      success: (0, _effects.take)(actionTypes.LOAD_ACTION_REQUEST_SUCCESS),
      failure: (0, _effects.take)(actionTypes.LOAD_ACTION_REQUEST_FAIL)
    }), (0, _effects.race)({
      success: (0, _effects.take)(actionTypes.LOAD_COMMENTS_REQUEST_SUCCESS),
      failure: (0, _effects.take)(actionTypes.LOAD_COMMENTS_REQUEST_FAIL)
    })]);

    try {
      if (actionFailure) throw Error(actionFailure.error.message);
      if (commentsFailure) throw Error(commentsFailure.error.message);
    } catch (error) {
      console.error(error);
    } // TODO: Toastr error handling?


    if (action && comments) {
      // Grab the username list for the add comment tagging
      yield (0, _effects.put)((0, _issues.getUsernameList)({
        token
      }, action.result));
    }
  }
}
/**
 * Saga triggered by `LOAD_ACTION_FOR_EDITING` for loading both the action data
 * and the rule data
 */


function* loadInitialActionFlow() {
  while (true) {
    // take the initial action + setup
    const {
      actionId
    } = yield (0, _effects.take)(actionTypes.LOAD_ACTION_FOR_EDITING);
    const token = yield (0, _effects.select)(selectors.getUserToken); // load the initial action data

    yield (0, _effects.put)((0, _actionCreators.loadActionRequestChain)(actionId, token));
    const {
      failure
    } = yield (0, _effects.race)({
      success: (0, _effects.take)(actionTypes.LOAD_ACTION_REQUEST_SUCCESS),
      failure: (0, _effects.take)(actionTypes.LOAD_ACTION_REQUEST_FAIL)
    });

    if (failure) {
      throw Error(failure.error.message);
    }

    yield (0, _effects.put)((0, _actionCreators.loadRulesRequestChain)(token));
    const {
      rulesLoadFail
    } = yield (0, _effects.race)({
      success: (0, _effects.take)(actionTypes.LOAD_RULES_REQUEST_SUCCESS),
      rulesLoadFail: (0, _effects.take)(actionTypes.LOAD_RULES_REQUEST_FAIL)
    });

    if (rulesLoadFail) {
      throw Error(rulesLoadFail.error.message);
    }
  }
}
/**
 * Upload and retreive urls for an image. Only exposed for tests, this generator
 * is used internally.
 * @param {string} id
 * @param {File} file
 */


function* uploadScreenshot(id, file) {
  const token = yield (0, _effects.select)(selectors.getUserToken);
  const {
    name,
    type
  } = file; // get the signed urls
  // NOTE: The auth on this endpoint is non-existent in captain

  yield (0, _effects.put)((0, _actionCreators2.getSignedUrl)({
    token
  }, id, name, type));
  const {
    signSuccess,
    signFailure
  } = yield (0, _effects.race)({
    signSuccess: (0, _effects.take)(attachmentActionTypes.GET_SIGNED_URL_SUCCESS),
    signFailure: (0, _effects.take)(attachmentActionTypes.GET_SIGNED_URL_FAIL)
  });

  if (signFailure) {
    yield (0, _effects.put)((0, _actionCreators.attachmentUploadFail)("Unable to get signed URL for attachment upload"));
  } // upload the image using the put url from signing


  yield (0, _effects.put)((0, _actionCreators2.uploadToS3)(signSuccess.result.signedPutUrl, file));
  const {
    s3Success,
    s3Failure
  } = yield (0, _effects.race)({
    s3Success: (0, _effects.take)(attachmentActionTypes.UPLOAD_TO_S3_SUCCESS),
    s3Failure: (0, _effects.take)(attachmentActionTypes.UPLOAD_TO_S3_FAIL)
  });

  if (s3Failure) {
    yield (0, _effects.put)((0, _actionCreators.attachmentUploadFail)("Unable to upload attachment"));
  }

  if (s3Success) {
    yield (0, _effects.put)((0, _actionCreators.attachmentUploadSuccess)(signSuccess.result.publicReadUrl));
  }
}
/**
 * Save/updated an action to Jira and dispatch errors if necessary and redirect.
 * Only exposed for tests, this generator is used internally.
 * @param {Object} action A serialised action object
 */


function* saveAction(action) {
  const token = yield (0, _effects.select)(selectors.getUserToken);
  const actionId = yield (0, _effects.select)(selectors.getActionId);
  yield (0, _effects.put)((0, _actionCreators.saveActionRequestChain)(actionId, action, token));
  const {
    success
  } = yield (0, _effects.race)({
    success: (0, _effects.take)(actionTypes.SAVE_ACTION_REQUEST_SUCCESS),
    failure: (0, _effects.take)(actionTypes.SAVE_ACTION_REQUEST_FAIL)
  });

  if (success) {
    const updatedAction = yield (0, _effects.select)(selectors.getAction);
    (0, _eventTracking.savedActionEvent)(action, actionId, updatedAction);

    _browserHistory.history.goBack();
  }
}
/**
 * Create a new action in Jira. Only exposed for tests, this generator is used
 * internally.
 * @param {Object} action A serialised action object
 * @param {Array<number>} equipList A list of equipRule ids for associating post action creation
 */


function* addAction(action, equipList) {
  const token = yield (0, _effects.select)(selectors.getUserToken);
  yield (0, _effects.put)((0, _actionCreators.addActionRequestChain)(action, token));
  const {
    actionCreated
  } = yield (0, _effects.race)({
    actionCreated: (0, _effects.take)(actionTypes.ADD_ACTION_REQUEST_SUCCESS),
    failure: (0, _effects.take)(actionTypes.ADD_ACTION_REQUEST_FAIL)
  });

  if (actionCreated && equipList.length > 0) {
    yield (0, _effects.put)((0, _associate.postAssociateEquipRule)({
      equipRuleIds: equipList,
      issueKey: actionCreated.result.issueKey,
      token
    })); // NOTE: Wait for the association to succeed. The error for this is
    // "handled" in the associate saga (TODO: it's just thrown)

    yield (0, _effects.take)(_associate.ASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS);
  }

  if (actionCreated) {
    (0, _eventTracking.addedActionEvent)(actionCreated.result.issueKey, action);

    _browserHistory.history.goBack();
  }
}
/**
 * The saga a user will follow to create a new action in the jira backend
 */


function* addActionFlow() {
  while (true) {
    const {
      action,
      equipList = []
    } = yield (0, _effects.take)(actionTypes.ADD_ACTION); // Serialise the action into a format the create API can consume

    const serialisedAction = (0, _serialiseAction.serialiseCreateAction)(action); // compute the upload id from the serialised action
    // NOTE: This is the same behaviour to support the captain API

    const uploadId = "".concat(serialisedAction.projectKey, "-").concat(serialisedAction.issueType); // If there is no screenshot, persist use the existing serialised action

    if (action.screenshot === null) {
      yield (0, _effects.fork)(addAction, serialisedAction, equipList);
    } else {
      // Fork and watch for the completion of the upload
      yield (0, _effects.fork)(uploadScreenshot, uploadId, action.screenshot);
      const {
        uploadSuccess
      } = yield (0, _effects.race)({
        uploadSuccess: (0, _effects.take)(actionTypes.ATTACHMENT_UPLOAD_SUCCESS),
        uploadFailure: (0, _effects.take)(actionTypes.ATTACHMENT_UPLOAD_FAIL)
      }); // If the upload was a success, update the screenshotUrl with the public
      // url and persist it to Jira

      if (uploadSuccess) {
        serialisedAction.screenshotUrl = uploadSuccess.url;
        yield (0, _effects.fork)(addAction, serialisedAction, equipList);
      }
    }
  }
}
/**
 * The saga in which the user will "update" or "save" an action to the Jira
 * backend
 */


function* saveActionFlow() {
  while (true) {
    const {
      action,
      equipList = []
    } = yield (0, _effects.take)(actionTypes.SAVE_ACTION);
    const uploadId = yield (0, _effects.select)(selectors.getActionId);
    const remoteAction = yield (0, _effects.select)(selectors.getAction); // Serialise the action into a format the edit API can consume

    const serialisedAction = (0, _serialiseAction.serialiseEditAction)(action); // If a transition reason is selected, we need to add some extra properties

    if (remoteAction.status !== action.status) {
      const userName = yield (0, _effects.select)(selectors.getUsername);
      const transitionText = ["~Transition: Transitioned from ".concat(remoteAction.status, " to ").concat(action.status, ".~"), "??".concat(userName, "??")].join("\n");
      serialisedAction.taggingList = [];
      serialisedAction.comment = transitionText;

      if (action.transitionReason !== "") {
        serialisedAction.transition_reason = {
          label: action.transitionReason,
          value: action.transitionReason
        };
      }
    }

    if (equipList.length > 0 && (action.status === "Closed" || action.status === "Rejected")) {
      const equips = (0, _formatEquipList.formatEquipList)(equipList);
      const equipRuleIds = equipList.map(equip => equip.id);
      const descriptionWithEquips = "".concat(serialisedAction.description, "\n\n\n**EQUIPS**\n").concat(equips);
      serialisedAction.description = descriptionWithEquips; // Tell synco to unassociate the equips from this action
      // and wait for request to finish

      yield (0, _effects.call)(_unassociate.unassociateEquipRuleFlow, equipRuleIds);
    } // If there is no screenshot, persist use the existing serialised action


    if (action.screenshot === null) {
      yield (0, _effects.fork)(saveAction, serialisedAction);
    } else {
      // Fork and watch for the completion of the upload
      yield (0, _effects.fork)(uploadScreenshot, uploadId, action.screenshot);
      const {
        uploadSuccess
      } = yield (0, _effects.race)({
        uploadSuccess: (0, _effects.take)(actionTypes.ATTACHMENT_UPLOAD_SUCCESS),
        uploadFailure: (0, _effects.take)(actionTypes.ATTACHMENT_UPLOAD_FAIL)
      }); // If the upload was a success, update the screenshot link with the public
      // url and persist it to Jira

      if (uploadSuccess) {
        serialisedAction.screenshot_link = uploadSuccess.url;
        yield (0, _effects.fork)(saveAction, serialisedAction);
      }
    }
  }
}