"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoadingWrapper = exports.QuickLink = exports.SectionHeading = exports.Description = exports.Targets = exports.Summary = exports.Row = exports.Content = exports.Inner = exports.Wrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;

function openClose(_ref) {
  let {
    isOpen
  } = _ref;
  return isOpen ? "block" : "none";
}

const Wrapper = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: ", ";\n  position: absolute;\n  left: 100%;\n  border-radius: ", ";\n  border: 1px solid ", ";\n  height: 380px;\n  width: 240px;\n  padding-bottom: 40px;\n  z-index: 10;\n  background: ", ";\n  box-shadow: ", ";\n  transform: translate(16px, -50%);\n  cursor: default;\n  white-space: normal;\n\n  &:before {\n    display: inline-block;\n    content: \"\";\n    position: absolute;\n    width: 0;\n    height: 0;\n    left: 0;\n    top: calc(50% - 6px);\n    box-sizing: border-box;\n    border: 12px solid black;\n    border-color: transparent transparent ", " ", ";\n    transform-origin: 0 0;\n    transform: rotate(45deg);\n    box-shadow: ", ";\n    z-index: 0;\n  }\n"])), openClose, _blocks.borderRadius.default, _blocks.color.theme.divider.solid, _blocks.color.pureWhite, _blocks.elevation.modal, _blocks.color.pureWhite, _blocks.color.pureWhite, _blocks.elevation.modal);

exports.Wrapper = Wrapper;

const Inner = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background: ", ";\n"])), _blocks.color.pureWhite);

exports.Inner = Inner;

const Content = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  position: relative;\n  padding: 12px 0;\n  height: 340px;\n  overflow-y: scroll;\n  background: ", ";\n  z-index: 1;\n"])), _blocks.color.pureWhite);

exports.Content = Content;

const Row = _styledComponents.default.div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  font-size: 12px;\n  line-height: 20px;\n  padding: 0 16px;\n\n  h3 {\n    margin: 0 0 4px;\n    font-size: 14px;\n    font-weight: 600;\n    line-height: 20px;\n  }\n\n  strong {\n    font-weight: 600;\n  }\n"])));

exports.Row = Row;

const Summary = _styledComponents.default.p(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 0;\n  padding: ", ";\n  font-size: 14px;\n  line-height: 20px;\n  color: ", ";\n"])), _blocks.spacing.default, _blocks.color.theme.typography.ink);

exports.Summary = Summary;

const Targets = _styledComponents.default.div(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n  ul {\n    padding: ", " ", ";\n  }\n\n  li {\n    padding: ", " 0;\n  }\n"])), _blocks.spacing.extraTight, _blocks.spacing.default, _blocks.spacing.extraTight);

exports.Targets = Targets;

const Description = _styledComponents.default.div(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n  font-size: 13px;\n  font-weight: 400;\n  h4 {\n    margin: 0;\n    padding: 0 ", " ", ";\n    font-size: 12px;\n    font-weight: 600;\n    border-bottom: 1px solid ", ";\n  }\n\n  p {\n    margin: 0 0 ", ";\n    padding: 0 ", ";\n  }\n"])), _blocks.spacing.default, _blocks.spacing.tight, _blocks.palette.neutral4, _blocks.spacing.extraTight, _blocks.spacing.default);

exports.Description = Description;

const SectionHeading = _styledComponents.default.h4(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 0;\n  padding: 0 ", " ", ";\n  font-size: 12px;\n  font-weight: 600;\n  border-bottom: 1px solid ", ";\n"])), _blocks.spacing.default, _blocks.spacing.tight, _blocks.palette.neutral4);

exports.SectionHeading = SectionHeading;

const QuickLink = _styledComponents.default.div(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  height: 40px;\n  border-top: 1px solid ", ";\n  padding: 0 16px;\n  background: ", ";\n  z-index: 1;\n\n  a {\n    font-size: 13px;\n    line-height: 38px;\n    color: ", ";\n  }\n"])), _blocks.palette.neutral4, _blocks.color.pureWhite, _blocks.color.theme.primaryColor.action.primary);

exports.QuickLink = QuickLink;

const LoadingWrapper = _styledComponents.default.div(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n"])));

exports.LoadingWrapper = LoadingWrapper;