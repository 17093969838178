"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActions = useActions;

var React = _interopRequireWildcard(require("react"));

var _axios = _interopRequireDefault(require("axios"));

var _ = require(".");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function useActions() {
  const dispatch = (0, _.useRulesDispatch)();
  const getRules = React.useCallback(async function getRules() {
    dispatch({
      type: "LOAD_RULES"
    });

    try {
      const request = await _axios.default.get("/rules");

      if (request.status === 200) {
        // * format rules to match expected config shape
        const formatted = Array.from(Object.values(request.data)).map(rule => ({
          priority: 0,
          filterable: rule.name,
          rule: {
            id: rule.id,
            name: rule.name
          }
        }));
        const rules = new Map(formatted.map(rule => [rule.rule.id, rule]));
        dispatch({
          type: "LOAD_RULES_SUCCESS",
          rules,
          ruleList: formatted
        });
      } else {
        dispatch({
          type: "LOAD_RULES_FAIL",
          error: request.statusText
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "LOAD_RULES_FAIL",
        error
      });
    }
  }, []);
  return {
    getRules
  };
}