"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMonthAverage = exports.getMonthValue = exports.getMonthTotal = exports.sumValues = exports.getOneMonthAgo = exports.filterByMonth = void 0;

var _moment = _interopRequireDefault(require("moment"));

function matchMonth(month, year, datum) {
  if (!datum) return false;
  const d = (0, _moment.default)(datum.timestamp);
  const m = d.utc().month();
  const y = d.utc().year();
  return m === month && y === year;
}
/**
 * @param {array} data array of objects with a timestamp and one or more value properties
 * @argument {instanceOf(moment)} timestamp moment data against which to filter
 * @returns {array} Array of all objects that fall within supplied date month
 */


const filterByMonth = function filterByMonth() {
  let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let timestamp = arguments.length > 1 ? arguments[1] : undefined;
  const filterDate = (0, _moment.default)(timestamp);
  const month = filterDate.utc().month();
  const year = filterDate.utc().year();
  return data.filter(matchMonth.bind(null, month, year));
};

exports.filterByMonth = filterByMonth;

const getOneMonthAgo = dateObj => (0, _moment.default)(dateObj).subtract(1, "months").utc();

exports.getOneMonthAgo = getOneMonthAgo;

const sumValues = function sumValues() {
  let values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let key = arguments.length > 1 ? arguments[1] : undefined;
  return values.reduce((prev, curr) => prev + Number(curr[key]), 0) || 0;
};

exports.sumValues = sumValues;

const getMonthTotal = (data, date, key) => sumValues(filterByMonth(data, date), key);

exports.getMonthTotal = getMonthTotal;

function sortByDescendingTimestamp(left, right) {
  return _moment.default.utc(right.timestamp).diff(_moment.default.utc(left.timestamp));
}
/**
 * Returns a single value from the input data that matches the year & month of the input datetime.
 * If multiple objects match the input year/month, the most recent object will be selected.
 * @param {array} data Array of objects with timestamp and one or more value properties
 * @param {instanceOf(moment)} date  moment data against which to filter
 * @param {*} key Key of object to target in data for return value
 * @returns {string} Matching value for given date, or '0' if no matches are found
 */


const getMonthValue = (data, date, key) => {
  const matchList = filterByMonth(data, date).sort(sortByDescendingTimestamp);
  return matchList.length > 0 ? matchList[0][key] : 0;
};
/**
 * @param {array} data Array of objects with timestamp and one or more value properties
 * @param {instanceOf(moment)} date moment data against which to filter
 * @param {string} key Key of object to target in data for return value
 * @returns {number} Average of all values within given month from dataset
 */


exports.getMonthValue = getMonthValue;

const getMonthAverage = function getMonthAverage() {
  let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let date = arguments.length > 1 ? arguments[1] : undefined;
  let key = arguments.length > 2 ? arguments[2] : undefined;
  // If the array is empty, return 0
  if (data.length < 1) return 0; // Get month data and exit early if no data exists

  const monthData = filterByMonth(data, date);
  if (monthData.length < 1) return 0;
  const total = sumValues(monthData, key);
  return total / monthData.length;
};

exports.getMonthAverage = getMonthAverage;