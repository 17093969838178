"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.load = load;
exports.login = login;
exports.logout = logout;
exports.login_keycloak = login_keycloak;

var _actionTypes = require("./actionTypes");

// TODO: Exported as `loadAuth`
// TODO: auth state
function load(user) {
  return {
    types: [_actionTypes.LOAD_AUTH_USER, _actionTypes.LOAD_AUTH_USER_SUCCESS, _actionTypes.LOAD_AUTH_USER_FAIL],
    promise: client => client.get("/user/".concat(user.username), {
      headers: {
        Authorization: "Bearer ".concat(user.token)
      }
    })
  };
}

function login(username, password) {
  return {
    types: [_actionTypes.LOGIN, _actionTypes.LOGIN_SUCCESS, _actionTypes.LOGIN_FAIL],
    username,
    promise: client => client.post("/auth/login", {
      data: {
        username,
        password
      },
      headers: {
        "Content-Type": "application/json"
      }
    })
  };
}

function logout() {
  return {
    type: _actionTypes.LOGOUT_KEYCLOAK
  };
}

function login_keycloak(username, token) {
  return {
    type: _actionTypes.LOGIN_KEYCLOAK,
    username,
    token
  };
}