"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useInsightsEffects = useInsightsEffects;

var React = _interopRequireWildcard(require("react"));

var _lodash = _interopRequireDefault(require("lodash.groupby"));

var _lodash2 = _interopRequireDefault(require("lodash.intersection"));

var _insights = require("../../../context/insights");

var _actions = require("../../../context/insights/actions");

var _utils = require("../../../redux/modules/insights/utils");

var _occurrenceTransforms = require("../../../components/Analysis/utils/occurrenceTransforms");

var _types = require("../../../components/Analysis/utils/types");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function indexOccurrencesById(occurrences) {
  return occurrences.reduce((group, occurrence) => {
    group[occurrence.occurrenceId] = occurrence;
    return group;
  }, {});
}
/**
 * A hook that requires everything for making a new request to the insights
 * endpoint. Under the hood it's merely a flow control wrapper around the base
 * `useInsights` hook that manages state and dispatch.
 *
 * @param timeRange
 * @param selectedSites
 * @param sites
 * @param projects
 */


function useInsightsEffects() {
  let timeRange = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 3;
  let selectedSites = arguments.length > 1 ? arguments[1] : undefined;
  let shouldRequest = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  let sites = arguments.length > 3 ? arguments[3] : undefined;
  let projects = arguments.length > 4 ? arguments[4] : undefined;
  const [state, dispatch] = (0, _insights.useInsights)(); // Retrieve initial data on first load and when time range changes

  React.useEffect(() => {
    if (shouldRequest && !state.isLoading) {
      void (0, _actions.getOccurrences)(dispatch, timeRange, selectedSites);
    }
  }); // When the request has finished loading, compose occurrences

  React.useEffect(() => {
    if (!state.isLoaded || state.isLoading) return;
    const composedOccurrences = (0, _utils.composeOccurrences)(Object.values(state.rawData.hit), sites, projects); // Process siteGroups here as it's a one-off expensive operation

    const composedOccurrencesBySite = (0, _lodash.default)(composedOccurrences, o => o.siteId);
    dispatch({
      type: "SET_COMPOSED_GROUPED_OCCURRENCES",
      composedOccurrences,
      composedOccurrencesBySite
    });
  }, [state.isLoaded, state.rawData, state.isLoading]); // Process initial data once retrieved

  React.useEffect(() => {
    if (!state.isComposed || state.isLoading) return;
    const filtered = state.composedFilteredOccurrences.length > 0 ? state.composedFilteredOccurrences : state.composedOccurrences; // Group filtered occurrences into the current analysis group

    const composedOccurrencesById = indexOccurrencesById(filtered);
    const groupKey = (0, _occurrenceTransforms.mapEnumToAnalysisGroupKey)(state.groupBy);
    const categoryKey = (0, _occurrenceTransforms.mapSelectedCategoryToCategoryKey)(state.category);
    const composedAndGroupedOccurrences = Object.values(state.rawData[groupKey]).reduce((acc, ids) => {
      // remove occurrence ids that don't exist in the grouped key or in the
      // currently selected category
      const {
        category: categoryData
      } = state.rawData;
      const categorisedKeys = state.category === _types.Category.All ? ids : (0, _lodash2.default)(categoryData[categoryKey], ids);
      acc.push(categorisedKeys.filter(id => !!composedOccurrencesById[id]).map(id => composedOccurrencesById[id]));
      return acc;
    }, []).filter(arr => arr.length !== 0); // Convert analysis group to insights for render

    const composedAndGroupedInsights = (0, _occurrenceTransforms.sortInsights)((0, _occurrenceTransforms.groupOccurrencesIntoInsights)(composedAndGroupedOccurrences, state.groupBy), state.groupBy, state.sortBy); // HACK: re-sort here to put priority 0 at the bottom

    const sorted = state.groupBy !== _types.GroupBy.Priority ? composedAndGroupedInsights : composedAndGroupedInsights.sort((a, b) => {
      if (a[0].priority === 0) return 1;
      if (b[0].priority === 0) return -1;
      return 0;
    }); // Dispatch processed data and grouped data

    dispatch({
      type: "SET_PROCESSED_INSIGHTS",
      occurrences: composedAndGroupedOccurrences,
      insights: sorted
    });
  }, [state.isLoaded, state.isLoading, state.groupBy, state.search, state.isComposed, state.category]); // Synchronise composedInsights with the sortBy state

  React.useEffect(() => {
    if (!state.isProcessed) return;
    const composedAndGroupedInsights = (0, _occurrenceTransforms.sortInsights)(state.composedAndGroupedInsights, state.groupBy, state.sortBy); // HACK: re-sort here to put priority 0 at the bottom

    const sorted = state.groupBy !== _types.GroupBy.Priority ? composedAndGroupedInsights : composedAndGroupedInsights.sort((a, b) => {
      if (a[0].priority === 0) return 1;
      if (b[0].priority === 0) return -1;
      return 0;
    });
    dispatch({
      type: "SET_COMPOSED_AND_GROUPED_INSIGHTS",
      composedAndGroupedInsights: sorted
    });
  }, [state.sortBy, state.isProcessed]);
}