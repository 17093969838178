"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Opened = exports.Description = exports.Summary = exports.ID = exports.Container = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

const Container = _styledComponents.default.button.attrs({
  type: "button"
})(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: block;\n  padding: ", ";\n  width: 100%;\n  border: none;\n  outline: 0;\n  text-align: left;\n  border-bottom: 1px solid ", ";\n  background: ", ";\n\n  &:last-child {\n    border-bottom: none;\n  }\n\n  &:hover {\n    background: ", ";\n  }\n"])), _blocks.spacing.default, _blocks.color.theme.divider.solid, props => props.isSelected ? _blocks.palette.neutral2 : _blocks.color.pureWhite, _blocks.palette.neutral2);

exports.Container = Container;

const ID = _styledComponents.default.h2(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 0;\n  margin-bottom: ", ";\n  display: inline-block;\n  font-size: 12px;\n  color: ", ";\n"])), _blocks.spacing.tight, _blocks.palette.neutral7);

exports.ID = ID;

const Summary = _styledComponents.default.h3(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 0;\n  margin-bottom: ", ";\n  font-size: 14px;\n  font-weight: 700;\n"])), _blocks.spacing.tight);

exports.Summary = Summary;

const Description = _styledComponents.default.p(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 0;\n  margin-bottom: ", ";\n  font-size: 12px;\n  color: ", ";\n"])), _blocks.spacing.tight, _blocks.palette.neutral6);

exports.Description = Description;

const Opened = _styledComponents.default.span(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  display: block;\n  font-size: 12px;\n  font-weight: 400;\n  color: ", ";\n"])), _blocks.palette.neutral7);

exports.Opened = Opened;