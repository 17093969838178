"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChartMargin = exports.GridMargin = exports.Margin = exports.LeftMargin = exports.TopMargin = exports.ContentWrap = exports.PageHeader = exports.Wrap = exports.Container = exports.Page = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;

/** Implements the background for the entire page */
const Page = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  background: ", ";\n"])), _blocks.color.theme.appBackground);
/**
 * The flex child to push the entire content area to the bottom
 */


exports.Page = Page;

const Container = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  flex-grow: 1;\n"])));
/**
 * Wraps the page content to impose a maximum width, balanced margins, and
 * padding
 */


exports.Container = Container;

const Wrap = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  position: relative;\n  max-width: ", ";\n  min-width: 1200px;\n  width: 100%;\n  margin: 0 auto;\n  padding: ", ";\n"])), _blocks.dimension.maxWidth.mainContent, _blocks.spacing.extraLoose);
/**
 * Wraps the area above PageContent. Typically contains text of some sort
 * describing the page.
 */


exports.Wrap = Wrap;

const PageHeader = _styledComponents.default.div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  margin-bottom: ", ";\n  align-items: center;\n  justify-content: center;\n"])), _blocks.spacing.default);
/**
 * Typically a child of PageContent, wraps the primary content on the page and
 * applies a Frame-esque style.
 */


exports.PageHeader = PageHeader;

const ContentWrap = _styledComponents.default.div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  margin-bottom: ", ";\n  border-radius: ", ";\n  box-shadow: ", ";\n  background: ", ";\n"])), _blocks.spacing.extraLoose, _blocks.borderRadius.large, _blocks.elevation.content, _blocks.color.pureWhite);

exports.ContentWrap = ContentWrap;

const TopMargin = _styledComponents.default.div(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n  margin-top: 40px;\n"])));

exports.TopMargin = TopMargin;

const LeftMargin = _styledComponents.default.div(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n  margin-left: 30px;\n"])));

exports.LeftMargin = LeftMargin;

const Margin = _styledComponents.default.div(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 25px 0 100px 0;\n"])));

exports.Margin = Margin;

const GridMargin = _styledComponents.default.div(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 15px;\n"])));

exports.GridMargin = GridMargin;

const ChartMargin = _styledComponents.default.div(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 30px 0 30px 20px;\n"])));

exports.ChartMargin = ChartMargin;