"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActions = useActions;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var React = _interopRequireWildcard(require("react"));

var _axios = _interopRequireDefault(require("axios"));

var _types = require("./types");

var _ = require("./");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function useActions() {
  const dispatch = (0, _.useDispatch)();
  /**
   * Retrieves the summary action counts for the workflow
   * performance view.
   */

  const getSummary = React.useCallback(async function getSummary(metricsType, sites, startDate, endDate) {
    dispatch({
      type: "LOAD_SUMMARY"
    });

    try {
      const siteIds = Array.from(sites.keys());
      const {
        data
      } = await _axios.default.post("/v1/metrics/actions/summary", {
        siteIds,
        startDate,
        endDate
      });
      dispatch(_objectSpread(_objectSpread({
        type: "SUMMARY_REQUEST_SUCCESS"
      }, data), {}, {
        sites: siteIds,
        metricsType
      }));
    } catch (error) {// @TODO: Dispatch error state
      // When a site is unable to return the requested data, an error is thrown.
      // This seems to be triggered when a site has no 'closed' actions.
      // Worth investigating a better way to handle this.
    }
  }, []);
  /**
   * Retrieves the detailed metrics data for the workflow
   * charts.  Includes both by Team and by Site.
   */

  const getMetrics = React.useCallback(async function getMetrics(metricsType, sites, startDate, endDate) {
    dispatch({
      type: "LOAD_METRICS"
    });

    try {
      const siteIds = Array.from(sites);
      const url = "/v1/metrics/actions/".concat(metricsType === _types.MetricsType.Opened ? "opened" : "completed");
      const {
        data
      } = await _axios.default.post(url, {
        siteIds,
        startDate,
        endDate
      });
      dispatch(_objectSpread({
        type: "METRICS_REQUEST_SUCCESS",
        metricsType
      }, data));
    } catch (error) {// @TODO: Dispatch error state
      // When a site is unable to return the requested data, an error is thrown.
      // This seems to be triggered when a site has no 'closed' actions.
      // Worth investigating a better way to handle this.
    }
  }, []);
  /**
   * Update selected time range for workflow metrics
   */

  const setTimeFrame = React.useCallback(function setTimeFrame(timeFrameOption, startDate, endDate) {
    dispatch({
      type: "SET_TIME_FRAME",
      startDate,
      endDate,
      timeFrameOption
    });
  }, []);
  /**
   * Set selected sites for workflow metrics
   */

  const setSites = React.useCallback(function setSites(sites) {
    dispatch({
      type: "SET_SITES_LIST",
      sites
    });
  }, []);
  /**
   * Set the byType (sites || responsibility)
   */

  const setByType = React.useCallback(function setByType(by) {
    dispatch({
      type: "SET_METRICS_BY_TYPE",
      by
    });
  }, []);
  /**
   * Set ordering of chart bars (most to least || least to most)
   */

  const setSortDirection = React.useCallback(function setSortDirection(value) {
    dispatch({
      type: "SET_SORTING_DIRECTION",
      sortingDirection: value
    });
  }, []);
  return {
    getSummary,
    getMetrics,
    setTimeFrame,
    setSites,
    setByType,
    setSortDirection
  };
}