"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatagridWrapper = exports.Heading = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2;

const Heading = (0, _styledComponents.default)(_blocks.DisplaySmall)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  color: ", ";\n  flex-basis: 100%;\n"])), _blocks.color.theme.typography.ink);
exports.Heading = Heading;

const DatagridWrapper = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  flex-basis: 100%;\n  padding: 0 ", " ", ";\n"])), _blocks.spacing.default, _blocks.spacing.default);

exports.DatagridWrapper = DatagridWrapper;