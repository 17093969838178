"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$Avatar = exports.$MenuWrapper = exports.$Wrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3;

const $Wrapper = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  position: relative;\n"])));

exports.$Wrapper = $Wrapper;

const $MenuWrapper = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  display: inline-flex;\n  flex: 1;\n  justify-content: flex-end;\n  margin-right: ", ";\n"])), _blocks.spacing.extraLoose);

exports.$MenuWrapper = $MenuWrapper;

const $Avatar = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  border-radius: 100%;\n  height: 48px;\n  width: 48px;\n  text-align: center;\n  font-size: 18px;\n  font-weight: bold;\n  color: ", ";\n  background: rgba(255, 255, 255, 0.1);\n  cursor: pointer;\n"])), _blocks.color.pureWhite);

exports.$Avatar = $Avatar;