"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapEnumToAnalysisGroupKey = mapEnumToAnalysisGroupKey;
exports.mapSelectedCategoryToCategoryKey = mapSelectedCategoryToCategoryKey;
exports.groupOccurrencesIntoInsights = groupOccurrencesIntoInsights;
exports.sortInsights = sortInsights;

var _types = require("./types");

var _groups = require("./groups");

var _sorts = require("./sorts");

// In this file you'll find functions for transforming `ComposedOccurrence` into
// various formats that are consumed by the Insights and Monitoring pages

/**
 * Convert the UI state value for grouping into the key from the API response
 */
function mapEnumToAnalysisGroupKey(groupBy) {
  switch (groupBy) {
    case _types.GroupBy.Rule:
      return "rule";

    case _types.GroupBy.Priority:
      return "priority";

    case _types.GroupBy.Category:
      return "category";

    default:
      return "site";
  }
}
/**
 * Convert the UI state value for the active category into the key from the API
 * response
 */


function mapSelectedCategoryToCategoryKey(category) {
  switch (category) {
    case _types.Category.Metering:
      return "metering";

    case _types.Category.Performance:
      return "performance";

    case _types.Category.Connectivity:
      return "connectivity";

    case _types.Category.Comfort:
      return "comfort";

    default:
      return "null";
  }
}
/**
 * Using `composeInsights` and `lodash.groupBy`, group "grouped" occurrences
 * into composed insights ready for rendering.
 */


function groupOccurrencesIntoInsights(analysisGroup, groupByValue) {
  const insights = analysisGroup.map(group => {
    switch (groupByValue) {
      case _types.GroupBy.Rule:
        return (0, _groups.groupByRule)(group);

      case _types.GroupBy.Priority:
        return (0, _groups.groupByPriority)(group);

      default:
        return (0, _groups.groupBySite)(group);
    }
  });
  return insights;
}
/**
 * Sort composed insights by a supported sort method
 *
 * @param data Pre-grouped composed insights
 * @param groupBy the current insights group
 * @param sortBy the sort option in use
 */


function sortInsights(data, groupBy, sortBy) {
  if (data.length === 0) return [];

  switch (sortBy) {
    case _types.SortBy.Equips:
      return (0, _sorts.sortByEquips)(data);

    case _types.SortBy.InsightsAsc:
      return (0, _sorts.sortByInsights)(data, "asc");

    case _types.SortBy.InsightsDesc:
      return (0, _sorts.sortByInsights)(data, "desc");

    case _types.SortBy.AlphaAsc:
      return (0, _sorts.sortByAlpha)(data, groupBy, "asc");

    case _types.SortBy.AlphaDesc:
      return (0, _sorts.sortByAlpha)(data, groupBy, "desc");

    case _types.SortBy.Hits:
    default:
      return (0, _sorts.sortByHits)(data);
  }
}