"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.persistedReducer = exports.PERSIST_CONFIG = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reduxPersist = require("redux-persist");

var _storage = _interopRequireDefault(require("redux-persist/lib/storage"));

var _autoMergeLevel = _interopRequireDefault(require("redux-persist/lib/stateReconciler/autoMergeLevel2"));

var _store = require("./redux/store");

var _utils = require("./redux/modules/_BETA/utils");

var _actionsUI = require("./redux/modules/_BETA/actionsUI");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// ! TODO: Remove redux persist!!!

/**
 * Prevents session-specific keys from being
 * persisted from the `auth' branch of the store
 */
const blacklistAuthTransform = (0, _reduxPersist.createTransform)((inboundState, key) => {
  if (key !== "auth") return inboundState;
  return _objectSpread(_objectSpread({}, inboundState), {}, {
    loaded: false,
    loading: false,
    loggingIn: false,
    loggingOut: false,
    user: _objectSpread(_objectSpread({}, inboundState.user), {}, {
      portfolio: {
        projects: [],
        sites: []
      }
    })
  });
});
const blacklistInsightsTransform = (0, _reduxPersist.createTransform)((inboundState, key) => {
  if (key !== "insights") return inboundState; // If e2e test, set showSnoozed default to true

  const showSnoozed = window.Cypress && window.Cypress.env("user") ? true : inboundState.showSnoozed;
  return _objectSpread(_objectSpread({}, inboundState), {}, {
    loading: false,
    ready: false,
    hasLoaded: false,
    raw: [],
    insights: [],
    siteGroups: undefined,
    composed: undefined,
    error: undefined,
    showSnoozed
  });
});
const blacklistSelectionsTransform = (0, _reduxPersist.createTransform)((inboundState, key) => {
  if (key !== "betaSelections") return inboundState;
  return _objectSpread(_objectSpread({}, inboundState), {}, {
    activeProject: {
      jira_key: "",
      sites: [],
      skysparkURL: ""
    },
    activeSite: {
      label: "",
      id: "",
      syncoId: undefined
    }
  });
});
const migrations = {
  0: state => (0, _utils.resetState)(state),
  1: state => (0, _utils.resetState)(state),
  // Clear old column state values that may be invalid
  2: state => _objectSpread(_objectSpread({}, state), {}, {
    betaEquipUI: undefined,
    betaRuleUI: undefined,
    betaActionsUI: _actionsUI.INITIAL_STATE
  }),
  3: state => _objectSpread(_objectSpread({}, state), {}, {
    betaEquipUI: undefined,
    betaRuleUI: undefined,
    betaActionsUI: _actionsUI.INITIAL_STATE
  }),
  4: state => _objectSpread(_objectSpread({}, state), {}, {
    betaRuleUI: undefined,
    betaActionsUI: _actionsUI.INITIAL_STATE
  })
}; // NOTE: If introducing structural changes to persisted store,
// increment version number by 1
// Only increment version number when absolutely necessary
// WARNING: This will force all users to be logged out on next insite load
// DO NOT decrement version number

const PERSIST_CONFIG = {
  version: 4,
  key: "state",
  storage: _storage.default,
  whitelist: ["betaSelections", "auth", "betaEquipUI", "betaRuleUI", "betaActionsUI", "insights", "search"],
  migrate: (0, _reduxPersist.createMigrate)(migrations, {
    debug: false
  }),
  transforms: [blacklistAuthTransform, blacklistInsightsTransform, blacklistSelectionsTransform],
  stateReconciler: _autoMergeLevel.default
};
exports.PERSIST_CONFIG = PERSIST_CONFIG;
const persistedReducer = (0, _reduxPersist.persistReducer)(PERSIST_CONFIG, _store.rootReducer);
exports.persistedReducer = persistedReducer;