"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$Select = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _Menu = require("../../../components/Menu/Menu.styles");

var _App = require("../../../components/AppChrome/App.constants");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

// SELECT ELEMENTS
// ---------------
const $Select = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: inline-block;\n  position: relative;\n"])));

exports.$Select = $Select;
$Select.label = _styledComponents.default.button.attrs({
  type: "button"
})(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  display: inline-flex;\n  align-items: center;\n  min-height: 32px;\n  border: none;\n  background: none;\n  align-items: center;\n  font-size: 12px;\n  font-weight: bold;\n  text-transform: uppercase;\n\n  &:focus {\n    outline: 0;\n  }\n\n  .Label-text {\n    display: block;\n  }\n"])));
$Select.triangle = (0, _styledComponents.default)(_blocks.ArrowDropdownDown)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  margin-left: ", ";\n"])), _blocks.spacing.extraTight);
$Select.list = (0, _styledComponents.default)(_Menu.$Menu)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  display: ", ";\n  left: auto;\n  right: 0;\n  top: 20px;\n"])), props => props.isOpen ? "block" : "none");
$Select.option = (0, _styledComponents.default)(_Menu.$ButtonItem).attrs({
  type: "button"
})(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  font-weight: ", ";\n  padding: ", " ", ";\n  width: 100%;\n\n  span {\n    margin-right: ", ";\n  }\n\n  label > span {\n    flex-grow: 1;\n  }\n\n  &:disabled {\n    opacity: ", ";\n\n    span {\n      cursor: default;\n    }\n  }\n"])), props => props.isActive ? "".concat(_App.TYPOGRAPHY.WEIGHTS.BOLD) : "".concat(_App.TYPOGRAPHY.WEIGHTS.REGULAR), _blocks.spacing.tight, _blocks.spacing.snug, _blocks.spacing.tight, _blocks.opacity.subdued);
$Select.title = _Menu.$MenuTitle;