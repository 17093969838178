"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = require("react");

var _reactRedux = require("react-redux");

var _axios = _interopRequireDefault(require("axios"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _dec, _class;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

let IssuePreviewProps = (_dec = (0, _reactRedux.connect)(state => ({
  user: state.auth.user
})), _dec(_class = class IssuePreviewProps extends _react.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: undefined,
      error: undefined
    };
    this.handleRequestIssue = this.handleRequestIssue.bind(this);
  }

  async handleRequestIssue(issueId) {
    // Avoid duplicate requests
    if (this.state.data !== undefined) return;

    try {
      const [issueSummary, equipRules] = await Promise.all([_axios.default.get("/issue/".concat(issueId)).then(result => {
        if (!result.data.key) throw Error("No issue found with key ".concat(issueId));
        return result.data;
      }), _axios.default.post("/synco/issue/equipRules", {
        issueKeys: [issueId]
      }).then(result => result.data[issueId])]);
      this.setState({
        loading: false,
        data: _objectSpread(_objectSpread({}, issueSummary), {}, {
          relatedEquipRules: equipRules
        })
      });
    } catch (err) {
      this.setState({
        loading: false,
        data: undefined,
        error: err.message
      });
    }
  }

  render() {
    const {
      loading,
      data,
      error
    } = this.state;
    return this.props.children({
      loading,
      data,
      error,
      getPreview: this.handleRequestIssue
    });
  }

}) || _class);
IssuePreviewProps.propTypes = {
  issueId: _propTypes.default.string.isRequired,
  requestIssue: _propTypes.default.func,
  children: _propTypes.default.func.isRequired
};
IssuePreviewProps.defaultProps = {
  requestIssue: () => {}
};
var _default = IssuePreviewProps;
exports.default = _default;
module.exports = exports.default;