"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.navigate = exports.getLinkTo = exports.getQueryString = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _queryStringForAll = _interopRequireDefault(require("query-string-for-all"));

var _browserHistory = require("../../browserHistory");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const getQueryString = () => {
  const qs = _queryStringForAll.default.parse(_browserHistory.history.location.search);

  return _queryStringForAll.default.stringify(_objectSpread({}, qs));
};

exports.getQueryString = getQueryString;

const getLinkTo = pathname => {
  return {
    pathname,
    search: _browserHistory.history.location.search
  };
};

exports.getLinkTo = getLinkTo;

const navigate = function navigate(pathname) {
  let method = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "push";
  return () => {
    _browserHistory.history[method](getLinkTo(pathname));
  };
};

exports.navigate = navigate;