"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = clientMiddleware;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _actionCreators = require("../modules/auth/actionCreators");

var _UserService = _interopRequireDefault(require("../../utils/keycloak/UserService"));

var _navigate = require("../../utils/routes/navigate");

var _config = require("../../config");

const _excluded = ["promise", "types"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// typically client will be an instance of ApiClient
function clientMiddleware(client) {
  return _ref => {
    let {
      dispatch,
      getState
    } = _ref;
    return next => action => {
      if (typeof action === "function") {
        return action(dispatch, getState);
      } // Used window.isTokenUpdated set to notupdated at first, it is set
      // to updated if the token gets updated in the updateToken function of the UserService class,
      // and it resets to notUpdated after 15000 ms
      // because we are calling too many async actions at one time,
      // and they all update the token if it is expired,
      // that way we update the token by the first async call and the simultaneous other calls
      // don't update the token if it was updated less than 15000 ms ago
      // i used window dot variable name to set it as a global variable ,
      // Please let me know if i should change this global variable


      window.isTokenUpdated = "notUpdated";

      const updateTokenFunc = () => {
        // when performing a backend call, we need to attempt to refresh the token, if it is not expired yet,
        // Before giving access to the backend and modify the data.
        // the updateToken updates the token if it is about to expire, and if it is already expired,
        // it force the user to logout.
        _UserService.default.updateToken();

        setTimeout(function () {
          window.isTokenUpdated = "notUpdated";
        }, 15000);
      };

      const {
        promise,
        types
      } = action,
            rest = (0, _objectWithoutProperties2.default)(action, _excluded); // eslint-disable-line no-redeclare

      if (!promise) {
        updateTokenFunc();
        return next(action);
      } // if the action that was dispatched has a 'promise' key
      // dispatch a REQUEST action (as extracted from the 'types' key)


      const [REQUEST, SUCCESS, FAILURE] = types;
      next(_objectSpread(_objectSpread({}, rest), {}, {
        type: REQUEST
      })); // call the 'promise' function (from the action)
      // and dispatch a SUCCESS or FAILURE action (depending on the result)

      const actionPromise = promise(client);
      actionPromise.then(result => {
        updateTokenFunc();
        next(_objectSpread(_objectSpread({}, rest), {}, {
          result,
          type: SUCCESS
        }));
      }, error => {
        // if authentication fails, logout and navigate to the login screen
        if (error && error.status && error.status === 401) {
          // Fire off the logout action that triggers a global state reset.
          // when the user is not authorized log him out
          next((0, _actionCreators.logout)());
        }

        next(_objectSpread(_objectSpread({}, rest), {}, {
          error,
          type: FAILURE
        }));
      }).catch(error => {
        console.error("MIDDLEWARE ERROR:", error);
        next(_objectSpread(_objectSpread({}, rest), {}, {
          error,
          type: FAILURE
        }));
      });
      return actionPromise;
    };
  };
}

module.exports = exports.default;