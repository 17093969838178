"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActions = useActions;

var React = _interopRequireWildcard(require("react"));

var _axios = _interopRequireDefault(require("axios"));

var _types = require("./types");

var _ = require("./");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function useActions() {
  const dispatch = (0, _.useDispatch)();
  /**
   * Retrieves the detailed metrics data for the utilities
   */

  const getMetrics = React.useCallback(async function getMetrics(metricsType, sites, startDate, endDate) {
    dispatch({
      type: "LOAD_METRICS"
    });

    try {
      const siteIds = [...new Set(sites)];
      const url = "/v1/metrics/sites/utilities";
      const {
        data
      } = await _axios.default.post(url, {
        siteIds,
        startDate,
        endDate,
        type: metricsType
      });
      const metricsUnit = _types.MetricsUnit[metricsType];
      dispatch({
        type: "METRICS_REQUEST_SUCCESS",
        metricsType,
        metricsUnit,
        metrics: data
      });
    } catch (error) {// @TODO: Dispatch error state
    }
  }, []);
  /**
   * Update selected time range for utilities metrics
   */

  const setTimeFrame = React.useCallback(function setTimeFrame(timeFrameOption, startDate, endDate) {
    dispatch({
      type: "SET_TIME_FRAME",
      startDate,
      endDate,
      timeFrameOption
    });
  }, []);
  /**
   * Set ordering of ranked sites chart (most to least || least to most)
   */

  const setRankedSitesSortingDirection = React.useCallback(function setSortDirection(value) {
    dispatch({
      type: "SET_RANKED_SITES_SORTING_DIRECTION",
      rankedSites: value
    });
  }, []);
  /**
   * Set ordering of ranked years chart (latest to oldest || oldest to latest)
   */

  const setRankedYearsSortingDirection = React.useCallback(function setSortDirection(value) {
    dispatch({
      type: "SET_RANKED_YEARS_SORTING_DIRECTION",
      rankedYears: value
    });
  }, []);
  /**
   * Update selected sites
   */

  const setSelectedSites = React.useCallback(function setSelectedSites(selectedSites) {
    dispatch({
      type: "SET_SELECTED_SITES",
      selectedSites: [...new Set(selectedSites)]
    });
  }, []);
  /**
   * Update currnet metrics type of utilities
   */

  const setMetricsType = React.useCallback(function SetMetricsType(metricsType) {
    dispatch({
      type: "SET_METRICS_TYPE",
      metricsType
    });
  }, []);
  return {
    getMetrics,
    setTimeFrame,
    setMetricsType,
    setSelectedSites,
    setRankedSitesSortingDirection,
    setRankedYearsSortingDirection
  };
}