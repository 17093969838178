"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VALIDATE_FORM_ERROR = exports.EDITING_PROFILE_FORM = exports.END_ACTION = exports.CHANGING_PASSWORD = exports.CHANGE_PASSWORD_FAIL = exports.CHANGE_PASSWORD_SUCCESS = exports.CHANGE_PASSWORD = exports.CHANGE_PASSWORD_REQUEST = void 0;
// Changing user password
const CHANGE_PASSWORD_REQUEST = "bonfire/profile/CHANGE_PASSWORD_REQUEST";
exports.CHANGE_PASSWORD_REQUEST = CHANGE_PASSWORD_REQUEST;
const CHANGE_PASSWORD = "bonfire/profile/CHANGE_PASSWORD";
exports.CHANGE_PASSWORD = CHANGE_PASSWORD;
const CHANGE_PASSWORD_SUCCESS = "bonfire/profile/CHANGE_PASSWORD_SUCCESS";
exports.CHANGE_PASSWORD_SUCCESS = CHANGE_PASSWORD_SUCCESS;
const CHANGE_PASSWORD_FAIL = "bonfire/profile/CHANGE_PASSWORD_FAIL";
exports.CHANGE_PASSWORD_FAIL = CHANGE_PASSWORD_FAIL;
// GUI CONTROL
const CHANGING_PASSWORD = "bonfire/profile/CHANGING_PASSWORD";
exports.CHANGING_PASSWORD = CHANGING_PASSWORD;
const END_ACTION = "bonfire/profile/END_ACTION";
exports.END_ACTION = END_ACTION;
const EDITING_PROFILE_FORM = "bonfire/profile/EDITING_PROFILE_FORM";
exports.EDITING_PROFILE_FORM = EDITING_PROFILE_FORM;
const VALIDATE_FORM_ERROR = "bonfire/profile/VALIDATE_FORM_ERROR";
exports.VALIDATE_FORM_ERROR = VALIDATE_FORM_ERROR;