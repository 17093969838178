"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BY_TYPE_OPTIONS = exports.getTimeFrameRange = exports.getTimeFrameList = void 0;

var _types = require("../../context/reports/workflow/types");

var _types2 = require("../../context/reports/types");

const getTimeFrameList = (i18n, sliceCount) => {
  const list = [{
    value: _types2.TimeFrame.LastMonth,
    label: i18n._(
    /*i18n*/
    {
      id: "time.last_month",
      defaults: "Last Month"
    })
  }, {
    value: _types2.TimeFrame.Last3Months,
    label: i18n._(
    /*i18n*/
    {
      id: "time.last_three_month",
      defaults: "Last 3 Months"
    })
  }, {
    value: _types2.TimeFrame.Last6Months,
    label: i18n._(
    /*i18n*/
    {
      id: "time.last_six_months",
      defaults: "Last 6 Months"
    })
  }, {
    value: _types2.TimeFrame.Last12Months,
    label: i18n._(
    /*i18n*/
    {
      id: "time.last_twelve_months",
      defaults: "Last 12 Months"
    })
  }];
  return sliceCount ? list.slice(0, sliceCount) : list;
};
/**
 *
 * @param timeFrameOption number/enum of selectable months to request
 * @param today optional parameter to make testing easier
 */


exports.getTimeFrameList = getTimeFrameList;

const getTimeFrameRange = function getTimeFrameRange(timeFrameOption) {
  let today = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();
  const start = new Date(today);
  const end = new Date(today);
  const current = {
    from: '',
    to: ''
  };
  const compare = {
    from: '',
    to: ''
  };

  if (timeFrameOption === _types2.TimeFrame.CurrentMonth) {
    // * Start of month up to today
    start.setDate(1);
    current.from = new Date(start).toISOString();
    current.to = new Date(today).toISOString(); // * Start of previous month up to start of current month

    const startCompare = new Date(current.to);
    const endCompare = new Date(current.from);
    endCompare.setDate(0);
    startCompare.setMonth(startCompare.getMonth() - 1);
    startCompare.setDate(1);
    compare.to = new Date(endCompare).toISOString();
    compare.from = new Date(startCompare).toISOString();
  } else {
    // * `current`
    // * Start of (RANGE) months ago to end of previous month
    start.setDate(1);
    start.setMonth(start.getMonth() - timeFrameOption);
    end.setDate(0);
    current.from = new Date(start).toISOString();
    current.to = new Date(end).toISOString(); // * `compare`
    // * Start of (2 x RANGE) months ago to end of (RANGE) months ago

    const endCompare = new Date(current.from);
    endCompare.setDate(0);
    compare.to = endCompare.toISOString();
    const startCompare = new Date(compare.to);
    startCompare.setDate(1);
    startCompare.setMonth(startCompare.getMonth() - (timeFrameOption - 1));
    compare.from = new Date(startCompare).toISOString();
  }

  return {
    timeFrameOption,
    current,
    compare
  };
};

exports.getTimeFrameRange = getTimeFrameRange;
const BY_TYPE_OPTIONS = {
  [_types.MetricsByType.BySite]: {
    label: 'Site',
    value: _types.MetricsByType.BySite
  },
  [_types.MetricsByType.ByResponsible]: {
    label: 'Team',
    value: _types.MetricsByType.ByResponsible
  }
};
exports.BY_TYPE_OPTIONS = BY_TYPE_OPTIONS;