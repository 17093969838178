"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IssueDefaults = exports.ActiveColumnsProps = exports.IssueProps = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _columns = require("../../utils/columns");

const IssueProps = _propTypes.default.shape({
  assignee_display: _propTypes.default.string,
  assignee_name: _propTypes.default.string,
  bonfire_status: _propTypes.default.string,
  closed: _propTypes.default.any,
  components: _propTypes.default.string,
  context: _propTypes.default.string.isRequired,
  created: _propTypes.default.any,
  creator_name: _propTypes.default.string.isRequired,
  creator_name_display: _propTypes.default.string.isRequired,
  description: _propTypes.default.string,
  jira_link: _propTypes.default.string.isRequired,
  key: _propTypes.default.string.isRequired,
  priority: _propTypes.default.string.isRequired,
  project_key: _propTypes.default.string.isRequired,
  project_name: _propTypes.default.string.isRequired,
  reporter_name: _propTypes.default.string.isRequired,
  reporter_name_display: _propTypes.default.string.isRequired,
  resolution: _propTypes.default.string,
  resolutiondate: _propTypes.default.any,
  responsible: _propTypes.default.string.isRequired,
  screenshot_link: _propTypes.default.string,
  site_comments: _propTypes.default.any,
  skyspark_link: _propTypes.default.string,
  spark_type: _propTypes.default.string,
  status: _propTypes.default.string.isRequired,
  summary: _propTypes.default.string.isRequired,
  target: _propTypes.default.string,
  total_financial_impact: _propTypes.default.any,
  transition_reason: _propTypes.default.string,
  updated: _propTypes.default.any,
  operational_savings: _propTypes.default.oneOfType([_propTypes.default.string, _propTypes.default.number]),
  elec: _propTypes.default.oneOfType([_propTypes.default.string, _propTypes.default.number]),
  gas: _propTypes.default.oneOfType([_propTypes.default.string, _propTypes.default.number]),
  water: _propTypes.default.oneOfType([_propTypes.default.string, _propTypes.default.number])
});

exports.IssueProps = IssueProps;
const IssueDefaults = {
  assignee_name: "Unassigned",
  assignee_display: "Unassigned",
  closed: undefined,
  components: "Unknown",
  created: undefined,
  elec: 0,
  gas: 0,
  operational_savings: "",
  resolution: "",
  resolutiondate: undefined,
  screenshot_link: undefined,
  site_comments: undefined,
  skyspark_link: "",
  spark_type: "Unassigned",
  target: "",
  total_financial_impact: undefined,
  transition_reason: undefined,
  updated: undefined,
  water: 0
};
exports.IssueDefaults = IssueDefaults;

const ActiveColumnsProps = _propTypes.default.shape({
  [_columns.actionColumns.ID.key]: _propTypes.default.bool,
  [_columns.actionColumns.PRIORITY.key]: _propTypes.default.bool,
  [_columns.actionColumns.SITE.key]: _propTypes.default.bool,
  [_columns.actionColumns.SUMMARY.key]: _propTypes.default.bool,
  [_columns.actionColumns.CONTEXT.key]: _propTypes.default.bool,
  [_columns.actionColumns.RESPONSIBLE.key]: _propTypes.default.bool,
  [_columns.actionColumns.DATES.key]: _propTypes.default.bool,
  [_columns.actionColumns.STATUS.key]: _propTypes.default.bool
});

exports.ActiveColumnsProps = ActiveColumnsProps;