"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseName = exports.parseId = void 0;

// @ts-check

/**
 * Format a name into the first letter of first name + last name
 *
 * ie. Turn "Jack Cuthbert" into "J. Cuthbert"
 * @param {string} name
 */
const parseName = name => {
  const [first, last] = name.split(" ");
  return "".concat(first.charAt(0), ". ").concat(last);
};
/**
 * Parse a JIRA key into a number
 *
 * ie. Turn "DEXUS-1234" into "1234"
 * @param {string} id
 */


exports.parseName = parseName;

const parseId = function parseId() {
  let id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  return id.split("-")[1];
};

exports.parseId = parseId;