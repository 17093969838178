"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMonthlyValueDelta = getMonthlyValueDelta;
exports.getDaysAvgDelta = getDaysAvgDelta;
exports.composeReportData = composeReportData;

var _numbers = require("../../../utils/numbers");

var _dataUtils = require("./dataUtils");

/**
 * HACK!!
 * NOTE: These magic numbers have been calculated by hand via Jira
 * These will need to be periodically updated, by hand, as the averages change over time
 * This is an understood compromise for the short-term.
 * DO NOT FOLLOW THIS EXAMPLE!!!
 */
const MAGIC_BUENO_ACTIVE_AVERAGE_NUMBER = 90;
const MAGIC_BUENO_CLOSED_AVERAGE_NUMBER = 60;

function getMonthlyValueDelta(data, timestamp) {
  let key = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "total";

  if (!data) {
    return {
      value: 0,
      delta: 0
    };
  }

  const lastMonth = (0, _dataUtils.getOneMonthAgo)(timestamp);
  const total = (0, _dataUtils.getMonthTotal)(data, timestamp, key);
  const lastMonthTotal = (0, _dataUtils.getMonthTotal)(data, lastMonth, key);
  const delta = Number(total) - Number(lastMonthTotal);
  return {
    value: total,
    delta
  };
}

function getDaysAvgDelta(data, timestamp, magicNumber) {
  let key = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "age";
  const value = Math.round((0, _dataUtils.getMonthAverage)(data, timestamp, key));
  const prevMonthValue = Math.round((0, _dataUtils.getMonthAverage)(data, (0, _dataUtils.getOneMonthAgo)(timestamp), "age"));
  const delta = value - prevMonthValue;
  const buenoAverageDelta = (0, _numbers.getPercentageChange)(magicNumber, value, 0);
  return {
    days: value,
    delta,
    average: buenoAverageDelta // NOTE: this field is not yet used.  Delete it?

  };
}

function composeReportData(data, timestamp) {
  const lastMonth = (0, _dataUtils.getOneMonthAgo)(timestamp);
  const outstandingValue = (0, _numbers.zeroIfNaN)(Number((0, _dataUtils.getMonthValue)(data.outstanding, timestamp, "total")));
  const outstandingDelta = outstandingValue - (0, _numbers.zeroIfNaN)(Number((0, _dataUtils.getMonthValue)(data.outstanding, lastMonth, "total")));
  const openIssueCount = (0, _numbers.zeroIfNaN)(Number((0, _dataUtils.getMonthValue)(data.open, timestamp, "total")));
  const openIssueDelta = openIssueCount - (0, _numbers.zeroIfNaN)(Number((0, _dataUtils.getMonthValue)(data.open, lastMonth, "total")));
  const atAGlance = {
    done: {
      closed: getMonthlyValueDelta(data.closed, timestamp),
      raised: getMonthlyValueDelta(data.raised, timestamp)
    },
    next: {
      open: {
        value: openIssueCount,
        delta: openIssueDelta
      },
      pending: {
        value: (0, _numbers.zeroIfNaN)(Number(data.pending)),
        delta: 0
      },
      stagnant: {
        value: (0, _numbers.zeroIfNaN)(Number(data.stagnant)),
        delta: 0
      }
    },
    progress: {
      outstanding: {
        value: outstandingValue,
        delta: outstandingDelta
      },
      active: getDaysAvgDelta(data.activeAge.delta, timestamp, MAGIC_BUENO_ACTIVE_AVERAGE_NUMBER),
      industryAvgActive: MAGIC_BUENO_ACTIVE_AVERAGE_NUMBER,
      closed: getDaysAvgDelta(data.closeAge.delta, timestamp, MAGIC_BUENO_CLOSED_AVERAGE_NUMBER),
      industryAvgClosed: MAGIC_BUENO_CLOSED_AVERAGE_NUMBER
    }
  };
  return {
    atAGlance
  };
}