"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActions = useActions;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var React = _interopRequireWildcard(require("react"));

var _queryStringForAll = _interopRequireDefault(require("query-string-for-all"));

var _ = require("./");

var _storage = require("../../utils/storage");

var _constants = require("../../components/Analysis/utils/constants");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function useActions() {
  const dispatch = (0, _.useDispatch)();
  const toggleExpandAll = React.useCallback(function setExpandAll() {
    dispatch({
      type: "TOGGLE_EXPAND_ALL"
    });
  }, []);
  const setSortBy = React.useCallback(function setSortBy(sortBy) {
    dispatch({
      type: "SET_SORT_BY",
      sortBy
    });
    (0, _storage.setPersisted)({
      key: _constants.INSIGHTS_LS_KEY,
      prop: _constants.SORT_BY_KEY
    }, sortBy);
  }, []);
  const setGroupBy = React.useCallback(function setGroupBy(groupBy) {
    dispatch({
      type: "SET_GROUP_BY",
      groupBy
    });
    (0, _storage.setPersisted)({
      key: _constants.INSIGHTS_LS_KEY,
      prop: _constants.GROUP_BY_KEY
    }, groupBy);
  }, []);
  const setCategory = React.useCallback(function setCategory(history, category) {
    const qs = _queryStringForAll.default.parse(history.location.search);

    const stringified = _queryStringForAll.default.stringify(_objectSpread(_objectSpread({}, qs), {}, {
      category
    }));

    history.replace({
      search: stringified
    });
    dispatch({
      type: "SET_CATEGORY",
      category
    });
  }, []);
  return {
    toggleExpandAll,
    setGroupBy,
    setSortBy,
    setCategory
  };
}