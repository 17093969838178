"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getClientRoutes = getClientRoutes;

var _routes = require("../../utils/routes");

function getInitials() {
  let displayName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  return displayName.split(" ").map(name => name[0]).join("");
}

function getClientRoutes(user) {
  const appLinks = [];
  const adminLinks = {
    support: {
      label: i18n => i18n._(
      /*i18n*/
      {
        id: "user_menu.support",
        defaults: "Support"
      }),
      href: "mailto:support@conserveitiot.com"
    },
    profile: undefined,
    userAdmin: undefined,
    groupAdmin: undefined,
    siteSettings: undefined
  };
  const userDetails = {
    name: "",
    initials: ""
  }; // UserMenu and userDetails conditions

  if (user.token) {
    adminLinks.profile = {
      label: i18n => i18n._(
      /*i18n*/
      {
        id: "user_menu.my_profile",
        defaults: "My Profile"
      }),
      href: "/profile"
    };
    userDetails.name = user.display_name;
    userDetails.initials = getInitials(user.display_name);
  }

  if (user.access && user.access.includes("userAdmin")) {
    adminLinks.userAdmin = {
      label: i18n => i18n._(
      /*i18n*/
      {
        id: "user_menu.user_settings",
        defaults: "User Settings"
      }),
      href: "/users"
    };
  }

  if (user.access && user.access.includes("groupAdmin")) {
    adminLinks.groupAdmin = {
      label: i18n => i18n._(
      /*i18n*/
      {
        id: "user_menu.group_settings",
        defaults: "Group Settings"
      }),
      href: "/groups"
    };
  }

  if (user.access && user.groups !== undefined && user.groups.includes("ConserveIt")) {
    adminLinks.siteSettings = {
      label: i18n => i18n._(
      /*i18n*/
      {
        id: "user_menu.project_settings",
        defaults: "Project Settings"
      }),
      href: _routes.SETTINGS_INSIGHTS
    };
  }

  if (!user.token) {
    adminLinks.auth = {
      label: i18n => i18n._(
      /*i18n*/
      {
        id: "user_menu.login",
        defaults: "Login"
      }),
      href: "/login"
    };
  } else {
    adminLinks.auth = {
      label: i18n => i18n._(
      /*i18n*/
      {
        id: "user_menu.logout",
        defaults: "Logout"
      }),
      href: "/logout"
    };
  } // AppMenu Link conditions


  if (user.access && user.groups !== undefined && user.groups.includes("ConserveIt")) {
    appLinks.push({
      label: i18n => i18n._(
      /*i18n*/
      {
        id: "app_chrome.monitoring",
        defaults: "Monitoring"
      }),
      href: "/analysis/monitoring"
    });
  }

  if (user.access && user.access.includes("viewTriagePage")) {
    appLinks.push({
      label: i18n => i18n._(
      /*i18n*/
      {
        id: "app_chrome.insights",
        defaults: "Insights"
      }),
      href: "/analysis/insights"
    });
  }

  if (user.token) {
    appLinks.push({
      label: i18n => i18n._(
      /*i18n*/
      {
        id: "app_chrome.actions",
        defaults: "Actions"
      }),
      href: "/actions"
    });
  }

  if (user.access && user.access.includes("viewReportGeneratorPage")) {
    appLinks.push({
      label: i18n => i18n._(
      /*i18n*/
      {
        id: "app_chrome.reports",
        defaults: "Reports"
      }),
      href: "/reports/performance/workflow"
    });
  }

  if (user.access && user.access.includes('viewSiteDetailsPage') && user.groups && user.groups.includes("iws_api")) {
    appLinks.push({
      label: i18n => i18n._(
      /*i18n*/
      {
        id: "app_chrome.site_details",
        defaults: "Site Details"
      }),
      href: "/sitedetails/details"
    });
  }

  return {
    userDetails,
    appLinks,
    adminLinks
  };
}