"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.startAddGroup = startAddGroup;
exports.handleClose = handleClose;
exports.handleSubmit = handleSubmit;
exports.addGroupRequest = addGroupRequest;
exports.saveGroupRequest = saveGroupRequest;
exports.startEditGroup = startEditGroup;
exports.handleChange = exports.loadGroups = void 0;

var _actionTypes = require("./actionTypes");

const loadGroups = function loadGroups(user) {
  let lookup = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  let url = "/groups";
  if (lookup) url = "".concat(url, "/lookup");
  return {
    types: [_actionTypes.LOAD_GROUPS, _actionTypes.LOAD_GROUPS_SUCCESS, _actionTypes.LOAD_GROUPS_FAIL],
    promise: client => client.get(url, {
      headers: {
        Authorization: "Bearer ".concat(user.token)
      },
      params: {
        role: user.role
      }
    })
  };
};

exports.loadGroups = loadGroups;

function startAddGroup() {
  return {
    type: _actionTypes.ADDING_GROUP
  };
}

function handleClose() {
  return {
    type: _actionTypes.END_ACTION
  };
} // TODO: Why does this exist?


function handleSubmit(func) {
  return func();
}

function addGroupRequest() {
  return {
    type: _actionTypes.ADD_GROUP_REQUEST
  };
}

function saveGroupRequest() {
  return {
    type: _actionTypes.SAVE_GROUP_REQUEST
  };
}

function startEditGroup(row) {
  return {
    type: _actionTypes.EDITING_GROUP,
    row
  };
}

const handleChange = function handleChange(e) {
  let inputId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  let inputValue = {};

  if (!inputId) {
    inputId = e.currentTarget.id;

    if (e.currentTarget.type === "checkbox") {
      inputValue = e.currentTarget.checked;
    } else {
      inputValue = e.currentTarget.value;
    }
  } else {
    inputValue = e;
  }

  return {
    type: _actionTypes.EDITING_GROUP_FORM,
    input_id: inputId,
    input_value: inputValue
  };
};

exports.handleChange = handleChange;