"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useUtilitiesEffect = useUtilitiesEffect;

var React = _interopRequireWildcard(require("react"));

var _utilities = require("../../../../context/reports/utilities");

var _useActions = require("../../../../context/reports/utilities/useActions");

var _navigate = require("../../../../utils/routes/navigate");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

/**
 * Handles utilities effect
 * @param metricsType
 * @param selectedSites
 * @param currentSiteId current site identifer is set on single site scene only
 */
function useUtilitiesEffect(metricsType, selectedSites, currentSiteId) {
  const [state] = (0, _utilities.useUtilities)();
  const {
    getMetrics,
    setMetricsType,
    setSelectedSites
  } = (0, _useActions.useActions)();
  /**
   * Set metrics type into state if it's been changed
   */

  React.useEffect(() => {
    setMetricsType(metricsType);
  }, [metricsType]);
  /**
   * Set selected sites into state if it's been changed
   */

  React.useEffect(() => {
    const selected = [...new Set(selectedSites)];

    if (state.selectedSites.length !== selected.length || state.selectedSites.every((value, index) => value !== selected[index])) {
      setSelectedSites(selectedSites);
    }
  }, [selectedSites]);
  /**
   * Specific effect for single site scene
   * Observe selected sites and navigate to portfolio view, but only when
   * selected sites does not include current single site id
   */

  React.useEffect(() => {
    if (currentSiteId && !selectedSites.includes(currentSiteId)) {
      (0, _navigate.navigate)("/reports/performance/utilities/".concat(metricsType.toLowerCase()))();
      return;
    }
  }, [selectedSites, currentSiteId]);
  /**
   * Handle metrics requests
   */

  React.useEffect(() => {
    if (!state.metricsLoaded[metricsType] && !state.metricsLoading) {
      void getMetrics(metricsType, selectedSites, state.startDate, state.endDate);
    }
  }, [metricsType, state.metricsLoaded, state.metricsLoading]);
}