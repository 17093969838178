"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _utils = require("./utils");

var _utils2 = require("../../../components/Reports/Comfort/utils");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function reducer(state, action) {
  const actionType = action.type;

  switch (action.type) {
    case "LOAD_PORTFOLIO":
      return _objectSpread(_objectSpread({}, state), {}, {
        portfolioLoading: true,
        activeSitesHash: action.activeSitesHash
      });

    case "LOAD_PORTFOLIO_SUCCESS":
      return _objectSpread(_objectSpread({}, state), {}, {
        portfolioLoaded: true,
        portfolioLoading: false,
        portfolioError: false,
        portfolio: (0, _utils.calcSitePortfolio)(action.data.sites)
      });

    case "LOAD_PORTFOLIO_FAIL":
      return _objectSpread(_objectSpread({}, state), {}, {
        portfolioLoading: false,
        portfolioError: true
      });

    case "LOAD_ZONES":
      return _objectSpread(_objectSpread({}, state), {}, {
        zonesLoading: true
      });

    case "LOAD_ZONES_SUCCESS":
      return _objectSpread(_objectSpread({}, state), {}, {
        zonesLoaded: true,
        zonesLoading: false,
        zonesError: false,
        zones: action.data
      });

    case "LOAD_ZONES_FAIL":
      return _objectSpread(_objectSpread({}, state), {}, {
        zonesLoading: false,
        zonesError: true
      });

    case "RESET_ZONES":
      return _objectSpread(_objectSpread({}, state), {}, {
        zones: undefined,
        zonesLoading: false,
        zonesLoaded: false
      });

    case "SET_VIEW_BY":
      return _objectSpread(_objectSpread({}, state), {}, {
        viewBy: action.viewBy
      });

    case "SET_RANK_DIR":
      return _objectSpread(_objectSpread({}, state), {}, {
        rankDir: action.rankDir
      });

    case "SET_TIME_FRAME":
      {
        const {
          current,
          compare
        } = (0, _utils2.getTimeFrameCompareRanges)(action.timeFrameOption, action.compareToOption);
        return _objectSpread(_objectSpread({}, state), {}, {
          timeFrameOption: action.timeFrameOption,
          compareToOption: action.compareToOption,
          current,
          compare
        });
      }

    default:
      throw new Error("Unhandled action type: ".concat(actionType));
  }
}