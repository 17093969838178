"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addAttachmentFlow = addAttachmentFlow;

var _effects = require("redux-saga/effects");

var _actionTypes = require("./actionTypes");

var _actionCreators = require("./actionCreators");

var _helpers = require("../helpers/helpers");

var _selectors = require("./selectors");

function* addAttachmentFlow() {
  /*
   * Returns the pre-signed put URL and public read URL for the attachment that is about to be uploaded
   */
  while (true) {
    const {
      attachment
    } = yield (0, _effects.take)(_actionTypes.ADD_ATTACHMENT_REQUEST);
    const {
      user
    } = yield (0, _effects.select)(_selectors.getAuth); // Get Signed URL action

    yield (0, _effects.fork)(_helpers.dispatchGeneratorFunc, _actionCreators.getSignedUrl, user, attachment.id, attachment.fileName, attachment.fileType);
    const {
      success
    } = yield (0, _effects.race)({
      success: (0, _effects.take)(_actionTypes.GET_SIGNED_URL_SUCCESS),
      fail: (0, _effects.take)(_actionTypes.GET_SIGNED_URL_FAIL)
    });

    if (success) {
      yield (0, _effects.put)({
        type: _actionTypes.UPLOAD_ATTACHMENTS_COMPLETED
      });
    }
  }
}