"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.analysisColumns = exports.monitoringColumns = exports.equipColumns = exports.ruleColumns = exports.insightColumns = exports.actionColumns = exports.Columns = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash.orderby"));

var _sorting = require("./sorting");

var _moment = _interopRequireDefault(require("moment"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

let Columns;
exports.Columns = Columns;

(function (Columns) {
  Columns["ID"] = "ID";
  Columns["PRIORITY"] = "PRIORITY";
  Columns["SITE"] = "SITE";
  Columns["SUMMARY"] = "SUMMARY";
  Columns["CONTEXT"] = "CONTEXT";
  Columns["RESPONSIBLE"] = "RESPONSIBLE";
  Columns["DATES"] = "DATES";
  Columns["STATUS"] = "STATUS";
  Columns["LATEST"] = "LATEST";
  Columns["REGION"] = "REGION";
  Columns["RULE_NAME"] = "RULE_NAME";
  Columns["EQUIP_NAME"] = "EQUIP_NAME";
  Columns["EQUIP_DETAILS"] = "EQUIP_DETAILS";
  Columns["TRIGGERED_RULES"] = "TRIGGERED_RULES";
  Columns["AFFECTED_EQUIPS"] = "AFFECTED_EQUIPS";
  Columns["RULE_TYPE"] = "RULE_TYPE";
  Columns["SEVERITY"] = "SEVERITY";
  Columns["ACTIONS_COUNT"] = "ACTIONS_COUNT";
})(Columns || (exports.Columns = Columns = {}));

const prioritySortMap = (0, _sorting.createSortMap)(["Blocker", "Critical", "Major", "Moderate", "Minor", "Trivial"], true);
const ID = {
  key: Columns.ID,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.id",
    defaults: "ID"
  }),
  width: "70px",
  sortFn: (issues,
  /* @TODO */
  direction) => (0, _lodash.default)(issues, issue => {
    if (issue.key === undefined) return 1;
    return Number(issue.key.split("-")[1]);
  }, direction),
  sortDirection: "desc",
  disabled: true,
  hidden: false
};
const PRIORITY = {
  key: Columns.PRIORITY,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.priority",
    defaults: "Priority"
  }),
  width: "120px",
  sortFn: (issues,
  /* @TODO */
  direction) => (0, _lodash.default)(issues, issue => prioritySortMap[issue.priority], direction),
  sortDirection: "desc",
  disabled: false,
  hidden: false
};
const MONITORING_PRIORITY = {
  key: Columns.PRIORITY,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.priority",
    defaults: "Priority"
  }),
  width: "120px",
  sortFn: (insights,
  /* @TODO */
  direction) => (0, _lodash.default)(insights, i => i.priority, direction),
  sortDirection: "desc",
  disabled: false,
  hidden: false
};
const SITE = {
  key: Columns.SITE,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.site",
    defaults: "Site"
  }),
  width: "180px",
  sortFn: (issues,
  /* @TODO */
  direction) => (0, _lodash.default)(issues, issue => issue.components.toLowerCase(), direction),
  sortDirection: "asc",
  disabled: false,
  hidden: false
};
const SUMMARY = {
  key: Columns.SUMMARY,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.summary",
    defaults: "Summary"
  }),
  width: "290px",
  sortFn: (issues,
  /* @TODO */
  direction) => // NOTE: The issue summary may sometimes have a leading space, thus
  // calling `.trim()` here. This should (in my opinion) be resolved by
  // trimming before it's served to the frontend. -- Jack 11/01/2019
  (0, _lodash.default)(issues, issue => issue.summary.toLowerCase().trim(), direction),
  sortDirection: "asc",
  disabled: true,
  hidden: false
};
const CONTEXT = {
  key: Columns.CONTEXT,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.type",
    defaults: "Type"
  }),
  width: "110px",
  sortFn: (issues,
  /* @TODO */
  direction) => (0, _lodash.default)(issues, issue => issue.context.toLowerCase(), direction),
  sortDirection: "asc",
  disabled: false,
  hidden: false
};
const RESPONSIBLE = {
  key: Columns.RESPONSIBLE,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.responsible",
    defaults: "Responsible"
  }),
  width: "140px",
  sortFn: (issues,
  /* @TODO */
  direction) => (0, _lodash.default)(issues, issue => issue.responsible.toLowerCase(), direction),
  sortDirection: "asc",
  disabled: false,
  hidden: false
};
const DATES = {
  key: Columns.DATES,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.updated",
    defaults: "Updated"
  }),
  width: "120px",
  sortFn: function sortFn(issues) {
    let direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "desc";
    return (0, _lodash.default)(issues, issue => (0, _moment.default)(issue.updated).unix(), direction);
  },
  sortDirection: "desc",
  disabled: false,
  hidden: false
};
const STATUS = {
  key: Columns.STATUS,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.status",
    defaults: "Status"
  }),
  width: "115px",
  sortFn: (issues,
  /* @TODO */
  direction) => (0, _lodash.default)(issues, issue => issue.bonfire_status.toLowerCase(), direction),
  sortDirection: "asc",
  disabled: false,
  hidden: false
};
const LATEST = {
  key: Columns.LATEST,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.latest",
    defaults: "Latest"
  }),
  width: "90px",
  minWidth: "90px",
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, s => s.latestUnix, direction),
  sortDirection: "desc",
  disabled: false,
  hidden: false
};
const REGION = {
  key: Columns.REGION,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.region",
    defaults: "Region"
  }),
  width: "80px",
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, s => s.site.state, direction),
  sortDirection: "asc",
  disabled: false,
  hidden: false
};
const RULE_NAME = {
  key: Columns.RULE_NAME,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.rule_name",
    defaults: "Rule"
  }),
  width: "420px",
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, r => r.ruleName, direction),
  sortDirection: "asc",
  disabled: false,
  hidden: false
};
const EQUIP_NAME = {
  key: Columns.EQUIP_NAME,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.equip_name",
    defaults: "Equip"
  }),
  width: "140px",
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, r => r.targetName, direction),
  sortDirection: "asc",
  disabled: false,
  hidden: false
};
const EQUIP_DETAILS = {
  key: Columns.EQUIP_DETAILS,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.equip_details",
    defaults: "Details"
  }),
  width: "350px",
  sortFn: (hits,
  /* @TODO */
  direction) => (0, _lodash.default)(hits, h => h.description, direction),
  sortDirection: "asc",
  disabled: false,
  hidden: false
};
const TRIGGERED_RULES = {
  key: Columns.TRIGGERED_RULES,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.triggered_rules",
    defaults: "Hits"
  }),
  width: "100px",
  minWidth: "120px",
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, s => s.activeHits, direction),
  sortDirection: "desc",
  disabled: false,
  hidden: false
};
const AFFECTED_EQUIPS = {
  key: Columns.AFFECTED_EQUIPS,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.affected_equips",
    defaults: "Equips"
  }),
  width: "100px",
  minWidth: "120px",
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, s => s.activeTargetCount, direction),
  sortDirection: "desc",
  disabled: false,
  hidden: false
};
const RULE_TYPE = {
  key: Columns.RULE_TYPE,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.rule_type",
    defaults: "Rule Type"
  }),
  width: "120px",
  minWidth: "100px",
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, r => r.ruleType === "" ? "~" : r.ruleType, direction),
  sortDirection: "asc",
  disabled: false,
  hidden: false
};
const SEVERITY = {
  key: Columns.SEVERITY,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.severity",
    defaults: "Severity"
  }),
  width: "110px",
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, [r => prioritySortMap[r.severity], r => r.latestUnix], direction),
  sortDirection: "desc",
  disabled: false,
  hidden: false
};
const ACTIONS_COUNT = {
  key: Columns.ACTIONS_COUNT,
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "columns.actions_count",
    defaults: "Actions"
  }),
  width: "100px",
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, [s => s.actionsCount, r => r.severity], direction),
  sortDirection: "desc",
  disabled: false,
  hidden: false
};
const actionColumns = {
  ID,
  PRIORITY,
  SITE,
  SUMMARY,
  CONTEXT,
  RESPONSIBLE,
  DATES,
  STATUS
};
exports.actionColumns = actionColumns;
const insightColumns = {
  LATEST,
  PRIORITY: MONITORING_PRIORITY,
  SITE: _objectSpread(_objectSpread({}, SITE), {}, {
    width: "450px",
    sortFn: (hits,
    /* @TODO */
    direction) => (0, _lodash.default)(hits, h => h.site.label, direction)
  }),
  REGION,
  RULE_NAME,
  TRIGGERED_RULES,
  AFFECTED_EQUIPS,
  RULE_TYPE,
  SEVERITY,
  ACTIONS_COUNT
};
exports.insightColumns = insightColumns;
const ruleColumns = {
  LATEST,
  SEVERITY,
  RULE_NAME,
  AFFECTED_EQUIPS: _objectSpread(_objectSpread({}, AFFECTED_EQUIPS), {}, {
    width: "220px"
  }),
  RULE_TYPE,
  ACTIONS_COUNT
};
exports.ruleColumns = ruleColumns;
const equipColumns = {
  LATEST: _objectSpread(_objectSpread({}, LATEST), {}, {
    sortFn: (summaries,
    /* @TODO */
    direction) => (0, _lodash.default)(summaries, s => (0, _moment.default)(s.occurredOn).unix(), direction)
  }),
  SEVERITY,
  EQUIP_NAME,
  EQUIP_DETAILS,
  TRIGGERED_RULES: _objectSpread(_objectSpread({}, TRIGGERED_RULES), {}, {
    width: "140px",
    sortFn: (hits,
    /* @TODO */
    direction) => (0, _lodash.default)(hits, h => h.times.length, direction)
  }),
  RULE_TYPE,
  ACTIONS_COUNT: _objectSpread(_objectSpread({}, ACTIONS_COUNT), {}, {
    sortFn: (hits,
    /* @TODO */
    direction) => (0, _lodash.default)(hits, [h => h.issueKey === undefined ? "~" : h.issueKey], direction),
    sortDirection: "asc"
  })
};
exports.equipColumns = equipColumns;
const monitoringColumns = {
  LATEST,
  PRIORITY: MONITORING_PRIORITY,
  SITE: _objectSpread(_objectSpread({}, SITE), {}, {
    width: "450px",
    sortFn: (hits,
    /* @TODO */
    direction) => (0, _lodash.default)(hits, h => h.site.label, direction)
  }),
  REGION,
  RULE_NAME,
  TRIGGERED_RULES,
  AFFECTED_EQUIPS,
  RULE_TYPE,
  SEVERITY,
  ACTIONS_COUNT
};
exports.monitoringColumns = monitoringColumns;
const analysisColumns = {
  monitoring: monitoringColumns,
  insights: insightColumns
};
exports.analysisColumns = analysisColumns;