"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.i18n = exports.defaultLocale = exports.locales = void 0;

var _core = require("@lingui/core");

const locales = {
  en: "English",
  fr: "Français"
};
exports.locales = locales;
const defaultLocale = "en";
exports.defaultLocale = defaultLocale;

function loadCatalog(locale) {
  return import("./locales/".concat(locale, "/messages.js"));
}

const i18n = (0, _core.setupI18n)();
exports.i18n = i18n;
i18n.willActivate(loadCatalog);