"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useZoneEffects = useZoneEffects;

var React = _interopRequireWildcard(require("react"));

var _comfort = require("../../../../context/reports/comfort");

var _useActions = require("../../../../context/reports/comfort/useActions");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function useZoneEffects(_ref) {
  let {
    appReady,
    sites,
    siteId
  } = _ref;
  const {
    current,
    compare,
    timeFrameOption,
    compareToOption,
    portfolioLoaded,
    portfolioLoading,
    zonesLoaded,
    zonesLoading
  } = (0, _comfort.useState)();
  const {
    getPortfolio,
    getZones,
    resetZones
  } = (0, _useActions.useActions)();
  const didMountRef = React.useRef(false); // * If portfolio data has not yet been loaded, request it

  React.useEffect(() => {
    if (appReady && !portfolioLoading && !portfolioLoaded) {
      didMountRef.current = true;
      void getPortfolio({
        siteIds: sites,
        current,
        compare
      });
    }

    return () => {
      resetZones();
    };
  }, [appReady]); // * Request site zones data

  React.useEffect(() => {
    if (siteId && appReady && !zonesLoading && !zonesLoaded) {
      void getZones({
        siteId,
        current,
        compare
      });
    }
  }, [appReady, siteId]); // * If siteId argument is defined, AND
  // * timeFrame changes
  // * Request site zones and portfolio data

  React.useEffect(() => {
    if (siteId && appReady && !zonesLoading && zonesLoaded && didMountRef.current) {
      void getZones({
        siteId,
        current,
        compare
      });
    }

    if (!portfolioLoading && portfolioLoaded && didMountRef.current) {
      void getPortfolio({
        siteIds: sites,
        current,
        compare
      });
    }

    didMountRef.current = true;
  }, [timeFrameOption, compareToOption, siteId]);
}