"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActions = useActions;

var React = _interopRequireWildcard(require("react"));

var _axios = _interopRequireDefault(require("axios"));

var _ = require("./");

var _utils = require("../../../components/Reports/Comfort/utils");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function useActions() {
  const dispatch = (0, _.useDispatch)();
  /**
   * Get portfolio data for sites
   */

  const getPortfolio = React.useCallback(async function getPortfolio(_ref) {
    let {
      siteIds,
      current,
      compare
    } = _ref;
    dispatch({
      type: "LOAD_PORTFOLIO",
      activeSitesHash: siteIds.join("")
    });
    const url = "/v1/metrics/sites/comfort";
    const payload = {
      siteIds,
      current,
      compare
    };

    try {
      const {
        data
      } = await _axios.default.post(url, payload);
      dispatch({
        type: "LOAD_PORTFOLIO_SUCCESS",
        data
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: "LOAD_PORTFOLIO_FAIL"
      });
    }
  }, []);
  const getZones = React.useCallback(async function getZones(_ref2) {
    let {
      siteId,
      current,
      compare
    } = _ref2;
    dispatch({
      type: "LOAD_ZONES"
    });
    const url = "/v1/metrics/sites/".concat(siteId, "/comfort/zones");
    const payload = {
      current,
      compare
    };

    try {
      const {
        data
      } = await _axios.default.post(url, payload);
      dispatch({
        type: "LOAD_ZONES_SUCCESS",
        data
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: "LOAD_ZONES_FAIL"
      });
    }
  }, []);
  const resetZones = React.useCallback(function resetZones() {
    dispatch({
      type: "RESET_ZONES"
    });
  }, []);
  const setViewBy = React.useCallback(viewBy => {
    dispatch({
      type: "SET_VIEW_BY",
      viewBy
    });
  }, []);
  const setRankDir = React.useCallback(rankDir => {
    dispatch({
      type: "SET_RANK_DIR",
      rankDir
    });
  }, []);
  const setTimeFrame = React.useCallback((timeFrameOption, compareToOption) => {
    const {
      current,
      compare
    } = (0, _utils.getTimeFrameCompareRanges)(timeFrameOption, compareToOption);
    dispatch({
      type: "SET_TIME_FRAME",
      timeFrameOption,
      compareToOption,
      current: {
        from: current.from,
        to: current.to
      },
      compare: {
        from: compare.from,
        to: compare.to
      }
    });
  }, []);
  return {
    getPortfolio,
    getZones,
    resetZones,
    setViewBy,
    setRankDir,
    setTimeFrame
  };
}