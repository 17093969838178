"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _actionTypes = require("./actionTypes");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const INITIAL_STATE = {
  uploadAttachment: false,
  attachments: [],
  uploadedAttachments: 0,
  attachment_to_add: {}
};

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actionTypes.START_UPLOAD_ATTACHMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadAttachment: true
      });

    case _actionTypes.END_UPLOAD_ATTACHMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadAttachment: false
      });

    case _actionTypes.ADD_ATTACHMENT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        attachment_to_add: action.attachment,
        uploadError: null,
        uploadAttachment: false
      });

    case _actionTypes.GET_SIGNED_URL_SUCCESS:
      {
        const attachment = _objectSpread({}, state.attachment_to_add);

        attachment.uploadUrl = action.result.signedPutUrl;
        attachment.imageUrl = action.result.publicReadUrl; // HACK: This is gross
        // TODO: Refactor this flow so that attachments can be passed
        // through with a key from `getSignedUrl`

        if ("key" in attachment !== true) {
          attachment.key = new Date().getTime();
        }

        const attachments = [...state.attachments, attachment];
        return _objectSpread(_objectSpread({}, state), {}, {
          attachments,
          uploadAttachment: false,
          attachment_to_add: attachment
        });
      }

    case _actionTypes.UPLOAD_TO_S3_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadedAttachments: state.uploadedAttachments + 1
      });

    case _actionTypes.UPLOAD_TO_S3_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadError: action.error // NOTE: Nothing is being done with this message

      });

    case _actionTypes.UPLOAD_ATTACHMENTS_COMPLETED:
      return state;

    case _actionTypes.REMOVE_ATTACHMENT:
      {
        const reducedAttachments = [...state.attachments];
        reducedAttachments.splice(reducedAttachments.indexOf(action.attachment), 1);
        return _objectSpread(_objectSpread({}, state), {}, {
          attachments: reducedAttachments,
          uploadError: null
        });
      }

    case _actionTypes.INITIALIZE_ATTACHMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadedAttachments: 0,
        attachments: [],
        attachment_to_add: {}
      });

    default:
      return state;
  }
}