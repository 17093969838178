"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListWrap = exports.NoResultsText = exports.ListSection = exports.InlineAction = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

const InlineAction = _styledComponents.default.button.attrs({
  type: "button"
})(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: inline-block;\n  border: 0;\n  background: transparent;\n  margin: 0;\n  padding: ", " ", ";\n\n  ", "\n  color: ", ";\n"])), _blocks.spacing.extraTight, _blocks.spacing.tight, _blocks.typography.bodySmall, _blocks.palette.blue7);

exports.InlineAction = InlineAction;

const ListSection = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  margin-bottom: ", ";\n  padding: 0 ", ";\n  padding-bottom: ", ";\n"])), _blocks.spacing.snug, _blocks.spacing.extraTight, _blocks.spacing.extraTight);

exports.ListSection = ListSection;

const NoResultsText = _styledComponents.default.p(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  ", "\n"])), _blocks.typography.body);

exports.NoResultsText = NoResultsText;

const ListWrap = _styledComponents.default.div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)([""])));

exports.ListWrap = ListWrap;