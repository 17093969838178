"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.handleCreateAndAssociate = handleCreateAndAssociate;
exports.watchCreateAndAssociate = watchCreateAndAssociate;
exports.createActionRequestChain = exports.createAction = exports.CREATE_ACTION_REQUEST_FAILURE = exports.CREATE_ACTION_REQUEST_SUCCESS = exports.CREATE_ACTION_REQUEST = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _effects = require("redux-saga/effects");

var _associate = require("./associate");

var _eventTracking = require("../../../utils/eventTracking");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const INITIAL_STATE = {
  isCreating: false
};
const CREATE_ACTION = "bonfire/issues/CREATE_ACTION";
const CREATE_ACTION_REQUEST = "bonfire/issues/CREATE_ACTION_REQUEST";
exports.CREATE_ACTION_REQUEST = CREATE_ACTION_REQUEST;
const CREATE_ACTION_REQUEST_SUCCESS = "bonfire/issues/CREATE_ACTION_REQUEST_SUCCESS";
exports.CREATE_ACTION_REQUEST_SUCCESS = CREATE_ACTION_REQUEST_SUCCESS;
const CREATE_ACTION_REQUEST_FAILURE = "bonfire/issues/CREATE_ACTION_REQUEST_FAIL";
exports.CREATE_ACTION_REQUEST_FAILURE = CREATE_ACTION_REQUEST_FAILURE;

const createAction = issue => ({
  type: CREATE_ACTION,
  issue
});

exports.createAction = createAction;

const createActionRequestChain = (user, issue) => ({
  types: [CREATE_ACTION_REQUEST, CREATE_ACTION_REQUEST_SUCCESS, CREATE_ACTION_REQUEST_FAILURE],
  promise: client => client.post("/issue", {
    headers: {
      Authorization: "Bearer ".concat(user.token)
    },
    data: _objectSpread({}, issue)
  })
});

exports.createActionRequestChain = createActionRequestChain;

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case CREATE_ACTION_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isCreating: true
      });

    case CREATE_ACTION_REQUEST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isCreating: false
      });

    case CREATE_ACTION_REQUEST_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isCreating: false
      });

    default:
      return state;
  }
} // TODO: This should probably live in the `associate.js` sagas


function* handleCreateAndAssociate(action) {
  try {
    const {
      projects
    } = yield (0, _effects.select)(state => state.userSites);
    const site = yield (0, _effects.select)(state => state.betaSelections.activeSite);
    const {
      user
    } = yield (0, _effects.select)(state => state.auth);
    const projectKey = projects[site.projectId].jira_key;
    const {
      target
    } = action.issue;

    const issue = _objectSpread(_objectSpread({}, action.issue), {}, {
      projectKey,
      components: [{
        name: site.id.split("~")[1]
      }],
      target: "",
      issueType: "Spark",
      transition: action.issue.status,
      labels: ["insite-created", "user-created"]
    });

    yield (0, _effects.put)(createActionRequestChain(user, issue));
    const {
      success,
      failure
    } = yield (0, _effects.race)({
      success: (0, _effects.take)(CREATE_ACTION_REQUEST_SUCCESS),
      failure: (0, _effects.take)(CREATE_ACTION_REQUEST_FAILURE)
    });
    if (failure) throw new Error("Error creating issue: ".concat(failure.error.message));

    if (success) {
      // Associate equip/rules to newly created issue
      const {
        issueKey
      } = success.result;
      yield (0, _effects.put)((0, _associate.associateEquipRule)({
        equipRuleIds: target,
        issueKey
      }));
      const {
        associateSuccess,
        associateFailure
      } = yield (0, _effects.race)({
        associateSuccess: (0, _effects.take)(_associate.ASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS),
        associateFailure: (0, _effects.take)(_associate.ASSOCIATE_RULE_EQUIP_REQUEST_FAIL)
      });
      const associateResult = associateSuccess ? associateSuccess.result : null;
      const associateError = associateFailure ? "Failed to associate" : null;
      (0, _eventTracking.addedActionEvent)(success.result.issueKey, action.issue, site, associateResult, associateError);
    }
  } catch (err) {
    yield (0, _effects.put)({
      type: CREATE_ACTION_REQUEST_FAILURE,
      error: err
    });
  }
}

function* watchCreateAndAssociate() {
  yield (0, _effects.takeLatest)(CREATE_ACTION, handleCreateAndAssociate);
}