"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleChange = exports.handleSubmit = exports.changePasswordRequest = exports.handleClose = exports.startChangePassword = void 0;

var _actionTypes = require("./actionTypes");

// TODO: Remove unused actionTypes/Creators for old change password modal
const startChangePassword = () => ({
  type: _actionTypes.CHANGING_PASSWORD
});

exports.startChangePassword = startChangePassword;

const handleClose = () => ({
  type: _actionTypes.END_ACTION
});

exports.handleClose = handleClose;

const changePasswordRequest = () => ({
  type: _actionTypes.CHANGE_PASSWORD_REQUEST
}); // TODO: uh..???


exports.changePasswordRequest = changePasswordRequest;

const handleSubmit = func => func();

exports.handleSubmit = handleSubmit;

const handleChange = (e, inputId) => {
  let inputValue = "";

  if (!inputId) {
    // eslint-disable-next-line
    inputId = e.target.id;

    if (e.target.type === "checkbox") {
      inputValue = e.target.checked;
    } else {
      inputValue = e.target.value;
    }
  } else {
    inputValue = e;
  }

  return {
    type: _actionTypes.EDITING_PROFILE_FORM,
    input_id: inputId,
    input_value: inputValue
  };
};

exports.handleChange = handleChange;