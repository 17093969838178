"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _actionTypes = require("./actionTypes");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const initialState = {
  data: undefined,
  error: undefined,
  site: "",
  loading: false,
  loaded: false
};

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actionTypes.LOAD_REPORT_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        loaded: false
      });

    case _actionTypes.LOAD_REPORT_DATA_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        data: action.result.data,
        site: action.result.site,
        loading: false,
        loaded: true
      });

    case _actionTypes.LOAD_REPORT_DATA_FAIL:
      // TODO: Add proper error handling
      return _objectSpread(_objectSpread({}, state), {}, {
        data: state.data,
        loading: false,
        loaded: false,
        error: "Something went wrong"
      });

    default:
      return state;
  }
}