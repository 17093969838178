"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.selectActiveProject = selectActiveProject;
exports.selectActiveSite = selectActiveSite;
exports.updateIsMultiSiteRoute = updateIsMultiSiteRoute;
exports.updateSelectedSites = updateSelectedSites;
exports.updateDaysRange = updateDaysRange;
exports.setLocale = setLocale;
exports.selectActiveSiteFlow = selectActiveSiteFlow;
exports.selectActiveProjectFlow = selectActiveProjectFlow;
exports.updateSelectedSitesFlow = updateSelectedSitesFlow;
exports.selectionsFlow = selectionsFlow;
exports.SET_LOCALE = exports.UPDATE_DAYS_RANGE = exports.UPDATE_IS_MULTI_SITE_ROUTE = exports.UPDATE_SELECTED_SITES = exports.UPDATE_ACTIVE_PROJECT = exports.UPDATE_ACTIVE_SITE = exports.SELECT_BETA_PROJECT = exports.SELECT_BETA_SITE = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _effects = require("redux-saga/effects");

var _reduxPersist = require("redux-persist");

var _moment = _interopRequireDefault(require("moment"));

var _actionTypes = require("../userSites/actionTypes");

var _i18n = require("../../../i18n");

var _actionTypes2 = require("../appSetup/actionTypes");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const INITIAL_STATE = {
  activeProject: {
    jira_key: "",
    sites: [],
    skysparkURL: ""
  },
  activeSite: {
    label: "",
    id: "",
    syncoId: undefined
  },
  selectedSites: [],
  daysRange: 3,
  locale: _i18n.defaultLocale,
  isMultiSite: true
}; // Global site select

const SELECT_BETA_SITE = "bonfire/betaSelections/SELECT_BETA_SITE";
exports.SELECT_BETA_SITE = SELECT_BETA_SITE;
const SELECT_BETA_PROJECT = "bonfire/betaSelections/SELECT_BETA_PROJECT";
exports.SELECT_BETA_PROJECT = SELECT_BETA_PROJECT;
const UPDATE_ACTIVE_SITE = "bonfire/betaSelections/UPDATE_ACTIVE_SITE";
exports.UPDATE_ACTIVE_SITE = UPDATE_ACTIVE_SITE;
const UPDATE_ACTIVE_PROJECT = "bonfire/betaSelections/UPDATE_ACTIVE_PROJECT";
exports.UPDATE_ACTIVE_PROJECT = UPDATE_ACTIVE_PROJECT;
const UPDATE_SELECTED_SITES = "bonfire/betaSelections/UPDATE_SELECTED_SITES";
exports.UPDATE_SELECTED_SITES = UPDATE_SELECTED_SITES;
const UPDATE_IS_MULTI_SITE_ROUTE = "bonfire/betaSelections/UPDATE_IS_MULTI_SITE_ROUTE"; // Triage time range select

exports.UPDATE_IS_MULTI_SITE_ROUTE = UPDATE_IS_MULTI_SITE_ROUTE;
const UPDATE_DAYS_RANGE = "bonfire/betaSelections/UPDATE_DAYS_RANGE"; // Locale option select

exports.UPDATE_DAYS_RANGE = UPDATE_DAYS_RANGE;
const SET_LOCALE = "bonfire/betaSelections/SET_LOCALE"; // TODO: With multiselect we'll probably end up removing this concept of an active site.

exports.SET_LOCALE = SET_LOCALE;

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case _reduxPersist.PURGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeProject: INITIAL_STATE.activeProject
      });

    case UPDATE_ACTIVE_PROJECT:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeProject: action.project
      });

    case UPDATE_ACTIVE_SITE:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeProject: action.project,
        activeSite: action.site
      });

    case UPDATE_IS_MULTI_SITE_ROUTE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isMultiSite: action.isMultiSite
      });

    case UPDATE_SELECTED_SITES:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedSites: action.sites
      });

    case UPDATE_DAYS_RANGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        daysRange: action.daysRange
      });

    case SET_LOCALE:
      return _objectSpread(_objectSpread({}, state), {}, {
        locale: action.locale
      });

    default:
      return state;
  }
}

function selectActiveProject(projectId) {
  return {
    type: SELECT_BETA_PROJECT,
    projectId
  };
}

function updateActiveProject(project) {
  return {
    type: UPDATE_ACTIVE_PROJECT,
    project
  };
}

function selectActiveSite(syncoId) {
  return {
    type: SELECT_BETA_SITE,
    syncoId
  };
}

function updateActiveSite(site, project) {
  return {
    type: UPDATE_ACTIVE_SITE,
    site,
    project
  };
}

function updateIsMultiSiteRoute(isMultiSite) {
  return {
    type: UPDATE_IS_MULTI_SITE_ROUTE,
    isMultiSite
  };
}

function updateSelectedSites(sites) {
  return {
    type: UPDATE_SELECTED_SITES,
    sites
  };
}

function updateDaysRange() {
  let daysRange = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 3;
  return {
    type: UPDATE_DAYS_RANGE,
    daysRange
  };
}

function setLocale(locale) {
  return {
    type: SET_LOCALE,
    locale
  };
} // TODO: finish this


function* selectActiveSiteFlow() {
  while (true) {
    const {
      syncoId
    } = yield (0, _effects.take)(SELECT_BETA_SITE);
    const {
      activeSite
    } = yield (0, _effects.select)(state => state.betaSelections);
    const {
      isLoading
    } = yield (0, _effects.select)(state => state.userSites);
    if (isLoading) yield (0, _effects.take)(_actionTypes.LOAD_USER_SITES_COMPLETE);

    if (!activeSite || !activeSite.syncoId || activeSite.syncoId !== syncoId) {
      const {
        projects,
        sites
      } = yield (0, _effects.select)(state => state.userSites);
      const site = sites[syncoId];
      const project = projects[site.projectId];
      yield (0, _effects.put)(updateActiveSite(site, project));
    }
  }
}

function* selectActiveProjectFlow() {
  while (true) {
    const {
      projectId
    } = yield (0, _effects.take)(SELECT_BETA_PROJECT);
    const {
      activeProject
    } = yield (0, _effects.select)(state => state.betaSelections);

    if (activeProject.project_pk !== projectId) {
      const {
        projects
      } = yield (0, _effects.select)(state => state.userSites);
      const project = projects[projectId];
      yield (0, _effects.put)(updateActiveProject(project));
    }
  }
}

function* updateSelectedSitesFlow() {
  yield (0, _effects.take)(_actionTypes2.APP_SETUP_COMPLETE);

  while (true) {
    const {
      sites: syncoIds
    } = yield (0, _effects.take)(UPDATE_SELECTED_SITES);
    const {
      activeSite,
      selectedSites
    } = yield (0, _effects.select)(state => state.betaSelections);
    const {
      sites
    } = yield (0, _effects.select)(state => state.userSites); // If the currently active site doesn't exist in the new list of selected
    // sites, update the active site to be the first of the newly selected sites
    // This will ensure that Actions and Insights are never displaying data for a site that isn't selected

    if (!activeSite.syncoId || !syncoIds.includes(activeSite.syncoId)) {
      // If there are already selected sites, use the first site in the list
      // Otherwise use the first from sites available to user
      const firstSite = selectedSites.length > 0 ? selectedSites[0] : sites[syncoIds[0]].syncoId;
      yield (0, _effects.put)(selectActiveSite(firstSite));
    }
  }
}

function* updateLocale() {
  yield (0, _effects.take)(_reduxPersist.REHYDRATE);
  const userLocale = yield (0, _effects.select)(state => state.betaSelections.locale);

  _i18n.i18n.activate(userLocale);

  _moment.default.updateLocale(userLocale, {
    relativeTime: {
      future: "%s",
      past: "%s"
    }
  });

  while (true) {
    const {
      locale
    } = yield (0, _effects.take)(SET_LOCALE);

    _i18n.i18n.activate(locale);

    _moment.default.updateLocale(userLocale, {
      relativeTime: {
        future: "%s",
        past: "%s"
      }
    });

    if (window.zE) {
      window.zE("webWidget", "setLocale", locale);
    }
  }
}

function* selectionsFlow() {
  yield (0, _effects.fork)(updateLocale);
  yield (0, _effects.take)(_actionTypes.LOAD_USER_SITES_COMPLETE);
  yield (0, _effects.fork)(selectActiveSiteFlow);
  yield (0, _effects.fork)(selectActiveProjectFlow);
  yield (0, _effects.fork)(updateSelectedSitesFlow);
}