"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.truncateString = truncateString;
exports.escapeRegExp = escapeRegExp;

function truncateString(str, maxSize) {
  if (str.length > maxSize) {
    return "".concat(str.substring(0, maxSize - 3), "...");
  }

  return str;
}
/**
 * Escape regex-sensitive characters
 */


function escapeRegExp() {
  let text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  return text.replace(/[-[\]{}()*+?.,\\^$|#]/g, "\\$&");
}