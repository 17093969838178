"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SortBy = exports.ReadingType = exports.MetricsType = exports.MetricsUnit = exports.TimeFrame = void 0;
let TimeFrame;
exports.TimeFrame = TimeFrame;

(function (TimeFrame) {
  TimeFrame[TimeFrame["YearToDate"] = 0] = "YearToDate";
  TimeFrame[TimeFrame["Last2Years"] = 1] = "Last2Years";
  TimeFrame[TimeFrame["Last3Years"] = 2] = "Last3Years";
})(TimeFrame || (exports.TimeFrame = TimeFrame = {}));

let MetricsUnit; // Keep values equivalent to API keys

exports.MetricsUnit = MetricsUnit;

(function (MetricsUnit) {
  MetricsUnit["Electricity"] = "kWh";
  MetricsUnit["Gas"] = "MJ";
  MetricsUnit["Water"] = "kL";
})(MetricsUnit || (exports.MetricsUnit = MetricsUnit = {}));

let MetricsType; // Keep values equivalent to API keys except ALL

exports.MetricsType = MetricsType;

(function (MetricsType) {
  MetricsType["Electricity"] = "Electricity";
  MetricsType["Gas"] = "Gas";
  MetricsType["Water"] = "Water";
})(MetricsType || (exports.MetricsType = MetricsType = {}));

let ReadingType;
exports.ReadingType = ReadingType;

(function (ReadingType) {
  ReadingType["All"] = "ALL";
  ReadingType["Actual"] = "ACTUAL";
  ReadingType["Estimated"] = "ESTIMATED";
  ReadingType["Interpolated"] = "INTERPOLATED";
  ReadingType["Forecast"] = "FORECAST";
})(ReadingType || (exports.ReadingType = ReadingType = {}));

let SortBy;
exports.SortBy = SortBy;

(function (SortBy) {
  SortBy["TotalConsumption"] = "TotalConsumption";
  SortBy["LatestConsumption"] = "LatestConsumption";
  SortBy["Key"] = "Key";
})(SortBy || (exports.SortBy = SortBy = {}));