"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$SelectedSite = exports.$Site = exports.$Project = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3;

function isSelected(_ref) {
  let {
    active
  } = _ref;
  const weight = active ? _blocks.fontWeight.bold : _blocks.fontWeight.normal;
  return "font-weight: ".concat(weight);
}

function isNoMatch(_ref2) {
  let {
    noMatch
  } = _ref2;
  return noMatch ? "\n    font-weight: normal;\n    color: #dadada;\n    opacity: 0.4;\n  " : "";
}

const $Project = _styledComponents.default.button.attrs({
  type: "button"
})(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border: none;\n  outline: 0;\n  width: 100%;\n  /* TODO: hacky  */\n  padding: ", " ", " !important;\n  font-size: 14px;\n  line-height: 1;\n  text-align: left;\n  color: ", ";\n  ", ";\n  ", ";\n  cursor: pointer;\n  background: transparent;\n\n  &:hover {\n    background: ", ";\n  }\n\n  &:active,\n  &:focus {\n    outline: 0;\n  }\n"])), _blocks.spacing.tight, _blocks.spacing.default, _blocks.color.theme.black, isSelected, isNoMatch, _blocks.color.theme.supportingColor.tint);

exports.$Project = $Project;

const $Site = _styledComponents.default.button(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  padding: 0 ", ";\n  color: ", ";\n  font-size: 14px;\n  line-height: 32px;\n  outline: 0;\n  border: none;\n  background: none;\n  ", ";\n\n  .Site-label {\n    padding-right: ", ";\n    flex-grow: 1;\n    text-align: left;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n  }\n\n  &:hover {\n    background: ", ";\n  }\n"])), _blocks.spacing.default, _blocks.color.theme.black, isNoMatch, _blocks.spacing.default, _blocks.color.theme.supportingColor.tint);

exports.$Site = $Site;
const $SelectedSite = (0, _styledComponents.default)($Site)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  color: ", ";\n  font-size: 13px;\n  font-weight: bold;\n\n  svg {\n    display: none;\n    fill: ", ";\n  }\n\n  &:hover svg {\n    display: inline-block;\n  }\n"])), _blocks.palette.blue7, _blocks.palette.blue7);
exports.$SelectedSite = $SelectedSite;