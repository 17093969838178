"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.postTemplateDetails = exports.getTemplateDetails = exports.postSiteRules = exports.getSiteRules = exports.postSiteDetails = exports.getSiteDetails = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _config = _interopRequireDefault(require("../config"));

// GET request for SITE DETAILS
const getSiteDetails = (syncoId, callback, handleError) => {
  _axios.default.get("".concat(_config.default.iwsRouteBase).concat(_config.default.iwsUrlPrefix, "/").concat(syncoId, "/synco"), {
    url: "".concat(_config.default.iwsUrlPrefix, "/").concat(syncoId, "/synco"),
    baseURL: "".concat(_config.default.iwsRouteBase),
    headers: {
      "Content-Type": "application/json"
    }
  }).then(response => {
    if (callback) {
      callback(response.data);
    }
  }).catch(ex => {
    if (ex.response) {
      if (handleError) {
        handleError({
          data: ex.response.data,
          url: ex.response.config.url
        });
      }
    } else if (handleError) {
      handleError({
        data: "Network error",
        url: "undefined"
      });
    }
  });
}; // POST request for SITE DETAILS


exports.getSiteDetails = getSiteDetails;

const postSiteDetails = (syncoId, siteDetails, callback, handleError) => {
  _axios.default.post("".concat(_config.default.iwsRouteBase).concat(_config.default.iwsUrlPrefix, "/").concat(syncoId, "/update/synco"), siteDetails, {
    url: "".concat(_config.default.iwsUrlPrefix, "/").concat(syncoId, "/update/synco"),
    baseURL: "".concat(_config.default.iwsRouteBase)
  }).then(response => {
    if (callback) {
      callback(response.data);
    }
  }).catch(ex => {
    if (ex.response) {
      if (handleError) {
        handleError({
          data: ex.response.data,
          url: ex.response.config.url
        });
      }
    } else if (handleError) {
      handleError({
        data: "Network error",
        url: "undefined"
      });
    }
  });
}; // GET request for SITE RULES


exports.postSiteDetails = postSiteDetails;

const getSiteRules = (syncoId, callback, handleError) => {
  _axios.default.get("".concat(_config.default.iwsRouteBase).concat(_config.default.iwsUrlPrefix, "/").concat(syncoId, "/rules/synco"), {
    url: "".concat(_config.default.iwsUrlPrefix, "/").concat(syncoId, "/rules/synco"),
    baseURL: "".concat(_config.default.iwsRouteBase),
    headers: {
      "Content-Type": "application/json"
    }
  }).then(response => {
    if (callback) {
      callback(response.data);
    }
  }).catch(ex => {
    if (ex.response) {
      if (handleError) {
        handleError({
          data: ex.response.data,
          url: ex.response.config.url
        });
      }
    } else if (handleError) {
      handleError({
        data: "Network error",
        url: "undefined"
      });
    }
  });
}; // POST request for SITE RULES


exports.getSiteRules = getSiteRules;

const postSiteRules = (syncoId, siteRules, callback, handleError) => {
  _axios.default.post("".concat(_config.default.iwsRouteBase).concat(_config.default.iwsUrlPrefix, "/").concat(syncoId, "/rules/update/synco"), siteRules, {
    url: "".concat(_config.default.iwsUrlPrefix, "/").concat(syncoId, "/rules/update/synco"),
    baseURL: "".concat(_config.default.iwsRouteBase)
  }).then(response => {
    if (callback) {
      callback(response.data);
    }
  }).catch(ex => {
    if (ex.response) {
      if (handleError) {
        handleError({
          data: ex.response.data,
          url: ex.response.config.url
        });
      }
    } else if (handleError) {
      handleError({
        data: "Network error",
        url: "undefined"
      });
    }
  });
}; // GET request for Templates


exports.postSiteRules = postSiteRules;

const getTemplateDetails = (syncoId, callback, handleError) => {
  _axios.default.get("".concat(_config.default.iwsRouteBase).concat(_config.default.iwsUrlPrefix, "/").concat(syncoId, "/templates/synco"), {
    url: "".concat(_config.default.iwsUrlPrefix, "/").concat(syncoId, "/templates/synco"),
    baseURL: "".concat(_config.default.iwsRouteBase),
    headers: {
      "Content-Type": "application/json"
    }
  }).then(response => {
    if (callback) {
      callback(response.data);
    }
  }).catch(ex => {
    if (ex.response) {
      if (handleError) {
        handleError({
          data: ex.response.data,
          url: ex.response.config.url
        });
      }
    } else if (handleError) {
      handleError({
        data: "Network error",
        url: "undefined"
      });
    }
  });
};

exports.getTemplateDetails = getTemplateDetails;

const postTemplateDetails = (syncoId, templates, callback, handleError) => {
  _axios.default.post("".concat(_config.default.iwsRouteBase).concat(_config.default.iwsUrlPrefix, "/").concat(syncoId, "/templates/update/synco"), templates, {
    url: "".concat(_config.default.iwsUrlPrefix, "/").concat(syncoId, "/templates/update/synco"),
    baseURL: "".concat(_config.default.iwsRouteBase)
  }).then(response => {
    if (callback) {
      callback(response.data);
    }
  }).catch(ex => {
    if (ex.response) {
      if (handleError) {
        handleError({
          data: ex.response.data,
          url: ex.response.config.url
        });
      }
    } else if (handleError) {
      handleError({
        data: "Network error",
        url: "undefined"
      });
    }
  });
};

exports.postTemplateDetails = postTemplateDetails;