"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash.isequal"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const reducer = (state, action) => {
  const actionType = action.type;

  switch (action.type) {
    case "UPDATE_INSIGHT_TYPE":
      return _objectSpread(_objectSpread({}, state), {}, {
        shouldReset: false,
        insightType: action.insightType
      });

    case "UPDATE_ACTIVE_PROJECT":
      {
        if (state.activeProject === action.activeProject) return state;
        let confirm = true;

        if (!state.pristine) {
          confirm = window.confirm("You're about to discard all unsaved changes, continue?");
        }

        if (confirm) {
          return _objectSpread(_objectSpread({}, state), {}, {
            activeProject: action.activeProject,
            selectedRule: undefined
          });
        }

        return state;
      }

    case "SAVE_CONFIG":
      return _objectSpread(_objectSpread({}, state), {}, {
        isSaving: true
      });

    case "LOAD_CONFIG":
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });

    case "LOAD_CONFIG_SUCCESS":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          shouldReset: false,
          isLoading: false,
          isSaving: false,
          saved: action.saved,
          active: action.saved,
          pristine: true
        });
      }

    case "LOAD_CONFIG_FAIL":
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        isSaving: false
      });

    case "UPDATE_CONFIG_RULES":
      {
        const active = _objectSpread({}, state.active); // tslint:disable-next-line: strict-type-predicates


        const removeRule = active[action.rule.rule.id] !== undefined;

        if (removeRule) {
          delete active[action.rule.rule.id];
        } else {
          // NOTE: Strip unused rule properties ruleType and severity
          const existing = state.saved[action.rule.rule.id] || _objectSpread({
            // @ts-ignore
            priority: 1
          }, action.rule);

          active[action.rule.rule.id] = _objectSpread(_objectSpread({}, existing), action.rule);
        }

        const pristine = (0, _lodash.default)(active, state.saved);
        return _objectSpread(_objectSpread({}, state), {}, {
          active,
          pristine,
          shouldReset: false
        });
      }

    case "SET_SELECTED_RULE":
      {
        const selectedRule = state.active[action.selectedRule.rule.id] || state.saved[action.selectedRule.rule.id] || action.selectedRule;
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedRule,
          shouldReset: false
        });
      }

    case "UPDATE_RULE_CONFIG":
      {
        const existing = state.saved[action.rule.rule.id] || {};

        const active = _objectSpread(_objectSpread({}, state.active), {}, {
          [action.rule.rule.id]: _objectSpread(_objectSpread({}, existing), action.rule)
        });

        const pristine = (0, _lodash.default)(active, state.saved);
        return _objectSpread(_objectSpread({}, state), {}, {
          active,
          selectedRule: action.rule,
          shouldReset: false,
          pristine
        });
      }

    case "BULK_UPDATE_RULE_CONFIG":
      const pristine = (0, _lodash.default)(action.rules, state.saved);
      return _objectSpread(_objectSpread({}, state), {}, {
        active: action.rules,
        pristine
      });

    case "RESET_CONFIG":
      return _objectSpread(_objectSpread({}, state), {}, {
        active: state.saved,
        shouldReset: true,
        pristine: true
      });
    // TODO: Error state
    // case 'LOAD_RULES_FAIL':

    default:
      throw new Error("Unhandled action type: ".concat(actionType));
  }
};

exports.reducer = reducer;