"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.logout = logout;
exports.analyticsIdentify = analyticsIdentify;
exports.zendeskInit = zendeskInit;

var _effects = require("redux-saga/effects");

var _reduxPersist = require("redux-persist");

var _jsonwebtoken = _interopRequireDefault(require("jsonwebtoken"));

var _zendesk = require("../../../helpers/zendesk");

var _actionTypes = require("./actionTypes");

var _utils = require("../_BETA/utils");

var eventTracking = _interopRequireWildcard(require("../../../utils/eventTracking"));

var _UserService = _interopRequireDefault(require("../../../utils/keycloak/UserService"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function* logout() {
  while (true) {
    yield (0, _effects.take)(_actionTypes.LOGOUT_KEYCLOAK); // Reset segment

    if (window.analytics) {
      window.analytics.reset();
    } // Reset Zendesk integrations


    if (window.zE) {
      window.zE('webWidget', 'logout');
      window.zE('webWidget', 'reset');
      window.zE('webWidget', 'hide');
    } // Reset persisted state
    // TODO: The store value here can't be asserted until client.js is converted
    // to TypeScript


    const store = require('../../../client').store;

    const state = (0, _utils.resetState)(store.getState());
    localStorage.setItem('persist:state', JSON.stringify({
      state
    })); // Complete logout flow and trigger redirect

    yield (0, _effects.call)(logoutFromKeycloak);
    yield (0, _effects.put)({
      type: _actionTypes.LOGOUT_SUCCESS
    });
  }
}

function logoutFromKeycloak() {
  return new Promise(() => {
    _UserService.default.doLogout();
  });
}

function* analyticsIdentify() {
  yield (0, _effects.take)(_reduxPersist.REHYDRATE);
  let loggedInUser = null; // NOTE: Intentionally null

  let existingUser = yield (0, _effects.select)(state => state.auth.user); // If there is nothing from the persisted state, wait until they've logged in
  // and set the logged in user value

  if (!existingUser || !existingUser.token) {
    const {
      result
    } = yield (0, _effects.take)(_actionTypes.LOAD_AUTH_USER_SUCCESS);
    loggedInUser = result;
  }

  if (process.env.SEGMENT_WRITE_KEY && window.analytics) {
    window.analytics.load(process.env.SEGMENT_WRITE_KEY); // If the logged in user value is not null, someone has had to log in
    // manually. Set this to the existing user value and track that they've
    // logged in

    if (loggedInUser !== null) {
      existingUser = loggedInUser;
      eventTracking.userLogin();
    } // NOTE: We intentionally do not 'alias' users as we only want to track
    // registered, logged in users
    // Identify a user after the user object is fetched. We want to do this
    // on any page change (or 'refresh' in a client app)


    window.analytics.identify(existingUser.id, {
      email: existingUser.email,
      name: existingUser.display_name,
      product: 'Bonfire'
    });
  }
}

function* zendeskInit() {
  const {
    ZENDESK_SHARED_SECRET
  } = process.env;
  yield (0, _effects.take)(_reduxPersist.REHYDRATE); // If there's no shared secret, the widget script isn't loaded at all

  if (!ZENDESK_SHARED_SECRET) return; // Set some defaults before the widget is initialised

  window.zESettings = _zendesk.zendeskSettings; // Initialise zendesk widget, hide by default, show when user is authenticated

  if (window.zE) {
    const {
      locale
    } = yield (0, _effects.select)(state => state.betaSelections);
    window.zE('webWidget', 'setLocale', locale);
    window.zE('webWidget', 'hide');
    let user = yield (0, _effects.select)(state => state.auth.user);

    if (!user || !user.token) {
      const {
        result
      } = yield (0, _effects.take)(_actionTypes.LOAD_AUTH_USER_SUCCESS);
      user = result;
    }

    const zendeskToken = _jsonwebtoken.default.sign({
      name: user.display_name,
      email: user.email,
      iat: Math.floor(Date.now() / 1000),
      jti: Math.random().toString(36).substring(2) // unique token string

    }, ZENDESK_SHARED_SECRET);

    window.zE('webWidget', 'updateSettings', {
      authenticate: {
        jwt: zendeskToken
      }
    });
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'identify', {
      name: user.display_name,
      email: user.email
    });
    window.zE('webWidget', 'prefill', {
      name: {
        value: user.display_name,
        readOnly: true
      },
      email: {
        value: user.email,
        readOnly: true
      }
    });
  }
}