"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleDataGridHeading = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject;

const StyleDataGridHeading = (0, _styledComponents.default)(_blocks.DataGridHeading)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  justify-content: flex-end;\n"])));
exports.StyleDataGridHeading = StyleDataGridHeading;