"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$TabLabel = exports.$SelectionLabel = exports.$SelectionInfo = exports.$Header = exports.$Container = exports.CloseButton = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _App = require("../../../components/AppChrome/App.constants");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

const $Container = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"])));

exports.$Container = $Container;

const $Header = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  position: relative;\n  padding: ", ";\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n"])), _blocks.spacing.tight);

exports.$Header = $Header;

const $SelectionInfo = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  padding: ", ";\n  display: grid;\n  grid-row-gap: 0;\n  grid-column-gap: ", ";\n  grid-template-columns: 50px auto;\n  background-color: ", ";\n  font-size: 14px;\n  line-height: 20px;\n  color: ", ";\n  border-top: 1px solid ", ";\n"])), _blocks.spacing.default, _blocks.spacing.tight, _blocks.color.pureWhite, _blocks.color.theme.typography.ink, _blocks.color.theme.divider.solid);

exports.$SelectionInfo = $SelectionInfo;

const $SelectionLabel = _styledComponents.default.span(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  font-size: 12px;\n  font-weight: ", ";\n"])), _App.TYPOGRAPHY.WEIGHTS.BOLD);

exports.$SelectionLabel = $SelectionLabel;

const $TabLabel = _styledComponents.default.span(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  height: ", ";\n  line-height: ", ";\n  display: inline-block;\n  border: none;\n  padding: 0 ", ";\n  outline: 0;\n  font-size: 14px;\n  font-weight: ", ";\n  color: ", ";\n  text-decoration: none;\n"])), _blocks.spacing.ultraLoose, _blocks.spacing.ultraLoose, _blocks.spacing.tight, _App.TYPOGRAPHY.WEIGHTS.BOLD, _blocks.color.theme.typography.ink);

exports.$TabLabel = $TabLabel;
const CloseButton = (0, _styledComponents.default)(_blocks.IconButton)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: ", ";\n  margin: auto 0;\n"])), _blocks.spacing.snug);
exports.CloseButton = CloseButton;