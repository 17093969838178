"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PerformanceTertiaryLinks = exports.SecondaryLinks = exports.links = exports.Route = void 0;
let Route;
exports.Route = Route;

(function (Route) {
  Route["Root"] = "/reports";
  Route["Performance"] = "/reports/performance";
  Route["Summary"] = "/reports/summary";
  Route["Generator"] = "/reports/generate";
  Route["UtilitiesPortfolio"] = "/reports/performance/utilities";
  Route["UtilitiesType"] = "/reports/performance/utilities/:type";
  Route["UtilitiesSite"] = "/reports/performance/utilities/:type/:siteId";
  Route["WorkflowPortfolio"] = "/reports/performance/workflow";
  Route["WorkflowSite"] = "/reports/performance/workflow/site/:siteId";
  Route["WorkflowTeam"] = "/reports/performance/workflow/team/:teamId";
  Route["ComfortPortfolio"] = "/reports/performance/comfort";
  Route["ComfortZones"] = "/reports/performance/comfort/:siteId";
  Route["Equipment"] = "/reports/performance/equipment";
})(Route || (exports.Route = Route = {}));

const links = [{
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "reports.performance",
    defaults: "Performance"
  }),
  href: Route.Performance
}, {
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "reports.monthly_summary",
    defaults: "Monthly Summary"
  }),
  href: Route.Summary
}, {
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "reports.generator",
    defaults: "Generator"
  }),
  href: Route.Generator
}];
exports.links = links;
const SecondaryLinks = !process.env.BETA ? links.filter(l => !l.isBeta) : links;
exports.SecondaryLinks = SecondaryLinks;
const PerformanceTertiaryLinks = [{
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "reports.performance_workflow",
    defaults: "Workflow"
  }),
  href: Route.WorkflowPortfolio
}, {
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "reports.performance_comfort",
    defaults: "Comfort"
  }),
  href: Route.ComfortPortfolio
}, {
  label: i18n => i18n._(
  /*i18n*/
  {
    id: "reports.performance_utilities",
    defaults: "Utilities"
  }),
  href: Route.UtilitiesPortfolio
} // {
//   label: (i18n: I18n) => i18n._(t('reports.performance_sysequip')`Systems & Equipment`),
//   href: Route.Equipment
// }
];
exports.PerformanceTertiaryLinks = PerformanceTertiaryLinks;