"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.CHANGE_PASSWORD_MODAL_DATA = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _actionTypes = require("./actionTypes");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const INITIAL_PROFILE_FORM_DATA = {};
const initialState = {
  working: false,
  // indicates that remote fetching is in progress
  changing_password: false,
  // shows the modal dialog
  profile_form_data: INITIAL_PROFILE_FORM_DATA,
  modal_data: {},
  success: null
}; // OTHER CONSTANTS

const CHANGE_PASSWORD_MODAL_DATA = {
  title: "Change User Password",
  submitText: "Update Password",
  submitStyle: "success"
};
exports.CHANGE_PASSWORD_MODAL_DATA = CHANGE_PASSWORD_MODAL_DATA;

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actionTypes.CHANGING_PASSWORD:
      return _objectSpread(_objectSpread({}, state), {}, {
        changing_password: true,
        modal_data: CHANGE_PASSWORD_MODAL_DATA
      });

    case _actionTypes.CHANGE_PASSWORD:
      return _objectSpread(_objectSpread({}, state), {}, {
        working: true
      });

    case _actionTypes.CHANGE_PASSWORD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        working: false,
        error: ["".concat(action.error.message)] || ["".concat(action.error.error)]
      });

    case _actionTypes.CHANGE_PASSWORD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: null,
        working: false,
        changing_password: false,
        profile_form_data: INITIAL_PROFILE_FORM_DATA,
        success: true
      });

    case _actionTypes.EDITING_PROFILE_FORM:
      return _objectSpread(_objectSpread({}, state), {}, {
        profile_form_data: _objectSpread(_objectSpread({}, state.profile_form_data), {}, {
          [action.input_id]: action.input_value
        })
      });

    case _actionTypes.END_ACTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        // TODO: should working be here??? if so, add to users.js also
        working: false,
        changing_password: false,
        profile_form_data: INITIAL_PROFILE_FORM_DATA,
        error: null
      });

    case _actionTypes.VALIDATE_FORM_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error
      });

    default:
      return state;
  }
}