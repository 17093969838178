"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clearPersistedUI = clearPersistedUI;
exports.setStorage = setStorage;
exports.getStorage = getStorage;
exports.clearStorage = clearStorage;
exports.getPersisted = getPersisted;
exports.setPersisted = setPersisted;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const BONFIRE_STORAGE_KEY = "BonfireLS";

function clearPersistedUI(prop) {
  const persisted = window.sessionStorage.getItem(BONFIRE_STORAGE_KEY);
  const state = persisted !== null ? JSON.parse(persisted) : {};

  const newState = _objectSpread(_objectSpread({}, state), {}, {
    [prop]: undefined
  });

  window.sessionStorage.setItem(BONFIRE_STORAGE_KEY, JSON.stringify(newState));
}

function setStorage(_ref) {
  let {
    key,
    prop,
    data,
    useLocalStorage
  } = _ref;
  const storage = useLocalStorage ? window.localStorage : window.sessionStorage;
  const persisted = storage.getItem(BONFIRE_STORAGE_KEY);
  const state = persisted !== null ? JSON.parse(persisted) : {
    [key]: {}
  };

  const newState = _objectSpread(_objectSpread({}, state), {}, {
    [key]: _objectSpread(_objectSpread({}, state[key]), {}, {
      [prop]: data
    })
  });

  storage.setItem(BONFIRE_STORAGE_KEY, JSON.stringify(newState));
}

function getStorage(_ref2) {
  let {
    key,
    prop,
    useLocalStorage
  } = _ref2;
  const storage = useLocalStorage ? window.localStorage : window.sessionStorage;
  const persisted = storage.getItem(BONFIRE_STORAGE_KEY);
  const state = persisted !== null ? JSON.parse(persisted) : {
    [key]: {}
  };
  return state[key] ? state[key][prop] : undefined;
}

function clearStorage(_ref3) {
  let {
    key,
    useLocalStorage
  } = _ref3;
  const storage = useLocalStorage ? window.localStorage : window.sessionStorage;
  const persisted = storage.getItem(BONFIRE_STORAGE_KEY);
  const state = persisted !== null ? JSON.parse(persisted) : {};

  const newState = _objectSpread(_objectSpread({}, state), {}, {
    [key]: undefined
  });

  storage.setItem(BONFIRE_STORAGE_KEY, JSON.stringify(newState));
}

function getPersisted(config, initial) {
  const d = getStorage(config);

  if (d !== undefined) {
    return d;
  }

  return initial;
}

function setPersisted(config, data) {
  setStorage(_objectSpread(_objectSpread({}, config), {}, {
    data
  }));
}