"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSortFunc = updateSortFunc;
exports.updateActiveColumns = updateActiveColumns;
exports.reducer = reducer;
exports.toggleIncludeOpenIssues = exports.ACTIONS_UI_TOGGLE_INCLUDE_OPEN_STATUS = exports.INITIAL_STATE = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _columns = require("../../../utils/columns");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const INITIAL_STATE = {
  sortFilter: _columns.actionColumns.DATES.key,
  sortDirection: _columns.actionColumns.DATES.sortDirection,
  activeColumns: {
    [_columns.actionColumns.ID.key]: true,
    [_columns.actionColumns.PRIORITY.key]: true,
    [_columns.actionColumns.SITE.key]: true,
    [_columns.actionColumns.SUMMARY.key]: true,
    [_columns.actionColumns.CONTEXT.key]: true,
    [_columns.actionColumns.RESPONSIBLE.key]: true,
    [_columns.actionColumns.DATES.key]: true,
    [_columns.actionColumns.STATUS.key]: true
  },
  // NOTE: Temporary filter for conditionally excluding non-pending issues as an admin for presentations
  includeOpenStatus: false
};
exports.INITIAL_STATE = INITIAL_STATE;
const ACTIONS_UI_UPDATE_SORT = "bonfire/actionsUI/ACTIONS_UI_UPDATE_SORT";
const ACTIONS_UI_UPDATE_ACTIVE_COLUMNS = "bonfire/actionsUI/ACTIONS_UI_UPDATE_ACTIVE_COLUMNS";
const ACTIONS_UI_TOGGLE_INCLUDE_OPEN_STATUS = "bonfire/actionsUI/ACTIONS_UI_TOGGLE_INCLUDE_OPEN_STATUS";
exports.ACTIONS_UI_TOGGLE_INCLUDE_OPEN_STATUS = ACTIONS_UI_TOGGLE_INCLUDE_OPEN_STATUS;

function updateSortFunc(sortFuncKey) {
  return {
    type: ACTIONS_UI_UPDATE_SORT,
    sortFuncKey
  };
}

function updateActiveColumns(column) {
  return {
    type: ACTIONS_UI_UPDATE_ACTIVE_COLUMNS,
    column
  };
}

const toggleIncludeOpenIssues = () => ({
  type: ACTIONS_UI_TOGGLE_INCLUDE_OPEN_STATUS
});

exports.toggleIncludeOpenIssues = toggleIncludeOpenIssues;

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case ACTIONS_UI_UPDATE_SORT:
      {
        if (state.sortFilter === action.sortFuncKey) {
          return _objectSpread(_objectSpread({}, state), {}, {
            sortDirection: state.sortDirection === "asc" ? "desc" : "asc"
          });
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          sortFilter: action.sortFuncKey,
          sortDirection: _columns.actionColumns[action.sortFuncKey].sortDirection
        });
      }

    case ACTIONS_UI_UPDATE_ACTIVE_COLUMNS:
      {
        // Toggle value of targeted active column
        const activeColumns = _objectSpread(_objectSpread({}, state.activeColumns), {}, {
          [action.column]: !state.activeColumns[action.column]
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          activeColumns
        });
      }

    case ACTIONS_UI_TOGGLE_INCLUDE_OPEN_STATUS:
      {
        const toggled = !state.includeOpenStatus;
        return _objectSpread(_objectSpread({}, state), {}, {
          includeOpenStatus: toggled
        });
      }

    default:
      return state;
  }
}