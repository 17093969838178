"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.prettyFormatNumber = prettyFormatNumber;
exports.moreOrLess = moreOrLess;
exports.getPercentageChange = getPercentageChange;
exports.countZero = countZero;
exports.zeroIfNaN = zeroIfNaN;
exports.round = round;
exports.roundDatum = roundDatum;

/**
 * Format a number with commas
 *
 * @param num Number to delimit with commas when over 999
 */
function prettyFormatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
/**
 * If `num < 0`, returns `less`, otherwise `more`. Zero intentionally returns
 * `more`.
 *
 * @param num Number to determine `more` or `less` than zero against
 * @param more Text to use as `more`
 * @param less Text to use as `less`
 */


function moreOrLess(num) {
  let more = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "more";
  let less = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "less";
  return num <= 0 ? less : more;
}
/**
 * Return the percentage change value given the original value and a new value
 *
 * @param baseVal base value to compare against
 * @param updatedVal new value
 * @param precision Level of precision to round output to
 */


function getPercentageChange(baseVal, updatedVal) {
  let precision = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  // If no change, don't try to divide by zero
  if (baseVal === 0) return 0;
  if (baseVal === updatedVal) return 0;
  const delta = updatedVal - baseVal;
  return Number((delta / baseVal * 100).toFixed(precision));
}
/**
 * Count the number of times `0` appears in an array of numbers
 */


function countZero(arr) {
  return arr.reduce((prev, curr) => curr === 0 ? prev + 1 : prev, 0);
}
/**
 * Returns zero if the input value is null or not a number; Otherwise
 * simply returns the input value (this includes Infinity)
 *
 * @param num The input to check
 */


function zeroIfNaN(num) {
  const isNumber = typeof num === "number";

  if (isNumber) {
    return Number.isNaN(num) ? 0 : num;
  }

  return 0;
}
/**
 * Round any number to any level of precision
 * Defaults to 1 (integer)
 * @param value value to round
 * @param precision number of decimals to round to (defaults to nearest integer)
 */


function round(value) {
  let precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  const multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
}
/**
 * If datum is a number less than 10, keep single decimal
 * precision.
 * If datum is greater than 10, round to nearest integer.
 * @param hours number
 */


function roundDatum(datum) {
  const precision = datum > 10 ? 0 : 1;
  return round(datum, precision);
}