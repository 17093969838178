"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.eqSet = eqSet;
exports.calcIsAllSelected = calcIsAllSelected;
exports.selectAllSetState = selectAllSetState;
exports.projectChangeSetState = projectChangeSetState;

function eqSet(setA, setB) {
  // If sets are different sizes, return false early
  if (setA.size !== setB.size) return false; // If sets are the same size,
  // but setA contians something not in setB
  // return false

  for (const a of setA) if (!setB.has(a)) return false; // Otherwise, sets are equal


  return true;
}

function calcIsAllSelected() {
  let projectSites = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let selectedSites = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Set();
  // If there aren't any sites in the project, projects are still loading in.
  if (projectSites.length === 0 || selectedSites.size === 0) return false;
  const selected = new Set(selectedSites); // isAllSelected if selectedSites contains all sites in activeProject

  return projectSites.filter(s => selected.has(s.syncoId)).length === projectSites.length;
}

function selectAllSetState(projectSites, activeSites) {
  return state => {
    const {
      selectedSites,
      shouldFilter,
      matchingSites
    } = state;
    const filteredSites = projectSites.filter(s => matchingSites.has(s.syncoId));
    let clone = new Set(selectedSites);
    const isAllSelected = shouldFilter ? calcIsAllSelected(filteredSites, selectedSites) : calcIsAllSelected(projectSites, selectedSites);
    const siteIds = shouldFilter ? filteredSites.map(s => s.syncoId) : projectSites.map(s => s.syncoId);

    if (isAllSelected) {
      projectSites.forEach(s => clone.delete(s.syncoId));
    } else {
      clone = new Set([...selectedSites, ...siteIds]);
    }

    const isPristine = eqSet(new Set(activeSites), clone);
    return {
      isAllSelected: !isAllSelected,
      isPristine,
      selectedSites: clone
    };
  };
}

function projectChangeSetState(activeSites) {
  return _ref => {
    let {
      selectedSites,
      shouldFilter,
      matchingSites
    } = _ref;
    const sites = shouldFilter ? activeSites.filter(site => matchingSites.has(site.syncoId)) : activeSites;
    const isAllSelected = calcIsAllSelected(sites, selectedSites);
    return {
      isAllSelected
    };
  };
}