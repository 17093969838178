"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateForm = validateForm;
exports.executeGroupAction = executeGroupAction;
exports.saveGroup = saveGroup;
exports.saveGroupFlow = saveGroupFlow;

var _effects = require("redux-saga/effects");

var _actionTypes = require("./actionTypes");

function validateForm(groupData) {
  // Test validate return ok always
  const error = [];
  let ok = true;

  if (!groupData.group_type) {
    error.push("Group Type is required.");
  }

  if (!groupData.group_name) {
    error.push("Group Name is required.");
  }

  if (!groupData.group_key && groupData.group_type === "project") {
    error.push("Group Key is required.");
  }

  if (!groupData.project && groupData.group_type === "site") {
    error.push("Project is required.");
  }

  if (error.length > 0) {
    ok = false;
  }

  return {
    ok,
    error
  };
}

function* executeGroupAction(response, func, user, auth) {
  // NOTE: This only handles client-side validation
  if (response.ok) {
    // call add user asynchronously
    yield (0, _effects.fork)(func, user, auth);
  } else {
    // Return errors in form - Better to use redux form
    yield (0, _effects.put)({
      type: _actionTypes.VALIDATE_FORM_ERROR,
      error: response.error
    });
  }
} // Our watcher Saga:
// const getCreateUserFormValues = state => state.form.createUser.values


const getGroupFormValues = state => state.groups.group_form_data;

const getAuthToken = state => state.auth.user.token;

function* addGroup(row, token) {
  const action = {
    types: [_actionTypes.ADD_GROUP, _actionTypes.ADD_GROUP_SUCCESS, _actionTypes.ADD_GROUP_FAIL],
    promise: client => client.post("/group", {
      headers: {
        Authorization: "Bearer ".concat(token)
      },
      data: {
        group_type: row.group_type,
        group_name: row.group_name,
        group_key: row.group_key,
        project: row.project
      }
    })
  };
  yield (0, _effects.put)(action);
}

function* saveGroup(row, token) {
  const action = yield {
    types: [_actionTypes.SAVE_GROUP, _actionTypes.SAVE_GROUP_SUCCESS, _actionTypes.SAVE_GROUP_FAIL],
    // replace with edit group url
    promise: client => client.post("/group", {
      headers: {
        Authorization: "Bearer ".concat(token)
      },
      data: {
        group_id: Number(row.group_id),
        group_key: row.group_key,
        group_name: row.group_name,
        group_type: row.group_type
      }
    })
  };
  yield (0, _effects.put)(action);
}

function* saveGroupFlow() {
  while (true) {
    // Wait for start of cycle action
    const action = yield (0, _effects.take)([_actionTypes.ADD_GROUP_REQUEST, _actionTypes.SAVE_GROUP_REQUEST]);
    const func = action.type === _actionTypes.ADD_GROUP_REQUEST ? addGroup : saveGroup; // Get values from form - Better to use redux form

    const groupData = yield (0, _effects.select)(getGroupFormValues);
    const token = yield (0, _effects.select)(getAuthToken); // Validate - Better to do this with redux form

    const response = yield (0, _effects.call)(validateForm, groupData); // Save User task

    yield (0, _effects.fork)(executeGroupAction, response, func, groupData, token); // Wait for end of cycle action

    yield (0, _effects.take)([_actionTypes.ADD_GROUP_SUCCESS, _actionTypes.SAVE_GROUP_SUCCESS, _actionTypes.ADD_GROUP_FAIL, _actionTypes.SAVE_GROUP_FAIL, _actionTypes.END_ACTION, _actionTypes.VALIDATE_FORM_ERROR]);
  }
}