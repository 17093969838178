"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WrapLegacyAddComment = exports.EquipList = exports.SavingsCard = exports.SectionHeading = exports.Section = exports.SectionGrid = exports.CreatedUpdatedText = exports.Content = exports.SiteTitle = exports.EditLink = exports.Header = exports.Container = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _App = require("../../../AppChrome/App.constants");

var _AttachmentsPreview = require("../../../AttachmentsPreview/AttachmentsPreview.styled");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;

const Container = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  position: absolute;\n  right: 0;\n  top: 0;\n  height: 100%;\n  width: 400px;\n  border-left: 1px solid ", ";\n  border-top: 1px solid ", ";\n  background-color: white;\n"])), _blocks.color.theme.divider.solid, _blocks.color.theme.divider.solid);

exports.Container = Container;

const Header = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: ", " ", " ", "\n    ", ";\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  background-color: white;\n  z-index: 1;\n"])), _blocks.spacing.tight, _blocks.spacing.extraTight, _blocks.spacing.tight, _blocks.spacing.default);

exports.Header = Header;

const EditLink = _styledComponents.default.a(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  color: ", ";\n  text-decoration: underline;\n  text-decoration-style: dashed;\n  text-underline-position: under;\n"])), _blocks.color.theme.primaryColor.action.primary);

exports.EditLink = EditLink;

const SiteTitle = _styledComponents.default.h3(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 0 0 ", ";\n  font-size: 18px;\n  font-weight: ", ";\n  color: ", ";\n\n  span {\n    display: inline-block;\n    padding-left: ", ";\n    color: ", ";\n  }\n"])), _blocks.spacing.extraTight, _App.TYPOGRAPHY.WEIGHTS.REGULAR, _blocks.color.theme.typography.ink, _blocks.spacing.tight, _blocks.palette.neutral6);

exports.SiteTitle = SiteTitle;

const Content = _styledComponents.default.div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  padding: ", ";\n  padding-top: 56px;\n  height: 100%;\n  overflow-y: auto;\n"])), _blocks.spacing.default);

exports.Content = Content;

const CreatedUpdatedText = _styledComponents.default.p(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n  font-size: 14px;\n  color: ", ";\n  margin-bottom: ", ";\n"])), _blocks.palette.neutral8, _blocks.spacing.snug);

exports.CreatedUpdatedText = CreatedUpdatedText;

const SectionGrid = _styledComponents.default.div(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-column-gap: ", ";\n"])), _blocks.spacing.loose);

exports.SectionGrid = SectionGrid;

const Section = _styledComponents.default.div(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\n  margin-bottom: ", ";\n\n  .Issue-description {\n    color: ", ";\n    margin-bottom: ", ";\n  }\n"])), _blocks.spacing.loose, _blocks.palette.neutral8, _blocks.spacing.default);

exports.Section = Section;

const SectionHeading = _styledComponents.default.h4(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\n  font-size: 14px;\n  font-weight: ", ";\n  text-transform: uppercase;\n  margin: ", " 0;\n"])), _App.TYPOGRAPHY.WEIGHTS.BOLD, _blocks.spacing.tight);

exports.SectionHeading = SectionHeading;

const SavingsCard = _styledComponents.default.div(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2.default)(["\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-column-gap: ", ";\n  padding: ", " ", ";\n  background-color: ", ";\n  border: 1px solid ", ";\n  margin-bottom: ", ";\n\n  ", ", p {\n    font-size: 12px;\n  }\n"])), _blocks.spacing.tight, _blocks.spacing.tight, _blocks.spacing.default, _blocks.color.pureWhite, _blocks.color.theme.divider.solid, _blocks.spacing.loose, SectionHeading); // TODO: This is duplicated


exports.SavingsCard = SavingsCard;

const EquipList = _styledComponents.default.ul(_templateObject11 || (_templateObject11 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 0 0 ", " 0;\n  padding-left: ", ";\n\n  li:empty {\n    display: none;\n  }\n"])), _blocks.spacing.snug, _blocks.spacing.loose);
/**
 * HACK: This is a huge gross hack that should be addressed ASAP when the
 * comments system is revisted.
 */


exports.EquipList = EquipList;

const WrapLegacyAddComment = _styledComponents.default.div(_templateObject12 || (_templateObject12 = (0, _taggedTemplateLiteral2.default)(["\n  padding: ", ";\n  background-color: ", ";\n  border-radius: ", ";\n  border: 1px solid ", ";\n  ", "\n  /* Change layout of components */\n  .col-xs-12 {\n    display: flex;\n    flex-direction: row-reverse;\n    justify-content: space-between;\n    font-size: 0px;\n  }\n\n  label {\n    margin-top: ", ";\n  }\n\n  .form-group {\n    margin: 0;\n    display: flex;\n    flex-direction: column-reverse;\n  }\n\n  & > div {\n    border: 0;\n  }\n\n  ", " {\n    margin-top: ", ";\n    margin-bottom: 0;\n  }\n\n  ", " {\n    color: ", ";\n  }\n"])), _blocks.spacing.snug, _blocks.color.pureWhite, _blocks.borderRadius.default, _blocks.color.theme.divider.solid, _blocks.typography.body, _blocks.spacing.default, _AttachmentsPreview.AttachmentPreviewItem, _blocks.spacing.default, _AttachmentsPreview.RemoveButton, _blocks.color.theme.icon.subdued);

exports.WrapLegacyAddComment = WrapLegacyAddComment;