"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommentActions = exports.Wrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _blocks = require("@conserveit/blocks");

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject, _templateObject2;

const Wrapper = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  padding-top: ", ";\n  border-top: 1px solid;\n  border-color: #ddd;\n"])), _blocks.spacing.extraTight);

exports.Wrapper = Wrapper;

const CommentActions = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: ", ";\n"])), _blocks.spacing.default);

exports.CommentActions = CommentActions;