"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HelpLink = exports.FooterContent = exports.Footer = exports.SuccessMessage = exports.ErrorMessage = exports.Content = exports.Header = exports.FormContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

/**
 * Outermost `<form />` element
 */
const FormContainer = _styledComponents.default.form(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  border-radius: 3px;\n  box-shadow: ", ";\n"])), _blocks.elevation.content);
/**
 * Container for the form title
 *
 * TODO: Port to FrameView and use typography components instead
 */


exports.FormContainer = FormContainer;

const Header = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  padding: ", ";\n  font-size: 20px;\n  line-height: 28px;\n  text-align: center;\n  border-bottom: 1px solid ", ";\n  background-color: ", ";\n"])), _blocks.spacing.default, _blocks.palette.neutral4, _blocks.color.pureWhite);
/**
 * Content box around form elements
 */


exports.Header = Header;

const Content = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  padding: ", " ", ";\n  background-color: ", ";\n"])), _blocks.spacing.extraLoose, _blocks.spacing.ultraLoose, _blocks.color.pureWhite); // TODO: Remove this


exports.Content = Content;

const ErrorMessage = _styledComponents.default.div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  padding: ", ";\n  margin-bottom: ", ";\n\n  font-weight: 700;\n\n  background-color: ", ";\n  border: 1px solid ", ";\n  color: ", ";\n  border-radius: ", ";\n"])), _blocks.spacing.default, _blocks.spacing.loose, _blocks.palette.red1, _blocks.palette.red5, _blocks.palette.red7, _blocks.borderRadius.default);

exports.ErrorMessage = ErrorMessage;
const SuccessMessage = (0, _styledComponents.default)(ErrorMessage)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  background-color: ", ";\n  border: 1px solid ", ";\n  color: ", ";\n"])), _blocks.palette.green1, _blocks.palette.green5, _blocks.palette.green7);
/**
 * Outermost element for the footer
 * TODO: Port to FrameView
 */

exports.SuccessMessage = SuccessMessage;

const Footer = _styledComponents.default.div(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  justify-content: flex-end;\n  padding: ", ";\n  border-top: 1px solid ", ";\n  background-color: ", ";\n"])), _blocks.spacing.default, _blocks.color.theme.divider.solid, _blocks.color.pureWhite);
/**
 * Content wrapper inside outermost footer element
 * TODO: Port to FrameView
 */


exports.Footer = Footer;

const FooterContent = _styledComponents.default.div(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  align-items: center;\n  flex-grow: 1;\n"])));

exports.FooterContent = FooterContent;

const HelpLink = _styledComponents.default.a(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\n  font-size: 12px;\n  font-weight: 700;\n  color: ", ";\n  text-decoration: underline;\n\n  &:hover,\n  &:focus,\n  &:active {\n    color: ", ";\n  }\n"])), _blocks.palette.neutral7, _blocks.color.theme.typography.ink);

exports.HelpLink = HelpLink;