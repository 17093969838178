"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _actionTypes = require("./actionTypes");

var _types = require("../../../components/Analysis/utils/types");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const INITIAL_STATE = {
  hit: {},
  site: {},
  priority: {},
  rule: {},
  category: {},
  siteGroups: false,
  composed: [],
  sortByOption: _types.SortBy.AlphaAsc,
  loading: false,
  ready: false
};

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actionTypes.LOAD_MONITORING:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });

    case _actionTypes.LOAD_MONITORING_REQUEST_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.result), {}, {
        loading: false
      });

    case _actionTypes.LOAD_MONITORING_REQUEST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false
      });

    case _actionTypes.UPDATE_MONITORING:
      return _objectSpread(_objectSpread({}, state), {}, {
        ready: true,
        loading: false,
        siteGroups: action.siteGroups,
        composed: action.composed
      });

    case _actionTypes.UPDATE_SORT_BY_OPTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        sortByOption: action.sortByOption
      });

    default:
      return state;
  }
}