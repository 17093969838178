"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSortedKeys = getSortedKeys;
exports.getRankedYearsConfig = getRankedYearsConfig;
exports.getRankedSitesConfig = getRankedSitesConfig;
exports.getSingleSiteConfig = getSingleSiteConfig;
exports.getMonthName = getMonthName;
exports.getTypeColours = getTypeColours;
exports.getTimeFrameRange = exports.getTimeFrameList = void 0;

var _blocks = require("@conserveit/blocks");

var _types = require("../../../context/reports/utilities/types");

var _types2 = require("../../../context/reports/types");

var _state = require("../../../utils/state");

/**
 * Retrieve sorted top keys based on acumulated consumption or key name
 * @param data
 * @param sortOption LatestConsumption option is not suitable for `YearInSites` and `MonthInSites` structured data
 * @param sortDirection
 */
function getSortedKeys(data, sortOption, sortDirection) {
  // Define a reversible flag for sort function
  const flag = sortDirection === _types2.Sorting.Desc ? -1 : 1; // Sort by acumulated consumption

  if (sortOption === _types.SortBy.TotalConsumption || sortOption === _types.SortBy.LatestConsumption) {
    return Object.entries(data) // The total or latest consumption is required for each top key first
    .map(_ref => {
      let [key, consumption] = _ref;
      const entries = Object.values(consumption);
      return [key, entries.reduce((acc, num, index) => {
        // The down key here could be a month, a year or a siteId.
        // Keys are already sorted ascending unless siteIds. It's how JS behaves regarding numeric keys.
        // If sort option is TotalConsumption, accumulate all items,
        // Otherwise accumulate last items only which is last month or year or siteId
        return sortOption === _types.SortBy.TotalConsumption || index + 1 === entries.length ? acc + num : acc;
      }, 0)];
    }) // sort by accumulated consumption based on top key
    .sort((a, b) => a[1] > b[1] ? flag : flag * -1) // pick sorted top keys as an array of strings
    .map(entry => entry[0]);
  } // Sort by top key name regardless of consumption


  return Object.keys(data).sort((a, b) => a > b ? flag : flag * -1);
}
/**
 * Get chart data source for ranked years
 * @param data
 * @param yearsSortingDirection
 */


function getRankedYearsConfig(data, metricsType) {
  const categories = [];
  const series = [];
  Object.entries(data['AllMonthInSites']).map((_ref2, index) => {
    let [date, items] = _ref2;
    categories.push(date);
    Object.entries(items).forEach(_ref3 => {
      let [siteId, value] = _ref3;
      const siteInSeries = series.find(obj => {
        return obj.name === (0, _state.getSiteById)(siteId).label;
      });

      if (siteInSeries) {
        siteInSeries.data.push(value);
      } else {
        series.push({
          name: (0, _state.getSiteById)(siteId).label,
          data: [value],
          color: getColour(index, metricsType)
        });
      }
    });
  });
  return {
    categories,
    series
  };
}
/**
 * Get chart data source for ranked years
 * @param data
 * @param sitesSortingDirection
 * @param yearsSortingDirection
 */


function getRankedSitesConfig(data, metricsType, sitesSortingDirection, yearsSortingDirection) {
  const siteIds = getSortedKeys(data['SiteInYears'], _types.SortBy.LatestConsumption, sitesSortingDirection);
  const years = getSortedKeys(data['YearInSites'], _types.SortBy.Key, yearsSortingDirection);
  const categories = siteIds.map(siteId => (0, _state.getSiteById)(siteId).label);
  const series = years.map((year, index) => ({
    name: year,
    data: siteIds.map(siteId => data['YearInSites'][year][siteId] ? data['YearInSites'][year][siteId] : 0),
    color: getColour(index, metricsType)
  }));
  return {
    categories,
    series
  };
}
/**
 * Get chart data source for monthly resolution
 * @param data
 * @param yearsSortingDirection
 */


function getSingleSiteConfig(data, metricsType, yearsSortingDirection) {
  const months = Object.keys(data['MonthInSites']);
  const years = getSortedKeys(data['YearInMonths'], _types.SortBy.Key, yearsSortingDirection);
  return {
    categories: [{
      category: months.map(month => ({
        label: getMonthName(month)
      }))
    }],
    dataset: years.map((year, index) => ({
      seriesname: year,
      // showValues: true,
      color: getColour(index, metricsType),
      data: months.map(month => ({
        value: data['YearInMonths'][year][month] ? data['YearInMonths'][year][month] : 0
      }))
    }))
  };
}

const getTimeFrameList = (i18n, sliceCount) => {
  const list = [{
    value: _types.TimeFrame.YearToDate,
    label: i18n._(
    /*i18n*/
    {
      id: "time.year_to_date",
      defaults: "Year to date"
    })
  }, {
    value: _types.TimeFrame.Last2Years,
    label: i18n._(
    /*i18n*/
    {
      id: "time.last_two_years",
      defaults: "Last 2 years (inc. YTD)"
    })
  }, {
    value: _types.TimeFrame.Last3Years,
    label: i18n._(
    /*i18n*/
    {
      id: "time.last_three_years",
      defaults: "Last 3 years (inc. YTD)"
    })
  }];
  return sliceCount ? list.slice(0, sliceCount) : list;
};
/**
 * Get timeframe range
 * @param timeFrameOption number/enum of selectable months to request
 */


exports.getTimeFrameList = getTimeFrameList;

const getTimeFrameRange = timeFrameOption => {
  const year = new Date().getFullYear() - timeFrameOption;
  const startDate = new Date(year, 0, 1, 12, 0, 0).toISOString();
  const endDate = new Date().toISOString();
  return {
    timeFrameOption,
    startDate,
    endDate
  };
};
/**
 * Get three letters abbreviation of a month number
 * @param month month number starting from 1
 */


exports.getTimeFrameRange = getTimeFrameRange;

function getMonthName(month) {
  month = month.replace(/^0+/, ''); // Remove leading zero

  const list = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const index = parseInt(month, 10) - 1;
  return list[index];
}
/**
 * Retrieve hexadecimal colour code based on an index number
 * @param index
 */


function getColour(index, metricsType) {
  const colours = getTypeColours(metricsType);
  const list = [colours.primary, colours.secondary, colours.tertiary];

  if (index < 0 || index > 2) {
    index = 0;
  }

  return list[index];
}
/**
 * Retrieve all colours needed for a metrics type
 * @param type
 */


function getTypeColours(type) {
  const ink = (0, _blocks.hslaConverter)(_blocks.color.theme.typography.ink, 'hex');

  switch (type) {
    case _types.MetricsType.Gas:
      return {
        ink,
        canvas: (0, _blocks.hslaConverter)(_blocks.palette.purple1, 'hex'),
        primary: (0, _blocks.hslaConverter)(_blocks.palette.purple8, 'hex'),
        secondary: (0, _blocks.hslaConverter)(_blocks.palette.purple5, 'hex'),
        tertiary: (0, _blocks.hslaConverter)(_blocks.palette.purple3, 'hex')
      };

    case _types.MetricsType.Water:
      return {
        ink,
        canvas: (0, _blocks.hslaConverter)(_blocks.palette.blue2, 'hex'),
        primary: (0, _blocks.hslaConverter)(_blocks.palette.blue7, 'hex'),
        secondary: (0, _blocks.hslaConverter)(_blocks.palette.blue5, 'hex'),
        tertiary: (0, _blocks.hslaConverter)(_blocks.palette.blue3, 'hex')
      };

    case _types.MetricsType.Electricity:
    default:
      return {
        ink,
        canvas: (0, _blocks.hslaConverter)(_blocks.palette.orange1, 'hex'),
        primary: (0, _blocks.hslaConverter)(_blocks.palette.orange8, 'hex'),
        secondary: (0, _blocks.hslaConverter)(_blocks.palette.orange6, 'hex'),
        tertiary: (0, _blocks.hslaConverter)(_blocks.palette.orange4, 'hex')
      };
  }
}