"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SETTINGS_INSIGHTS = exports.SETTINGS_MONITORING = exports.RESET = exports.FORGOT = exports.LOGIN = exports.GROUP_ADMIN = exports.USER_ADMIN = exports.USER_PROFILE = exports.SITE_DETAILS = exports.VIEW_ACTION = exports.EDIT_ACTION = exports.ADD_ACTION = exports.ACTIONS = exports.MONITORING_EQUIPS = exports.INSIGHT_EQUIPS = exports.MONITORING = exports.INSIGHTS = exports.ANALYSIS = exports.InsightType = exports.SITEDETAILS = exports.REPORTS = void 0;

var _reports = require("./reports");

var _siteDetails = require("./siteDetails");

const REPORTS = _reports.Route;
exports.REPORTS = REPORTS;
const SITEDETAILS = _siteDetails.Route;
exports.SITEDETAILS = SITEDETAILS;
let InsightType; // Analysis routes -> Insights and Monitoring

exports.InsightType = InsightType;

(function (InsightType) {
  InsightType["Insights"] = "insights";
  InsightType["Monitoring"] = "monitoring";
})(InsightType || (exports.InsightType = InsightType = {}));

const ANALYSIS = "/analysis";
exports.ANALYSIS = ANALYSIS;
const INSIGHTS = "/analysis/".concat(InsightType.Insights);
exports.INSIGHTS = INSIGHTS;
const MONITORING = "/analysis/".concat(InsightType.Monitoring);
exports.MONITORING = MONITORING;
const INSIGHT_EQUIPS = "/analysis/".concat(InsightType.Insights, "/:syncoId/:ruleId");
exports.INSIGHT_EQUIPS = INSIGHT_EQUIPS;
const MONITORING_EQUIPS = "/analysis/".concat(InsightType.Monitoring, "/:syncoId/:ruleId"); // Actions routes

exports.MONITORING_EQUIPS = MONITORING_EQUIPS;
const ACTIONS = "/actions";
exports.ACTIONS = ACTIONS;
const ADD_ACTION = "/actions/add";
exports.ADD_ACTION = ADD_ACTION;
const EDIT_ACTION = "/actions/:actionId/edit";
exports.EDIT_ACTION = EDIT_ACTION;
const VIEW_ACTION = "/actions/:actionId"; // Site details routes

exports.VIEW_ACTION = VIEW_ACTION;
const SITE_DETAILS = "/sitedetails"; // Admin routes

exports.SITE_DETAILS = SITE_DETAILS;
const USER_PROFILE = "/profile";
exports.USER_PROFILE = USER_PROFILE;
const USER_ADMIN = "/users";
exports.USER_ADMIN = USER_ADMIN;
const GROUP_ADMIN = "/groups"; // Public routes

exports.GROUP_ADMIN = GROUP_ADMIN;
const LOGIN = "/login";
exports.LOGIN = LOGIN;
const FORGOT = "/forgot";
exports.FORGOT = FORGOT;
const RESET = "/reset"; // Settins routes

exports.RESET = RESET;
const SETTINGS_MONITORING = "/settings/".concat(InsightType.Monitoring);
exports.SETTINGS_MONITORING = SETTINGS_MONITORING;
const SETTINGS_INSIGHTS = "/settings/".concat(InsightType.Insights);
exports.SETTINGS_INSIGHTS = SETTINGS_INSIGHTS;