"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rootReducer = void 0;

var _redux = require("redux");

var _reactReduxToastr = require("react-redux-toastr");

var _reduxModal = require("redux-modal");

var _reducer = require("./modules/auth/reducer");

var _reducer2 = require("./modules/appSetup/reducer");

var _issues = _interopRequireDefault(require("./modules/issues"));

var _issues2 = require("./modules/_BETA/issues");

var _reducer3 = require("./modules/users/reducer");

var _reducer4 = require("./modules/groups/reducer");

var _reducer5 = require("./modules/lookups/reducer");

var _reducer6 = require("./modules/profile/reducer");

var _reducer7 = require("./modules/monthlySummary/reducer");

var _issue = require("./modules/_BETA/issue");

var _reducer8 = require("./modules/attachments/reducer");

var _reducer9 = require("./modules/insights/reducer");

var _associate = require("./modules/_BETA/associate");

var _unassociate = require("./modules/_BETA/unassociate");

var _snooze = require("./modules/_BETA/snooze");

var _unsnooze = require("./modules/_BETA/unsnooze");

var _reducer10 = require("./modules/action/reducer");

var _actionsUI = require("./modules/_BETA/actionsUI");

var _requestSchedule = require("./modules/_BETA/requestSchedule");

var _reportGenerator = _interopRequireDefault(require("./modules/reportGenerator"));

var _selections = require("./modules/_BETA/selections");

var _reducer11 = require("./modules/userSites/reducer");

var _reducer12 = require("./modules/urlHistory/reducer");

var _reducer13 = require("./modules/monitoring/reducer");

const rootReducer = (0, _redux.combineReducers)({
  toastr: _reactReduxToastr.reducer,
  auth: _reducer.reducer,
  issues: _issues.default,
  users: _reducer3.reducer,
  groups: _reducer4.reducer,
  lookups: _reducer5.reducer,
  profile: _reducer6.reducer,
  monthlySummary: _reducer7.reducer,
  attachments: _reducer8.reducer,
  reportGenerator: _reportGenerator.default,
  // BETA Reducers
  appSetup: _reducer2.reducer,
  betaModal: _reduxModal.reducer,
  userSites: _reducer11.reducer,
  betaSelections: _selections.reducer,
  betaIssues: _issues2.reducer,
  betaIssue: _issue.reducer,
  insights: _reducer9.reducer,
  betaAction: _reducer10.reducer,
  betaActionsUI: _actionsUI.reducer,
  betaAssociate: _associate.reducer,
  betaUnassociate: _unassociate.reducer,
  betaSnooze: _snooze.reducer,
  betaUnsnooze: _unsnooze.reducer,
  requestSchedule: _requestSchedule.reducer,
  urlHistory: _reducer12.reducer,
  monitoring: _reducer13.reducer
});
/**
 * The entire application state.
 *
 * Useful for typing `mapStateToProps` function parameters.
 */

exports.rootReducer = rootReducer;