"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TRACKING_EVENT_CHART_CLICK = exports.TRACKING_EVENT_ORDER_BY = exports.TRACKING_EVENT_GROUP_BY = exports.TRACKING_EVENT_SORT_BY = exports.TRACKING_EVENT_TIME_FRAME = exports.ORDER_BY_OPTIONS = exports.VIEW_BY_OPTIONS = exports.COMFORT_PERSIST_KEY = void 0;
const COMFORT_PERSIST_KEY = "Reports-comfort";
exports.COMFORT_PERSIST_KEY = COMFORT_PERSIST_KEY;
const VIEW_BY_OPTIONS = [{
  value: "hoursDiscomfort",
  label: "Total hours uncomfortable",
  sub: "Total hours"
}, {
  value: "pctDiscomfort",
  label: "% of occupied hours uncomfortable",
  sub: "% of occuppied hours"
}];
exports.VIEW_BY_OPTIONS = VIEW_BY_OPTIONS;
const ORDER_BY_OPTIONS = [{
  value: "worst",
  label: "Worst performing"
}, {
  value: "best",
  label: "Best performing"
}];
exports.ORDER_BY_OPTIONS = ORDER_BY_OPTIONS;
const TRACKING_EVENT_TIME_FRAME = "Selected Time Frame (Comfort Reports)";
exports.TRACKING_EVENT_TIME_FRAME = TRACKING_EVENT_TIME_FRAME;
const TRACKING_EVENT_SORT_BY = "Chart - Sorted DataGrid";
exports.TRACKING_EVENT_SORT_BY = TRACKING_EVENT_SORT_BY;
const TRACKING_EVENT_GROUP_BY = "Chart - Clicked GroupBy";
exports.TRACKING_EVENT_GROUP_BY = TRACKING_EVENT_GROUP_BY;
const TRACKING_EVENT_ORDER_BY = "Chart - Clicked OrderBy";
exports.TRACKING_EVENT_ORDER_BY = TRACKING_EVENT_ORDER_BY;
const TRACKING_EVENT_CHART_CLICK = "Chart - Clicked Chart element";
exports.TRACKING_EVENT_CHART_CLICK = TRACKING_EVENT_CHART_CLICK;