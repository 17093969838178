"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapAddActionToProps = mapAddActionToProps;
exports.mapEditActionToProps = mapEditActionToProps;
exports.formikOptions = exports.editActionSchema = exports.addActionSchema = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var Yup = _interopRequireWildcard(require("yup"));

var _i18n = require("../../../i18n");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

Yup.setLocale({
  string: {
    default: _i18n.i18n._(
    /*i18n*/
    {
      id: "validation.must_be_a_string",
      defaults: "Must be a string"
    })
  },
  number: {
    default: _i18n.i18n._(
    /*i18n*/
    {
      id: "validation.must_be_a_number",
      defaults: "Must be a number"
    })
  }
});
/**
 * The Yup schema object that Formik will use to validate the form's state
 */

const defaultActionSchema = () => ({
  summary: Yup.string().required(_i18n.i18n._(
  /*i18n*/
  {
    id: "action_form.validation.summary_cannot_be_blank",
    defaults: "Summary cannot be blank"
  })),
  description: Yup.string(),
  assignee: Yup.string(),
  skysparkLink: Yup.string().url(_i18n.i18n._(
  /*i18n*/
  {
    id: "action_form.validation.report_url_must_be_a_valid_url",
    defaults: "Report URL must be a valid URL"
  })),
  elec: Yup.number().min(0, _i18n.i18n._(
  /*i18n*/
  {
    id: "action_form.validation.electricity_savings_greater_or_equal_to_0",
    defaults: "Electricity savings must be a number greater than or equal to 0"
  })),
  water: Yup.number().min(0, _i18n.i18n._(
  /*i18n*/
  {
    id: "action_form.validation.water_savings_greater_or_equal_to_0",
    defaults: "Water savings must be a number greater than or equal to 0"
  })),
  gas: Yup.number().min(0, _i18n.i18n._(
  /*i18n*/
  {
    id: "action_form.validation.gas_savings_greater_or_equal_to_0",
    defaults: "Gas savings must be a number greater than or equal to 0"
  })),
  totalFinancialImpact: Yup.number().min(0, _i18n.i18n._(
  /*i18n*/
  {
    id: "action_form.validation.total_financial_impact_greater_or_equal_to_0",
    defaults: "Total financial impact must be a number greater than or equal to 0"
  })),
  operationalSavings: Yup.number().min(0, _i18n.i18n._(
  /*i18n*/
  {
    id: "action_form.validation.operational_savings_greater_or_equal_to_0",
    defaults: "Operational savings must be a number greater than or equal to 0"
  })),
  rule: Yup.string(),
  context: Yup.string().required(_i18n.i18n._(
  /*i18n*/
  {
    id: "action_form.validation.context_cannot_be_blank",
    defaults: "Context cannot be blank"
  })),
  priority: Yup.string().required(_i18n.i18n._(
  /*i18n*/
  {
    id: "action_form.validation.priority_cannot_be_blank",
    defaults: "Priority cannot be blank"
  })),
  status: Yup.string().required(_i18n.i18n._(
  /*i18n*/
  {
    id: "action_form.validation.status_cannot_be_blank",
    defaults: "Status cannot be blank"
  })),
  responsible: Yup.string().required(_i18n.i18n._(
  /*i18n*/
  {
    id: "action_form.validation.responsible_cannot_be_blank",
    defaults: "Responsible cannot be blank"
  }))
});

const addActionSchema = () => Yup.object().shape(_objectSpread(_objectSpread({}, defaultActionSchema()), {}, {
  site: Yup.string().required(_i18n.i18n._(
  /*i18n*/
  {
    id: "action_form.validation.site_cannot_be_blank",
    defaults: "Site cannot be blank"
  }))
}));

exports.addActionSchema = addActionSchema;

const editActionSchema = () => Yup.object().shape(_objectSpread(_objectSpread({}, defaultActionSchema()), {}, {
  comment: Yup.string(),
  transitionReason: Yup.string()
}));
/**
 * Used by formik to map some initial blank props
 */


exports.editActionSchema = editActionSchema;

function mapAddActionToProps(props) {
  const rule = props.fromAssociate ? props.fromAssociate.rule : "";
  const site = props.fromAssociate ? props.fromAssociate.site : "";
  return {
    site,
    summary: rule,
    // NOTE: Defaulting to the rule name if one is supplied
    description: "",
    skysparkLink: "",
    assignee: "",
    elec: "",
    // NOTE: number
    water: "",
    // NOTE: number
    gas: "",
    // NOTE: number
    totalFinancialImpact: "",
    // NOTE: number
    operationalSavings: "",
    // NOTE: number
    rule,
    context: "",
    priority: "",
    status: "",
    responsible: "",
    screenshot: null,
    screenshotLink: ""
  };
}
/**
 * Used by formik to map the initial action to the form values
 */


function mapEditActionToProps(_ref) {
  let {
    action
  } = _ref;
  return {
    summary: action.summary,
    description: action.description,
    skysparkLink: action.skysparkLink || "",
    assignee: action.assignee || "",
    elec: !action.elec ? "" : Number(action.elec),
    water: !action.water ? "" : Number(action.water),
    gas: !action.gas ? "" : Number(action.gas),
    totalFinancialImpact: !action.totalFinancialImpact ? "" : Number(action.totalFinancialImpact),
    operationalSavings: !action.operationalSavings ? "" : Number(action.operationalSavings),
    rule: action.rule,
    context: action.context,
    priority: action.priority,
    status: action.status,
    responsible: action.responsible,
    screenshot: null,
    screenshotLink: !action.screenshotLink ? "" : action.screenshotLink,

    /** NOTE: Transition reason **always** defaults to empty string */
    transitionReason: "",
    comment: ""
  };
}
/** Formik options shared between create and edit */


const formikOptions = {
  validateOnChange: true,
  validateOnBlur: true
};
exports.formikOptions = formikOptions;