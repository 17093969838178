"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createDispatchHook = void 0;

var _react = require("react");

/**
 * Create a dispatch hook
 */
const createDispatchHook = dispatchContext => {
  return () => {
    const dispatch = (0, _react.useContext)(dispatchContext);

    if (dispatch === undefined) {
      throw new Error("useDispatch must be used within a Provider");
    }

    return dispatch;
  };
};

exports.createDispatchHook = createDispatchHook;