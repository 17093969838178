"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calcSelectAllConfig = calcSelectAllConfig;
exports.calcRemoveAllConfig = calcRemoveAllConfig;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function calcSelectAllConfig(ruleList, configState) {
  const active = ruleList.reduce((acc, rule) => {
    const existing = configState.active[rule.rule.id] || configState.saved[rule.rule.id];

    if (existing) {
      const updated = {
        id: existing.id,
        rule: existing.rule,
        priority: existing.priority
      };
      acc[rule.rule.id] = updated;
      return acc;
    }

    acc[rule.rule.id] = {
      id: rule.id,
      rule: rule.rule,
      priority: 1
    };
    return acc;
  }, _objectSpread({}, configState.active));
  return active;
}

function calcRemoveAllConfig(ruleList, configState) {
  const active = _objectSpread({}, configState.active);

  ruleList.forEach(rule => {
    if (active[rule.rule.id]) {
      delete active[rule.rule.id];
    }
  });
  return active;
}