"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useProjectSettings = useProjectSettings;

var React = _interopRequireWildcard(require("react"));

var _config = require("../../../context/settings/config");

var _useActions = require("../../../context/settings/config/useActions");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function useProjectSettings(projectList) {
  const configState = (0, _config.useConfigState)();
  const {
    setSelectedRule,
    updateActiveProject,
    getProjectConfig
  } = (0, _useActions.useActions)();
  const initialProject = projectList[0].jira_key;
  React.useEffect(() => {
    void updateActiveProject(initialProject);
  }, [projectList]);
  const handleProjectSelect = React.useCallback(function onProjectSelect(projectKey) {
    return () => {
      void updateActiveProject(projectKey);
    };
  }, []);
  React.useEffect(() => {
    if (configState.activeProject && !configState.isLoading) {
      void getProjectConfig(configState.activeProject, configState.insightType);
    }
  }, [configState.activeProject, configState.insightType]);
  const handleRuleSelect = React.useCallback(function onRuleSelect(rule) {
    setSelectedRule(rule);
  }, []);
  return {
    handleProjectSelect,
    handleRuleSelect,
    isSaving: configState.isSaving,
    activeProject: configState.activeProject || initialProject,
    selectedRule: configState.selectedRule
  };
}