"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.groupByRule = exports.groupByPriority = exports.groupBySite = void 0;

var _lodash = _interopRequireDefault(require("lodash.groupby"));

var _utils = require("../../../redux/modules/insights/utils");

const groupBySite = function groupBySite(occurrences) {
  let uniqActionCount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (occurrences.length < 1) return [];
  return (0, _utils.composeInsights)((0, _lodash.default)(occurrences, o => o.ruleId), uniqActionCount);
};

exports.groupBySite = groupBySite;

const groupByPriority = function groupByPriority(occurrences) {
  let uniqActionCount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (occurrences.length < 1) return []; // NOTE: generating group key as combination of siteId + ruleId
  // to ensure site/rule relationship is maintained when grouping by priority

  return (0, _utils.composeInsights)((0, _lodash.default)(occurrences, o => "".concat(o.siteId, " ").concat(o.ruleId)), uniqActionCount);
};

exports.groupByPriority = groupByPriority;

const groupByRule = occurrences => {
  if (occurrences.length < 1) return [];
  return (0, _utils.composeInsights)((0, _lodash.default)(occurrences, o => o.siteId));
};

exports.groupByRule = groupByRule;