"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RemoveButton = exports.AttachmentPreviewItem = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject, _templateObject2;

const AttachmentPreviewItem = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: block;\n  margin-bottom: 10px;\n"])));

exports.AttachmentPreviewItem = AttachmentPreviewItem;

const RemoveButton = _styledComponents.default.button(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  margin-right: 10px;\n"])));

exports.RemoveButton = RemoveButton;