"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateMonitoring = updateMonitoring;
exports.loadMonitoring = loadMonitoring;
exports.loadMonitoringRequest = exports.updateSortByOption = void 0;

var _moment = _interopRequireDefault(require("moment"));

var _actionTypes = require("./actionTypes");

function updateMonitoring(_ref) {
  let {
    siteGroups,
    composed
  } = _ref;

  /* FIXME: argument type */
  return {
    type: _actionTypes.UPDATE_MONITORING,
    siteGroups,
    composed
  };
}

const updateSortByOption = sortByOption => ({
  type: _actionTypes.UPDATE_SORT_BY_OPTION,
  sortByOption
});

exports.updateSortByOption = updateSortByOption;

function loadMonitoring() {
  return {
    type: _actionTypes.LOAD_MONITORING
  };
} // @FIXME: returning `any` as Saga `put` method does not support our async action.


const loadMonitoringRequest = _ref2 => {
  let {
    siteIds,
    daysRange = 3,
    token
  } = _ref2;
  const m = (0, _moment.default)();
  const today = m.format("YYYY-MM-DD");
  const range = m.subtract(daysRange, "days").format("YYYY-MM-DD");
  return {
    types: [_actionTypes.LOAD_MONITORING_REQUEST, _actionTypes.LOAD_MONITORING_REQUEST_SUCCESS, _actionTypes.LOAD_MONITORING_REQUEST_FAIL],
    promise: client => client.post("/synco/ui/insights", {
      headers: {
        Authorization: "Bearer ".concat(token)
      },
      params: {
        from: range,
        to: today
      },
      data: {
        sites: siteIds,
        insightType: "monitoring"
      }
    })
  };
};

exports.loadMonitoringRequest = loadMonitoringRequest;