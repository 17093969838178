"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getReportData = getReportData;
exports.loadReportData = loadReportData;
exports.loadSampleData = loadSampleData;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _actionTypes = require("./actionTypes");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// Actions
function getReportData() {
  return {
    type: _actionTypes.LOAD_REPORT_DATA_REQUEST
  };
}

function loadReportData(user, site) {
  return {
    types: [_actionTypes.LOAD_REPORT_DATA, _actionTypes.LOAD_REPORT_DATA_SUCCESS, _actionTypes.LOAD_REPORT_DATA_FAIL],
    promise: client => client.post("monthlyreport/".concat(site), {
      headers: {
        Authorization: "Bearer ".concat(user.token)
      }
    }).then(response => ({
      site,
      data: _objectSpread({}, response)
    }))
  };
}

function loadSampleData(_user, site) {
  return {
    types: [_actionTypes.LOAD_REPORT_DATA, _actionTypes.LOAD_REPORT_DATA_SUCCESS, _actionTypes.LOAD_REPORT_DATA_FAIL],
    promise: () => Promise.resolve().then(() => ({
      site,
      data: _objectSpread({}, require("../../../scenes/Reports/Summary/data"))
    }))
  };
}