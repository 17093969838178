"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Subtitle = exports.Title = exports.Controls = exports.Heading = exports.Header = exports.GridContainer = exports.Content = exports.Block = exports.Container = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;

/** Outermost wrapper for the charts side */
const Container = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  flex-grow: 1;\n"])));
/** Outermost block for the main chart widget, styled Frame block */


exports.Container = Container;
const Block = (0, _styledComponents.default)(_blocks.Frame)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  margin-bottom: ", ";\n  overflow: hidden;\n"])), _blocks.spacing.extraLoose);
exports.Block = Block;
const Content = (0, _styledComponents.default)(_blocks.FrameContent)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  min-height: 100px;\n"])));
exports.Content = Content;

const GridContainer = _styledComponents.default.div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  padding: 0 ", " ", ";\n  max-height: 500px;\n  overflow: auto;\n"])), _blocks.spacing.default, _blocks.spacing.default);
/** Styled FrameHeader, wraps the heading and controls */


exports.GridContainer = GridContainer;
const Header = (0, _styledComponents.default)(_blocks.FrameHeader)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  ", ";\n  display: flex;\n  align-items: center;\n  border-bottom: none;\n  padding-bottom: 0;\n"])), _blocks.typography.displaySmall);
/** Wraps both the title and subtitle */

exports.Header = Header;

const Heading = _styledComponents.default.div(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n  flex-grow: 1;\n"])));
/** Wraps chart controls, pushed to the far right */


exports.Heading = Heading;

const Controls = _styledComponents.default.div(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)([""])));
/** Main title of the header, h3 by default */


exports.Controls = Controls;
const Title = (0, _styledComponents.default)(_blocks.DisplaySmall)(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\n  em {\n    font-style: italic;\n  }\n"])));
/** Typically a description of the chart information, shown below the Title */

exports.Title = Title;
const Subtitle = (0, _styledComponents.default)(_blocks.BodyLarge)(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\n  color: ", ";\n"])), (0, _blocks.hslaConverter)(_blocks.color.theme.typography.subdued, 'hex'));
exports.Subtitle = Subtitle;