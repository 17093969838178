"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TIMEFRAME_PERSIST_PROP = exports.TRACKING_EVENT_ORDER_BY = exports.TRACKING_EVENT_TIME_FRAME = exports.UTILITIES_PERSIST_KEY = void 0;
const UTILITIES_PERSIST_KEY = "Reports-utilities";
exports.UTILITIES_PERSIST_KEY = UTILITIES_PERSIST_KEY;
const TRACKING_EVENT_TIME_FRAME = "Selected Time Frame (Performance Reports)";
exports.TRACKING_EVENT_TIME_FRAME = TRACKING_EVENT_TIME_FRAME;
const TRACKING_EVENT_ORDER_BY = "Chart - Clicked OrderBy";
exports.TRACKING_EVENT_ORDER_BY = TRACKING_EVENT_ORDER_BY;
const TIMEFRAME_PERSIST_PROP = "timeFrameOption-utilities";
exports.TIMEFRAME_PERSIST_PROP = TIMEFRAME_PERSIST_PROP;