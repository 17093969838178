"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Unselected = exports.Status = exports.Description = exports.Updated = exports.Summary = exports.StyledArrowBack = exports.StyledAvatar = exports.ID = exports.Container = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;

const Container = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  padding: ", ";\n"])), _blocks.spacing.loose);

exports.Container = Container;

const ID = _styledComponents.default.h1(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 0;\n  padding: 0;\n  font-size: 16px;\n  color: ", ";\n\n  strong {\n    display: inline-block;\n    margin-right: ", ";\n    font-weight: 600;\n    color: ", ";\n  }\n"])), _blocks.palette.neutral6, _blocks.spacing.tight, _blocks.color.theme.typography.ink); // TODO: These any assertions are a result of TS infering that `className` is a
// required prop. This should be fixed up later with @bueno/blocks


exports.ID = ID;
const StyledAvatar = (0, _styledComponents.default)(_blocks.PersonSolid)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  margin-right: ", ";\n"])), _blocks.spacing.tight);
exports.StyledAvatar = StyledAvatar;
const StyledArrowBack = (0, _styledComponents.default)(_blocks.ArrowPointLeft)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  display: inline-block;\n  width: 16px;\n  transform: rotate(90deg);\n  fill: ", ";\n  margin-right: ", ";\n"])), props => props.priority === "Critical" ? _blocks.palette.red7 : _blocks.palette.neutral7, _blocks.spacing.tight);
exports.StyledArrowBack = StyledArrowBack;

const Summary = _styledComponents.default.h2(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 0 0 ", ";\n  padding: 0;\n  font-size: 16px;\n  line-height: 24px;\n  font-weight: 600;\n  color: ", ";\n"])), _blocks.spacing.tight, _blocks.color.theme.typography.ink);

exports.Summary = Summary;

const Updated = _styledComponents.default.span(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  justify-content: space-between;\n  margin: 0 0 ", ";\n  padding: 0 0 ", ";\n  border-bottom: 1px solid ", ";\n  font-size: 12px;\n  color: ", ";\n"])), _blocks.spacing.default, _blocks.spacing.default, _blocks.palette.neutral4, _blocks.palette.neutral7);

exports.Updated = Updated;

const Description = _styledComponents.default.div(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 0 0 ", ";\n  padding: 0 0 ", ";\n  border-bottom: 1px solid ", ";\n  color: ", ";\n"])), _blocks.spacing.default, _blocks.spacing.default, _blocks.palette.neutral4, _blocks.color.theme.typography.ink);

exports.Description = Description;
const Status = (0, _styledComponents.default)(Summary)(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  font-size: 14px;\n"])));
exports.Status = Status;

const Unselected = _styledComponents.default.div(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  height: 100%;\n  text-align: center;\n  opacity: ", ";\n"])), _blocks.opacity.subtle);

exports.Unselected = Unselected;