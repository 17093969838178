"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_SORT_BY_OPTION = exports.UPDATE_MONITORING = exports.LOAD_MONITORING_REQUEST_FAIL = exports.LOAD_MONITORING_REQUEST_SUCCESS = exports.LOAD_MONITORING_REQUEST = exports.LOAD_MONITORING = void 0;
const LOAD_MONITORING = "bonfire/monitoring/LOAD_MONITORING";
exports.LOAD_MONITORING = LOAD_MONITORING;
const LOAD_MONITORING_REQUEST = "bonfire/monitoring/LOAD_MONITORING_REQUEST";
exports.LOAD_MONITORING_REQUEST = LOAD_MONITORING_REQUEST;
const LOAD_MONITORING_REQUEST_SUCCESS = "bonfire/monitoring/LOAD_MONITORING_REQUEST_SUCCESS";
exports.LOAD_MONITORING_REQUEST_SUCCESS = LOAD_MONITORING_REQUEST_SUCCESS;
const LOAD_MONITORING_REQUEST_FAIL = "bonfire/monitoring/LOAD_MONITORING_REQUEST_FAIL";
exports.LOAD_MONITORING_REQUEST_FAIL = LOAD_MONITORING_REQUEST_FAIL;
const UPDATE_MONITORING = "bonfire/monitoring/UPDATE_MONITORING";
exports.UPDATE_MONITORING = UPDATE_MONITORING;
const UPDATE_SORT_BY_OPTION = "bonfire/monitoring/UPDATE_SORT_BY_OPTION";
exports.UPDATE_SORT_BY_OPTION = UPDATE_SORT_BY_OPTION;