"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformObjectToCsvString = transformObjectToCsvString;
exports.getCsvBlobUrl = getCsvBlobUrl;
exports.handleCsvExport = handleCsvExport;

/**
 * Retrieve CSV content from an object
 * @param obj
 */
function transformObjectToCsvString(obj) {
  let csvString = "";

  if (Object.keys(obj).length === 0) {
    return csvString;
  } // keys as first row


  csvString += Object.keys(obj[0]).join(",") + "\n"; // values afterwards

  csvString += obj.map(item => {
    return Object.values(item).map(value => {
      value = value.toString().replace(/"/g, "");
      return "".concat(JSON.stringify(value));
    }).join(",");
  }).join("\n");
  return csvString;
}
/**
 * Retrieve CSV content from an object
 * @param object
 */


function getCsvBlobUrl(obj) {
  const csvString = transformObjectToCsvString(obj);
  const blob = new Blob([csvString], {
    type: "text/csv;charset=utf-8;"
  });
  return URL.createObjectURL(blob);
}

function handleCsvExport(rawData, fileName, dataFormatter) {
  const formattedData = dataFormatter(rawData);
  const csvData = getCsvBlobUrl(formattedData);
  const element = document.createElement("a");
  element.setAttribute("href", csvData);
  element.setAttribute("download", fileName);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}