"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rootSaga = rootSaga;

var _effects = require("redux-saga/effects");

var _sagas = require("./modules/users/sagas");

var _sagas2 = require("./modules/profile/sagas");

var _sagas3 = require("./modules/groups/sagas");

var _issues = require("./modules/issues");

var _issues2 = require("./modules/_BETA/issues");

var _issue = require("./modules/_BETA/issue");

var _associate = require("./modules/_BETA/associate");

var _unassociate = require("./modules/_BETA/unassociate");

var _snooze = require("./modules/_BETA/snooze");

var _unsnooze = require("./modules/_BETA/unsnooze");

var _requestSchedule = require("./modules/_BETA/requestSchedule");

var _selections = require("./modules/_BETA/selections");

var _sagas4 = require("./modules/userSites/sagas");

var _sagas5 = require("./modules/attachments/sagas");

var _sagas6 = require("./modules/auth/sagas");

var _reportGenerator = require("./modules/reportGenerator");

var _sagas7 = require("./modules/monthlySummary/sagas");

var _sagas8 = require("./modules/appSetup/sagas");

var _sagas9 = require("./modules/monitoring/sagas");

var _sagas10 = require("./modules/action/sagas");

function* rootSaga() {
  yield (0, _effects.all)([(0, _sagas8.appSetupFlow)(), (0, _sagas6.logout)(), (0, _sagas.createUserFlow)(), (0, _sagas.saveUserFlow)(), (0, _sagas2.changePasswordFlow)(), (0, _sagas3.saveGroupFlow)(), (0, _sagas7.monthlySummarySagaFlow)(), (0, _sagas5.addAttachmentFlow)(), (0, _issues.postCommentFlow)(), (0, _sagas6.analyticsIdentify)(), (0, _sagas6.zendeskInit)(), (0, _reportGenerator.watchGenerateReport)(), // BETA Sagas
  (0, _issues2.issuesFlow)(), (0, _issue.watchCreateAndAssociate)(), (0, _selections.selectionsFlow)(), (0, _associate.associateEquipRuleFlow)(), (0, _unassociate.unassociateEquipRuleWatcher)(), (0, _snooze.snoozeEquipRuleFlow)(), (0, _unsnooze.unsnoozeEquipRuleFlow)(), (0, _requestSchedule.shouldRequestIssuesFlow)(), (0, _requestSchedule.shouldRequestInsightsFlow)(), (0, _requestSchedule.shouldRequestMonitoringFlow)(), (0, _requestSchedule.shouldRequestMonthlySummaryFlow)(), (0, _sagas4.userSitesFlow)(), // New action flow
  (0, _sagas10.loadActionFlow)(), (0, _sagas10.loadInitialActionFlow)(), (0, _sagas10.loadRulesFlow)(), (0, _sagas10.saveActionFlow)(), (0, _sagas10.addActionFlow)(), (0, _sagas9.loadMonitoring)()]);
}