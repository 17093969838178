"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var actionTypes = _interopRequireWildcard(require("./actionTypes"));

var _issues = require("../issues");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const INITIAL_STATE = {
  action: undefined,
  equipRules: [],
  rules: [],
  comments: [],
  userAccess: [],
  isLoading: false,
  isRulesLoading: false,
  isCommentsLoading: false,
  isPending: false,
  submitError: ""
};

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    // Loading in the action for editing
    case actionTypes.LOAD_ACTION_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true,
        comments: [],
        userAccess: [],
        action: undefined
      });

    case actionTypes.LOAD_ACTION_REQUEST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        action: _objectSpread(_objectSpread({}, action.result), {}, {
          skysparkLink: action.result.skyspark_link,
          screenshotLink: action.result.screenshot_link,
          totalFinancialImpact: action.result.total_financial_impact,
          operationalSavings: action.result.operational_savings,
          transitionReason: action.result.transition_reason,
          status: action.result.bonfire_status,
          rule: action.result.spark_type,
          assignee: action.result.insite_assignee
        })
      });

    case actionTypes.LOAD_ACTION_REQUEST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    case actionTypes.ACTION_NOT_FOUND:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        action: undefined
      });
    // Loading comments

    case actionTypes.LOAD_COMMENTS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isCommentsLoading: true
      });

    case actionTypes.LOAD_COMMENTS_REQUEST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        comments: action.result,
        userAccess: [],
        isCommentsLoading: false
      });

    case actionTypes.LOAD_COMMENTS_REQUEST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        comments: [],
        isCommentsLoading: false
      });
    // TODO: This exists in the old issues code

    case _issues.COMMENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        comments: [...state.comments, action.result]
      });
    // Loading in the rule list

    case actionTypes.LOAD_RULES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isRulesLoading: true
      });

    case actionTypes.LOAD_RULES_REQUEST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isRulesLoading: false,
        rules: action.result.sort()
      });

    case actionTypes.LOAD_RULES_REQUEST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isRulesLoading: false
      });
    // Saving an action

    case actionTypes.SAVE_ACTION:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          submitError: "",
          isPending: true
        });
      }

    case actionTypes.SAVE_ACTION_REQUEST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isPending: false
      });

    case actionTypes.SAVE_ACTION_REQUEST_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          submitError: action.error.message,
          isPending: false
        });
      }
    // Adding an action

    case actionTypes.ADD_ACTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        isPending: true,
        submitError: ""
      });

    case actionTypes.ADD_ACTION_REQUEST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isPending: false
      });

    case actionTypes.ADD_ACTION_REQUEST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        submitError: action.error.message,
        isPending: false
      });
    // Attachments

    case actionTypes.ATTACHMENT_UPLOAD_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          submitError: action.message,
          isPending: false
        });
      }

    default:
      return state;
  }
}