"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SiteDetailLinks = exports.links = exports.Route = void 0;
let Route;
exports.Route = Route;

(function (Route) {
  Route["Root"] = "/sitedetails";
  Route["Details"] = "/sitedetails/details";
  Route["Rules"] = "/sitedetails/rules";
  Route["Template"] = "/sitedetails/templates";
})(Route || (exports.Route = Route = {}));

const links = [{
  label: i18n => {
    return typeof i18n._ === "function" ? i18n._(
    /*i18n*/
    {
      id: "site_details.details",
      defaults: "Details"
    }) : "Details";
  },
  href: Route.Details
}, {
  label: i18n => {
    return typeof i18n._ === "function" ? i18n._(
    /*i18n*/
    {
      id: "site_details.rules",
      defaults: "Rule Hits"
    }) : "Rule Hits";
  },
  href: Route.Rules
}, {
  label: i18n => {
    return typeof i18n._ === "function" ? i18n._(
    /*i18n*/
    {
      id: "site_details.templates",
      defaults: "Templates"
    }) : "Templates";
  },
  href: Route.Template
}];
exports.links = links;
const SiteDetailLinks = !process.env.BETA ? links.filter(l => !l.isBeta) : links;
exports.SiteDetailLinks = SiteDetailLinks;