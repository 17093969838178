"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPLOAD_TO_S3_FAIL = exports.UPLOAD_TO_S3_SUCCESS = exports.UPLOAD_TO_S3 = exports.GET_SIGNED_URL_FAIL = exports.GET_SIGNED_URL_SUCCESS = exports.GET_SIGNED_URL = exports.INITIALIZE_ATTACHMENTS = exports.REMOVE_ATTACHMENT = exports.UPLOAD_ATTACHMENTS_COMPLETED = exports.ADD_ATTACHMENT_REQUEST = exports.END_UPLOAD_ATTACHMENT = exports.START_UPLOAD_ATTACHMENT = void 0;
const START_UPLOAD_ATTACHMENT = "bonfire/attachments/START_UPLOAD_ATTACHMENT";
exports.START_UPLOAD_ATTACHMENT = START_UPLOAD_ATTACHMENT;
const END_UPLOAD_ATTACHMENT = "bonfire/attachments/END_UPLOAD ATTACHMENT";
exports.END_UPLOAD_ATTACHMENT = END_UPLOAD_ATTACHMENT;
const ADD_ATTACHMENT_REQUEST = "bonfire/attachments/ADD_ATTACHMENT_REQUEST";
exports.ADD_ATTACHMENT_REQUEST = ADD_ATTACHMENT_REQUEST;
const UPLOAD_ATTACHMENTS_COMPLETED = "bonfire/attachments/UPLOAD_ATTACHMENTS_COMPLETED";
exports.UPLOAD_ATTACHMENTS_COMPLETED = UPLOAD_ATTACHMENTS_COMPLETED;
const REMOVE_ATTACHMENT = "bonfire/attachments/REMOVE_ATTACHMENT";
exports.REMOVE_ATTACHMENT = REMOVE_ATTACHMENT;
const INITIALIZE_ATTACHMENTS = "bonfire/attachments/INITIALIZE_ATTACHMENTS"; // AWS ACTIONS

exports.INITIALIZE_ATTACHMENTS = INITIALIZE_ATTACHMENTS;
const GET_SIGNED_URL = "bonfire/aws/GET_SIGNED_URL";
exports.GET_SIGNED_URL = GET_SIGNED_URL;
const GET_SIGNED_URL_SUCCESS = "bonfire/aws/GET_SIGNED_URL_SUCCESS";
exports.GET_SIGNED_URL_SUCCESS = GET_SIGNED_URL_SUCCESS;
const GET_SIGNED_URL_FAIL = "bonfire/aws/GET_SIGNED_URL_FAIL";
exports.GET_SIGNED_URL_FAIL = GET_SIGNED_URL_FAIL;
const UPLOAD_TO_S3 = "bonfire/aws/UPLOAD_TO_S3";
exports.UPLOAD_TO_S3 = UPLOAD_TO_S3;
const UPLOAD_TO_S3_SUCCESS = "bonfire/aws/UPLOAD_TO_S3_SUCCESS";
exports.UPLOAD_TO_S3_SUCCESS = UPLOAD_TO_S3_SUCCESS;
const UPLOAD_TO_S3_FAIL = "bonfire/aws/UPLOAD_TO_S3_FAIL";
exports.UPLOAD_TO_S3_FAIL = UPLOAD_TO_S3_FAIL;