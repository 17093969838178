"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _keycloakJs = _interopRequireDefault(require("keycloak-js"));

var _navigate = require("../routes/navigate");

var _client = require("../../client");

var _config = require("../../config");

var _selections = require("../../redux/modules/_BETA/selections");

var _actionCreators = require("../../redux/modules/auth/actionCreators");

const _kc = new _keycloakJs.default("/keycloak.json");
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 * Stores the Authentication ojbect in _kc variable where we can access all provided keycloak methods
 * @param onAuthenticatedCallback
 */


const initKeycloak = onAuthenticatedCallback => {
  _kc.init({
    // we can define the keycloak server here instead using the keycloak.json file
    // url: "http://apiHost:keycloakPort/auth",
    // realm: "Realm",
    // client_id: "clientId"
    onLoad: "login-required",
    silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
    pkceMethod: "S256",
    checkLoginIframe: false
  }).then(authenticated => {
    //loggedOut prevents InSite from automatically logging back in on logout.
    //When a logout occurs, a redirect to the login screen occurs.
    //As keycloak has not deregistered the user, insite picks this up and will try to log back in.
    //This check prevents this, as we can only log back in once the user is authenticated here.
    userAuth = true;

    if (!authenticated) {
      doLogin();
    } // Once authenticated, get the users locale. If it exists, dispatch it.


    _kc.loadUserProfile().then(profile => {
      const locale = profile.attributes["locale"];

      if (locale != undefined) {
        _client.store.dispatch((0, _selections.setLocale)(locale));
      }
    }).catch(console.error);

    onAuthenticatedCallback();
  }).catch(console.error);
}; // To call the login method which redirects the user to Keycloak login screen


const doLogin = _kc.login;
let userAuth = false;

const doLogout = () => {
  userAuth = false;

  _kc.logout("http://".concat(_config.host, ":").concat(_config.port));
};

const getToken = () => _kc.token;

const getTokenParsed = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

const updateToken = () => {
  // to update the token, it first checks if there is a session
  // it means the user already authenticated, and can update the session.
  // Checking the session is important, because when declaring the clientMiddleware
  // the updateToken is called from the middleware, and it returns an exception
  // if we don't put the if(_kc.sessionId)
  if (_kc.sessionId) {
    if (window.isTokenUpdated === "notUpdated" && userAuth == true) {
      _kc.updateToken(300) //300 minValidity seconds
      .then(function (refreshed) {
        if (refreshed && window.isTokenUpdated === "notUpdated") {
          window.isTokenUpdated = "Updated";
        }
      }).catch(function () {
        // logout the user if the refresh token wasn't successful
        doLogout();
      });
    }
  }
};

const getUsername = () => {
  var _kc$tokenParsed;

  return (_kc$tokenParsed = _kc.tokenParsed) === null || _kc$tokenParsed === void 0 ? void 0 : _kc$tokenParsed.preferred_username;
};

const hasRole = roles => roles.some(role => _kc.hasRealmRole(role));

const getEmail = () => _kc.tokenParsed.email;

const clearToken = _kc.clearToken;

const isTokenExpired = () => _kc.isTokenExpired(5);

const isUserAuthenticated = () => userAuth;

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getTokenParsed,
  getEmail,
  clearToken,
  isTokenExpired,
  isUserAuthenticated
};
var _default = UserService;
exports.default = _default;
module.exports = exports.default;