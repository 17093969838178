"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadMonitoring = loadMonitoring;

var _effects = require("redux-saga/effects");

var _requestSchedule = require("../_BETA/requestSchedule");

var _actionTypes = require("./actionTypes");

var _actionCreators = require("./actionCreators");

var _utils = require("../insights/utils");

function* loadMonitoring() {
  while (true) {
    try {
      yield (0, _effects.take)(_actionTypes.LOAD_MONITORING);
      const user = yield (0, _effects.select)(state => state.auth.user);
      const {
        selectedSites
      } = yield (0, _effects.select)(state => state.betaSelections);
      const {
        daysRange
      } = yield (0, _effects.select)(state => state.betaSelections);

      if (!user.rolesRestriction) {
        throw Error("Unable to authenticate user");
      }

      yield (0, _effects.put)((0, _actionCreators.loadMonitoringRequest)({
        token: user.token,
        siteIds: selectedSites,
        daysRange
      }));
      const {
        success,
        fail
      } = yield (0, _effects.race)({
        success: (0, _effects.take)(_actionTypes.LOAD_MONITORING_REQUEST_SUCCESS),
        fail: (0, _effects.take)(_actionTypes.LOAD_MONITORING_REQUEST_FAIL)
      });

      if (fail) {
        throw Error("Monitoring request failed: ".concat(fail));
      }

      if (success) {
        const {
          result: raw
        } = success;
        const {
          sites,
          projects
        } = yield (0, _effects.select)(state => state.userSites);
        const composed = (0, _utils.composeOccurrences)(Object.values(raw.hit), sites, projects);
        const siteGroups = (0, _utils.createSiteGroups)(composed, sites, projects);
        yield (0, _effects.put)((0, _requestSchedule.setShouldRequestMonitoring)(false));
        yield (0, _effects.put)((0, _actionCreators.updateMonitoring)({
          siteGroups,
          composed
        }));
      }
    } catch (error) {
      console.log("Something went wrong attempting to monitoring: ".concat(error));
    }
  }
}