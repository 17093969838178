"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useGridState = useGridState;

var React = _interopRequireWildcard(require("react"));

var _storage = require("../../../../utils/storage");

var _sorting = require("../../../../utils/sorting");

var _columns = require("../../../../utils/columns");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const DEFAULT_LOAD_COUNT = 10;

function useGridState(occurrences, filtered, persistKey) {
  const totalCount = occurrences.length;
  const defaultCount = totalCount < DEFAULT_LOAD_COUNT ? totalCount : DEFAULT_LOAD_COUNT;
  let initialSortDir = _columns.monitoringColumns.LATEST.sortDirection;
  let initialSortOption = _columns.Columns.LATEST;
  const firstRender = React.useRef(true);

  if (persistKey && firstRender.current) {
    firstRender.current = false;
    const sort = (0, _storage.getPersisted)({
      key: "Monitoring-colSort",
      prop: persistKey
    }, undefined);

    if (sort !== undefined) {
      initialSortDir = sort.direction;
      initialSortOption = sort.option;
    }
  }

  const [sorted, setSorted] = React.useState([]);
  const [currentCount, setCurrentCount] = React.useState(defaultCount);
  const [sort, setSort] = React.useState({
    option: initialSortOption,
    direction: initialSortDir
  });

  const handleSort = function handleSort(activeSort) {
    let shouldUpdateDirection = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return sortFuncKey => {
      let direction = _columns.monitoringColumns[sortFuncKey].sortDirection;

      if (activeSort === sortFuncKey && shouldUpdateDirection) {
        direction = sort.direction === "asc" ? "desc" : "asc";
      } else {
        direction = sort.direction;
      }

      if (persistKey) {
        (0, _storage.setPersisted)({
          key: "Monitoring-colSort",
          prop: persistKey
        }, {
          option: sortFuncKey,
          direction: direction
        });
      }

      let sorted = (0, _sorting.sortMonitoringData)(occurrences, sortFuncKey, direction);
      setSort({
        option: sortFuncKey,
        direction
      });
      setSorted(sorted);
    };
  };

  React.useEffect(() => {
    handleSort(sort.option, false)(sort.option);
  }, [occurrences]);
  React.useEffect(() => {
    let newCount;

    if (totalCount < DEFAULT_LOAD_COUNT) {
      newCount = totalCount;
    } else if (currentCount > totalCount) {
      newCount = totalCount;
    } else if (totalCount > DEFAULT_LOAD_COUNT && currentCount >= DEFAULT_LOAD_COUNT) {
      newCount = currentCount;
    } else {
      newCount = DEFAULT_LOAD_COUNT;
    }

    setCurrentCount(newCount);
  }, [totalCount, filtered]);
  return {
    activeSort: sort.option,
    currentCount,
    defaultCount: DEFAULT_LOAD_COUNT,
    sorted,
    setCurrentCount,
    sortDirection: sort.direction,
    totalCount,
    onSort: handleSort
  };
}