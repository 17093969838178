"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.msColumn2dBaseConfig = exports.scrollBar2DChartBaseConfig = void 0;

var _blocks = require("@conserveit/blocks");

const ink = (0, _blocks.hslaConverter)(_blocks.color.theme.typography.ink, 'hex');
const scrollColor = (0, _blocks.hslaConverter)(_blocks.color.theme.typography.subdued, 'hex');
const scrollBar2DChartBaseConfig = {
  divLineAlpha: 0,
  maxBarHeight: 32,
  numvisibleplot: 8,
  showPlotBorder: true,
  plotBorderThickness: 1,
  plotBorderColor: (0, _blocks.hslaConverter)(_blocks.palette.pureWhite, 'hex'),
  placeValuesInside: false,
  valuePadding: 4,
  valueFontSize: 14,
  valueFontBold: true,
  showYAxisValues: true,
  yAxisValueAlpha: 0,
  yAxisValueFontSize: 0,
  yAxisNamePadding: 0,
  valueFontColor: ink,
  legendItemFontColor: ink,
  labelFontColor: ink,
  xAxisNameFontColor: ink,
  yAxisValueFontColor: ink,
  yAxisNameFontColor: ink,
  flatScrollbars: true,
  scrollColor,
  scrollWidth: 12
};
exports.scrollBar2DChartBaseConfig = scrollBar2DChartBaseConfig;
const msColumn2dBaseConfig = {
  divLineAlpha: 0,
  maxBarHeight: 32,
  canvasBgAlpha: 100,
  plotHoverEffect: 0,
  showPlotBorder: true,
  plotBorderThickness: 1,
  plotBorderColor: (0, _blocks.hslaConverter)(_blocks.palette.pureWhite, 'hex'),
  yAxisNamePadding: 10,
  valueFontColor: ink,
  legendItemFontColor: ink,
  labelFontColor: ink,
  xAxisNameFontColor: ink,
  yAxisValueFontColor: ink,
  yAxisNameFontColor: ink,
  theme: 'fusion'
};
exports.msColumn2dBaseConfig = msColumn2dBaseConfig;