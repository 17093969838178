"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IssueList = exports.SearchContainer = exports.IssuesContainer = exports.Footer = exports.Right = exports.Left = exports.Content = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

const Content = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  border-top: 1px solid ", ";\n  flex-basis: 100%;\n  flex-grow: 1;\n  overflow: hidden;\n"])), _blocks.color.theme.divider.solid);

exports.Content = Content;

const Left = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  flex-basis: 50%;\n  height: 100%;\n  border-right: 1px solid ", ";\n"])), _blocks.color.theme.divider.solid);

exports.Left = Left;
const Right = (0, _styledComponents.default)(Left)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  box-shadow: none;\n  overflow-y: scroll;\n  background: ", ";\n"])), _blocks.color.pureWhite);
exports.Right = Right;

const Footer = _styledComponents.default.div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  padding: ", ";\n  border-top: 1px solid ", ";\n  background: ", ";\n"])), _blocks.spacing.default, _blocks.color.theme.divider.solid, _blocks.color.pureWhite);

exports.Footer = Footer;

const IssuesContainer = _styledComponents.default.div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"])));

exports.IssuesContainer = IssuesContainer;

const SearchContainer = _styledComponents.default.div(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)([""])));

exports.SearchContainer = SearchContainer;

const IssueList = _styledComponents.default.div(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n  flex: 1 0;\n  position: relative;\n  overflow-y: ", ";\n"])), props => props.noResults ? "hidden" : "auto");

exports.IssueList = IssueList;