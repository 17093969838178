"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _types = require("./types");

var _utils = require("./utils");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function reducer(state, action) {
  const actionType = action.type;

  switch (action.type) {
    case "LOAD_SUMMARY":
      return _objectSpread(_objectSpread({}, state), {}, {
        summaryLoading: true
      });

    case "SUMMARY_REQUEST_SUCCESS":
      {
        const composed = (0, _utils.composeSiteProperties)(state, action);
        const sites = new Map(composed.map(s => [s.siteId, s]));
        return _objectSpread(_objectSpread({}, state), {}, {
          summaryLoading: false,
          summaryLoaded: true,
          sites,
          shouldUpdateSummary: false,
          summary: {
            [_types.MetricsType.Completed]: action[_types.MetricsType.Completed],
            [_types.MetricsType.Opened]: action[_types.MetricsType.Opened],
            [_types.MetricsByType.BySite]: action.bySite,
            [_types.MetricsByType.ByResponsible]: action.byResponsible // @TODO: currently missing this in API response

          }
        });
      }

    case "SUMMARY_REQUEST_FAIL":
      // @TODO: implement fail state
      return _objectSpread(_objectSpread({}, state), {}, {
        summaryLoading: false,
        summaryLoaded: false,
        shouldUpdateSummary: false
      });

    case "SET_SHOULD_UPDATE_SUMMARY":
      return _objectSpread(_objectSpread({}, state), {}, {
        shouldUpdateSummary: action.shouldUpdate
      });

    case "SET_SHOULD_UPDATE_METRICS":
      return _objectSpread(_objectSpread({}, state), {}, {
        shouldUpdateMetrics: action.shouldUpdate
      });

    case "SET_TIME_FRAME":
      return _objectSpread(_objectSpread({}, state), {}, {
        shouldUpdateSummary: true,
        shouldUpdateMetrics: true,
        timeFrameOption: action.timeFrameOption,
        startDate: action.startDate,
        endDate: action.endDate
      });

    case "SET_SORTING_DIRECTION":
      return _objectSpread(_objectSpread({}, state), {}, {
        sortingDirection: action.sortingDirection
      });

    case "SET_SITES_LIST":
      return _objectSpread(_objectSpread({}, state), {}, {
        shouldUpdateSummary: true,
        shouldUpdateMetrics: true,
        sites: new Map(action.sites.map(s => [s.siteId, s])),
        active: new Set(action.sites.map(s => s.siteId))
      });

    case "ADD_RESPONSIBLE":
      const added = [...state.metrics.pinnedResponsibleList, action.responsible];
      return _objectSpread(_objectSpread({}, state), {}, {
        metrics: _objectSpread(_objectSpread({}, state.metrics), {}, {
          pinnedResponsibleList: [...new Set(added)]
        })
      });

    case "REMOVE_RESPONSIBLE":
      const removed = [...state.metrics.pinnedResponsibleList];
      const index = removed.indexOf(action.responsible);

      if (index > -1) {
        removed.splice(index, 1);
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        metrics: _objectSpread(_objectSpread({}, state.metrics), {}, {
          pinnedResponsibleList: [...new Set(removed)]
        })
      });

    case "LOAD_METRICS":
      return _objectSpread(_objectSpread({}, state), {}, {
        metricsLoading: true
      });

    case "METRICS_REQUEST_SUCCESS":
      return _objectSpread(_objectSpread({}, state), {}, {
        shouldUpdateMetrics: false,
        metricsLoading: false,
        metricsLoaded: true,
        metrics: _objectSpread(_objectSpread({}, state.metrics), {}, {
          currentMetricsType: action.metricsType,
          pinnedResponsibleList: [],
          [action.metricsType]: {
            total: action.total,
            [_types.MetricsByType.BySite]: action.bySite,
            [_types.MetricsByType.ByResponsible]: action.byResponsible
          }
        })
      });

    case "SET_METRICS_BY_TYPE":
      return _objectSpread(_objectSpread({}, state), {}, {
        byType: action.by
      });

    case "METRICS_REQUEST_FAIL":
      // @TODO: implement fail state
      return _objectSpread(_objectSpread({}, state), {}, {
        metricsLoading: false,
        metricsLoaded: false,
        shouldUpdateMetrics: false
      });

    default:
      throw new Error("Unhandled action type: ".concat(actionType));
  }
}