"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _actionTypes = require("./actionTypes");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const initialState = {
  groups_loaded: false,
  error: null,
  roles: {
    Users: "Users",
    "ReadOnlyUsers": "ReadOnlyUsers",
    "Project Admins": "Project Admins",
    "Engineering Admins": "Engineering Admins",
    Administrators: "Administrators"
  }
};

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actionTypes.LOAD_GROUPS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        groups: action.result,
        groups_loaded: true,
        error: null
      });

    case _actionTypes.LOAD_GROUPS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        groups_loaded: false,
        error: action.error.message || action.error.error
      });

    default:
      return state;
  }
}