"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCounts = void 0;

var React = _interopRequireWildcard(require("react"));

var _ = require("..");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const useCounts = (group, grouped, composedGrouped, showSnoozed) => {
  const [siteCount, setSiteCount] = React.useState(0);
  const [equipCount, setEquipCount] = React.useState(0);
  const [insightsCount, setInsightsCount] = React.useState(0);
  const [snoozedCount, setSnoozedCount] = React.useState(0);
  React.useEffect(() => {
    const {
      siteCount: sites,
      equipCount: equips
    } = (0, _.getMonitoringCounts)(group, grouped, showSnoozed);
    setSiteCount(sites);
    setEquipCount(equips);
  }, [group, grouped, showSnoozed]);
  React.useEffect(() => {
    setInsightsCount((0, _.getInsightsCount)(composedGrouped, showSnoozed));
    setSnoozedCount((0, _.getSnoozedCount)(composedGrouped));
  }, [composedGrouped, showSnoozed]);
  return {
    siteCount,
    equipCount,
    insightsCount,
    snoozedCount
  };
};

exports.useCounts = useCounts;