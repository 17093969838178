"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.composeSiteProperties = composeSiteProperties;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function composeSiteProperties(state, action) {
  const maxCount = Math.max.apply(Math, action.sites.map(id => action.bySite[id][action.metricsType]));
  return action.sites.map(siteId => {
    const site = state.sites.get(siteId);
    if (site === undefined) throw new Error("Unkownn site");
    const count = action.bySite[siteId][action.metricsType];
    return _objectSpread(_objectSpread({}, site), {}, {
      fill: count / maxCount,
      count
    });
  }).sort((a, b) => b.count <= a.count ? -1 : 1);
}