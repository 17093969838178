"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTrackedActions = useTrackedActions;

var React = _interopRequireWildcard(require("react"));

var _eventTracking = require("../../../../utils/eventTracking");

var _constants = require("../../../../context/reports/comfort/constants");

var _useActions = require("../../../../context/reports/comfort/useActions");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function useTrackedActions() {
  const {
    setRankDir,
    setViewBy,
    setTimeFrame
  } = (0, _useActions.useActions)();
  const trackedRankDir = React.useCallback(function trackedOrderBy(chartTitle) {
    return value => {
      const chartOrder = value === "worst" ? "least to most" : "most to least";
      (0, _eventTracking.orderByEvent)({
        chartTitle,
        chartOrder
      }, _constants.TRACKING_EVENT_ORDER_BY);
      setRankDir(value);
    };
  }, []);
  const trackedViewBy = React.useCallback(function trackedGroupBy(chartTitle) {
    return value => {
      (0, _eventTracking.groupByEvent)({
        chartTitle,
        chartGrouping: _constants.VIEW_BY_OPTIONS.find(o => o.value === value).label
      }, _constants.TRACKING_EVENT_GROUP_BY);
      setViewBy(value);
    };
  }, []);
  const trackedTimeFrame = React.useCallback(function trackedTimeFrame(timeFrameOption, compareToOption) {
    (0, _eventTracking.timeFrameEvent)({
      timeFrameOption
    }, _constants.TRACKING_EVENT_TIME_FRAME);
    setTimeFrame(timeFrameOption, compareToOption);
  }, []);
  return {
    trackedRankDir,
    trackedViewBy,
    trackedTimeFrame
  };
}