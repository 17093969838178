"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MetricsByType = exports.MetricsType = exports.ViewMode = void 0;
let ViewMode; // Keep values equivalent to API keys

exports.ViewMode = ViewMode;

(function (ViewMode) {
  ViewMode[ViewMode["Site"] = 0] = "Site";
  ViewMode[ViewMode["Responsible"] = 1] = "Responsible";
})(ViewMode || (exports.ViewMode = ViewMode = {}));

let MetricsType; // Keep values equivalent to API keys

exports.MetricsType = MetricsType;

(function (MetricsType) {
  MetricsType["Opened"] = "opened";
  MetricsType["Completed"] = "completed";
})(MetricsType || (exports.MetricsType = MetricsType = {}));

let MetricsByType;
exports.MetricsByType = MetricsByType;

(function (MetricsByType) {
  MetricsByType["BySite"] = "bySite";
  MetricsByType["ByResponsible"] = "byResponsible";
})(MetricsByType || (exports.MetricsByType = MetricsByType = {}));