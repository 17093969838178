"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

function _default(H) {
  H.setOptions({
    lang: {
      thousandsSep: ','
    }
  });
}

module.exports = exports.default;