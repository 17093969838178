"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

/**
 * @author Kyle Hofer
 * @overview A class for acting as a communication layer for messages being passed around.
 * @copyright Copyright 2022 Conserve It, Inc. All Rights Reserved.
 * @licence
 */

/**
 * A class for acting as a communication layer for messages being passed around
 * @alias ciConfiguration.utils.EventEmitter
 */
class EventEmitter {
  constructor() {
    (0, _defineProperty2.default)(this, "idMap", new Map());
  }

  /**
   * Registers an event for a specific id
   * @param {any} id The unique reference to use as a key
   * @param {String} event The event to listen to
   * @param {Function} callback The callback function for when the event fires
   */
  register(id, event, callback) {
    let item = this.idMap.get(id);

    if (!item) {
      this.idMap.set(id, item = new Map());
    }

    if (!item.has(event)) {
      item.set(event, callback);
    } else {
      console.warn("The event \"".concat(event, "\" is already registered for the id: ").concat(id, "."));
    }
  }
  /**
   * Fires an event on the emitter. All callbacks registered to the event will be called.
   * @param {String} event The event being fired
   * @param {any} [data] Optional data to be sent with the event
   */


  fire(event, data) {
    this.idMap.forEach(item => {
      const func = item.get(event);

      if (func) {
        func(data);
      }
    });
  }
  /**
   * Deregisters an event for a specific id
   * @param {*} id The unique reference to use as a key
   * @param {String} event The event to listen to
   */


  deregister(id, event) {
    const item = this.idMap.get(id);

    if (item && item.has(event)) {
      item.delete(event);
    } else {
      console.info("The id \"".concat(id, "\" hasn't got the registered event: ").concat(event, "."));
    }
  }
  /**
   * Deregisters all events for a specific id
   * @param {any} id The unique reference to use as a key
   */


  deregisterAll(id) {
    const item = this.idMap.get(id);

    if (item) {
      item.clear();
      this.idMap.delete(id);
    } else {
      console.info("The id \"".concat(id, "\" has no registered events."));
    }
  }

}

var _default = EventEmitter;
exports.default = _default;
module.exports = exports.default;