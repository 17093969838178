"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModalTitle = exports.SubduedDisplaySmall = exports.LoadingWrapper = exports.StyledDataGrid = exports.StyledModal = exports.StyleDataGridHeading = exports.GoToEquip = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactRouterDom = require("react-router-dom");

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

const GoToEquip = (0, _styledComponents.default)(_reactRouterDom.Link)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  color: inherit;\n  text-decoration: none;\n  display: contents;\n"])));
exports.GoToEquip = GoToEquip;
const StyleDataGridHeading = (0, _styledComponents.default)(_blocks.DataGridHeading)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  &:focus {\n    outline: none;\n  }\n"])));
exports.StyleDataGridHeading = StyleDataGridHeading;
const StyledModal = (0, _styledComponents.default)(_blocks.Modal)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  overflow: hidden;\n  justify-content: center;\n  /** ! HACK: Need to update the modal props in blocks to accept width/height props */\n  section {\n    min-height: 72px !important;\n    width: 700px !important;\n  }\n"])));
exports.StyledModal = StyledModal;
const StyledDataGrid = (0, _styledComponents.default)(_blocks.DataGrid)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  width: 100%;\n"])));
exports.StyledDataGrid = StyledDataGrid;

const LoadingWrapper = _styledComponents.default.div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 40px 0;\n  width: 100%;\n"])));

exports.LoadingWrapper = LoadingWrapper;
const SubduedDisplaySmall = (0, _styledComponents.default)(_blocks.DisplaySmall)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n  color: ", ";\n"])), _blocks.color.theme.typography.subdued);
exports.SubduedDisplaySmall = SubduedDisplaySmall;

const ModalTitle = _styledComponents.default.div(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n"])));

exports.ModalTitle = ModalTitle;