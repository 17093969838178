"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calcSiteAvgComfort = calcSiteAvgComfort;
exports.calcSitePortfolio = calcSitePortfolio;
exports.orderZones = orderZones;
exports.orderPortfolio = orderPortfolio;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash.orderby"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 *
 * @param zones time series points for individual site
 *
 * Used to reduce time series line chart data down to averages
 * for stacked bar chart summary data
 */
function calcSiteAvgComfort(data) {
  const avgs = data.reduce((acc, d) => {
    return {
      pctHot: acc.pctHot + d.totalPctHot,
      pctCold: acc.pctCold + d.totalPctCold,
      pctComfort: acc.pctComfort + d.totalPctComfort
    };
  }, {
    pctHot: 0,
    pctCold: 0,
    pctComfort: 0
  });
  return {
    pctHot: avgs.pctHot / data.length,
    pctCold: avgs.pctCold / data.length,
    pctComfort: avgs.pctComfort / data.length
  };
}

/**
 * Combines multiple site series into summary averages
 */
function calcSitePortfolio(data) {
  const portfolio = Object.entries(data).reduce((acc, _ref) => {
    let [key, site] = _ref;
    // * Site series data
    const series = {
      current: site.current.series,
      compare: site.compare ? site.compare.series : undefined
    }; // * Site summary data

    const summary = {
      current: _objectSpread({
        from: site.current.from,
        to: site.current.to
      }, calcSiteAvgComfort(site.current.series)),
      compare: site.compare ? _objectSpread({
        from: site.compare.from,
        to: site.compare.to
      }, calcSiteAvgComfort(site.compare.series)) : undefined
    };
    const composed = {
      name: site.name,
      series,
      summary
    };
    acc[key] = composed;
    return acc;
  }, {});
  return portfolio;
}
/**
 * Orders Zones by supplied key
 * When two or more zones have the same value they're
 * sorted alphabetically by zone name
 *
 * @param rankDir 'best' | 'worst'
 * @param viewBy keyof Zone
 * @param data Zone[]
 */


function orderZones(rankDir, viewBy, data) {
  const dirNeg = rankDir === "best" ? "asc" : "desc";
  const sortedByName = (0, _lodash.default)(data, d => d.name, "asc");
  return (0, _lodash.default)(sortedByName, d => d[viewBy], dirNeg);
}

function orderPortfolio(rankDir, data) {
  // * Remove null values
  const pairs = Object.entries(data).filter(d => Boolean(d[1].summary.current.pctComfort));

  if (rankDir === "best") {
    pairs.sort((a, b) => a[1].summary.current.pctComfort >= b[1].summary.current.pctComfort ? -1 : 1);
  }

  if (rankDir === "worst") {
    pairs.sort((a, b) => a[1].summary.current.pctComfort <= b[1].summary.current.pctComfort ? -1 : 1);
  }

  return pairs;
}