"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VALIDATE_FORM_ERROR = exports.EDITING_GROUP_FORM = exports.END_ACTION = exports.EDITING_GROUP = exports.ADDING_GROUP = exports.SAVE_GROUP_FAIL = exports.SAVE_GROUP_SUCCESS = exports.SAVE_GROUP = exports.SAVE_GROUP_REQUEST = exports.ADD_GROUP_FAIL = exports.ADD_GROUP_SUCCESS = exports.ADD_GROUP = exports.ADD_GROUP_REQUEST = exports.LOAD_GROUPS_FAIL = exports.LOAD_GROUPS_SUCCESS = exports.LOAD_GROUPS = void 0;
// load user groups
const LOAD_GROUPS = "bonfire/groups/LOAD_GROUPS";
exports.LOAD_GROUPS = LOAD_GROUPS;
const LOAD_GROUPS_SUCCESS = "bonfire/groups/LOAD_GROUPS_SUCCESS";
exports.LOAD_GROUPS_SUCCESS = LOAD_GROUPS_SUCCESS;
const LOAD_GROUPS_FAIL = "bonfire/groups/LOAD_GROUPS_FAIL";
exports.LOAD_GROUPS_FAIL = LOAD_GROUPS_FAIL;
// ADDING new groups
const ADD_GROUP_REQUEST = "bonfire/groups/ADD_GROUP_REQUEST";
exports.ADD_GROUP_REQUEST = ADD_GROUP_REQUEST;
const ADD_GROUP = "bonfire/groups/ADD_GROUP";
exports.ADD_GROUP = ADD_GROUP;
const ADD_GROUP_SUCCESS = "bonfire/groups/ADD_GROUP_SUCCESS";
exports.ADD_GROUP_SUCCESS = ADD_GROUP_SUCCESS;
const ADD_GROUP_FAIL = "bonfire/groups/ADD_GROUP_FAIL";
exports.ADD_GROUP_FAIL = ADD_GROUP_FAIL;
// save group changes
const SAVE_GROUP_REQUEST = "bonfire/groups/SAVE_GROUP_REQUEST";
exports.SAVE_GROUP_REQUEST = SAVE_GROUP_REQUEST;
const SAVE_GROUP = "bonfire/groups/SAVE_GROUP";
exports.SAVE_GROUP = SAVE_GROUP;
const SAVE_GROUP_SUCCESS = "bonfire/groups/SAVE_GROUP_SUCCESS";
exports.SAVE_GROUP_SUCCESS = SAVE_GROUP_SUCCESS;
const SAVE_GROUP_FAIL = "bonfire/groups/SAVE_GROUP_FAIL";
exports.SAVE_GROUP_FAIL = SAVE_GROUP_FAIL;
// GUI CONTROL
const ADDING_GROUP = "bonfire/groups/ADDING_GROUP";
exports.ADDING_GROUP = ADDING_GROUP;
const EDITING_GROUP = "bonfire/groups/EDITING_GROUP";
exports.EDITING_GROUP = EDITING_GROUP;
const END_ACTION = "bonfire/groups/END_ACTION";
exports.END_ACTION = END_ACTION;
const EDITING_GROUP_FORM = "bonfire/groups/EDITING_GROUP_FORM";
exports.EDITING_GROUP_FORM = EDITING_GROUP_FORM;
const VALIDATE_FORM_ERROR = "bonfire/groups/VALIDATE_FORM_ERROR";
exports.VALIDATE_FORM_ERROR = VALIDATE_FORM_ERROR;