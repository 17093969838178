"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListItem = exports.Link = exports.List = exports.Heading = exports.Section = exports.Container = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

/** Outermost wrapper for the sidebar */
const Container = _styledComponents.default.aside(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  flex-basis: 20%;\n  margin-right: ", ";\n  min-width: 280px;\n"])), _blocks.spacing.loose);
/** Adds some negative space around each section of the sidebar */


exports.Container = Container;

const Section = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  margin-bottom: ", ";\n"])), _blocks.spacing.ultraLoose);
/** Subtle titles for each sidebar section */


exports.Section = Section;

const Heading = _styledComponents.default.h3(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  margin-bottom: ", ";\n  ", ";\n  color: ", ";\n"])), _blocks.spacing.snug, _blocks.typography.title, _blocks.color.theme.typography.subtle);

exports.Heading = Heading;

const List = _styledComponents.default.ul(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n"])));
/** A button disguised as a Link, used for calling filter changes usually */


exports.List = List;

const Link = _styledComponents.default.button.attrs({
  type: 'button'
})(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  display: inline;\n  color: inherit;\n  ", ";\n  padding: 0;\n  background: transparent;\n  font-weight: ", ";\n  pointer-events: ", ";\n\n  em {\n    font-style: italic;\n  }\n"])), _blocks.typography.displaySmall, _blocks.fontWeight.normal, _ref => {
  let {
    disabled
  } = _ref;
  return disabled ? 'none' : 'default';
});

exports.Link = Link;

function getFillColor(props) {
  return props.isActive ? _blocks.color.theme.typography.ink : _blocks.color.theme.typography.subdued;
}
/**  */


const ListItem = _styledComponents.default.li(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  align-items: center;\n  margin-bottom: ", ";\n  color: ", ";\n\n  &:hover {\n    text-decoration: ", ";\n  }\n\n  svg {\n    margin-right: ", ";\n    stroke: ", ";\n  }\n"])), _blocks.spacing.tight, getFillColor, props => props.isActive ? 'none' : 'underline', _blocks.spacing.tight, getFillColor);

exports.ListItem = ListItem;