"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ValueUnit = exports.Value = exports.ValueLabel = exports.Right = exports.Left = exports.TipHead = exports.Container = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

const Container = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  padding: ", ";\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;\n"])), _blocks.spacing.extraTight);

exports.Container = Container;

const TipHead = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: ", ";\n  font-family: inherit;\n"])), _blocks.spacing.default);

exports.TipHead = TipHead;
const Left = (0, _styledComponents.default)(_blocks.BodySmall)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  margin-right: ", ";\n  color: ", ";\n"])), _blocks.spacing.default, _blocks.color.theme.typography.subdued);
exports.Left = Left;
const Right = (0, _styledComponents.default)(_blocks.BodySmall)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  margin-left: ", ";\n  color: ", ";\n"])), _blocks.spacing.default, _blocks.color.theme.typography.subdued);
exports.Right = Right;
const ValueLabel = (0, _styledComponents.default)(_blocks.Title)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  color: ", ";\n  font-weight: 700;\n  margin-bottom: ", ";\n"])), _blocks.color.theme.typography.subdued, _blocks.spacing.extraTight);
exports.ValueLabel = ValueLabel;
const Value = (0, _styledComponents.default)(_blocks.DisplayLarge)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n"])));
exports.Value = Value;
const ValueUnit = (0, _styledComponents.default)(_blocks.BodyLarge)(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n"])));
exports.ValueUnit = ValueUnit;