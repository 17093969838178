"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.EDIT_MODAL_DATA = exports.ADD_MODAL_DATA = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _actionCreators = require("./actionCreators");

var _actionTypes = require("./actionTypes");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// Other constants
const ADD_MODAL_DATA = {
  title: "Add Group",
  submitText: "Add Group",
  submitStyle: "success"
};
exports.ADD_MODAL_DATA = ADD_MODAL_DATA;
const EDIT_MODAL_DATA = {
  title: "Edit Group",
  submitText: "Save Changes",
  submitStyle: "success"
};
exports.EDIT_MODAL_DATA = EDIT_MODAL_DATA;
const INITIAL_GROUP_FORM_DATA = {};
const initialState = {
  groups: [],
  projects: {},
  users_loaded: false,
  adding: false,
  editing: false,
  working: false,
  group_form_data: INITIAL_GROUP_FORM_DATA,
  modal_data: {},
  success: null,
  project_added: false,
  project_edited: false
};

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actionTypes.LOAD_GROUPS:
      return _objectSpread({}, state);

    case _actionTypes.LOAD_GROUPS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        groups: action.result,
        error: null
      });

    case _actionTypes.ADDING_GROUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        adding: true,
        modal_data: ADD_MODAL_DATA,
        success: null
      });

    case _actionTypes.ADD_GROUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        working: true
      });

    case _actionTypes.ADD_GROUP_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        adding: true,
        working: false,
        // TODO: This is a catchall, need to improve error handling.
        error: "".concat(action.error.message || action.error.error, " - The selected Group Name likely already exists in Bonfire")
      });

    case _actionTypes.ADD_GROUP_SUCCESS:
      {
        const groups = [...state.groups];
        const newGroup = {
          group_id: state.group_form_data.group_key,
          // NOTE: This id is temporary to avoid `unique key` warning
          group_type: state.group_form_data.group_type,
          group_name: state.group_form_data.group_name,
          group_key: state.group_form_data.group_key,
          project: state.group_form_data.project
        };
        groups.unshift(newGroup);
        const projectAdded = newGroup.group_type === "project";
        return _objectSpread(_objectSpread({}, state), {}, {
          error: null,
          adding: false,
          working: false,
          groups,
          group_form_data: INITIAL_GROUP_FORM_DATA,
          success: "Group **".concat(state.group_form_data.group_name, "** is added successfully."),
          project_added: projectAdded
        });
      }

    case _actionTypes.SAVE_GROUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        working: true
      });

    case _actionTypes.SAVE_GROUP_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        editing: false,
        working: false,
        error: action.error.message || action.error.error
      });

    case _actionTypes.SAVE_GROUP_SUCCESS:
      {
        const groups = [...state.groups];
        const groupIndex = groups.findIndex(group => group.group_id === state.group_form_data.group_id);
        groups.splice(groupIndex, 1, state.group_form_data);
        return _objectSpread(_objectSpread({}, state), {}, {
          error: null,
          editing: false,
          working: false,
          groups,
          user_form_data: INITIAL_GROUP_FORM_DATA,
          success: "Changes to Group **".concat(state.group_form_data.group_name, "** is successfully saved.")
        });
      }

    case _actionTypes.EDITING_GROUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        editing: true,
        modal_data: EDIT_MODAL_DATA,
        group_form_data: action.row,
        submit_func: _actionCreators.saveGroupRequest,
        success: null
      });

    case _actionTypes.EDITING_GROUP_FORM:
      {
        const groupFormData = _objectSpread({}, state.group_form_data);

        groupFormData[action.input_id] = action.input_value;

        if (action.input_id === "group_type" && action.input_value === "project") {
          groupFormData.project = null;
        }

        if (action.input_id === "group_type" && action.input_value === "site") {
          groupFormData.group_key = null;
        }

        if (action.input_id === "group_type" && action.input_value === "group") {
          groupFormData.project = null;
          groupFormData.group_key = null;
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          group_form_data: groupFormData
        });
      }

    case _actionTypes.END_ACTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        editing: false,
        adding: false,
        group_form_data: INITIAL_GROUP_FORM_DATA,
        error: null
      });

    case _actionTypes.VALIDATE_FORM_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error.join("<br>")
      });

    default:
      return state;
  }
}