"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Heavy = exports.TipTitle = exports.TipRow = exports.TipWrapper = exports.Tip = exports.ToolTip = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

function openClose(_ref) {
  let {
    isOpen
  } = _ref;
  return isOpen ? "block" : "none";
}

const ToolTip = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: ", ";\n  position: absolute;\n  top: 0;\n  left: 0;\n  margin: 0 auto;\n  width: 100%;\n  height: 1px;\n  background: ", ";\n  overflow: visible;\n  z-index: ", ";\n"])), openClose, _blocks.color.pureWhite, _blocks.layer.contextNavigation);

exports.ToolTip = ToolTip;

const Tip = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  height: auto;\n  max-height: 400px;\n  max-width: 300px;\n  min-width: 240px;\n  border-radius: ", ";\n  z-index: ", ";\n  padding: ", ";\n  background: ", ";\n  box-shadow: ", ";\n  overflow: auto;\n  transform: translate(-25%, -100%);\n  cursor: default;\n"])), _blocks.borderRadius.default, _blocks.layer.contextNavigation, _blocks.spacing.default, _blocks.color.pureWhite, _blocks.elevation.contentNavigation);

exports.Tip = Tip;

const TipWrapper = _styledComponents.default.span(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  position: relative;\n  display: inline-block;\n"])));

exports.TipWrapper = TipWrapper;

const TipRow = _styledComponents.default.div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  margin-bottom: ", ";\n  font-size: 12px;\n  font-weight: 400;\n"])), _blocks.spacing.extraTight);

exports.TipRow = TipRow;
const TipTitle = (0, _styledComponents.default)(TipRow)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  margin-bottom: ", ";\n  font-weight: 700;\n"])), _blocks.spacing.tight);
exports.TipTitle = TipTitle;

const Heavy = _styledComponents.default.strong(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n  display: block;\n  font-size: 14px;\n  text-decoration: underline dashed;\n  text-underline-position: under;\n  margin-bottom: ", ";\n"])), _blocks.spacing.extraTight);

exports.Heavy = Heavy;