"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUsername = exports.getAction = exports.getActionId = exports.getUserToken = void 0;

const getUserToken = state => state.auth.user.token;

exports.getUserToken = getUserToken;

const getActionId = state => state.betaAction.action.key;

exports.getActionId = getActionId;

const getAction = state => state.betaAction.action;

exports.getAction = getAction;

const getUsername = state => state.auth.user.display_name;

exports.getUsername = getUsername;