"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.serialiseCreateAction = serialiseCreateAction;
exports.serialiseEditAction = serialiseEditAction;

var _controls = require("../../../../components/Action/utils/controls");

/**
 * Create an issue object the create API is able to consume. Eventually with a new API
 * the need for this should go away
 */
function serialiseCreateAction(actionForm) {
  const [projectKey, siteName] = actionForm.site.split("~"); // TODO: This is the payload consumed by Portico, this should be revisted soon

  const action = {
    projectKey,
    components: [{
      name: siteName
    }],
    summary: actionForm.summary,
    description: actionForm.description,
    target: "",
    issueType: "Spark",
    sparkType: actionForm.rule,
    priority: actionForm.priority,
    transition: actionForm.status,
    status: (0, _controls.valuePair)(actionForm.status),
    responsible: actionForm.responsible,
    labels: ["insite-created", "user-created"],
    issueContext: actionForm.context,
    screenshotUrl: actionForm.screenshotLink,
    assignee: actionForm.assignee
  }; // Conditionally send optional fields

  if (actionForm.skysparkLink !== "") {
    action.reportUrl = actionForm.skysparkLink;
  }

  if (actionForm.elec !== 0) {
    action.electricity = actionForm.elec;
  }

  if (actionForm.gas !== 0) {
    action.gas = actionForm.gas;
  }

  if (actionForm.water !== 0) {
    action.water = actionForm.water;
  }

  if (actionForm.totalFinancialImpact !== 0) {
    action.totalSavings = actionForm.totalFinancialImpact;
  }

  if (actionForm.operationalSavings !== 0) {
    action.operationalSavings = actionForm.operationalSavings;
  }

  return action;
}
/**
 * Create an issue object the edit API is able to consume. Eventually with a new
 * API the need for this should go away
 */


function serialiseEditAction(actionForm) {
  return {
    summary: actionForm.summary,
    target: "",
    // NOTE: Explicitly setting target to empty string
    spark_type: actionForm.rule,
    skyspark_link: actionForm.skysparkLink,
    context: {
      label: actionForm.context,
      value: actionForm.context
    },
    description: actionForm.description,
    priority: {
      label: actionForm.priority,
      value: actionForm.priority
    },
    responsible: {
      label: actionForm.responsible,
      value: actionForm.responsible
    },
    status: {
      label: actionForm.status,
      value: actionForm.status
    },
    elec: Number(actionForm.elec),
    water: Number(actionForm.water),
    gas: Number(actionForm.gas),
    total_financial_impact: Number(actionForm.totalFinancialImpact),
    operational_savings: Number(actionForm.operationalSavings),
    screenshot_link: actionForm.screenshotLink,
    assignee: actionForm.assignee // NOTE: These are conditionally sent to match legacy behaviour
    // transition_reason: actionForm.transitionReason,
    // taggingList: [], // NOTE: No longer supported
    // comment: null // NOTE: No longer supported

  };
}