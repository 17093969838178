"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _actionTypes = require("./actionTypes");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const INITIAL_USER_STATE = {
  token: undefined,
  access: []
};
/**
 * gets the initial state, conditionally prepopulating
 * with mock values if app is running in the context
 * of a Cypress test
 */

function getInitialState(defaultUser) {
  const e2eUser = window.Cypress && window.Cypress.env("user");
  const user = e2eUser || defaultUser;
  return {
    user,
    loading: false,
    loaded: false,
    loggingIn: false,
    loggingOut: false
  };
}

const INITIAL_STATE = getInitialState(INITIAL_USER_STATE);

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actionTypes.LOAD_AUTH_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });

    case _actionTypes.LOAD_AUTH_USER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: true,
        user: _objectSpread(_objectSpread({}, state.user), action.result)
      });

    case _actionTypes.LOAD_AUTH_USER_FAIL:
      return _objectSpread(_objectSpread({}, INITIAL_STATE), {}, {
        loading: false,
        loaded: false,
        error: action.error
      });

    case _actionTypes.LOGIN:
      return _objectSpread(_objectSpread({}, state), {}, {
        // TODO: Why is this the way that it is?
        user: {
          username: action.username
        },
        loggingIn: true,
        loginError: undefined
      });

    case _actionTypes.LOGIN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggingIn: false,
        user: _objectSpread(_objectSpread({}, state.user), {}, {
          token: action.result.token
        })
      });

    case _actionTypes.LOGIN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggingIn: false,
        user: INITIAL_USER_STATE,
        loginError: action.error.error || "Invalid username/password"
      });

    case _actionTypes.LOGIN_KEYCLOAK:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggingIn: false,
        loginError: undefined,
        user: {
          username: action.username,
          token: action.token
        }
      });

    case _actionTypes.LOGOUT_KEYCLOAK:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: false,
        loggingOut: false,
        user: INITIAL_USER_STATE
      });

    case _actionTypes.LOGOUT:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: false,
        loggingOut: true,
        user: INITIAL_USER_STATE
      });

    case _actionTypes.LOGOUT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggingOut: false
      });

    case _actionTypes.LOGOUT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggingOut: false,
        logoutError: action.error
      });

    default:
      return state;
  }
}