"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMonitoringState = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var React = _interopRequireWildcard(require("react"));

var _ = require("..");

var _types = require("../types");

var _storage = require("../../../../utils/storage");

var _constants = require("../constants");

var _useCounts = require("./useCounts");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const useMonitoringState = function useMonitoringState(data, category, showSnoozed) {
  let filterFirst = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  const [group, setGroup] = React.useState((0, _storage.getPersisted)({
    prop: _constants.GROUP_BY_KEY,
    key: _constants.MONITORING_LS_KEY
  }, _types.GroupBy.Priority) || _types.GroupBy.Priority);
  const [sort, setSort] = React.useState((0, _storage.getPersisted)({
    prop: _constants.SORT_BY_KEY,
    key: _constants.MONITORING_LS_KEY
  }, _types.SortBy.AlphaAsc) || _types.SortBy.AlphaAsc);
  const [preFiltered, setPreFiltered] = React.useState([]);
  const [filtered, setFiltered] = React.useState([]);
  const [composed, setComposed] = React.useState([]);
  const [grouped, setGrouped] = React.useState([]);
  const [composedGrouped, setComposedGrouped] = React.useState([]);
  const [finalGrouped, setFinalGrouped] = React.useState({
    composed: [],
    group: (0, _storage.getPersisted)({
      prop: _constants.GROUP_BY_KEY,
      key: _constants.MONITORING_LS_KEY
    }, _types.GroupBy.Priority) || _types.GroupBy.Priority
  });
  const counts = (0, _useCounts.useCounts)(group, grouped, composedGrouped, showSnoozed); // Process initial data

  React.useLayoutEffect(() => {
    if (data.composed.length > 0) {
      setComposed(data.composed);
      setPreFiltered(data.composed);
    }
  }, [data.composed.length]); // Replace hits with composed insights on data

  React.useLayoutEffect(() => {
    const filt = filtered.length > 0 ? filtered : preFiltered;
    if (filt.length === 0) return;
    const insights = filt.reduce((mapped, occ) => {
      mapped[occ.occurrenceId] = occ;
      return mapped;
    }, {});

    const newData = _objectSpread(_objectSpread({}, data), {}, {
      hit: insights
    }); // @ts-ignore


    const filteredByCategory = (0, _.filterByCategory)(category, group, newData);
    setGrouped(filteredByCategory);
  }, [category, group, filtered, composed, sort]); // Compose final insight groups

  React.useEffect(() => {
    const composedGroups = grouped.map(occ => (0, _.groupByGroupName)(group, occ));
    const sortedComposedGroups = (0, _.sortFilteredData)(composedGroups, group, sort);
    setComposedGrouped(sortedComposedGroups);
  }, [grouped, sort]);
  React.useEffect(() => {
    if (filterFirst && filtered.length > 0) {
      setFinalGrouped({
        composed: composedGrouped,
        group
      });
    }

    if (!filterFirst) {
      setFinalGrouped({
        composed: composedGrouped,
        group
      });
    }
  }, [composedGrouped]);
  return {
    composed,
    finalGrouped,
    counts,
    filtered,
    setFiltered,
    setGroup,
    setSort,
    sort
  };
};

exports.useMonitoringState = useMonitoringState;