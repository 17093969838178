"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOccurrences = getOccurrences;

var _axios = _interopRequireDefault(require("axios"));

var _moment = _interopRequireDefault(require("moment"));

var _requestSchedule = require("../../redux/modules/_BETA/requestSchedule");

/**
 * Load analysis data for a given array of sites over a specific time range
 *
 * @param dispatch
 * @param timeRange Over how many days should insights be retrieved
 * @param selectedSites Existing selections of sites
 */
async function getOccurrences(dispatch) {
  let timeRange = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
  let selectedSites = arguments.length > 2 ? arguments[2] : undefined;
  dispatch({
    type: "LOAD_OCCURRENCES"
  }); // HACK: This is to prevent any requests happening when they don't need to

  const store = require("../../client").store;

  store.dispatch({
    type: _requestSchedule.UPDATE_SHOULD_REQUEST_INSIGHTS,
    shouldRequestInsights: false
  });

  try {
    const m = (0, _moment.default)(); // NOTE: Must be defined before `from` because moment is mutable

    const to = m.format("YYYY-MM-DD");
    const from = m.subtract(timeRange, "days").format("YYYY-MM-DD"); // Make the request

    const endpoint = "/synco/ui/insights";
    const body = {
      sites: selectedSites,
      insightType: "insights"
    };
    const {
      data
    } = await _axios.default.post(endpoint, body, {
      params: {
        from,
        to
      }
    }); // Store the retrieved occurrences and data groups for later composition

    dispatch({
      type: "LOAD_OCCURRENCES_SUCCESS",
      rawData: data
    });
  } catch (error) {// TODO: Dispatch error state
  }
}