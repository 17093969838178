"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function reducer(state, action) {
  const actionType = action.type;

  switch (action.type) {
    // * Data state
    case "LOAD_OCCURRENCES":
      // 1
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true,
        isLoaded: false,
        isComposed: false,
        isProcessed: false
      });

    case "LOAD_OCCURRENCES_SUCCESS":
      // 2
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        isLoaded: true,
        rawData: action.rawData
      });

    case "SET_COMPOSED_GROUPED_OCCURRENCES":
      // 3
      return _objectSpread(_objectSpread({}, state), {}, {
        composedOccurrences: action.composedOccurrences,
        composedOccurrencesBySite: action.composedOccurrencesBySite,
        isComposed: true
      });

    case "SET_COMPOSED_FILTERED_OCCURRENCES":
      // 4
      return _objectSpread(_objectSpread({}, state), {}, {
        composedFilteredOccurrences: action.composedFilteredOccurrences,
        search: action.search
      });

    case "SET_COMPOSED_AND_GROUPED_INSIGHTS":
      return _objectSpread(_objectSpread({}, state), {}, {
        composedAndGroupedInsights: action.composedAndGroupedInsights
      });

    case "SET_PROCESSED_INSIGHTS":
      return _objectSpread(_objectSpread({}, state), {}, {
        composedAndGroupedOccurrences: action.occurrences,
        composedAndGroupedInsights: action.insights,
        isProcessed: true
      });
    // * UI State

    case "SET_SORT_DIRECTION":
      return _objectSpread(_objectSpread({}, state), {}, {
        sortDirection: action.direction
      });

    case "SET_GROUP_BY":
      return _objectSpread(_objectSpread({}, state), {}, {
        groupBy: action.groupBy
      });

    case "SET_SORT_BY":
      return _objectSpread(_objectSpread({}, state), {}, {
        sortBy: action.sortBy
      });

    case "TOGGLE_EXPAND_ALL":
      return _objectSpread(_objectSpread({}, state), {}, {
        expandAll: !state.expandAll
      });

    case "SET_CATEGORY":
      return _objectSpread(_objectSpread({}, state), {}, {
        category: action.category
      });

    default:
      throw new Error("Unhandled action type: ".concat(actionType));
  }
}