"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.attachmentUploadFail = attachmentUploadFail;
exports.attachmentUploadSuccess = attachmentUploadSuccess;
exports.loadRules = loadRules;
exports.loadRulesRequestChain = loadRulesRequestChain;
exports.loadCommentsRequestChain = loadCommentsRequestChain;
exports.loadAction = loadAction;
exports.loadActionForEditing = loadActionForEditing;
exports.loadActionRequestChain = loadActionRequestChain;
exports.actionNotFound = actionNotFound;
exports.saveAction = saveAction;
exports.saveActionRequestChain = saveActionRequestChain;
exports.addAction = addAction;
exports.addActionRequestChain = addActionRequestChain;

var actionTypes = _interopRequireWildcard(require("./actionTypes"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// Attaching a screenshot to an action
function attachmentUploadFail(message) {
  return {
    type: actionTypes.ATTACHMENT_UPLOAD_FAIL,
    message
  };
}

function attachmentUploadSuccess(url) {
  return {
    type: actionTypes.ATTACHMENT_UPLOAD_SUCCESS,
    url
  };
}

function loadRules() {
  return {
    type: actionTypes.LOAD_RULES
  };
}

function loadRulesRequestChain(token) {
  return {
    types: [actionTypes.LOAD_RULES_REQUEST, actionTypes.LOAD_RULES_REQUEST_SUCCESS, actionTypes.LOAD_RULES_REQUEST_FAIL],
    promise: client => client.get("/issues/spark-types", {
      headers: {
        Authorization: "Bearer ".concat(token)
      }
    })
  };
}
/**
 * Load comments for the given action
 * @param {string} actionId
 * @param {string} token
 */


function loadCommentsRequestChain(actionId, token) {
  return {
    types: [actionTypes.LOAD_COMMENTS_REQUEST, actionTypes.LOAD_COMMENTS_REQUEST_SUCCESS, actionTypes.LOAD_COMMENTS_REQUEST_FAIL],
    promise: client => client.get("/comments/".concat(actionId), {
      headers: {
        Authorization: "Bearer ".concat(token)
      }
    })
  };
}
/**
 * Load an action from an id
 * @param {string} actionId
 */


function loadAction(actionId) {
  return {
    type: actionTypes.LOAD_ACTION,
    actionId
  };
}
/**
 * Load an action and rules list from an action id
 * @param {string} actionId
 */


function loadActionForEditing(actionId) {
  return {
    type: actionTypes.LOAD_ACTION_FOR_EDITING,
    actionId
  };
}

function loadActionRequestChain(actionId, token) {
  return {
    types: [actionTypes.LOAD_ACTION_REQUEST, actionTypes.LOAD_ACTION_REQUEST_SUCCESS, actionTypes.LOAD_ACTION_REQUEST_FAIL],
    promise: client => client.get("/issue/".concat(actionId), {
      headers: {
        Authorization: "Bearer ".concat(token)
      }
    })
  };
}

function actionNotFound() {
  return {
    type: actionTypes.ACTION_NOT_FOUND
  };
} // Saving an action to Jira


function saveAction(action, equipList) {
  return {
    type: actionTypes.SAVE_ACTION,
    equipList,
    action
  };
}

function saveActionRequestChain(actionId, action, token) {
  return {
    types: [actionTypes.SAVE_ACTION_REQUEST, actionTypes.SAVE_ACTION_REQUEST_SUCCESS, actionTypes.SAVE_ACTION_REQUEST_FAIL],
    promise: client => client.post("/issue/".concat(actionId, "/edit"), {
      headers: {
        Authorization: "Bearer ".concat(token)
      },
      data: action
    })
  };
} // Creating a new action in Jira


function addAction(action, equipList) {
  return {
    type: actionTypes.ADD_ACTION,
    action,
    equipList
  };
}

function addActionRequestChain(action, token) {
  return {
    types: [actionTypes.ADD_ACTION_REQUEST, actionTypes.ADD_ACTION_REQUEST_SUCCESS, actionTypes.ADD_ACTION_REQUEST_FAIL],
    promise: client => client.post("/issue", {
      headers: {
        Authorization: "Bearer ".concat(token)
      },
      data: action
    })
  };
}