"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = require("react");

// TODO: Investigate other options for implementing abstract methods in
// superclasses. The linter doesn't like it 😭 -- Jack 17/5/18

/* eslint-disable */
class DynamicFormComponent extends _react.Component {
  getSelectedValues() {
    throw new TypeError("This method has not been implemented by its extended class.");
  }

  validate() {
    throw new TypeError("This method has not been implemented by its extended class.");
  }

}

exports.default = DynamicFormComponent;
module.exports = exports.default;