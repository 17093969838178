"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFetchActions = useFetchActions;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var React = _interopRequireWildcard(require("react"));

var _axios = _interopRequireDefault(require("axios"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function useFetchActions(equipIds) {
  const [actionsFetch, setActionsFetch] = React.useState({
    loading: false,
    actions: [],
    error: undefined
  });
  React.useEffect(function getActions() {
    try {
      if (equipIds === undefined || equipIds.length === 0) {
        setActionsFetch(_objectSpread(_objectSpread({}, actionsFetch), {}, {
          error: "Equip is undefined"
        }));
        throw Error("Action Ids cannot be undefined");
      }

      setActionsFetch(_objectSpread(_objectSpread({}, actionsFetch), {}, {
        loading: true
      }));

      const fetchActions = async () => {
        const {
          data
        } = await _axios.default.post("/v1/equips/actions", {
          equipIds
        }); // checking if the returned data is empty object

        if (Object.values(data).length === 0) {
          setActionsFetch(_objectSpread(_objectSpread({}, actionsFetch), {}, {
            loading: false,
            error: "No open actions for this equip"
          }));
        } else {
          setActionsFetch({
            // The API will return a data structure like
            // {
            //   endpoint1: [...actions],
            //   endpoint2: [...actions]
            //   ...etc
            // }
            // The reason we only grab the first element here
            // is because for this particular components, we just
            // sent one equipId in the request for the modal,
            // so we expect to get only one property in the
            // response objet.
            actions: Object.values(data)[0],
            loading: false,
            error: undefined
          });
        }
      };

      void fetchActions();
    } catch (error) {
      setActionsFetch(_objectSpread(_objectSpread({}, actionsFetch), {}, {
        loading: false,
        error
      }));
      console.error(error);
    }
  }, [equipIds]);
  return actionsFetch;
}