"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Row = exports.StyleDataGridHeading = exports.GridBadge = exports.Scrollable = exports.Wrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

const Wrapper = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  position: relative;\n"])));

exports.Wrapper = Wrapper;

const Scrollable = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  position: relative;\n  max-height: 360px;\n  overflow-y: scroll;\n"])));

exports.Scrollable = Scrollable;
const GridBadge = (0, _styledComponents.default)(_blocks.Badge)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  height: 16px;\n"])));
exports.GridBadge = GridBadge;
const StyleDataGridHeading = (0, _styledComponents.default)(_blocks.DataGridHeading)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  &:focus {\n    outline: none;\n  }\n\n  &.right-alignment {\n    justify-content: flex-end;\n  }\n"])));
exports.StyleDataGridHeading = StyleDataGridHeading;
const Row = (0, _styledComponents.default)(_blocks.DataGridRow)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  .action-cell {\n    i {\n      font-style: italic;\n    }\n    justify-content: flex-end;\n  }\n  .action-link {\n    &:hover {\n      cursor: pointer;\n      text-decoration: underline;\n    }\n  }\n"])));
exports.Row = Row;