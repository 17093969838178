"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useChart = void 0;

var React = _interopRequireWildcard(require("react"));

var _utilities = require("../../../../context/reports/utilities");

var _utils = require("../../../../context/reports/utilities/utils");

var _utils2 = require("../utils");

var _types = require("../../../../context/reports/utilities/types");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

/**
 * Retrieve charts configurations for utilities
 * @param filterBy
 */
const useChart = function useChart() {
  let filterBy = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  const [state] = (0, _utilities.useUtilities)();
  const {
    metrics,
    metricsType,
    sortingDirection
  } = state;
  const data = React.useMemo(() => {
    return (0, _utils.getConsumption)(metrics, metricsType, _types.ReadingType.All, filterBy);
  }, [metrics, metricsType, filterBy]);
  const rankedYears = (0, _utils2.getRankedYearsConfig)(data, metricsType);
  const rankedSites = (0, _utils2.getRankedSitesConfig)(data, metricsType, sortingDirection.rankedSites, sortingDirection.rankedYears);
  const singleSite = (0, _utils2.getSingleSiteConfig)(data, metricsType, sortingDirection.rankedYears);
  return {
    rankedSites,
    rankedYears,
    singleSite
  };
};

exports.useChart = useChart;