"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionedMain = exports.ActionedCell = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2;

const ActionedCell = (0, _styledComponents.default)(_blocks.DataGridCell)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  background-color: ", " !important;\n"])), _blocks.palette.neutral2);
exports.ActionedCell = ActionedCell;
const ActionedMain = (0, _styledComponents.default)(_blocks.Main)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  color: ", ";\n"])), _blocks.color.theme.typography.subdued);
exports.ActionedMain = ActionedMain;