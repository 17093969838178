"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trimWithEllipsis = trimWithEllipsis;

/**
 * Trims a string to the designated length (plus the ellipsis characters)
 * If `str` length is less than `length` then `str` is returned unaltered
 *
 * @param str The string to trim
 * @param length Number of characters to trim to
 * @param ellipsis Characters to append to end of trimmed string
 */
function trimWithEllipsis() {
  let str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  let length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
  let ellipsis = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "...";
  if (str.length <= length) return str;
  const trimCharLength = ellipsis.length;
  return "".concat(str.substring(0, length - trimCharLength)).concat(ellipsis);
}