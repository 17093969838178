"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EquipList = exports.FieldBody = exports.FieldLabel = exports.FieldHeading = exports.FieldSection = exports.CommentContainer = exports.ServerError = exports.ContentFooter = exports.TwoThirds = exports.OneThird = exports.Half = exports.ContentContainer = exports.GoBack = exports.BackIcon = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactRouterDom = require("react-router-dom");

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14;

// Main Layout
// @ts-ignore
const BackIcon = (0, _styledComponents.default)(_blocks.ArrowPointLeft)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  width: 20px;\n  height: 20px;\n  margin-right: ", ";\n  fill: ", ";\n"])), _blocks.spacing.snug, _blocks.palette.neutral7);
exports.BackIcon = BackIcon;
const GoBack = (0, _styledComponents.default)(_reactRouterDom.Link)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  text-decoration: none;\n"])));
exports.GoBack = GoBack;

const ContentContainer = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  flex-wrap: wrap;\n  border-radius: ", ";\n  box-shadow: ", ";\n  background: ", ";\n"])), _blocks.borderRadius.large, _blocks.elevation.content, _blocks.color.pureWhite);

exports.ContentContainer = ContentContainer;

const Half = _styledComponents.default.div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  flex-basis: 50%;\n  max-width: 50%;\n"])));

exports.Half = Half;

const OneThird = _styledComponents.default.div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  flex-basis: calc(100% / 3);\n  max-width: calc(100% / 3);\n"])));

exports.OneThird = OneThird;

const TwoThirds = _styledComponents.default.div(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n  flex-basis: calc(100% / 3 * 2);\n  max-width: calc(100% / 3 * 2);\n"])));

exports.TwoThirds = TwoThirds;

const ContentFooter = _styledComponents.default.div(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  width: 100%;\n  flex-direction: row-reverse;\n  border-top: 1px solid ", ";\n  background-color: ", ";\n  padding: ", ";\n"])), _blocks.color.theme.divider.solid, _blocks.palette.neutral2, _blocks.spacing.snug); // All forms


exports.ContentFooter = ContentFooter;

const ServerError = _styledComponents.default.p(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\n  text-align: right;\n  padding: ", ";\n  color: ", ";\n"])), _blocks.spacing.default, _blocks.palette.red5); // View Action specific
// TODO: Bazaar


exports.ServerError = ServerError;

const CommentContainer = _styledComponents.default.div(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\n  background-color: ", ";\n  border-radius: ", ";\n  margin-bottom: ", ";\n  box-shadow: ", ";\n  padding: ", ";\n"])), _blocks.color.pureWhite, _blocks.borderRadius.default, _blocks.spacing.default, _blocks.elevation.content, _blocks.spacing.snug);

exports.CommentContainer = CommentContainer;

const FieldSection = _styledComponents.default.section(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2.default)(["\n  padding: ", ";\n  border-bottom: 1px solid ", ";\n\n  &:last-of-type {\n    border: 0;\n  }\n\n  & > *:last-child {\n    margin-bottom: 0;\n  }\n"])), _blocks.spacing.extraLoose, _blocks.color.theme.divider.solid);

exports.FieldSection = FieldSection;
const FieldHeading = (0, _styledComponents.default)(_blocks.DisplaySmall)(_templateObject11 || (_templateObject11 = (0, _taggedTemplateLiteral2.default)(["\n  margin-bottom: ", ";\n"])), _blocks.spacing.snug); // TODO: Use Field component from bazaar for this

exports.FieldHeading = FieldHeading;

const FieldLabel = _styledComponents.default.label(_templateObject12 || (_templateObject12 = (0, _taggedTemplateLiteral2.default)(["\n  display: block;\n  margin-bottom: ", ";\n  color: ", ";\n  ", "\n"])), _blocks.spacing.extraTight, _blocks.color.theme.typography.ink, _blocks.typography.label);

exports.FieldLabel = FieldLabel;
const FieldBody = (0, _styledComponents.default)(_blocks.Body)(_templateObject13 || (_templateObject13 = (0, _taggedTemplateLiteral2.default)(["\n  margin-bottom: ", ";\n"])), _blocks.spacing.snug); // TODO: This is duplicated

exports.FieldBody = FieldBody;

const EquipList = _styledComponents.default.ul(_templateObject14 || (_templateObject14 = (0, _taggedTemplateLiteral2.default)(["\n  margin: 0 0 ", " 0;\n  padding-left: 0;\n\n  li {\n    margin-bottom: 6px;\n  }\n\n  li:empty {\n    display: none;\n  }\n"])), _blocks.spacing.snug);

exports.EquipList = EquipList;