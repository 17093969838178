"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUserSites = updateUserSites;

var _actionTypes = require("./actionTypes");

function updateUserSites(_ref) {
  let {
    projects,
    sites,
    projectGroups
  } = _ref;
  return {
    type: _actionTypes.UPDATE_USER_SITES,
    projects,
    sites,
    projectGroups
  };
}