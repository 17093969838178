"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadGroups = loadGroups;

var _actionTypes = require("./actionTypes");

function loadGroups(user) {
  let form = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return {
    types: [_actionTypes.LOAD_GROUPS, _actionTypes.LOAD_GROUPS_SUCCESS, _actionTypes.LOAD_GROUPS_FAIL],
    promise: client => client.get("groups/lookup", {
      headers: {
        Authorization: "Bearer ".concat(user.token)
      },
      params: {
        form
      }
    })
  };
}