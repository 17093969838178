"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SitesBoxContent = exports.BoxContent = exports.BoxHeading = exports.BoxHeader = exports.Foot = exports.SettingsBoxWrapper = exports.RulesBoxWrapper = exports.SitesBoxWrapper = exports.StyledActionList = exports.BoxWrapper = exports.ContentWrapper = exports.Menu = exports.Frame = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13;

const Frame = (0, _styledComponents.default)(_blocks.Frame)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  overflow: hidden;\n  min-height: calc(100vh - 245px);\n"])));
exports.Frame = Frame;

const Menu = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  flex: 0 20%;\n  background-color: ", ";\n  padding: ", " ", ";\n  border-right: 2px solid ", ";\n"])), _blocks.palette.neutral2, _blocks.spacing.default, _blocks.spacing.loose, _blocks.palette.neutral4);

exports.Menu = Menu;

const ContentWrapper = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  position: relative;\n  display: flex;\n  flex-grow: 1;\n  min-height: 0;\n"])));

exports.ContentWrapper = ContentWrapper;

const BoxWrapper = _styledComponents.default.div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  flex-basis: 50%;\n  min-height: 0;\n  height: calc(100vh - 245px - 72px);\n  overflow: hidden;\n"])));

exports.BoxWrapper = BoxWrapper;
const StyledActionList = (0, _styledComponents.default)(_blocks.ActionList)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  position: absolute;\n  top: 104px;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  overflow-y: auto;\n"])));
exports.StyledActionList = StyledActionList;
const SitesBoxWrapper = (0, _styledComponents.default)(BoxWrapper)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n  position: relative;\n  border-right: 2px solid ", ";\n"])), _blocks.palette.neutral4);
exports.SitesBoxWrapper = SitesBoxWrapper;
const RulesBoxWrapper = (0, _styledComponents.default)(BoxWrapper)(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n  position: relative;\n  border-right: 2px solid ", ";\n"])), _blocks.palette.neutral4);
exports.RulesBoxWrapper = RulesBoxWrapper;
const SettingsBoxWrapper = (0, _styledComponents.default)(BoxWrapper)(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)([""])));
exports.SettingsBoxWrapper = SettingsBoxWrapper;

const Foot = _styledComponents.default.div(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\n  flex: 0 100%;\n  background-color: ", ";\n  border-top: 1px solid ", ";\n  text-align: right;\n  padding: 16px;\n  height: 72px;\n"])), _blocks.palette.neutral2, _blocks.palette.neutral4);

exports.Foot = Foot;

const BoxHeader = _styledComponents.default.header(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2.default)(["\n  padding: 0 ", ";\n"])), _blocks.spacing.snug);

exports.BoxHeader = BoxHeader;

const BoxHeading = _styledComponents.default.div(_templateObject11 || (_templateObject11 = (0, _taggedTemplateLiteral2.default)(["\n  margin-top: ", ";\n  color: ", ";\n  font-size: 12px;\n  font-weight: bold;\n  letter-spacing: 0px;\n  line-height: 36px;\n  text-transform: uppercase;\n"])), _blocks.spacing.snug, _blocks.palette.neutral7);

exports.BoxHeading = BoxHeading;

const BoxContent = _styledComponents.default.div(_templateObject12 || (_templateObject12 = (0, _taggedTemplateLiteral2.default)(["\n  position: absolute;\n  top: 106px;\n  right: 0px;\n  bottom: 0px;\n  left: 0px;\n  overflow-y: auto;\n"])));

exports.BoxContent = BoxContent;
const SitesBoxContent = (0, _styledComponents.default)(BoxContent)(_templateObject13 || (_templateObject13 = (0, _taggedTemplateLiteral2.default)(["\n  padding: 0 ", ";\n"])), _blocks.spacing.tight);
exports.SitesBoxContent = SitesBoxContent;