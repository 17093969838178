"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  control: {
    backgroundColor: "#fff",
    fontSize: "14px",
    fontWeight: "normal"
  },
  input: {
    margin: 0,
    fontSize: "14px",
    color: "inherit"
  },
  "&multiLine": {
    control: {
      // fontFamily: 'monospace',
      border: "1px solid silver"
    },
    highlighter: {
      padding: 9
    },
    input: {
      padding: 9,
      minHeight: 63,
      outline: 0,
      border: 0
    }
  },
  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 10
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#dbedf8"
      }
    }
  }
};
exports.default = _default;
module.exports = exports.default;