"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.isLoaded = isLoaded;
exports.appendAttachmentsToComment = appendAttachmentsToComment;
exports.dispatchPostCommentFailAction = dispatchPostCommentFailAction;
exports.postComment = postComment;
exports.uploadAttachments = uploadAttachments;
exports.postCommentFlow = postCommentFlow;
exports.default = exports.getSelectedIssue = exports.getComment = exports.getAttachments = exports.getTaggingList = exports.getAuthUser = exports.actionCreators = exports.updateTaggingList = exports.updateCommentList = exports.updateSelectedIssue = exports.startAddComment = exports.sendHashtagNotificationEmail = exports.getUsernameList = exports.addComment = exports.selectRow = exports.UPDATE_TAGGING_LIST = exports.SEND_HASHTAGNOTIFICATION_FAIL = exports.SEND_HASHTAGNOTIFICATION_SUCCESS = exports.SEND_HASHTAGNOTIFICATION = exports.LOAD_EQUIPRULES_FAIL = exports.LOAD_EQUIPRULES_SUCCESS = exports.LOAD_EQUIPRULES = exports.PASTE_ATTACHMENTS_TO_COMMENT = exports.POSTING_COMMENT_FAIL = exports.COMMENT_FAIL = exports.COMMENT_SUCCESS = exports.ADD_COMMENT_REQUEST = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _effects = require("redux-saga/effects");

var _actionTypes = require("./attachments/actionTypes");

var _actionCreators = require("./attachments/actionCreators");

var _helpers = require("./helpers/helpers");

var _eventTracking = require("../../utils/eventTracking");

var _issues = require("./_BETA/issues");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// Selecting an issue
const SELECT = "bonfire/issues/SELECT";
const SELECT_SUCCESS = "bonfire/issues/SELECT_SUCCESS";
const SELECT_FAIL = "bonfire/issues/SELECT_FAIL"; // Adding a comment

const ADD_COMMENT_REQUEST = "bonfire/issues/ADD_COMMENT_REQUEST";
exports.ADD_COMMENT_REQUEST = ADD_COMMENT_REQUEST;
const COMMENT = "bonfire/issues/COMMENT";
const COMMENT_SUCCESS = "bonfire/issues/COMMENT_SUCCESS";
exports.COMMENT_SUCCESS = COMMENT_SUCCESS;
const COMMENT_FAIL = "bonfire/issues/COMMENT_FAIL";
exports.COMMENT_FAIL = COMMENT_FAIL;
const POSTING_COMMENT_FAIL = "bonfire/issues/POSTING_COMMENT_FAIL";
exports.POSTING_COMMENT_FAIL = POSTING_COMMENT_FAIL;
const PASTE_ATTACHMENTS_TO_COMMENT = "bonfire/issues/PASTE_ATTACHMENTS_TO_COMMENT"; // TODO: The EquipRule flows should factored out into their own reducer once the
// beta issues replaces the original issues views

exports.PASTE_ATTACHMENTS_TO_COMMENT = PASTE_ATTACHMENTS_TO_COMMENT;
const LOAD_EQUIPRULES = "bonfire/issues/LOAD_EQUIPRULES";
exports.LOAD_EQUIPRULES = LOAD_EQUIPRULES;
const LOAD_EQUIPRULES_SUCCESS = "bonfire/issues/LOAD_EQUIPRULES_SUCCESS";
exports.LOAD_EQUIPRULES_SUCCESS = LOAD_EQUIPRULES_SUCCESS;
const LOAD_EQUIPRULES_FAIL = "bonfire/issues/LOAD_EQUIPRULES_FAIL"; // loading usernameList

exports.LOAD_EQUIPRULES_FAIL = LOAD_EQUIPRULES_FAIL;
const LOAD_USERNAMELIST = "bonfire/issues/LOAD_USERNAMELIST";
const LOAD_USERNAMELIST_SUCCESS = "bonfire/issues/LOAD_USERNAMELIST_SUCCESS";
const LOAD_USERNAMELIST_FAIL = "bonfire/issues/LOAD_USERNAMELIST_FAIL"; // sending hashtagNotificationEmail

const SEND_HASHTAGNOTIFICATION = "bonfire/issues/SEND_HASHTAGNOTIFICATION";
exports.SEND_HASHTAGNOTIFICATION = SEND_HASHTAGNOTIFICATION;
const SEND_HASHTAGNOTIFICATION_SUCCESS = "bonfire/issues/SEND_HASHTAGNOTIFICATION_SUCCESS";
exports.SEND_HASHTAGNOTIFICATION_SUCCESS = SEND_HASHTAGNOTIFICATION_SUCCESS;
const SEND_HASHTAGNOTIFICATION_FAIL = "bonfire/issues/SEND_HASHTAGNOTIFICATION_FAIL"; // Update frontend after saving

exports.SEND_HASHTAGNOTIFICATION_FAIL = SEND_HASHTAGNOTIFICATION_FAIL;
const UPDATE_ISSUE_CHANGES = "bonfire/issues/UPDATE_ISSUE_CHANGES";
const UPDATE_COMMENT_CHANGES = "bonfire/issues/UPDATE_COMMENT_CHANGES";
const UPDATE_TAGGING_LIST = "bonfire/issues/UPDATE_TAGGING_LIST";
exports.UPDATE_TAGGING_LIST = UPDATE_TAGGING_LIST;
const initialState = {
  loaded: false,
  commenting: false,
  postingComment: false,
  completingIssue: false,
  editing: {},
  saveError: {},
  selected: {
    key: undefined
  },
  data: [],
  sites: {},
  priorities: {},
  contexts: {},
  statuses: {},
  insite_statuses: {},
  projects: {},
  parties: {},
  comments: [],
  issue: {},
  status: [],
  usernameList: [],
  taggingList: []
};

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case LOAD_EQUIPRULES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        issue: _objectSpread(_objectSpread({}, state.issue), {}, {
          equipRules: action.result
        }),
        error: null
      });

    case LOAD_EQUIPRULES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        issue: _objectSpread(_objectSpread({}, state.issue), {}, {
          equipRules: null
        }),
        error: action.error
      });

    case SELECT:
      return _objectSpread(_objectSpread({}, state), {}, {
        selected: action.selected,
        comments: [],
        attachments: [],
        attachment_to_add: {},
        error: null,
        uploadError: null
      });

    case SELECT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        selected: action.selected,
        comments: action.result,
        comments_loaded: true
      });

    case SELECT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        selected: {},
        comments_loaded: false
      });

    case COMMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        commenting: true
      });

    case COMMENT_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          comments: [...state.comments, action.result],
          commenting: false,
          postingComment: false,
          completingIssue: false,
          error: null,
          uploadError: null
        });
      }

    case COMMENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        commenting: false
      });

    case POSTING_COMMENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        postingComment: false
      });

    case ADD_COMMENT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        comment: action.comment,
        postingComment: action.action === "postComment",
        completingIssue: action.action === "completeIssue"
      });

    case PASTE_ATTACHMENTS_TO_COMMENT:
      {
        // Paste attachments to comment
        const comment = Object.assign({}, state.comment);
        const imageLinks = action.attachments.map(attachment => "[".concat(attachment.fileName, "|").concat(attachment.imageUrl, "]"));
        comment.text += imageLinks.length > 0 ? "\n\n----- Attachments -----\n" : "\n";
        comment.text += imageLinks.join("\n");
        return _objectSpread(_objectSpread({}, state), {}, {
          comment
        });
      }

    case LOAD_USERNAMELIST:
      // load Username List
      return _objectSpread(_objectSpread({}, state), {}, {
        usernameList: []
      });

    case LOAD_USERNAMELIST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        usernameList: action.result
      });

    case LOAD_USERNAMELIST_FAIL:
      return _objectSpread({}, state);

    case UPDATE_TAGGING_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        taggingList: action.taggingList
      });

    default:
      return state;
  }
}
/*
 * Exported Action Creators
 */


function isLoaded(globalState) {
  return globalState.issues && globalState.issues.loaded;
}

const selectRow = (issue, user) => ({
  types: [SELECT, SELECT_SUCCESS, SELECT_FAIL],
  selected: issue,
  promise: client => client.get("/comments/".concat(issue.key), {
    headers: {
      Authorization: "Bearer ".concat(user.token)
    }
  })
});

exports.selectRow = selectRow;

const addComment = (comment, user) => ({
  types: [COMMENT, COMMENT_SUCCESS, COMMENT_FAIL],
  promise: client => client.post("v2/actions/".concat(comment.key, "/comments"), {
    headers: {
      Authorization: "Bearer ".concat(user.token)
    },
    data: {
      body: comment.text
    }
  })
}); // TODO: This shouldn't live in the store.  It should be attached directly to the active action


exports.addComment = addComment;

const getUsernameList = (user, selectedProject) => {
  let queryString = "";

  if (selectedProject.components === "") {
    queryString = "/hashtagUsernamelist/".concat(selectedProject.project_key, "/NULL");
  } else {
    queryString = "/hashtagUsernamelist/".concat(selectedProject.project_key, "/").concat(selectedProject.components);
  }

  return {
    types: [LOAD_USERNAMELIST, LOAD_USERNAMELIST_SUCCESS, LOAD_USERNAMELIST_FAIL],
    promise: client => client.get(queryString, {
      headers: {
        Authorization: "Bearer ".concat(user.token)
      }
    })
  };
}; // TODO: Why is this in the store?


exports.getUsernameList = getUsernameList;

const sendHashtagNotificationEmail = (user, hashtagList, issue, comment) => ({
  types: [SEND_HASHTAGNOTIFICATION, SEND_HASHTAGNOTIFICATION_SUCCESS, SEND_HASHTAGNOTIFICATION_FAIL],
  promise: client => client.post("/hashtag-notification", {
    headers: {
      Authorization: "Bearer ".concat(user.token)
    },
    data: {
      hashtagList,
      comment,
      issue,
      user,
      workflow: false
    }
  })
});
/**
 * OTHER ACTION CREATORS
 */
// starts a saga; includes uploading of attachments first before posting comment


exports.sendHashtagNotificationEmail = sendHashtagNotificationEmail;

const startAddComment = (comment, action) => (0, _helpers.returnAction)(ADD_COMMENT_REQUEST, {
  comment,
  action
});

exports.startAddComment = startAddComment;

const updateSelectedIssue = (issueKey, params) => (0, _helpers.returnAction)(UPDATE_ISSUE_CHANGES, {
  issue_key: issueKey,
  params
});

exports.updateSelectedIssue = updateSelectedIssue;

const updateCommentList = comment => (0, _helpers.returnAction)(UPDATE_COMMENT_CHANGES, {
  comment
});

exports.updateCommentList = updateCommentList;

const updateTaggingList = taggingList => (0, _helpers.returnAction)(UPDATE_TAGGING_LIST, {
  taggingList
});

exports.updateTaggingList = updateTaggingList;
const actionCreators = {
  selectRow,
  getUsernameList,
  addComment,
  startAddComment,
  updateSelectedIssue,
  updateCommentList,
  sendHashtagNotificationEmail,
  updateTaggingList
};
/**
 * SAGA WATCHERS
 */

exports.actionCreators = actionCreators;

const getAuthUser = state => state.auth.user; // #hashtag


exports.getAuthUser = getAuthUser;

const getTaggingList = state => state.issues.taggingList; // Selectors


exports.getTaggingList = getTaggingList;

const getAttachments = state => state.attachments.attachments;

exports.getAttachments = getAttachments;

const getComment = state => state.issues.comment;

exports.getComment = getComment;

function* appendAttachmentsToComment(attachments) {
  yield (0, _effects.put)({
    type: PASTE_ATTACHMENTS_TO_COMMENT,
    attachments
  });
}

function* dispatchPostCommentFailAction() {
  yield (0, _effects.put)({
    type: POSTING_COMMENT_FAIL
  });
}

function commentPostSucceededAndAtLeastOneUserIsTagged(commentResult, taggingList) {
  return commentResult.type === COMMENT_SUCCESS && taggingList.length > 0;
}

function* postComment(issue, comment, user, taggingList) {
  yield (0, _effects.fork)(_helpers.dispatchGeneratorFunc, addComment, comment, user);
  const commentResult = yield (0, _effects.take)([COMMENT_FAIL, COMMENT_SUCCESS]);

  if (commentResult.type === COMMENT_SUCCESS) {
    (0, _eventTracking.messagePostedEvent)(issue, commentResult); // HACK: Update updated time in relevant action so that it's
    // reflected in the list in real time.

    yield (0, _effects.put)((0, _issues.updateBetaIssue)(issue.key, commentResult.result.updated));
  }

  if (commentPostSucceededAndAtLeastOneUserIsTagged(commentResult, taggingList)) {
    yield (0, _effects.fork)(_helpers.dispatchGeneratorFunc, sendHashtagNotificationEmail, user, taggingList, issue, comment); // NOTE: We're not actually handling this failure...

    yield (0, _effects.take)([SEND_HASHTAGNOTIFICATION_SUCCESS, SEND_HASHTAGNOTIFICATION_FAIL]); // TODO: Should dispatch an action that handles a failure to send notification
    // put({ type: HANDLE_ME_PLEASE!!! })
  }
}

function* uploadAttachments(attachments) {
  yield attachments.map(attachment => (0, _effects.fork)(_helpers.dispatchGeneratorFunc, _actionCreators.uploadToS3, attachment.uploadUrl, attachment.file));
}

const getSelectedIssue = state => state.betaAction.action;
/*
 * Handles posting of Comments but upload attachments first (if there is)
 * Note that upload is done directly from bonfire to s3.
 * If one of the upload fails, returns an error and posting of comment will not pursue.
 */


exports.getSelectedIssue = getSelectedIssue;

function* postCommentFlow() {
  while (true) {
    yield (0, _effects.take)(ADD_COMMENT_REQUEST);
    const attachments = yield (0, _effects.select)(getAttachments) || []; // NOTE: This will retrieve the currently active action from the betaAction store.
    // This file should eventually go away, and all issue/action responsibility will live
    // in the /action store
    // TODO: Temporary - Comment flow should live in an entirely different store.

    const issue = yield (0, _effects.select)(getSelectedIssue);
    const taggingList = yield (0, _effects.select)(getTaggingList);
    const user = yield (0, _effects.select)(getAuthUser);
    let comment = yield (0, _effects.select)(getComment); // If attachments present, handle upload and append to comment...

    if (attachments.length > 0) {
      yield (0, _effects.call)(uploadAttachments, attachments); // NOTE: This really only checks for one success or fail.
      // TODO: Update flow to account for entire array of attachments.
      // See `for/of` pattern for doing this.

      const {
        success,
        fail
      } = yield (0, _effects.race)({
        success: (0, _effects.take)(_actionTypes.UPLOAD_TO_S3_SUCCESS),
        fail: (0, _effects.take)(_actionTypes.UPLOAD_TO_S3_FAIL)
      });

      if (fail) {
        yield (0, _effects.call)(dispatchPostCommentFailAction);
      } else if (success) {
        yield (0, _effects.call)(appendAttachmentsToComment, attachments); // NOTE: comment (in our store) is modified by appendAttachmentsToComment so we
        // have to retrieve the updated comment

        comment = yield (0, _effects.select)(getComment); // Reset attachments state

        yield (0, _effects.put)({
          type: _actionTypes.INITIALIZE_ATTACHMENTS
        });
      }
    } // ...otherwise just post the comment


    yield (0, _effects.call)(postComment, issue, comment, user, taggingList);
  }
}

var _default = reducer;
exports.default = _default;