"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getReadableMetricsType = getReadableMetricsType;
exports.getReadableByType = getReadableByType;
exports.getChartSizes = getChartSizes;
exports.composeSites = exports.getWorkflowGridData = exports.getWorkflowBars = void 0;

var _types = require("../../../context/reports/workflow/types");

var _state = require("../../../utils/state");

/**
 * Retrieves bars data for site view or team view
 * When filterByKey is presented, it will retrieve breakdown data appropriate for second step of workflow
 */
const getWorkflowBars = function getWorkflowBars(metricsState, by) {
  let filterByKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  let metrics = Object.entries(metricsState[by]);

  if (filterByKey.length > 0) {
    metrics = metrics.filter(_ref => {
      let [r] = _ref;
      return filterByKey.includes(r);
    });
  }

  return metrics.reduce((acc, _ref2) => {
    let [key, {
      count,
      breakdown
    }] = _ref2;
    let records = [{
      id: key,
      label: by === _types.MetricsByType.BySite ? (0, _state.getSiteById)(key).label : key,
      value: count
    }];

    if (filterByKey.length > 0) {
      records = Object.entries(breakdown).map(_ref3 => {
        let [subKey, {
          name,
          count
        }] = _ref3;
        return {
          id: subKey,
          label: by === _types.MetricsByType.BySite ? name : (0, _state.getSiteById)(subKey).label,
          value: count
        };
      });
    }

    records.map(record => acc.push(record));
    return acc;
  }, []);
};
/**
 * Retrieves grid data for site view and team view
 * When filterByKey is presented, it will retrieve breakdown data appropriate for second step of workflow
 */


exports.getWorkflowBars = getWorkflowBars;

const getWorkflowGridData = function getWorkflowGridData(metricsState, by) {
  let filterByKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  const initial = {
    records: [],
    count: 0,
    critical: 0
  };
  let metrics = Object.entries(metricsState[by]);

  if (filterByKey.length > 0) {
    metrics = metrics.filter(_ref4 => {
      let [r] = _ref4;
      return filterByKey.includes(r);
    });
  }

  return metrics.reduce((acc, _ref5) => {
    let [label, {
      breakdown
    }] = _ref5;
    let records = [{
      label,
      totalCount: Object.entries(breakdown).reduce((acc, _ref6) => {
        let [, item] = _ref6;
        return acc + item.count;
      }, 0),
      criticalCount: Object.entries(breakdown).reduce((acc, _ref7) => {
        let [, item] = _ref7;
        return acc + item.critical;
      }, 0)
    }];

    if (filterByKey.length > 0) {
      records = Object.entries(breakdown).map(_ref8 => {
        let [subKey, {
          name,
          count,
          critical,
          actionIds
        }] = _ref8;
        return {
          label: by === _types.MetricsByType.BySite ? name : (0, _state.getSiteById)(subKey).label,
          totalCount: count,
          criticalCount: critical,
          actionIds
        };
      });
    }

    records.forEach(record => {
      acc.records.push(record);
      acc.count += record.totalCount;
      acc.critical += record.criticalCount;
    });
    return acc;
  }, initial);
};
/**
 * Compose sites making ready for site select component.
 */


exports.getWorkflowGridData = getWorkflowGridData;

const composeSites = (selectedSites, userSites) => {
  return selectedSites.map(id => {
    const site = userSites[id];
    return {
      siteId: site.syncoId,
      siteName: site.label
    };
  });
};
/**
 *
 * @param type metrics type enum to convert to user friendly string
 * @param capitalize sentence case or regular case
 */


exports.composeSites = composeSites;

function getReadableMetricsType(type, capitalize) {
  switch (type) {
    case _types.MetricsType.Completed:
      return capitalize ? 'Closed' : 'closed';

    case _types.MetricsType.Opened:
      return capitalize ? 'Created' : 'created';

    default:
      throw Error("Invalid metrics type ".concat(type));
  }
}
/**
 *
 * @param type metrics type enum to convert to user friendly string
 * @param capitalize sentence case or regular case
 */


function getReadableByType(type, capitalize) {
  switch (type) {
    case _types.MetricsByType.ByResponsible:
      return capitalize ? 'Team' : 'team';

    case _types.MetricsByType.BySite:
      return capitalize ? 'Site' : 'site';

    default:
      throw Error("Invalid metrics by type ".concat(type));
  }
}

function getChartSizes(count) {
  switch (count) {
    case 1:
      return 144;

    case 2:
      return 183;

    case 3:
      return 230;

    case 4:
      return 277;

    case 5:
      return 325;

    case 6:
      return 371;

    case 7:
      return 419;

    case 8:
      return 465;

    case 9:
      return 513;

    default:
      return 559;
  }
}