"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOAD_REPORT_DATA_FAIL = exports.LOAD_REPORT_DATA_SUCCESS = exports.LOAD_REPORT_DATA = exports.LOAD_REPORT_DATA_REQUEST = void 0;
const LOAD_REPORT_DATA_REQUEST = "bonfire/lookups/LOAD_REPORT_DATA_REQUEST";
exports.LOAD_REPORT_DATA_REQUEST = LOAD_REPORT_DATA_REQUEST;
const LOAD_REPORT_DATA = "bonfire/lookups/LOAD_REPORT_DATA";
exports.LOAD_REPORT_DATA = LOAD_REPORT_DATA;
const LOAD_REPORT_DATA_SUCCESS = "bonfire/lookups/LOAD_REPORT_DATA_SUCCESS";
exports.LOAD_REPORT_DATA_SUCCESS = LOAD_REPORT_DATA_SUCCESS;
const LOAD_REPORT_DATA_FAIL = "bonfire/lookups/LOAD_REPORT_DATA_FAIL";
exports.LOAD_REPORT_DATA_FAIL = LOAD_REPORT_DATA_FAIL;