"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledThirds = exports.Content = exports.Container = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3;

const Container = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  overflow: hidden;\n  position: relative;\n  min-height: calc(100vh - 330px);\n  width: ", ";\n  /* NOTE: This is temporarily disabled because we're now removing columns when\n  opening/closing the sidebar and this makes it look jarring. */\n  /* transition: width 0.3s ease-in-out; */\n  display: flex;\n  flex-direction: column;\n"])), props => props.sidebarOpen ? "100%" : "calc(100% + 400px)");

exports.Container = Container;

const Content = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: calc(100% - 400px);\n  justify-content: space-between;\n"])));

exports.Content = Content;
const StyledThirds = (0, _styledComponents.default)(_blocks.Thirds)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  width: 100%;\n"])));
exports.StyledThirds = StyledThirds;