"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetState = resetState;
exports.groupByProject = groupByProject;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash.groupby"));

var _lodash2 = _interopRequireDefault(require("lodash.orderby"));

var _selections = require("./selections");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * This method takes in the global state object from the redux store
 * and returns a new state object stripped of session-specific data and UI state.
 *
 * @param {object} state The global state object at the time of logout
 * @returns {object} The updated state object, stripped of session-specific data
 */
function resetState(state) {
  // TODO: Have I missed anything sensitive here?
  // TODO: These values should probably live in the corresponding reducer files
  // TODO: Clean up the issues properties when we drop legacy support
  const INITIAL_STATE = {
    auth: _objectSpread(_objectSpread({}, state.auth), {}, {
      user: {},
      loaded: false,
      loading: false,
      loginError: undefined
    }),
    issues: _objectSpread(_objectSpread({}, state.issues), {}, {
      loaded: false,
      completing: false,
      commenting: false,
      postingComment: false,
      completingIssue: false,
      collapsed: false,
      editing: {},
      saveError: {},
      selected: {},
      data: [],
      sites: {},
      priorities: {},
      contexts: {},
      statuses: {},
      insite_statuses: {},
      projects: {},
      parties: {},
      comments: [],
      issue: {},
      status: [],
      showOpenIssues: false,
      usernameList: [],
      taggingList: []
    }),
    betaIssues: _objectSpread(_objectSpread({}, state.betaIssues), {}, {
      loading: false,
      loaded: false,
      siteHasChanged: true,
      data: []
    }),
    betaIssue: {
      isDirty: false,
      loading: false,
      activeIssue: undefined,
      error: undefined,
      activeIssueId: ""
    },
    betaSelections: _objectSpread(_objectSpread({}, _selections.INITIAL_STATE), {}, {
      locale: state.betaSelections.locale
    }),
    insights: _objectSpread(_objectSpread({}, state.insights), {}, {
      loading: false,
      ready: false,
      composed: undefined,
      siteGroups: undefined,
      raw: undefined,
      error: undefined
    }),
    userSites: {
      sites: {},
      hasLoaded: false,
      isLoading: false
    },
    requestSchedule: {
      shouldRequestInsights: true,
      shouldRequestIssues: true
    }
  };
  return _objectSpread(_objectSpread({}, state), INITIAL_STATE);
}
/**
 * Accepts a collection of projects and sites
 * Returns a collection of sites, grouped by project, sorted in
 * ascending order by project_name
 */


function groupByProject(projects, sites) {
  const sitesByProject = (0, _lodash.default)(sites, site => site.projectId);
  const grouped = Object.entries(sitesByProject).reduce((projGroup, _ref) => {
    let [pId, s] = _ref;
    const project = projects[pId];
    const orderedSites = (0, _lodash2.default)(s, ["label"], ["asc"]);
    project.sites = orderedSites;
    projGroup[pId] = project;
    return projGroup;
  }, {});
  return (0, _lodash2.default)(grouped, ["project_name"], ["asc"]);
}