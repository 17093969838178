"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.unassociateEquipRuleFlow = unassociateEquipRuleFlow;
exports.unassociateEquipRuleWatcher = unassociateEquipRuleWatcher;
exports.deleteAssociateEquipRule = exports.unassociateEquipRule = exports.UNASSOCIATE_RULE_EQUIP_REQUEST_FAIL = exports.UNASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS = exports.UNASSOCIATE_RULE_EQUIP_REQUEST = exports.UNASSOCIATE_RULE_EQUIP = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _effects = require("redux-saga/effects");

var _helpers = require("../helpers/helpers");

var _requestSchedule = require("./requestSchedule");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const INITIAL_STATE = {
  pending: true,
  complete: false,
  error: undefined
};
const UNASSOCIATE_RULE_EQUIP = "bonfire/_BETA/triage/UNASSOCIATE_RULE_EQUIP";
exports.UNASSOCIATE_RULE_EQUIP = UNASSOCIATE_RULE_EQUIP;
const UNASSOCIATE_RULE_EQUIP_REQUEST = "bonfire/_BETA/triage/UNASSOCIATE_RULE_EQUIP_REQUEST";
exports.UNASSOCIATE_RULE_EQUIP_REQUEST = UNASSOCIATE_RULE_EQUIP_REQUEST;
const UNASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS = "bonfire/_BETA/triage/UNASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS";
exports.UNASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS = UNASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS;
const UNASSOCIATE_RULE_EQUIP_REQUEST_FAIL = "bonfire/_BETA/triage/UNASSOCIATE_RULE_EQUIP_REQUEST_FAIL";
exports.UNASSOCIATE_RULE_EQUIP_REQUEST_FAIL = UNASSOCIATE_RULE_EQUIP_REQUEST_FAIL;

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case UNASSOCIATE_RULE_EQUIP_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        pending: true,
        complete: false,
        error: undefined
      });

    case UNASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        pending: false,
        complete: true,
        error: undefined
      });

    case UNASSOCIATE_RULE_EQUIP_REQUEST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        pending: false,
        complete: true,
        error: action.error
      });

    default:
      {
        return state;
      }
  }
}

const unassociateEquipRule = _ref => {
  let {
    equipRuleIds
  } = _ref;
  return {
    type: UNASSOCIATE_RULE_EQUIP,
    equipRuleIds
  };
};

exports.unassociateEquipRule = unassociateEquipRule;

const deleteAssociateEquipRule = _ref2 => {
  let {
    equipRuleIds = [],
    token
  } = _ref2;
  return {
    types: [UNASSOCIATE_RULE_EQUIP_REQUEST, UNASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS, UNASSOCIATE_RULE_EQUIP_REQUEST_FAIL],
    promise: client => client.delete("/synco/equipRule/associate", {
      headers: {
        Authorization: "Bearer ".concat(token)
      },
      data: {
        equipRuleIds
      }
    })
  };
};

exports.deleteAssociateEquipRule = deleteAssociateEquipRule;

const getAuthUser = state => state.auth.user;

function* unassociateEquipRuleFlow(equipRuleIds) {
  try {
    const user = yield (0, _effects.select)(getAuthUser); // Check that user has necessary permissions to make request

    if (!user.rolesRestriction) throw Error("Unable to authenticate user"); // validate that at least one equipRuleId has been given

    if (equipRuleIds.length < 1) throw Error("No equip rules given");
    yield (0, _effects.fork)(_helpers.dispatchGeneratorFunc, deleteAssociateEquipRule, {
      token: user.token,
      equipRuleIds
    });
    const {
      success,
      failure
    } = yield (0, _effects.race)({
      success: (0, _effects.take)(UNASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS),
      failure: (0, _effects.take)(UNASSOCIATE_RULE_EQUIP_REQUEST_FAIL)
    });

    if (failure) {
      throw new Error("Failed to associate");
    }

    if (success) {
      yield (0, _effects.put)((0, _requestSchedule.setShouldRequestInsights)(true));
      yield (0, _effects.put)((0, _requestSchedule.setShouldRequestMonitoring)(true));
    }
  } catch (err) {
    console.error("Something went wrong attempting to associate equip rule: ".concat(err));
  }
}

function* unassociateEquipRuleWatcher() {
  while (true) {
    const {
      equipRuleIds
    } = yield (0, _effects.take)(UNASSOCIATE_RULE_EQUIP);
    yield (0, _effects.fork)(unassociateEquipRuleFlow, equipRuleIds);
  }
}