"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getChartSizes = getChartSizes;
exports.combineZones = combineZones;
exports.calculateYAxisMaxValue = calculateYAxisMaxValue;
exports.createLegendItemsUsingDates = createLegendItemsUsingDates;
exports.getTimeFrameCompareRanges = getTimeFrameCompareRanges;
exports.getCompareTolist = void 0;

var _types = require("../../../context/reports/types");

/**
 * Calculates the chart height necessary to produce a 2 group bar chart
 * with bars of 32px high and 16px gap between bar groups
 *
 * @param count number of bar groups
 * @param spacing additional space for external chart elements, such as legend and caption
 */
function getChartSizes(count) {
  let spacing = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 134;
  let groupSize = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  // * Each group consists of 2 bars
  const barCount = count < 10 ? count * groupSize : 10 * groupSize; // * Each bar is 32px high

  const barHeight = 32; // * gap between bar groups is a percentage of a single bar's height (25% of 32px = 8px)

  const gap = barHeight * 0.25; // * Add it all together to calculate the height needed for bars of 32px with a gap of 16px
  // * plus 8px above first group and below last group

  const height = spacing + barCount * barHeight + barCount * gap;
  return height;
}

function combineZones(rankDir, viewBy, currentZones, compareZones) {
  const combined = {}; // go through the current data, to populate both
  // current and compare object based on the current equip keys

  Object.entries(currentZones.zones).forEach(_ref => {
    let [key, val] = _ref;
    combined[key] = {
      current: val,
      compare: compareZones && compareZones.zones[key] ? compareZones.zones[key] : null
    };
  });

  if (compareZones) {
    // go through compare data to see if there is data that
    // exists in the compare data but not in the current data
    // then we assign null to the current under the equip key object
    // and populate the compare object
    Object.entries(compareZones.zones).forEach(_ref2 => {
      let [key, val] = _ref2;

      if (!combined[key]) {
        combined[key] = {
          current: null,
          compare: val
        };
      }
    });
  }

  const sorted = Object.values(combined).sort((a, b) => {
    if (a.current === null || b.current === null) {
      return 1;
    }

    if (rankDir === "worst") {
      return a.current[viewBy] < b.current[viewBy] ? 1 : -1;
    }

    return a.current[viewBy] < b.current[viewBy] ? -1 : 1;
  });
  return sorted;
}

function calculateYAxisMaxValue(currentZones, compareZones) {
  let yAxisMaxValue;
  const currentDataArray = Object.values(currentZones.zones).filter(d => d.pctDiscomfort);
  const compareDataArray = compareZones ? Object.values(compareZones.zones).filter(d => d.pctDiscomfort) : []; // get the max hoursDiscomfort value from current period dataset

  const currentDataYAxisMaxValue = Math.max.apply(Math, currentDataArray.map(z => z.hoursDiscomfort)); // get the max hoursDiscomfort value from compared period dataset if there is compare data

  const comparisonDataYAxisMaxValue = compareDataArray.length !== 0 ? Math.max.apply(Math, compareDataArray.map(z => z.hoursDiscomfort)) : null;

  if (comparisonDataYAxisMaxValue === null) {
    yAxisMaxValue = currentDataYAxisMaxValue;
  } else {
    yAxisMaxValue = currentDataYAxisMaxValue > comparisonDataYAxisMaxValue ? currentDataYAxisMaxValue : comparisonDataYAxisMaxValue;
  }

  return yAxisMaxValue;
}

function createLegendItemsUsingDates(from, to) {
  return "".concat(new Date(from).toLocaleString("default", {
    month: "short"
  }), " ").concat(new Date(from).getFullYear(), " - ").concat(new Date(to).toLocaleString("default", {
    month: "short"
  }), " ").concat(new Date(to).getFullYear());
}

const getCompareTolist = (i18n, sliceCount) => {
  const list = [{
    value: _types.CompareTo.PreviousPeriod,
    label: i18n._(
    /*i18n*/
    {
      id: "time.previous_period",
      defaults: "Previous Period"
    })
  }, {
    value: _types.CompareTo.SamePeriodLastYear,
    label: i18n._(
    /*i18n*/
    {
      id: "time.same_period_previous_year",
      defaults: "Same Period Previous Year"
    })
  }];
  return sliceCount ? list.slice(0, sliceCount) : list;
};
/**
 * Get comparison time frame ranges for seasonal periods.
 *
 * ie. The last X months, compared to the same X months Y years ago.
 */


exports.getCompareTolist = getCompareTolist;

function getTimeFrameCompareRanges(timeFrameOption, compareToOption) {
  let today = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new Date();
  // today - X month, start of month
  const currentFrom = new Date(today);
  currentFrom.setMonth(currentFrom.getMonth() - timeFrameOption);
  currentFrom.setDate(1); // end of last month

  const currentTo = new Date(today);
  currentTo.setDate(0);
  let compareFrom = new Date(today);
  let compareTo = new Date(today);

  if (compareToOption !== _types.CompareTo.PreviousPeriod) {
    // same from date, but Y years ago
    compareFrom = new Date(currentFrom);
    compareFrom.setFullYear(compareFrom.getFullYear() - compareToOption); // add on the same period that was taken from currentFrom, less 1 month
    // because months are zero-indexed

    compareTo = new Date(compareFrom);
    compareTo.setMonth(compareTo.getMonth() + (timeFrameOption - 1)); // then to the end of that month by getting the number of days in that month
    // NOTE: This works by abusing the way `0` works when creating a date

    const daysInMonth = new Date(compareTo.getFullYear(), compareTo.getMonth() + 1, 0).getDate();
    compareTo.setDate(daysInMonth);
  } else {
    // the start of the previous period is the last day of the month prior to the currentFrom
    compareTo = new Date(currentFrom);
    compareTo.setDate(0); // the end of the previous period comparison is the same difference between the current period

    compareFrom = new Date(compareTo);
    compareFrom.setMonth(compareFrom.getMonth() - (timeFrameOption - 1));
    compareFrom.setDate(1);
  }

  return {
    timeFrameOption,
    compareToOption,
    current: {
      from: currentFrom.toISOString(),
      to: currentTo.toISOString()
    },
    compare: {
      from: compareFrom.toISOString(),
      to: compareTo.toISOString()
    }
  };
}