"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SubduedBodyLarge = exports.TodayCountWrapper = exports.IconWrapper = exports.Right = exports.Left = exports.Bar = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

const Bar = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"])));

exports.Bar = Bar;

const Segment = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  display: inline-flex;\n  align-items: center;\n"])));

const Left = (0, _styledComponents.default)(Segment)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  justify-content: flex-start;\n"])));
exports.Left = Left;
const Right = (0, _styledComponents.default)(Segment)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  justify-content: flex-end;\n"])));
exports.Right = Right;
const IconWrapper = (0, _styledComponents.default)(Segment)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  margin: ", ";\n"])), _blocks.spacing.tight);
exports.IconWrapper = IconWrapper;
const TodayCountWrapper = (0, _styledComponents.default)(_blocks.Body)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n  color: ", ";\n"])), _blocks.color.theme.typography.subdued);
exports.TodayCountWrapper = TodayCountWrapper;
const SubduedBodyLarge = (0, _styledComponents.default)(_blocks.BodyLarge)(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n  color: ", ";\n"])), _blocks.color.theme.typography.subdued);
exports.SubduedBodyLarge = SubduedBodyLarge;