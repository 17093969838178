"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.APP_SETUP_ERROR = exports.APP_SETUP_RESET = exports.APP_SETUP_COMPLETE = void 0;
const APP_SETUP_COMPLETE = "bonfire/appSetup/APP_SETUP_COMPLETE";
exports.APP_SETUP_COMPLETE = APP_SETUP_COMPLETE;
const APP_SETUP_RESET = "bonfire/appSetup/APP_SETUP_RESET";
exports.APP_SETUP_RESET = APP_SETUP_RESET;
const APP_SETUP_ERROR = "bonfire/appSetup/APP_SETUP_ERROR";
exports.APP_SETUP_ERROR = APP_SETUP_ERROR;