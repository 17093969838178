"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _superagent = _interopRequireDefault(require("superagent"));

var _config = require("../config");

const methods = ["get", "post", "put", "patch", "delete"];

function formatUrl(path) {
  const adjustedPath = path[0] !== "/" ? "/".concat(path) : path;

  if (__SERVER__) {
    // Prepend host and port of the API server to the path.
    return "http://".concat(_config.apiHost, ":").concat(_config.apiPort).concat(adjustedPath);
  } // Prepend `/api` to relative URL, to proxy to API server.


  return "/api".concat(adjustedPath);
}

class ApiClient {
  constructor(req) {
    methods.forEach(method => {
      this[method] = function (path) {
        let {
          params,
          data,
          form,
          headers
        } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        let config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
          externalPath: false
        };
        return new Promise((resolve, reject) => {
          const destinationPath = !config.externalPath ? formatUrl(path) : path;

          const request = _superagent.default[method](destinationPath);

          if (params) {
            request.query(params);
          }

          if (form) {
            request.type("form");
          }

          if (__SERVER__ && req.get("cookie")) {
            request.set("cookie", req.get("cookie"));
          }

          if (headers) {
            Object.keys(headers).forEach(key => request.set(key, headers[key]));
          }

          if (data) {
            request.send(data);
          }

          request.end(function (err) {
            let {
              body
            } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            return err ? reject(body || err) : resolve(body);
          });
        });
      };
    });
  }
  /*
   * There's a V8 bug where, when using Babel, exporting classes with only
   * constructors sometimes fails. Until it's patched, this is a solution to
   * "ApiClient is not defined" from issue #14.
   * https://github.com/erikras/react-redux-universal-hot-example/issues/14
   *
   * Relevant Babel bug (but they claim it's V8): https://phabricator.babeljs.io/T2455
   *
   * Remove it at your own risk.
   */


  empty() {}

}

exports.default = ApiClient;
module.exports = exports.default;