"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.associateEquipRuleFlow = associateEquipRuleFlow;
exports.postAssociateEquipRule = exports.associateEquipRule = exports.ASSOCIATE_RULE_EQUIP_COMPLETE = exports.ASSOCIATE_RULE_EQUIP_REQUEST_FAIL = exports.ASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS = exports.ASSOCIATE_RULE_EQUIP_REQUEST = exports.ASSOCIATE_RULE_EQUIP = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _effects = require("redux-saga/effects");

var _reduxModal = require("redux-modal");

var _helpers = require("../helpers/helpers");

var _eventTracking = require("../../../utils/eventTracking");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const INITIAL_STATE = {
  isAssociating: false,
  complete: false,
  error: undefined
};
const ASSOCIATE_RULE_EQUIP = "bonfire/_BETA/triage/ASSOCIATE_RULE_EQUIP";
exports.ASSOCIATE_RULE_EQUIP = ASSOCIATE_RULE_EQUIP;
const ASSOCIATE_RULE_EQUIP_REQUEST = "bonfire/_BETA/triage/ASSOCIATE_RULE_EQUIP_REQUEST";
exports.ASSOCIATE_RULE_EQUIP_REQUEST = ASSOCIATE_RULE_EQUIP_REQUEST;
const ASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS = "bonfire/_BETA/triage/ASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS";
exports.ASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS = ASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS;
const ASSOCIATE_RULE_EQUIP_REQUEST_FAIL = "bonfire/_BETA/triage/ASSOCIATE_RULE_EQUIP_REQUEST_FAIL";
exports.ASSOCIATE_RULE_EQUIP_REQUEST_FAIL = ASSOCIATE_RULE_EQUIP_REQUEST_FAIL;
const ASSOCIATE_RULE_EQUIP_COMPLETE = "bonfire/_BETA/triage/ASSOCIATE_RULE_EQUIP_COMPLETE";
exports.ASSOCIATE_RULE_EQUIP_COMPLETE = ASSOCIATE_RULE_EQUIP_COMPLETE;

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case ASSOCIATE_RULE_EQUIP:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAssociating: true
      });

    case ASSOCIATE_RULE_EQUIP_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        complete: false,
        error: undefined
      });

    case ASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        complete: true,
        error: undefined
      });

    case ASSOCIATE_RULE_EQUIP_REQUEST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        complete: true,
        error: action.error
      });

    case ASSOCIATE_RULE_EQUIP_COMPLETE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAssociating: false
      });

    default:
      {
        return state;
      }
  }
}

const associateEquipRule = _ref => {
  let {
    equipRuleIds = [],
    issueKey
  } = _ref;
  return {
    type: ASSOCIATE_RULE_EQUIP,
    equipRuleIds,
    issueKey
  };
};

exports.associateEquipRule = associateEquipRule;

const postAssociateEquipRule = _ref2 => {
  let {
    equipRuleIds = [],
    issueKey,
    token
  } = _ref2;
  return {
    types: [ASSOCIATE_RULE_EQUIP_REQUEST, ASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS, ASSOCIATE_RULE_EQUIP_REQUEST_FAIL],
    promise: client => client.post("/v2/actions/associate", {
      headers: {
        Authorization: "Bearer ".concat(token)
      },
      data: {
        equipRuleIds,
        actionId: issueKey
      }
    })
  };
};

exports.postAssociateEquipRule = postAssociateEquipRule;

const getAuthUser = state => state.auth.user;

const getBetaIssues = state => state.betaIssues && state.betaIssues.data && state.betaIssues.data.length > 0 ? state.betaIssues.data : [];

function* associateEquipRuleFlow() {
  while (true) {
    const {
      issueKey,
      equipRuleIds
    } = yield (0, _effects.take)(ASSOCIATE_RULE_EQUIP);

    try {
      const user = yield (0, _effects.select)(getAuthUser);
      const betaIssues = yield (0, _effects.select)(getBetaIssues); // Check that user has necessary permissions to make request

      if (!user.rolesRestriction) throw Error("Unable to authenticate user"); // Validate that an issueKey has been given

      if (!issueKey) throw Error("No issueKey given"); // validate that at least one equipRuleId has been given

      if (equipRuleIds.length < 1) throw Error("No equip rules given");
      yield (0, _effects.fork)(_helpers.dispatchGeneratorFunc, postAssociateEquipRule, {
        token: user.token,
        issueKey,
        equipRuleIds
      }); // TODO: Handle error state properly

      const {
        success,
        failure
      } = yield (0, _effects.race)({
        success: (0, _effects.take)(ASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS),
        failure: (0, _effects.take)(ASSOCIATE_RULE_EQUIP_REQUEST_FAIL)
      });

      if (failure) {
        throw new Error("Failed to associate");
      }

      if (success) {
        // Track user event on success for analytics
        (0, _eventTracking.associatedInsightWithActionEvent)(betaIssues, success.result);
        yield (0, _effects.put)((0, _reduxModal.hide)("TRIAGE_ACTION_MODAL")); // Update isAssociating flag so UI is no longer blocked

        yield (0, _effects.put)({
          type: ASSOCIATE_RULE_EQUIP_COMPLETE
        });
      }
    } catch (err) {
      console.error("Something went wrong attempting to associate equip rule: ".concat(err));
    }
  }
}