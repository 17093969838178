"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getContextMap = getContextMap;
exports.getContextOptions = getContextOptions;
exports.getResponsibleMap = getResponsibleMap;
exports.getResponsibleOptions = getResponsibleOptions;
exports.getPriorityMap = getPriorityMap;
exports.getPriorityOptions = getPriorityOptions;
exports.getTransitionReasonMap = getTransitionReasonMap;
exports.getTransitionReasonOptions = getTransitionReasonOptions;
exports.getStatusMap = getStatusMap;
exports.getStatusOptions = getStatusOptions;

var _lists = require("../../../utils/lists");

function getContextMap(i18n) {
  return {
    Maintenance: i18n._(
    /*i18n*/
    {
      id: "context_list.maintenance_label",
      defaults: "Maintenance"
    }),
    DLP: i18n._(
    /*i18n*/
    {
      id: "context_list.dlp_label",
      defaults: "DLP"
    }),
    Optimisation: i18n._(
    /*i18n*/
    {
      id: "context_list.optimisation_label",
      defaults: "Optimisation"
    }),
    Other: i18n._(
    /*i18n*/
    {
      id: "context_list.other_label",
      defaults: "Other"
    })
  };
}

function getContextOptions(i18n) {
  const labelMap = getContextMap(i18n);
  return _lists.CONTEXT_LIST.map(l => ({
    value: l,
    label: labelMap[l]
  }));
}

function getResponsibleMap(i18n) {
  let isWOOL = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return isWOOL ? {
    "Asset Team - Building": i18n._(
    /*i18n*/
    {
      id: "responsible_list.asset_team_building",
      defaults: "Asset Team - Building"
    }),
    "Asset Team - Electrical": i18n._(
    /*i18n*/
    {
      id: "responsible_list.asset_team_electrical",
      defaults: "Asset Team - Electrical"
    }),
    "Asset Team - Plumbing": i18n._(
    /*i18n*/
    {
      id: "responsible_list.asset_team_plumbing",
      defaults: "Asset Team - Plumbing"
    }),
    "Asset Team - Refrigeration": i18n._(
    /*i18n*/
    {
      id: "responsible_list.asset_team_refrigeration",
      defaults: "Asset Team - Refrigeration"
    }),
    "EMC - Energy Optimisation Specialist": i18n._(
    /*i18n*/
    {
      id: "responsible_list.emc_energy_optimisation_specialist",
      defaults: "EMC - Energy Optimisation Specialist"
    }),
    "EMC - Operations/IT": i18n._(
    /*i18n*/
    {
      id: "responsible_list.emc_operations_it",
      defaults: "EMC - Operations/IT"
    }),
    "External Contractors (Back to Back)": i18n._(
    /*i18n*/
    {
      id: "responsible_list.external_contractors_back_to_back",
      defaults: "External Contractors (Back to Back)"
    }),
    "External Contractors (Others)": i18n._(
    /*i18n*/
    {
      id: "responsible_list.external_contractors_others",
      defaults: "External Contractors (Others)"
    }),
    "Field Maintenance Technician (FMT) - HVAC": i18n._(
    /*i18n*/
    {
      id: "responsible_list.fmt_hvac",
      defaults: "Field Maintenance Technician (FMT) - HVAC"
    }),
    "Field Maintenance Technician (FMT) - Refrigeration": i18n._(
    /*i18n*/
    {
      id: "responsible_list.fmt_refrigeration",
      defaults: "Field Maintenance Technician (FMT) - Refrigeration"
    }),
    "Service Team (STL)": i18n._(
    /*i18n*/
    {
      id: "responsible_list.service_team",
      defaults: "Service Team (STL)"
    }),
    "State Admin Manager (SAM)": i18n._(
    /*i18n*/
    {
      id: "responsible_list.state_admin_manager",
      defaults: "State Admin Manager (SAM)"
    }),
    "Technical Support Team (TST)": i18n._(
    /*i18n*/
    {
      id: "responsible_list.technical_support_team",
      defaults: "Technical Support Team (TST)"
    }),
    "WFM Support Centre": i18n._(
    /*i18n*/
    {
      id: "responsible_list.wfm_support_centre",
      defaults: "WFM Support Centre"
    }),
    Other: i18n._(
    /*i18n*/
    {
      id: "responsible_list.other",
      defaults: "Other"
    }),
    ConserveIt: i18n._(
    /*i18n*/
    {
      id: "responsible_list.conserveit",
      defaults: "ConserveIt"
    })
  } : {
    ConserveIt: i18n._(
    /*i18n*/
    {
      id: "responsible_list.conserveit",
      defaults: "ConserveIt"
    }),
    "Site Local": i18n._(
    /*i18n*/
    {
      id: "responsible_list.site_local",
      defaults: "Site Local"
    }),
    "Site National": i18n._(
    /*i18n*/
    {
      id: "responsible_list.site_national",
      defaults: "Site National"
    }),
    "Mech. Contractor": i18n._(
    /*i18n*/
    {
      id: "responsible_list.mech_contractor",
      defaults: "Mech. Contractor"
    }),
    "BMS Contractor": i18n._(
    /*i18n*/
    {
      id: "responsible_list.bms_contractor",
      defaults: "BMS Contractor"
    }),
    "Other Contractor": i18n._(
    /*i18n*/
    {
      id: "responsible_list.other_contractor",
      defaults: "Other Contractor"
    }),
    "InSite Engineer": i18n._(
    /*i18n*/
    {
      id: "responsible_list.insite_engineer",
      defaults: "InSite Engineer"
    }),
    Monitoring: i18n._(
    /*i18n*/
    {
      id: "responsible_list.monitoring",
      defaults: "Monitoring"
    })
  };
}

function getResponsibleOptions(userGroups, i18n) {
  let legacySiteId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
  // HACK: Mistakes were made...
  // If selected site is a WOOL site, return WOOL responsible list
  const isWoolSite = legacySiteId.split("~")[0] === "WOOL";
  const list = userGroups.includes("WOOL") && isWoolSite ? _lists.WW_RESPONSIBLE_LIST : _lists.RESPONSIBLE_LIST;
  const labelMap = getResponsibleMap(i18n, isWoolSite);
  return list.map(l => ({
    value: l,
    label: labelMap[l]
  }));
}

function getPriorityMap(i18n) {
  return {
    Blocker: i18n._(
    /*i18n*/
    {
      id: "priority_list.blocker",
      defaults: "Blocker"
    }),
    Critical: i18n._(
    /*i18n*/
    {
      id: "priority_list.critical",
      defaults: "Critical"
    }),
    Major: i18n._(
    /*i18n*/
    {
      id: "priority_list.major",
      defaults: "Major"
    }),
    Moderate: i18n._(
    /*i18n*/
    {
      id: "priority_list.moderate",
      defaults: "Moderate"
    }),
    Minor: i18n._(
    /*i18n*/
    {
      id: "priority_list.minor",
      defaults: "Minor"
    }),
    Trivial: i18n._(
    /*i18n*/
    {
      id: "priority_list.trivial",
      defaults: "Trivial"
    })
  };
}

function getPriorityOptions(i18n) {
  const labelMap = getPriorityMap(i18n);
  return _lists.PRIORITY_LIST.map(l => ({
    value: l,
    label: labelMap[l]
  }));
}

function getTransitionReasonMap(i18n) {
  return {
    Fixed: i18n._(
    /*i18n*/
    {
      id: "transition_reason_list.fixed",
      defaults: "Fixed"
    }),
    "False alert": i18n._(
    /*i18n*/
    {
      id: "transition_reason_list.false_alert",
      defaults: "False alert"
    }),
    "Cannot fix (known issue)": i18n._(
    /*i18n*/
    {
      id: "transition_reason_list.cannot_fix",
      defaults: "Cannot fix (known issue)"
    }),
    Duplicate: i18n._(
    /*i18n*/
    {
      id: "transition_reason_list.duplicate",
      defaults: "Duplicate"
    }),
    "Too difficult/expensive": i18n._(
    /*i18n*/
    {
      id: "transition_reason_list.too_difficult_expensive",
      defaults: "Too difficult/expensive"
    }),
    "Low priority": i18n._(
    /*i18n*/
    {
      id: "transition_reason_list.low_priority",
      defaults: "Low priority"
    }),
    Other: i18n._(
    /*i18n*/
    {
      id: "transition_reason_list.other",
      defaults: "Other"
    })
  };
}

function getTransitionReasonOptions(i18n) {
  const labelMap = getTransitionReasonMap(i18n);
  return _lists.TRANSITION_REASON_LIST.map(l => ({
    value: l,
    label: labelMap[l]
  }));
}

function getStatusMap(i18n) {
  return {
    Open: i18n._(
    /*i18n*/
    {
      id: "status_list.open",
      defaults: "Open"
    }),
    "To Be Raised MM": i18n._(
    /*i18n*/
    {
      id: "status_list.to_be_raised_mm",
      defaults: "To Be Raised MM"
    }),
    "Work in progress": i18n._(
    /*i18n*/
    {
      id: "status_list.work_in_progress",
      defaults: "Work in progress"
    }),
    "Scope Required": i18n._(
    /*i18n*/
    {
      id: "status_list.scope_required",
      defaults: "Scope Required"
    }),
    "To be quoted": i18n._(
    /*i18n*/
    {
      id: "status_list.to_be_quoted",
      defaults: "To be quoted"
    }),
    "Pending PO": i18n._(
    /*i18n*/
    {
      id: "status_list.pending_po",
      defaults: "Pending PO"
    }),
    "In Review": i18n._(
    /*i18n*/
    {
      id: "status_list.in_review",
      defaults: "In Review"
    }),
    Parked: i18n._(
    /*i18n*/
    {
      id: "status_list.parked",
      defaults: "Parked"
    }),
    Closed: i18n._(
    /*i18n*/
    {
      id: "status_list.closed",
      defaults: "Closed"
    }),
    Rejected: i18n._(
    /*i18n*/
    {
      id: "status_list.rejected",
      defaults: "Rejected"
    })
  };
}

function getStatusOptions(i18n) {
  const labelMap = getStatusMap(i18n);
  return _lists.STATUS_LIST.map(l => ({
    value: l,
    label: labelMap[l]
  }));
}