"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.shouldRequestIssuesFlow = shouldRequestIssuesFlow;
exports.shouldRequestInsightsFlow = shouldRequestInsightsFlow;
exports.shouldRequestMonitoringFlow = shouldRequestMonitoringFlow;
exports.shouldRequestMonthlySummaryFlow = shouldRequestMonthlySummaryFlow;
exports.getShouldRequestMonitoring = exports.getShouldRequestInsights = exports.getShouldRequestIssues = exports.setShouldRequestMonthlySummary = exports.setShouldRequestMonitoring = exports.setShouldRequestInsights = exports.setShouldRequestIssues = exports.UPDATE_SHOULD_REQUEST_MONTHLY_SUMMARY = exports.UPDATE_SHOULD_REQUEST_ISSUES = exports.UPDATE_SHOULD_REQUEST_MONITORING = exports.UPDATE_SHOULD_REQUEST_INSIGHTS = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _effects = require("redux-saga/effects");

var _selections = require("./selections");

var _issues = require("./issues");

var _actionsUI = require("./actionsUI");

var _actionTypes = require("../monthlySummary/actionTypes");

var _actionTypes2 = require("../monitoring/actionTypes");

var _actionTypes3 = require("../action/actionTypes");

var _associate = require("./associate");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const UPDATE_SHOULD_REQUEST_INSIGHTS = "bonfire/_BETA/requestSchedule/UPDATE_SHOULD_REQUEST_INSIGHTS";
exports.UPDATE_SHOULD_REQUEST_INSIGHTS = UPDATE_SHOULD_REQUEST_INSIGHTS;
const UPDATE_SHOULD_REQUEST_MONITORING = "bonfire/_BETA/requestSchedule/UPDATE_SHOULD_REQUEST_MONITORING";
exports.UPDATE_SHOULD_REQUEST_MONITORING = UPDATE_SHOULD_REQUEST_MONITORING;
const UPDATE_SHOULD_REQUEST_ISSUES = "bonfire/_BETA/requestSchedule/UPDATE_SHOULD_REQUEST_ISSUES";
exports.UPDATE_SHOULD_REQUEST_ISSUES = UPDATE_SHOULD_REQUEST_ISSUES;
const UPDATE_SHOULD_REQUEST_MONTHLY_SUMMARY = "bonfire/_BETA/requestSchedule/UPDATE_SHOULD_REQUEST_MONTHLY_SUMMARY";
exports.UPDATE_SHOULD_REQUEST_MONTHLY_SUMMARY = UPDATE_SHOULD_REQUEST_MONTHLY_SUMMARY;
const INITIAL_STATE = {
  shouldRequestInsights: true,
  shouldRequestMonitoring: true,
  shouldRequestIssues: true,
  shouldRequestMonthlySummary: true
};

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case UPDATE_SHOULD_REQUEST_INSIGHTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        shouldRequestInsights: action.shouldRequestInsights
      });

    case UPDATE_SHOULD_REQUEST_MONITORING:
      return _objectSpread(_objectSpread({}, state), {}, {
        shouldRequestMonitoring: action.shouldRequestMonitoring
      });

    case UPDATE_SHOULD_REQUEST_ISSUES:
      return _objectSpread(_objectSpread({}, state), {}, {
        shouldRequestIssues: action.shouldRequestIssues
      });

    case UPDATE_SHOULD_REQUEST_MONTHLY_SUMMARY:
      return _objectSpread(_objectSpread({}, state), {}, {
        shouldRequestMonthlySummary: action.shouldRequestMonthlySummary
      });

    default:
      return state;
  }
}

const setShouldRequestIssues = shouldRequestIssues => ({
  type: UPDATE_SHOULD_REQUEST_ISSUES,
  shouldRequestIssues
});

exports.setShouldRequestIssues = setShouldRequestIssues;

const setShouldRequestInsights = shouldRequestInsights => ({
  type: UPDATE_SHOULD_REQUEST_INSIGHTS,
  shouldRequestInsights
});

exports.setShouldRequestInsights = setShouldRequestInsights;

const setShouldRequestMonitoring = shouldRequestMonitoring => ({
  type: UPDATE_SHOULD_REQUEST_MONITORING,
  shouldRequestMonitoring
});

exports.setShouldRequestMonitoring = setShouldRequestMonitoring;

const setShouldRequestMonthlySummary = shouldRequestMonthlySummary => ({
  type: UPDATE_SHOULD_REQUEST_MONTHLY_SUMMARY,
  shouldRequestMonthlySummary
}); // Selectors


exports.setShouldRequestMonthlySummary = setShouldRequestMonthlySummary;

const getShouldRequestIssues = state => state.requestSchedule.shouldRequestIssues;

exports.getShouldRequestIssues = getShouldRequestIssues;

const getShouldRequestInsights = state => state.requestSchedule.shouldRequestInsights;

exports.getShouldRequestInsights = getShouldRequestInsights;

const getShouldRequestMonitoring = state => state.requestSchedule.shouldRequestMonitoring; // Sagas


exports.getShouldRequestMonitoring = getShouldRequestMonitoring;

function* shouldRequestIssuesFlow() {
  while (true) {
    yield (0, _effects.race)({
      siteChanged: (0, _effects.take)(_selections.UPDATE_SELECTED_SITES),
      actionChanged: (0, _effects.take)(_actionTypes3.SAVE_ACTION_REQUEST_SUCCESS),
      actionAdded: (0, _effects.take)(_actionTypes3.ADD_ACTION_REQUEST_SUCCESS),
      includeOpenStatusChanged: (0, _effects.take)(_actionsUI.ACTIONS_UI_TOGGLE_INCLUDE_OPEN_STATUS)
    });
    yield (0, _effects.put)(setShouldRequestIssues(true));
    yield (0, _effects.take)(_issues.LOAD_BETA_ISSUES_REQUEST_SUCCESS);
    yield (0, _effects.put)(setShouldRequestIssues(false));
  }
}

function* shouldRequestInsightsFlow() {
  while (true) {
    yield (0, _effects.race)({
      equipAssociated: (0, _effects.take)(_associate.ASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS),
      siteChanged: (0, _effects.take)(_selections.UPDATE_SELECTED_SITES),
      daysRangeChanged: (0, _effects.take)(_selections.UPDATE_DAYS_RANGE)
    });
    yield (0, _effects.put)(setShouldRequestInsights(true));
  }
}

function* shouldRequestMonitoringFlow() {
  while (true) {
    yield (0, _effects.race)({
      equipAssociated: (0, _effects.take)(_associate.ASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS),
      siteChanged: (0, _effects.take)(_selections.UPDATE_SELECTED_SITES),
      daysRangeChanged: (0, _effects.take)(_selections.UPDATE_DAYS_RANGE)
    });
    yield (0, _effects.put)(setShouldRequestMonitoring(true));
    yield (0, _effects.take)(_actionTypes2.LOAD_MONITORING_REQUEST_SUCCESS);
    yield (0, _effects.put)(setShouldRequestMonitoring(false));
  }
}

function* shouldRequestMonthlySummaryFlow() {
  while (true) {
    yield (0, _effects.take)(_selections.UPDATE_ACTIVE_SITE);
    yield (0, _effects.put)(setShouldRequestMonthlySummary(true));
    yield (0, _effects.take)(_actionTypes.LOAD_REPORT_DATA_SUCCESS);
    yield (0, _effects.put)(setShouldRequestMonthlySummary(false));
  }
}