"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$ButtonItem = exports.$Item = exports.$MenuTitle = exports.$Menu = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _App = require("../AppChrome/App.constants");

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

// NOTE: Must be used in a relatively positioned container
function openClose(_ref) {
  let {
    isOpen
  } = _ref;
  return isOpen ? "block" : "none";
}

const $Menu = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: ", ";\n  position: absolute;\n  border-radius: ", ";\n  height: auto;\n  min-width: 180px;\n  z-index: ", ";\n  background: ", ";\n  box-shadow: ", ";\n  overflow: hidden;\n  transform: translateY(", ");\n"])), openClose, _blocks.borderRadius.default, _blocks.layer.contextNavigation, _blocks.color.pureWhite, _blocks.elevation.contentNavigation, _blocks.spacing.tight);

exports.$Menu = $Menu;

const $Item = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  a,\n  button {\n    display: block;\n    padding: ", ";\n    font-size: 14px;\n    border: none;\n    outline: 0;\n    background: none;\n    text-align: left;\n    color: ", ";\n    white-space: nowrap;\n  }\n\n  a:not([disabled]):hover,\n  button:not([disabled]):hover {\n    background: ", ";\n    text-decoration: none;\n  }\n\n  &:last-child {\n    border-bottom: none;\n  }\n"])), _blocks.spacing.snug, _blocks.color.theme.typography.ink, _blocks.palette.neutral2);

exports.$Item = $Item;

function selected(_ref2) {
  let {
    isSelected
  } = _ref2;
  return isSelected ? "".concat(_App.TYPOGRAPHY.WEIGHTS.BOLD) : "".concat(_App.TYPOGRAPHY.WEIGHTS.REGULAR);
}

const $ButtonItem = _styledComponents.default.button.attrs({
  type: "button"
})(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  display: block;\n  margin: 0;\n  width: 100%;\n  padding: ", " ", ";\n  font-weight: ", ";\n  border: none;\n  outline: 0;\n  font-size: 12px;\n  background: transparent;\n  text-align: left;\n  white-space: nowrap;\n\n  &:not([disabled]):focus {\n    outline: 0;\n  }\n\n  &:not([disabled]):hover {\n    background: ", ";\n  }\n"])), _blocks.spacing.tight, _blocks.spacing.snug, selected, _blocks.palette.neutral2);

exports.$ButtonItem = $ButtonItem;

const $MenuTitle = _styledComponents.default.span(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  display: block;\n  border-bottom: 1px solid ", ";\n  padding: ", ";\n  text-transform: uppercase;\n  font-size: 12px;\n  font-weight: bold;\n  color: ", ";\n  text-align: left;\n"])), _blocks.color.theme.divider.solid, _blocks.spacing.snug, _blocks.color.theme.icon.default);

exports.$MenuTitle = $MenuTitle;