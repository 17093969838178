"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useChart = void 0;

var _lodash = _interopRequireDefault(require("lodash.orderby"));

var _workflow = require("../../../../context/reports/workflow");

var _utils = require("../utils");

var routes = _interopRequireWildcard(require("../../../../utils/routes"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// import * as React from 'react'
const useChart = match => {
  const [state] = (0, _workflow.useWorkflow)();
  const current = state.metrics[state.metrics.currentMetricsType];
  const type = state.byType; // bySite or byResponsible (team)

  const filterBy = match.path === routes.REPORTS.WorkflowSite ? [match.params.siteId] : match.path === routes.REPORTS.WorkflowTeam ? [match.params.teamId] : []; // @TODO: Try to memorise.

  const bars = (0, _lodash.default)((0, _utils.getWorkflowBars)(current, type, filterBy), o => o.value, state.sortingDirection);
  const gridData = (0, _utils.getWorkflowGridData)(current, type, filterBy);
  return {
    bars,
    gridData
  };
};

exports.useChart = useChart;