"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.EDIT_MODAL_DATA = exports.VIEW_MODAL_DATA = exports.ADD_MODAL_DATA = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _helpers = require("../helpers/helpers");

var _actionTypes = require("./actionTypes");

var _actionCreators = require("./actionCreators");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const INITIAL_USER_FORM_DATA = {};
const ADD_MODAL_DATA = {
  title: "Add User",
  submitText: "Add User",
  submitStyle: "success"
};
exports.ADD_MODAL_DATA = ADD_MODAL_DATA;
const VIEW_MODAL_DATA = {
  title: "View User",
  submitText: "Edit User",
  submitStyle: "primary"
};
exports.VIEW_MODAL_DATA = VIEW_MODAL_DATA;
const EDIT_MODAL_DATA = {
  title: "Edit User",
  submitText: "Save Changes",
  submitStyle: "success"
};
exports.EDIT_MODAL_DATA = EDIT_MODAL_DATA;
const initialState = {
  users: [],
  statuses: {},
  users_loaded: false,
  adding: false,
  viewing: false,
  editing: false,
  working: false,
  user_form_data: INITIAL_USER_FORM_DATA,
  modal_data: {},
  success: null,
  toggleDeactivateWorking: false
};

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let users = [];
  let newUser = {};
  let userFormData = {};

  switch (action.type) {
    case _actionTypes.LOAD_USERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        users_loading: true
      });

    case _actionTypes.LOAD_USERS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        users_loading: false,
        users_loaded: true,
        users: action.result,
        error: null,
        statuses: (0, _helpers.getUniqueList)(action.result, "status")
      });

    case _actionTypes.LOAD_USERS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        users_loading: false,
        users_loaded: false,
        users: null,
        error: action.error
      });

    case _actionTypes.ADDING_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        adding: true,
        modal_data: ADD_MODAL_DATA,
        success: null
      });

    case _actionTypes.ADD_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        working: true
      });

    case _actionTypes.ADD_USER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        working: false,
        error: action.error.message || action.error.error
      });

    case _actionTypes.ADD_USER_SUCCESS:
      users = Object.assign([], state.users);
      newUser = _objectSpread(_objectSpread({}, state.user_form_data), {}, {
        user_id: action.result.id,
        status: "active"
      });
      users.unshift(newUser);
      return _objectSpread(_objectSpread({}, state), {}, {
        error: null,
        adding: false,
        working: false,
        users,
        user_form_data: INITIAL_USER_FORM_DATA,
        success: "User **".concat(state.user_form_data.user_username, "** is added successfully.")
      });

    case _actionTypes.SAVE_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        working: true
      });

    case _actionTypes.SAVE_USER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        working: false,
        error: action.error.message || action.error.error
      });

    case _actionTypes.SAVE_USER_SUCCESS:
      {
        users = [...state.users];
        const userIndex = users.findIndex(user => user.user_id === state.user_form_data.user_id);
        users.splice(userIndex, 1, state.user_form_data);
        return _objectSpread(_objectSpread({}, state), {}, {
          error: null,
          viewing: false,
          editing: false,
          working: false,
          users,
          user_form_data: INITIAL_USER_FORM_DATA,
          success: "Changes to User **".concat(state.user_form_data.user_username, "** is successfully saved.")
        });
      }

    case _actionTypes.VIEWING_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        viewing: true,
        modal_data: VIEW_MODAL_DATA,
        user_form_data: action.row,
        submit_func: _actionCreators.startEditUser,
        // NOTE: why is there a function in the store?
        success: null
      });

    case _actionTypes.EDITING_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        editing: true,
        modal_data: EDIT_MODAL_DATA,
        submit_func: _actionCreators.saveUserRequest // NOTE: why is there a function in the store?

      });

    case _actionTypes.EDITING_USER_FORM:
      userFormData = _objectSpread(_objectSpread({}, state.user_form_data), {}, {
        [action.input_id]: action.input_value
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        user_form_data: userFormData
      });

    case _actionTypes.END_ACTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        editing: false,
        viewing: false,
        adding: false,
        working: false,
        user_form_data: INITIAL_USER_FORM_DATA,
        error: null
      });

    case _actionTypes.VALIDATE_FORM_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error.join("<br>")
      });

    case _actionTypes.TOGGLE_DEACTIVATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        toggleDeactivateWorking: true
      });

    case _actionTypes.TOGGLE_DEACTIVATE_SUCCESS:
      {
        const user = Object.assign({}, state.user_form_data);
        user.status = action.result.status;
        const resultingAction = action.result.status === "deleted" ? "deactivated" : "reactivated";
        const params = {
          list: Object.assign([], state.users),
          new_object: user,
          key: "user_id",
          value: state.user_form_data.user_id
        };
        const updatedUsers = (0, _helpers.updateListWithNewObject)(params);
        return _objectSpread(_objectSpread({}, state), {}, {
          error: null,
          toggleDeactivateWorking: false,
          adding: false,
          viewing: false,
          editing: false,
          users: updatedUsers,
          toggleDeactivateUser: null,
          toggleDeactivateAction: null,
          statuses: (0, _helpers.getUniqueList)(updatedUsers, "status"),
          success: "User **".concat(action.result.username, "** has been **").concat(resultingAction, "** successfully.")
        });
      }

    case _actionTypes.TOGGLE_DEACTIVATE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        toggleDeactivateWorking: false,
        toggleDeactivateUser: null,
        toggleDeactivateAction: null
      });

    default:
      return state;
  }
}