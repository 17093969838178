"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UnassociateButton = exports.PreviewHeader = exports.QuickLink = exports.Row = exports.Content = exports.Inner = exports.Wrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

const Wrapper = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  display: block;\n  position: absolute;\n  left: 0;\n  border-radius: 4px;\n  border: 1px solid ", ";\n  height: 250px;\n  width: 240px;\n  padding-bottom: 50px;\n  z-index: 10;\n  background: ", ";\n  box-shadow: 0 0 10px 0 rgba(55, 64, 71, 0.12),\n    0 2px 3px 0 rgba(55, 64, 71, 0.1);\n  transform: translate(0, calc(-100% + 20px));\n  cursor: default;\n\n  &:before {\n    display: inline-block;\n    content: \"\";\n    position: absolute;\n    width: 0;\n    height: 0;\n    left: 12px;\n    bottom: -12px;\n    box-sizing: border-box;\n    border: 12px solid black;\n    border-color: transparent transparent ", " ", ";\n    transform-origin: 50%;\n    transform: rotate(-45deg);\n    box-shadow: 0 0 10px 0 rgba(55, 64, 71, 0.12),\n      0px 3px 3px 1px rgba(55, 64, 71, 0.1);\n    z-index: 0;\n  }\n"])), _blocks.palette.neutral4, _blocks.color.pureWhite, _blocks.color.pureWhite, _blocks.color.pureWhite);

exports.Wrapper = Wrapper;

const Inner = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background: ", ";\n"])), _blocks.color.pureWhite);

exports.Inner = Inner;

const Content = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  position: relative;\n  padding: ", " 0 40px;\n  height: 200px;\n  overflow-y: auto;\n  background: ", ";\n  z-index: 1;\n"])), _blocks.spacing.snug, _blocks.color.pureWhite);

exports.Content = Content;

const Row = _styledComponents.default.div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  font-size: 12px;\n  line-height: 20px;\n  padding: 0 ", ";\n\n  h3 {\n    margin: 0 0 4px;\n    font-size: 14px;\n    font-weight: 600;\n    line-height: 20px;\n  }\n\n  strong {\n    font-weight: 600;\n  }\n"])), _blocks.spacing.snug);

exports.Row = Row;

const QuickLink = _styledComponents.default.div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  height: 40px;\n  border-top: 1px solid ", ";\n  padding: 0 16px;\n  background: ", ";\n  z-index: 1;\n\n  a {\n    font-size: 13px;\n    line-height: 38px;\n    color: ", ";\n    border-bottom: 1px dotted ", ";\n  }\n"])), _blocks.color.theme.divider.solid, _blocks.color.pureWhite, _blocks.color.theme.primaryColor.action.primary, _blocks.color.theme.primaryColor.action.primary);

exports.QuickLink = QuickLink;

const PreviewHeader = _styledComponents.default.div(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 40px;\n  border-bottom: 1px solid ", ";\n  padding: 0 ", ";\n\n  h3 {\n    display: inline-block;\n    margin: 0;\n    color: ", ";\n    font-size: 12px;\n    font-weight: 600;\n    text-transform: uppercase;\n  }\n"])), _blocks.color.theme.divider.solid, _blocks.spacing.snug, _blocks.palette.neutral7);

exports.PreviewHeader = PreviewHeader;

const UnassociateButton = _styledComponents.default.button.attrs({
  type: "button"
})(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n  && {\n    display: inline-block;\n    position: relative;\n    margin: 0;\n    padding: 0;\n    border: none;\n    outline: 0;\n    font-size: 12px;\n    color: ", ";\n\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n"])), _blocks.color.theme.semantic.destructive.secondary.foreground);

exports.UnassociateButton = UnassociateButton;