"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatTransition = formatTransition;
exports.formatAttachments = formatAttachments;
exports.formatBody = formatBody;

/**
 * TODO: This is copy pasta from a very bad time in our product's life.
 * This should be replaced with a solution closer to the more acceptable side
 * of sanity at the earliest possible opportunity (and the below should be cast into a volcano)
 */

/**
 * Return the transition text parsed from the body
 * @param {string} body
 * @returns {string} Transition text
 */
function formatTransition(body) {
  const regexTransitionText = /(~Transition:.*~)/;
  const transitionMatch = regexTransitionText.exec(body);
  const transitionText = transitionMatch && transitionMatch[1] ? transitionMatch[1] : null;
  if (!transitionText) return null;
  const regexFrom = /.+ from (.+).to.+/g;
  const regexTo = /.+to (.+)../g;
  const fromStatus = regexFrom.exec(transitionText)[1].trim();
  const toStatus = regexTo.exec(transitionText)[1].trim();
  return "Transitioned from \"".concat(fromStatus, "\" to \"").concat(toStatus, "\".");
}
/**
 * Return an array of attachments parsed from the body
 * @param {string} body
 * @returns {Array<string>} Attachments
 */


function formatAttachments(body) {
  const regex = /\[(.*?)\]/g;
  const attachments = [];
  let m;

  while ((m = regex.exec(body)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) regex.lastIndex += 1;
    attachments.push(m[1]);
  }

  return attachments.map(attachment => attachment.split("|"));
}
/**
 * Remove transitions and attachments from the body
 * @param {string} body
 * @returns {string} Body text
 */


function formatBody(body) {
  // Remove transition text
  const transitionRm = /(~Transition:.*~)/; // Remove attachment text

  const regex = /\[(.*?)\]/g;
  const attachmentRm = /----- Attachments -----/g;
  return body.replace(regex, "").replace(transitionRm, "").replace(attachmentRm, "");
}