"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWrap = exports.StyledBackgroundRow = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _Page = require("../Layout/Page");

var _templateObject, _templateObject2;

const StyledBackgroundRow = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  background: #fff;\n  border-bottom: 1px solid ", ";\n"])), _blocks.palette.neutral4);

exports.StyledBackgroundRow = StyledBackgroundRow;
const StyledWrap = (0, _styledComponents.default)(_Page.Wrap)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  padding-top: ", ";\n  padding-bottom: ", ";\n"])), _blocks.spacing.extraLoose, _blocks.spacing.extraLoose);
exports.StyledWrap = StyledWrap;