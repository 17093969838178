"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOGGLE_DEACTIVATE_FAIL = exports.TOGGLE_DEACTIVATE_SUCCESS = exports.TOGGLE_DEACTIVATE = exports.VALIDATE_FORM_ERROR = exports.EDITING_USER_FORM = exports.END_ACTION = exports.EDITING_USER = exports.VIEWING_USER = exports.ADDING_USER = exports.SAVE_USER_FAIL = exports.SAVE_USER_SUCCESS = exports.SAVE_USER = exports.SAVE_USER_REQUEST = exports.ADD_USER_FAIL = exports.ADD_USER_SUCCESS = exports.ADD_USER = exports.ADD_USER_REQUEST = exports.LOAD_USERS_FAIL = exports.LOAD_USERS_SUCCESS = exports.LOAD_USERS = void 0;
// load users
const LOAD_USERS = "bonfire/users/LOAD_USERS";
exports.LOAD_USERS = LOAD_USERS;
const LOAD_USERS_SUCCESS = "bonfire/users/LOAD_USERS_SUCCESS";
exports.LOAD_USERS_SUCCESS = LOAD_USERS_SUCCESS;
const LOAD_USERS_FAIL = "bonfire/users/LOAD_USERS_FAIL"; // ADDING new user

exports.LOAD_USERS_FAIL = LOAD_USERS_FAIL;
const ADD_USER_REQUEST = "bonfire/users/ADD_USER_REQUEST";
exports.ADD_USER_REQUEST = ADD_USER_REQUEST;
const ADD_USER = "bonfire/users/ADD_USER";
exports.ADD_USER = ADD_USER;
const ADD_USER_SUCCESS = "bonfire/users/ADD_USER_SUCCESS";
exports.ADD_USER_SUCCESS = ADD_USER_SUCCESS;
const ADD_USER_FAIL = "bonfire/users/ADD_USER_FAIL"; // save user changes

exports.ADD_USER_FAIL = ADD_USER_FAIL;
const SAVE_USER_REQUEST = "bonfire/users/SAVE_USER_REQUEST";
exports.SAVE_USER_REQUEST = SAVE_USER_REQUEST;
const SAVE_USER = "bonfire/users/SAVE_USER";
exports.SAVE_USER = SAVE_USER;
const SAVE_USER_SUCCESS = "bonfire/users/SAVE_USER_SUCCESS";
exports.SAVE_USER_SUCCESS = SAVE_USER_SUCCESS;
const SAVE_USER_FAIL = "bonfire/users/SAVE_USER_FAIL"; // GUI CONTROL

exports.SAVE_USER_FAIL = SAVE_USER_FAIL;
const ADDING_USER = "bonfire/users/ADDING_USER";
exports.ADDING_USER = ADDING_USER;
const VIEWING_USER = "bonfire/users/VIEWING_USER";
exports.VIEWING_USER = VIEWING_USER;
const EDITING_USER = "bonfire/users/EDITING_USER";
exports.EDITING_USER = EDITING_USER;
const END_ACTION = "bonfire/users/END_ACTION";
exports.END_ACTION = END_ACTION;
const EDITING_USER_FORM = "bonfire/users/EDITING_USER_FORM";
exports.EDITING_USER_FORM = EDITING_USER_FORM;
const VALIDATE_FORM_ERROR = "bonfire/users/VALIDATE_FORM_ERROR"; // Deactivate User

exports.VALIDATE_FORM_ERROR = VALIDATE_FORM_ERROR;
const TOGGLE_DEACTIVATE = "bonfire/users/TOGGLE_DEACTIVATE";
exports.TOGGLE_DEACTIVATE = TOGGLE_DEACTIVATE;
const TOGGLE_DEACTIVATE_SUCCESS = "bonfire/users/TOGGLE_DEACTIVATE_SUCCESS";
exports.TOGGLE_DEACTIVATE_SUCCESS = TOGGLE_DEACTIVATE_SUCCESS;
const TOGGLE_DEACTIVATE_FAIL = "bonfire/users/TOGGLE_DEACTIVATE_FAIL";
exports.TOGGLE_DEACTIVATE_FAIL = TOGGLE_DEACTIVATE_FAIL;