"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Icon = exports.EquipListRow = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2;

const EquipListRow = (0, _styledComponents.default)(_blocks.DataGridRow)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  cursor: default;\n  overflow: visible !important;\n\n  .Row-actions-snoozeIcon,\n  .Row-actions-reportIcon,\n  .Row-actions-actionIcon {\n    display: inline-block;\n    margin-right: ", ";\n  }\n  &:hover {\n    .Row-actions-snoozeIcon,\n    .Row-actions-reportIcon,\n    .Row-actions-actionIcon {\n      opacity: 1;\n    }\n  }\n\n  .Row-actions-reportIcon,\n  .Row-actions-actionIcon {\n    opacity: ", ";\n  }\n\n  .Row-actions-snoozeIcon {\n    opacity: ", ";\n  }\n"])), _blocks.spacing.tight, _ref => {
  let {
    isActive
  } = _ref;
  return isActive ? 1 : 0;
}, _ref2 => {
  let {
    isSnoozed,
    isActive
  } = _ref2;
  return isSnoozed || isActive ? 1 : 0;
});
exports.EquipListRow = EquipListRow;

const Icon = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  display: inline-flex;\n  height: 20px;\n  width: 20px;\n  min-height: 20px;\n  min-width: 20px;\n  fill: ", ";\n"])), _blocks.color.theme.icon.default);

exports.Icon = Icon;