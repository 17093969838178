"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _types = require("./types");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function reducer(state, action) {
  const actionType = action.type;

  switch (action.type) {
    case "LOAD_METRICS":
      return _objectSpread(_objectSpread({}, state), {}, {
        metricsLoading: true
      });

    case "SET_TIME_FRAME":
      return _objectSpread(_objectSpread({}, state), {}, {
        timeFrameOption: action.timeFrameOption,
        startDate: action.startDate,
        endDate: action.endDate,
        metricsLoaded: {
          [_types.MetricsType.Electricity]: false,
          [_types.MetricsType.Gas]: false,
          [_types.MetricsType.Water]: false
        }
      });

    case "SET_SELECTED_SITES":
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedSites: action.selectedSites,
        metricsLoaded: {
          [_types.MetricsType.Electricity]: false,
          [_types.MetricsType.Gas]: false,
          [_types.MetricsType.Water]: false
        }
      });

    case "SET_METRICS_TYPE":
      return _objectSpread(_objectSpread({}, state), {}, {
        metricsType: action.metricsType,
        metricsUnit: _types.MetricsUnit[action.metricsType]
      });

    case "SET_RANKED_SITES_SORTING_DIRECTION":
      return _objectSpread(_objectSpread({}, state), {}, {
        sortingDirection: _objectSpread(_objectSpread({}, state["sortingDirection"]), {}, {
          rankedSites: action.rankedSites
        })
      });

    case "SET_RANKED_YEARS_SORTING_DIRECTION":
      return _objectSpread(_objectSpread({}, state), {}, {
        sortingDirection: _objectSpread(_objectSpread({}, state["sortingDirection"]), {}, {
          rankedYears: action.rankedYears
        })
      });

    case "METRICS_REQUEST_SUCCESS":
      const newState = _objectSpread(_objectSpread({}, state), {}, {
        metricsLoading: false,
        metricsLoadingError: false,
        metricsLoaded: _objectSpread(_objectSpread({}, state.metricsLoaded), {}, {
          [action.metricsType]: true
        }),
        metricsType: action.metricsType,
        metricsUnit: action.metricsUnit,
        metrics: _objectSpread(_objectSpread({}, state.metrics), action.metrics)
      }); // If the response is empty, the existing metrics data for current action must be cleared


      if (Object.keys(action.metrics).length === 0) {
        delete newState.metrics[action.metricsType];
      }

      return newState;

    case "METRICS_REQUEST_FAIL":
      return _objectSpread(_objectSpread({}, state), {}, {
        metricsLoading: false,
        metricsLoadingError: true,
        metricsLoaded: _objectSpread(_objectSpread({}, state.metricsLoaded), {}, {
          [state.metricsType]: false
        })
      });

    default:
      throw new Error("Unhandled action type: ".concat(actionType));
  }
}