"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getConsumption = getConsumption;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _types = require("./types");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * Accumulate consumption into a group
 * @param group group name
 * @param acc the accumulator
 * @param consumption current consumption of utility datum
 * @param topKey top level key of grouping
 * @param downKey down level key of grouping
 */
function accumulate(group, acc, consumption, topKey, downKey) {
  const top = acc[group] && acc[group][topKey];
  const down = top && acc[group][topKey][downKey];
  return _objectSpread(_objectSpread({}, acc[group]), {}, {
    [topKey]: _objectSpread(_objectSpread({}, top), {}, {
      [downKey]: consumption + (down || 0)
    })
  });
}
/**
 * Retrieve accumulated consumption in different groups
 * @param data
 * @param metricsType
 * @param filterBy filter sites by siteIds
 */


function getConsumption(data, metricsType) {
  let readingType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _types.ReadingType.All;
  let filterBy = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  const sites = data[metricsType] ? Object.entries(data[metricsType]) : [];
  return sites.filter(_ref => {
    let [siteId] = _ref;
    return !filterBy.length || filterBy.includes(siteId);
  }).reduce((acc, _ref2) => {
    let [siteId, dates] = _ref2;
    Object.entries(dates).forEach(_ref3 => {
      let [date, datum] = _ref3;
      let [year, month] = date.split("-");
      month = month.replace(/^0+/, ""); // Remove leading zero from month

      const consumption = datum.reduce((sum, item) => {
        const shouldAdd = readingType === _types.ReadingType.All || readingType === item.type;
        return shouldAdd ? sum + item.consumption : sum;
      }, 0); // Accumulating consumption in various groups
      // This has been done to feed different kind of charts easily and quickly

      acc["SiteInMonths"] = accumulate("SiteInMonths", acc, consumption, siteId, month);
      acc["SiteInYears"] = accumulate("SiteInYears", acc, consumption, siteId, year);
      acc["YearInMonths"] = accumulate("YearInMonths", acc, consumption, year, month);
      acc["YearInSites"] = accumulate("YearInSites", acc, consumption, year, siteId);
      acc["MonthInSites"] = accumulate("MonthInSites", acc, consumption, month, siteId);
      acc["MonthInYears"] = accumulate("MonthInYears", acc, consumption, month, year);
      acc["AllMonthInSites"] = accumulate("AllMonthInSites", acc, consumption, date, siteId);
    });
    return acc;
  }, {
    SiteInMonths: {},
    SiteInYears: {},
    YearInMonths: {},
    YearInSites: {},
    MonthInSites: {},
    MonthInYears: {},
    AllMonthInSites: {}
  });
}