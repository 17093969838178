"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _actionTypes = require("./actionTypes");

var routes = _interopRequireWildcard(require("../../../utils/routes"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const INITIAL_HISTORY = {
  search: "",
  pathname: "",
  hash: "",
  key: "",
  path: "",
  state: ""
};
const INITIAL_STATE = {
  insightsHistory: _objectSpread(_objectSpread({}, INITIAL_HISTORY), {}, {
    path: routes.INSIGHTS,
    pathname: routes.INSIGHTS
  }),
  monitoringHistory: _objectSpread(_objectSpread({}, INITIAL_HISTORY), {}, {
    path: routes.MONITORING,
    pathname: routes.MONITORING
  }),
  actionsHistory: _objectSpread(_objectSpread({}, INITIAL_HISTORY), {}, {
    path: routes.ACTIONS,
    pathname: routes.ACTIONS
  })
};

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actionTypes.UPDATE_INSIGHTS_URL_HISTORY:
      return _objectSpread(_objectSpread({}, state), {}, {
        insightsHistory: action.location
      });

    case _actionTypes.UPDATE_MONITORING_URL_HISTORY:
      return _objectSpread(_objectSpread({}, state), {}, {
        monitoringHistory: action.location
      });

    case _actionTypes.UPDATE_ACTIONS_URL_HISTORY:
      return _objectSpread(_objectSpread({}, state), {}, {
        actionsHistory: action.location
      });

    default:
      return state;
  }
}