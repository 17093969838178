"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const data = {
  closed: [{
    total: '1',
    timestamp: '2017-11-08T13:00:00.000Z'
  }, {
    total: '6',
    timestamp: '2017-11-13T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-11-14T13:00:00.000Z'
  }, {
    total: '3',
    timestamp: '2017-12-18T13:00:00.000Z'
  }, {
    total: '6',
    timestamp: '2017-12-19T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-12-31T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-01-01T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-01-09T13:00:00.000Z'
  }, {
    total: '2',
    timestamp: '2018-01-15T13:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-01-16T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-01-17T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-02-10T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-02-13T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-02-19T13:00:00.000Z'
  }, {
    total: '2',
    timestamp: '2018-02-20T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-03-05T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-03-06T13:00:00.000Z'
  }, {
    total: '2',
    timestamp: '2018-03-15T13:00:00.000Z'
  }, {
    total: '2',
    timestamp: '2018-03-20T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-03-21T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-04-16T14:00:00.000Z'
  }, {
    total: '7',
    timestamp: '2018-04-17T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-05-03T14:00:00.000Z'
  }, {
    total: '4',
    timestamp: '2018-05-15T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-05-21T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-06-04T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-06-14T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-06-19T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-07-04T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-07-05T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-07-16T14:00:00.000Z'
  }, {
    total: '5',
    timestamp: '2018-07-17T14:00:00.000Z'
  }, {
    total: '3',
    timestamp: '2018-08-12T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-08-13T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-08-14T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-09-04T14:00:00.000Z'
  }, {
    total: '2',
    timestamp: '2018-09-15T14:00:00.000Z'
  }, {
    total: '3',
    timestamp: '2018-09-16T14:00:00.000Z'
  }, {
    total: '2',
    timestamp: '2018-10-03T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-10-16T13:00:00.000Z'
  }],
  raised: [{
    total: '1',
    timestamp: '2017-06-27T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-07-17T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-08-03T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-08-03T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-08-08T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-08-08T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-08-13T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-08-14T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-08-14T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-08-14T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-08-20T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-08-21T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-09-05T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-09-05T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-09-24T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-10-04T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-10-10T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-10-15T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-10-16T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-10-16T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-11-08T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-11-13T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-12-18T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-12-19T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2017-12-19T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-01-02T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-01-02T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-01-15T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-01-16T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-01-18T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-01-22T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-02-08T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-02-08T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-02-13T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-02-13T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-02-19T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-02-25T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-03-05T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-03-21T13:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-04-04T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-04-10T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-04-11T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-04-11T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-04-17T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-05-14T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-05-17T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-05-28T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-06-04T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-06-05T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-06-05T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-06-06T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-06-13T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-07-04T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-07-17T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-07-17T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-07-17T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-08-12T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-08-12T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-08-12T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-08-14T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-08-19T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-08-21T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-08-22T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-08-29T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-08-30T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-09-13T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-09-27T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-10-03T14:00:00.000Z'
  }, {
    total: '1',
    timestamp: '2018-10-15T13:00:00.000Z'
  }],
  stagnant: '22',
  outstanding: [{
    total: '65',
    timestamp: '2017-10-22T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-10-23T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-10-24T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-10-25T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-10-26T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-10-27T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-10-28T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-10-29T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-10-30T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-10-31T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-11-01T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-11-02T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-11-03T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-11-04T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-11-05T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-11-06T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-11-07T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-11-08T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-11-09T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-11-10T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-11-11T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-11-12T13:00:00.000Z'
  }, {
    total: '65',
    timestamp: '2017-11-13T13:00:00.000Z'
  }, {
    total: '63',
    timestamp: '2017-11-14T13:00:00.000Z'
  }, {
    total: '62',
    timestamp: '2017-11-15T13:00:00.000Z'
  }, {
    total: '62',
    timestamp: '2017-11-16T13:00:00.000Z'
  }, {
    total: '62',
    timestamp: '2017-11-17T13:00:00.000Z'
  }, {
    total: '62',
    timestamp: '2017-11-18T13:00:00.000Z'
  }, {
    total: '62',
    timestamp: '2017-11-19T13:00:00.000Z'
  }, {
    total: '62',
    timestamp: '2017-11-20T13:00:00.000Z'
  }, {
    total: '62',
    timestamp: '2017-11-21T13:00:00.000Z'
  }, {
    total: '62',
    timestamp: '2017-11-22T13:00:00.000Z'
  }, {
    total: '62',
    timestamp: '2017-11-23T13:00:00.000Z'
  }, {
    total: '62',
    timestamp: '2017-11-24T13:00:00.000Z'
  }, {
    total: '62',
    timestamp: '2017-11-25T13:00:00.000Z'
  }, {
    total: '62',
    timestamp: '2017-11-26T13:00:00.000Z'
  }, {
    total: '62',
    timestamp: '2017-11-27T13:00:00.000Z'
  }, {
    total: '62',
    timestamp: '2017-11-28T13:00:00.000Z'
  }, {
    total: '62',
    timestamp: '2017-11-29T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-11-30T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-01T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-02T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-03T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-04T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-05T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-06T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-07T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-08T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-09T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-10T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-11T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-12T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-13T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-14T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-15T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-16T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-17T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2017-12-18T13:00:00.000Z'
  }, {
    total: '59',
    timestamp: '2017-12-19T13:00:00.000Z'
  }, {
    total: '60',
    timestamp: '2017-12-20T13:00:00.000Z'
  }, {
    total: '60',
    timestamp: '2017-12-21T13:00:00.000Z'
  }, {
    total: '60',
    timestamp: '2017-12-22T13:00:00.000Z'
  }, {
    total: '60',
    timestamp: '2017-12-23T13:00:00.000Z'
  }, {
    total: '60',
    timestamp: '2017-12-24T13:00:00.000Z'
  }, {
    total: '60',
    timestamp: '2017-12-25T13:00:00.000Z'
  }, {
    total: '60',
    timestamp: '2017-12-26T13:00:00.000Z'
  }, {
    total: '60',
    timestamp: '2017-12-27T13:00:00.000Z'
  }, {
    total: '60',
    timestamp: '2017-12-28T13:00:00.000Z'
  }, {
    total: '60',
    timestamp: '2017-12-29T13:00:00.000Z'
  }, {
    total: '60',
    timestamp: '2017-12-30T13:00:00.000Z'
  }, {
    total: '60',
    timestamp: '2017-12-31T13:00:00.000Z'
  }, {
    total: '59',
    timestamp: '2018-01-01T13:00:00.000Z'
  }, {
    total: '59',
    timestamp: '2018-01-02T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2018-01-03T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2018-01-04T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2018-01-05T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2018-01-06T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2018-01-07T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2018-01-08T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2018-01-09T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2018-01-10T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2018-01-11T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2018-01-12T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2018-01-13T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2018-01-14T13:00:00.000Z'
  }, {
    total: '61',
    timestamp: '2018-01-15T13:00:00.000Z'
  }, {
    total: '60',
    timestamp: '2018-01-16T13:00:00.000Z'
  }, {
    total: '50',
    timestamp: '2018-01-17T13:00:00.000Z'
  }, {
    total: '49',
    timestamp: '2018-01-18T13:00:00.000Z'
  }, {
    total: '50',
    timestamp: '2018-01-19T13:00:00.000Z'
  }, {
    total: '50',
    timestamp: '2018-01-20T13:00:00.000Z'
  }, {
    total: '50',
    timestamp: '2018-01-21T13:00:00.000Z'
  }, {
    total: '50',
    timestamp: '2018-01-22T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-01-23T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-01-24T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-01-25T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-01-26T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-01-27T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-01-28T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-01-29T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-01-30T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-01-31T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-02-01T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-02-02T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-02-03T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-02-04T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-02-05T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-02-06T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-02-07T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-02-08T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-02-09T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-02-10T13:00:00.000Z'
  }, {
    total: '52',
    timestamp: '2018-02-11T13:00:00.000Z'
  }, {
    total: '52',
    timestamp: '2018-02-12T13:00:00.000Z'
  }, {
    total: '52',
    timestamp: '2018-02-13T13:00:00.000Z'
  }, {
    total: '54',
    timestamp: '2018-02-14T13:00:00.000Z'
  }, {
    total: '54',
    timestamp: '2018-02-15T13:00:00.000Z'
  }, {
    total: '54',
    timestamp: '2018-02-16T13:00:00.000Z'
  }, {
    total: '54',
    timestamp: '2018-02-17T13:00:00.000Z'
  }, {
    total: '54',
    timestamp: '2018-02-18T13:00:00.000Z'
  }, {
    total: '54',
    timestamp: '2018-02-19T13:00:00.000Z'
  }, {
    total: '54',
    timestamp: '2018-02-20T13:00:00.000Z'
  }, {
    total: '52',
    timestamp: '2018-02-21T13:00:00.000Z'
  }, {
    total: '52',
    timestamp: '2018-02-22T13:00:00.000Z'
  }, {
    total: '52',
    timestamp: '2018-02-23T13:00:00.000Z'
  }, {
    total: '52',
    timestamp: '2018-02-24T13:00:00.000Z'
  }, {
    total: '52',
    timestamp: '2018-02-25T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-02-26T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-02-27T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-02-28T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-03-01T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-03-02T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-03-03T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-03-04T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-03-05T13:00:00.000Z'
  }, {
    total: '54',
    timestamp: '2018-03-06T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-03-07T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-03-08T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-03-09T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-03-10T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-03-11T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-03-12T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-03-13T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-03-14T13:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-03-15T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-03-16T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-03-17T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-03-18T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-03-19T13:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-03-20T13:00:00.000Z'
  }, {
    total: '49',
    timestamp: '2018-03-21T13:00:00.000Z'
  }, {
    total: '49',
    timestamp: '2018-03-22T13:00:00.000Z'
  }, {
    total: '49',
    timestamp: '2018-03-23T13:00:00.000Z'
  }, {
    total: '49',
    timestamp: '2018-03-24T13:00:00.000Z'
  }, {
    total: '49',
    timestamp: '2018-03-25T13:00:00.000Z'
  }, {
    total: '49',
    timestamp: '2018-03-26T13:00:00.000Z'
  }, {
    total: '49',
    timestamp: '2018-03-27T13:00:00.000Z'
  }, {
    total: '49',
    timestamp: '2018-03-28T13:00:00.000Z'
  }, {
    total: '49',
    timestamp: '2018-03-29T13:00:00.000Z'
  }, {
    total: '49',
    timestamp: '2018-03-30T13:00:00.000Z'
  }, {
    total: '49',
    timestamp: '2018-03-31T13:00:00.000Z'
  }, {
    total: '49',
    timestamp: '2018-04-01T14:00:00.000Z'
  }, {
    total: '49',
    timestamp: '2018-04-02T14:00:00.000Z'
  }, {
    total: '49',
    timestamp: '2018-04-03T14:00:00.000Z'
  }, {
    total: '49',
    timestamp: '2018-04-04T14:00:00.000Z'
  }, {
    total: '50',
    timestamp: '2018-04-05T14:00:00.000Z'
  }, {
    total: '50',
    timestamp: '2018-04-06T14:00:00.000Z'
  }, {
    total: '50',
    timestamp: '2018-04-07T14:00:00.000Z'
  }, {
    total: '50',
    timestamp: '2018-04-08T14:00:00.000Z'
  }, {
    total: '50',
    timestamp: '2018-04-09T14:00:00.000Z'
  }, {
    total: '50',
    timestamp: '2018-04-10T14:00:00.000Z'
  }, {
    total: '51',
    timestamp: '2018-04-11T14:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-04-12T14:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-04-13T14:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-04-14T14:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-04-15T14:00:00.000Z'
  }, {
    total: '53',
    timestamp: '2018-04-16T14:00:00.000Z'
  }, {
    total: '52',
    timestamp: '2018-04-17T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-04-18T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-04-19T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-04-20T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-04-21T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-04-22T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-04-23T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-04-24T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-04-25T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-04-26T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-04-27T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-04-28T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-04-29T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-04-30T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-05-01T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-05-02T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-05-03T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-05-04T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-05-05T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-05-06T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-05-07T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-05-08T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-05-09T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-05-10T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-05-11T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-05-12T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-05-13T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-05-14T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-05-15T14:00:00.000Z'
  }, {
    total: '42',
    timestamp: '2018-05-16T14:00:00.000Z'
  }, {
    total: '42',
    timestamp: '2018-05-17T14:00:00.000Z'
  }, {
    total: '43',
    timestamp: '2018-05-18T14:00:00.000Z'
  }, {
    total: '43',
    timestamp: '2018-05-19T14:00:00.000Z'
  }, {
    total: '43',
    timestamp: '2018-05-20T14:00:00.000Z'
  }, {
    total: '43',
    timestamp: '2018-05-21T14:00:00.000Z'
  }, {
    total: '42',
    timestamp: '2018-05-22T14:00:00.000Z'
  }, {
    total: '42',
    timestamp: '2018-05-23T14:00:00.000Z'
  }, {
    total: '42',
    timestamp: '2018-05-24T14:00:00.000Z'
  }, {
    total: '42',
    timestamp: '2018-05-25T14:00:00.000Z'
  }, {
    total: '42',
    timestamp: '2018-05-26T14:00:00.000Z'
  }, {
    total: '42',
    timestamp: '2018-05-27T14:00:00.000Z'
  }, {
    total: '42',
    timestamp: '2018-05-28T14:00:00.000Z'
  }, {
    total: '43',
    timestamp: '2018-05-29T14:00:00.000Z'
  }, {
    total: '43',
    timestamp: '2018-05-30T14:00:00.000Z'
  }, {
    total: '43',
    timestamp: '2018-05-31T14:00:00.000Z'
  }, {
    total: '43',
    timestamp: '2018-06-01T14:00:00.000Z'
  }, {
    total: '43',
    timestamp: '2018-06-02T14:00:00.000Z'
  }, {
    total: '43',
    timestamp: '2018-06-03T14:00:00.000Z'
  }, {
    total: '43',
    timestamp: '2018-06-04T14:00:00.000Z'
  }, {
    total: '43',
    timestamp: '2018-06-05T14:00:00.000Z'
  }, {
    total: '44',
    timestamp: '2018-06-06T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-07T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-08T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-09T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-10T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-11T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-12T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-13T14:00:00.000Z'
  }, {
    total: '46',
    timestamp: '2018-06-14T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-15T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-16T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-17T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-18T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-19T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-20T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-21T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-22T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-23T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-24T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-25T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-26T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-27T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-28T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-29T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-06-30T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-07-01T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-07-02T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-07-03T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-07-04T14:00:00.000Z'
  }, {
    total: '45',
    timestamp: '2018-07-05T14:00:00.000Z'
  }, {
    total: '44',
    timestamp: '2018-07-06T14:00:00.000Z'
  }, {
    total: '44',
    timestamp: '2018-07-07T14:00:00.000Z'
  }, {
    total: '44',
    timestamp: '2018-07-08T14:00:00.000Z'
  }, {
    total: '44',
    timestamp: '2018-07-09T14:00:00.000Z'
  }, {
    total: '44',
    timestamp: '2018-07-10T14:00:00.000Z'
  }, {
    total: '44',
    timestamp: '2018-07-11T14:00:00.000Z'
  }, {
    total: '44',
    timestamp: '2018-07-12T14:00:00.000Z'
  }, {
    total: '44',
    timestamp: '2018-07-13T14:00:00.000Z'
  }, {
    total: '44',
    timestamp: '2018-07-14T14:00:00.000Z'
  }, {
    total: '44',
    timestamp: '2018-07-15T14:00:00.000Z'
  }, {
    total: '44',
    timestamp: '2018-07-16T14:00:00.000Z'
  }, {
    total: '43',
    timestamp: '2018-07-17T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-07-18T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-07-19T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-07-20T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-07-21T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-07-22T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-07-23T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-07-24T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-07-25T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-07-26T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-07-27T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-07-28T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-07-29T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-07-30T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-07-31T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-08-01T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-08-02T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-08-03T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-08-04T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-08-05T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-08-06T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-08-07T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-08-08T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-08-09T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-08-10T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-08-11T14:00:00.000Z'
  }, {
    total: '41',
    timestamp: '2018-08-12T14:00:00.000Z'
  }, {
    total: '43',
    timestamp: '2018-08-13T14:00:00.000Z'
  }, {
    total: '42',
    timestamp: '2018-08-14T14:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-08-15T14:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-08-16T14:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-08-17T14:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-08-18T14:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-08-19T14:00:00.000Z'
  }, {
    total: '36',
    timestamp: '2018-08-20T14:00:00.000Z'
  }, {
    total: '36',
    timestamp: '2018-08-21T14:00:00.000Z'
  }, {
    total: '37',
    timestamp: '2018-08-22T14:00:00.000Z'
  }, {
    total: '38',
    timestamp: '2018-08-23T14:00:00.000Z'
  }, {
    total: '38',
    timestamp: '2018-08-24T14:00:00.000Z'
  }, {
    total: '38',
    timestamp: '2018-08-25T14:00:00.000Z'
  }, {
    total: '38',
    timestamp: '2018-08-26T14:00:00.000Z'
  }, {
    total: '38',
    timestamp: '2018-08-27T14:00:00.000Z'
  }, {
    total: '38',
    timestamp: '2018-08-28T14:00:00.000Z'
  }, {
    total: '38',
    timestamp: '2018-08-29T14:00:00.000Z'
  }, {
    total: '39',
    timestamp: '2018-08-30T14:00:00.000Z'
  }, {
    total: '40',
    timestamp: '2018-08-31T14:00:00.000Z'
  }, {
    total: '40',
    timestamp: '2018-09-01T14:00:00.000Z'
  }, {
    total: '40',
    timestamp: '2018-09-02T14:00:00.000Z'
  }, {
    total: '40',
    timestamp: '2018-09-03T14:00:00.000Z'
  }, {
    total: '40',
    timestamp: '2018-09-04T14:00:00.000Z'
  }, {
    total: '39',
    timestamp: '2018-09-05T14:00:00.000Z'
  }, {
    total: '39',
    timestamp: '2018-09-06T14:00:00.000Z'
  }, {
    total: '39',
    timestamp: '2018-09-07T14:00:00.000Z'
  }, {
    total: '39',
    timestamp: '2018-09-08T14:00:00.000Z'
  }, {
    total: '39',
    timestamp: '2018-09-09T14:00:00.000Z'
  }, {
    total: '39',
    timestamp: '2018-09-10T14:00:00.000Z'
  }, {
    total: '39',
    timestamp: '2018-09-11T14:00:00.000Z'
  }, {
    total: '39',
    timestamp: '2018-09-12T14:00:00.000Z'
  }, {
    total: '39',
    timestamp: '2018-09-13T14:00:00.000Z'
  }, {
    total: '40',
    timestamp: '2018-09-14T14:00:00.000Z'
  }, {
    total: '40',
    timestamp: '2018-09-15T14:00:00.000Z'
  }, {
    total: '38',
    timestamp: '2018-09-16T14:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-09-17T14:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-09-18T14:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-09-19T14:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-09-20T14:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-09-21T14:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-09-22T14:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-09-23T14:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-09-24T14:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-09-25T14:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-09-26T14:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-09-27T14:00:00.000Z'
  }, {
    total: '36',
    timestamp: '2018-09-28T14:00:00.000Z'
  }, {
    total: '36',
    timestamp: '2018-09-29T14:00:00.000Z'
  }, {
    total: '36',
    timestamp: '2018-09-30T14:00:00.000Z'
  }, {
    total: '36',
    timestamp: '2018-10-01T14:00:00.000Z'
  }, {
    total: '36',
    timestamp: '2018-10-02T14:00:00.000Z'
  }, {
    total: '36',
    timestamp: '2018-10-03T14:00:00.000Z'
  }, {
    total: '37',
    timestamp: '2018-10-04T14:00:00.000Z'
  }, {
    total: '37',
    timestamp: '2018-10-05T14:00:00.000Z'
  }, {
    total: '37',
    timestamp: '2018-10-06T14:00:00.000Z'
  }, {
    total: '37',
    timestamp: '2018-10-07T13:00:00.000Z'
  }, {
    total: '37',
    timestamp: '2018-10-08T13:00:00.000Z'
  }, {
    total: '37',
    timestamp: '2018-10-09T13:00:00.000Z'
  }, {
    total: '37',
    timestamp: '2018-10-10T13:00:00.000Z'
  }, {
    total: '37',
    timestamp: '2018-10-11T13:00:00.000Z'
  }, {
    total: '37',
    timestamp: '2018-10-12T13:00:00.000Z'
  }, {
    total: '37',
    timestamp: '2018-10-13T13:00:00.000Z'
  }, {
    total: '37',
    timestamp: '2018-10-14T13:00:00.000Z'
  }, {
    total: '37',
    timestamp: '2018-10-15T13:00:00.000Z'
  }, {
    total: '38',
    timestamp: '2018-10-16T13:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-10-17T13:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-10-18T13:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-10-19T13:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-10-20T13:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-10-21T13:00:00.000Z'
  }, {
    total: '35',
    timestamp: '2018-10-22T13:00:00.000Z'
  }],
  pending: '0',
  open: [{
    total: '26',
    timestamp: '2017-10-22T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-10-23T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-10-24T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-10-25T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-10-26T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-10-27T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-10-28T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-10-29T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-10-30T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-10-31T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-11-01T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-11-02T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-11-03T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-11-04T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-11-05T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-11-06T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-11-07T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-11-08T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-11-09T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-11-10T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-11-11T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-11-12T13:00:00.000Z'
  }, {
    total: '26',
    timestamp: '2017-11-13T13:00:00.000Z'
  }, {
    total: '24',
    timestamp: '2017-11-14T13:00:00.000Z'
  }, {
    total: '23',
    timestamp: '2017-11-15T13:00:00.000Z'
  }, {
    total: '23',
    timestamp: '2017-11-16T13:00:00.000Z'
  }, {
    total: '23',
    timestamp: '2017-11-17T13:00:00.000Z'
  }, {
    total: '23',
    timestamp: '2017-11-18T13:00:00.000Z'
  }, {
    total: '23',
    timestamp: '2017-11-19T13:00:00.000Z'
  }, {
    total: '23',
    timestamp: '2017-11-20T13:00:00.000Z'
  }, {
    total: '23',
    timestamp: '2017-11-21T13:00:00.000Z'
  }, {
    total: '23',
    timestamp: '2017-11-22T13:00:00.000Z'
  }, {
    total: '23',
    timestamp: '2017-11-23T13:00:00.000Z'
  }, {
    total: '23',
    timestamp: '2017-11-24T13:00:00.000Z'
  }, {
    total: '23',
    timestamp: '2017-11-25T13:00:00.000Z'
  }, {
    total: '23',
    timestamp: '2017-11-26T13:00:00.000Z'
  }, {
    total: '23',
    timestamp: '2017-11-27T13:00:00.000Z'
  }, {
    total: '23',
    timestamp: '2017-11-28T13:00:00.000Z'
  }, {
    total: '23',
    timestamp: '2017-11-29T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-11-30T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-01T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-02T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-03T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-04T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-05T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-06T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-07T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-08T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-09T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-10T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-11T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-12T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-13T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-14T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-15T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-16T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-17T13:00:00.000Z'
  }, {
    total: '22',
    timestamp: '2017-12-18T13:00:00.000Z'
  }, {
    total: '20',
    timestamp: '2017-12-19T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2017-12-20T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2017-12-21T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2017-12-22T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2017-12-23T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2017-12-24T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2017-12-25T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2017-12-26T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2017-12-27T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2017-12-28T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2017-12-29T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2017-12-30T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2017-12-31T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2018-01-01T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2018-01-02T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2018-01-03T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2018-01-04T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2018-01-05T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2018-01-06T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2018-01-07T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2018-01-08T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2018-01-09T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2018-01-10T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2018-01-11T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2018-01-12T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2018-01-13T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2018-01-14T13:00:00.000Z'
  }, {
    total: '19',
    timestamp: '2018-01-15T13:00:00.000Z'
  }, {
    total: '18',
    timestamp: '2018-01-16T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-01-17T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-01-18T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-01-19T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-01-20T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-01-21T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-01-22T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-01-23T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-01-24T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-01-25T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-01-26T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-01-27T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-01-28T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-01-29T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-01-30T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-01-31T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-01T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-02T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-03T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-04T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-05T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-06T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-07T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-08T13:00:00.000Z'
  }, {
    total: '14',
    timestamp: '2018-02-09T13:00:00.000Z'
  }, {
    total: '14',
    timestamp: '2018-02-10T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-11T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-12T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-13T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-14T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-15T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-16T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-17T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-18T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-19T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-02-20T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-02-21T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-02-22T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-02-23T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-02-24T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-02-25T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-26T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-27T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-02-28T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-01T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-02T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-03T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-04T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-05T13:00:00.000Z'
  }, {
    total: '14',
    timestamp: '2018-03-06T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-07T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-08T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-09T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-10T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-11T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-12T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-13T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-14T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-15T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-16T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-17T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-18T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-19T13:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-03-20T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-03-21T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-03-22T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-03-23T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-03-24T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-03-25T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-03-26T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-03-27T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-03-28T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-03-29T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-03-30T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-03-31T13:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-01T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-02T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-03T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-04T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-05T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-06T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-07T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-08T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-09T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-10T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-11T14:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-04-12T14:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-04-13T14:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-04-14T14:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-04-15T14:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-04-16T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-17T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-18T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-19T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-20T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-21T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-22T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-23T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-24T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-25T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-26T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-27T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-28T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-29T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-04-30T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-01T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-02T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-03T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-04T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-05T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-06T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-07T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-08T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-09T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-10T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-11T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-12T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-13T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-14T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-15T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-05-16T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-05-17T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-18T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-19T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-20T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-05-21T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-05-22T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-05-23T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-05-24T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-05-25T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-05-26T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-05-27T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-05-28T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-05-29T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-05-30T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-05-31T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-06-01T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-06-02T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-06-03T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-06-04T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-06-05T14:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-06-06T14:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-06-07T14:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-06-08T14:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-06-09T14:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-06-10T14:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-06-11T14:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-06-12T14:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-06-13T14:00:00.000Z'
  }, {
    total: '13',
    timestamp: '2018-06-14T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-06-15T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-06-16T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-06-17T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-06-18T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-06-19T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-06-20T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-06-21T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-06-22T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-06-23T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-06-24T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-06-25T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-06-26T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-06-27T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-06-28T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-06-29T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-06-30T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-07-01T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-07-02T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-07-03T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-07-04T14:00:00.000Z'
  }, {
    total: '12',
    timestamp: '2018-07-05T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-07-06T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-07-07T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-07-08T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-07-09T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-07-10T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-07-11T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-07-12T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-07-13T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-07-14T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-07-15T14:00:00.000Z'
  }, {
    total: '11',
    timestamp: '2018-07-16T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-07-17T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-07-18T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-07-19T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-07-20T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-07-21T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-07-22T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-07-23T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-07-24T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-07-25T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-07-26T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-07-27T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-07-28T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-07-29T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-07-30T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-07-31T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-08-01T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-08-02T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-08-03T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-08-04T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-08-05T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-08-06T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-08-07T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-08-08T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-08-09T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-08-10T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-08-11T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-08-12T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-08-13T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-08-14T14:00:00.000Z'
  }, {
    total: '7',
    timestamp: '2018-08-15T14:00:00.000Z'
  }, {
    total: '7',
    timestamp: '2018-08-16T14:00:00.000Z'
  }, {
    total: '7',
    timestamp: '2018-08-17T14:00:00.000Z'
  }, {
    total: '7',
    timestamp: '2018-08-18T14:00:00.000Z'
  }, {
    total: '7',
    timestamp: '2018-08-19T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-08-20T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-08-21T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-08-22T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-08-23T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-08-24T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-08-25T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-08-26T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-08-27T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-08-28T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-08-29T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-08-30T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-08-31T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-09-01T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-09-02T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-09-03T14:00:00.000Z'
  }, {
    total: '10',
    timestamp: '2018-09-04T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-09-05T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-09-06T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-09-07T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-09-08T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-09-09T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-09-10T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-09-11T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-09-12T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-09-13T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-09-14T14:00:00.000Z'
  }, {
    total: '9',
    timestamp: '2018-09-15T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-09-16T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-09-17T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-09-18T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-09-19T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-09-20T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-09-21T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-09-22T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-09-23T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-09-24T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-09-25T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-09-26T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-09-27T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-09-28T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-09-29T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-09-30T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-10-01T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-10-02T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-10-03T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-10-04T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-10-05T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-10-06T14:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-10-07T13:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-10-08T13:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-10-09T13:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-10-10T13:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-10-11T13:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-10-12T13:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-10-13T13:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-10-14T13:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-10-15T13:00:00.000Z'
  }, {
    total: '8',
    timestamp: '2018-10-16T13:00:00.000Z'
  }, {
    total: '7',
    timestamp: '2018-10-17T13:00:00.000Z'
  }, {
    total: '7',
    timestamp: '2018-10-18T13:00:00.000Z'
  }, {
    total: '7',
    timestamp: '2018-10-19T13:00:00.000Z'
  }, {
    total: '7',
    timestamp: '2018-10-20T13:00:00.000Z'
  }, {
    total: '7',
    timestamp: '2018-10-21T13:00:00.000Z'
  }, {
    total: '7',
    timestamp: '2018-10-22T13:00:00.000Z'
  }],
  activeAge: {
    delta: [{
      age: '366',
      timestamp: '2018-04-04T14:00:00.000Z'
    }, {
      age: '314',
      timestamp: '2018-08-23T14:00:00.000Z'
    }, {
      age: '349',
      timestamp: '2018-03-19T13:00:00.000Z'
    }, {
      age: '388',
      timestamp: '2018-06-12T14:00:00.000Z'
    }, {
      age: '399',
      timestamp: '2018-06-05T14:00:00.000Z'
    }, {
      age: '385',
      timestamp: '2018-05-13T14:00:00.000Z'
    }, {
      age: '342',
      timestamp: '2018-02-23T13:00:00.000Z'
    }, {
      age: '299',
      timestamp: '2017-11-17T13:00:00.000Z'
    }, {
      age: '334',
      timestamp: '2017-12-25T13:00:00.000Z'
    }, {
      age: '284',
      timestamp: '2017-11-01T13:00:00.000Z'
    }, {
      age: '380',
      timestamp: '2018-08-14T14:00:00.000Z'
    }, {
      age: '341',
      timestamp: '2018-03-01T13:00:00.000Z'
    }, {
      age: '399',
      timestamp: '2018-06-23T14:00:00.000Z'
    }, {
      age: '300',
      timestamp: '2017-11-18T13:00:00.000Z'
    }, {
      age: '355',
      timestamp: '2018-02-08T13:00:00.000Z'
    }, {
      age: '274',
      timestamp: '2018-10-18T13:00:00.000Z'
    }, {
      age: '341',
      timestamp: '2018-02-22T13:00:00.000Z'
    }, {
      age: '302',
      timestamp: '2017-11-20T13:00:00.000Z'
    }, {
      age: '400',
      timestamp: '2018-06-02T14:00:00.000Z'
    }, {
      age: '389',
      timestamp: '2018-05-21T14:00:00.000Z'
    }, {
      age: '412',
      timestamp: '2018-07-30T14:00:00.000Z'
    }, {
      age: '404',
      timestamp: '2018-07-07T14:00:00.000Z'
    }, {
      age: '394',
      timestamp: '2018-06-18T14:00:00.000Z'
    }, {
      age: '279',
      timestamp: '2018-10-05T14:00:00.000Z'
    }, {
      age: '422',
      timestamp: '2018-08-09T14:00:00.000Z'
    }, {
      age: '352',
      timestamp: '2018-02-05T13:00:00.000Z'
    }, {
      age: '320',
      timestamp: '2018-08-29T14:00:00.000Z'
    }, {
      age: '330',
      timestamp: '2017-12-21T13:00:00.000Z'
    }, {
      age: '346',
      timestamp: '2018-04-12T14:00:00.000Z'
    }, {
      age: '404',
      timestamp: '2018-07-22T14:00:00.000Z'
    }, {
      age: '282',
      timestamp: '2018-10-16T13:00:00.000Z'
    }, {
      age: '276',
      timestamp: '2018-10-20T13:00:00.000Z'
    }, {
      age: '336',
      timestamp: '2017-12-27T13:00:00.000Z'
    }, {
      age: '399',
      timestamp: '2018-05-23T14:00:00.000Z'
    }, {
      age: '309',
      timestamp: '2017-11-27T13:00:00.000Z'
    }, {
      age: '373',
      timestamp: '2018-05-03T14:00:00.000Z'
    }, {
      age: '402',
      timestamp: '2018-06-26T14:00:00.000Z'
    }, {
      age: '404',
      timestamp: '2018-05-28T14:00:00.000Z'
    }, {
      age: '273',
      timestamp: '2018-10-17T13:00:00.000Z'
    }, {
      age: '381',
      timestamp: '2018-05-09T14:00:00.000Z'
    }, {
      age: '409',
      timestamp: '2018-07-03T14:00:00.000Z'
    }, {
      age: '389',
      timestamp: '2018-06-13T14:00:00.000Z'
    }, {
      age: '280',
      timestamp: '2018-10-06T14:00:00.000Z'
    }, {
      age: '340',
      timestamp: '2018-01-17T13:00:00.000Z'
    }, {
      age: '423',
      timestamp: '2018-08-10T14:00:00.000Z'
    }, {
      age: '348',
      timestamp: '2018-04-14T14:00:00.000Z'
    }, {
      age: '351',
      timestamp: '2018-02-04T13:00:00.000Z'
    }, {
      age: '276',
      timestamp: '2018-09-19T14:00:00.000Z'
    }, {
      age: '394',
      timestamp: '2018-05-17T14:00:00.000Z'
    }, {
      age: '401',
      timestamp: '2018-07-19T14:00:00.000Z'
    }, {
      age: '322',
      timestamp: '2018-08-22T14:00:00.000Z'
    }, {
      age: '412',
      timestamp: '2018-07-15T14:00:00.000Z'
    }, {
      age: '333',
      timestamp: '2018-08-15T14:00:00.000Z'
    }, {
      age: '336',
      timestamp: '2018-08-18T14:00:00.000Z'
    }, {
      age: '400',
      timestamp: '2018-07-18T14:00:00.000Z'
    }, {
      age: '361',
      timestamp: '2018-03-30T13:00:00.000Z'
    }, {
      age: '419',
      timestamp: '2018-08-06T14:00:00.000Z'
    }, {
      age: '364',
      timestamp: '2018-04-09T14:00:00.000Z'
    }, {
      age: '281',
      timestamp: '2017-10-29T13:00:00.000Z'
    }, {
      age: '300',
      timestamp: '2018-09-05T14:00:00.000Z'
    }, {
      age: '379',
      timestamp: '2018-05-15T14:00:00.000Z'
    }, {
      age: '319',
      timestamp: '2018-08-28T14:00:00.000Z'
    }, {
      age: '386',
      timestamp: '2018-05-18T14:00:00.000Z'
    }, {
      age: '321',
      timestamp: '2017-12-05T13:00:00.000Z'
    }, {
      age: '403',
      timestamp: '2018-06-27T14:00:00.000Z'
    }, {
      age: '384',
      timestamp: '2018-06-08T14:00:00.000Z'
    }, {
      age: '327',
      timestamp: '2017-12-11T13:00:00.000Z'
    }, {
      age: '355',
      timestamp: '2018-03-24T13:00:00.000Z'
    }, {
      age: '293',
      timestamp: '2017-11-10T13:00:00.000Z'
    }, {
      age: '284',
      timestamp: '2018-10-10T13:00:00.000Z'
    }, {
      age: '406',
      timestamp: '2018-07-09T14:00:00.000Z'
    }, {
      age: '282',
      timestamp: '2018-10-02T14:00:00.000Z'
    }, {
      age: '382',
      timestamp: '2018-05-10T14:00:00.000Z'
    }, {
      age: '323',
      timestamp: '2017-12-07T13:00:00.000Z'
    }, {
      age: '300',
      timestamp: '2018-09-16T14:00:00.000Z'
    }, {
      age: '339',
      timestamp: '2018-01-05T13:00:00.000Z'
    }, {
      age: '280',
      timestamp: '2018-09-23T14:00:00.000Z'
    }, {
      age: '402',
      timestamp: '2018-05-26T14:00:00.000Z'
    }, {
      age: '279',
      timestamp: '2017-10-27T13:00:00.000Z'
    }, {
      age: '396',
      timestamp: '2018-05-29T14:00:00.000Z'
    }, {
      age: '361',
      timestamp: '2018-04-21T14:00:00.000Z'
    }, {
      age: '288',
      timestamp: '2018-10-14T13:00:00.000Z'
    }, {
      age: '363',
      timestamp: '2018-04-01T14:00:00.000Z'
    }, {
      age: '333',
      timestamp: '2017-12-17T13:00:00.000Z'
    }, {
      age: '285',
      timestamp: '2017-11-02T13:00:00.000Z'
    }, {
      age: '331',
      timestamp: '2017-12-15T13:00:00.000Z'
    }, {
      age: '311',
      timestamp: '2017-11-29T13:00:00.000Z'
    }, {
      age: '352',
      timestamp: '2018-03-12T13:00:00.000Z'
    }, {
      age: '343',
      timestamp: '2018-01-27T13:00:00.000Z'
    }, {
      age: '328',
      timestamp: '2017-12-12T13:00:00.000Z'
    }, {
      age: '341',
      timestamp: '2018-01-25T13:00:00.000Z'
    }, {
      age: '405',
      timestamp: '2018-06-29T14:00:00.000Z'
    }, {
      age: '337',
      timestamp: '2017-12-28T13:00:00.000Z'
    }, {
      age: '278',
      timestamp: '2018-09-28T14:00:00.000Z'
    }, {
      age: '365',
      timestamp: '2018-04-03T14:00:00.000Z'
    }, {
      age: '418',
      timestamp: '2018-08-05T14:00:00.000Z'
    }, {
      age: '294',
      timestamp: '2017-11-11T13:00:00.000Z'
    }, {
      age: '350',
      timestamp: '2018-03-10T13:00:00.000Z'
    }, {
      age: '398',
      timestamp: '2018-05-31T14:00:00.000Z'
    }, {
      age: '358',
      timestamp: '2018-04-17T14:00:00.000Z'
    }, {
      age: '286',
      timestamp: '2017-11-03T13:00:00.000Z'
    }, {
      age: '287',
      timestamp: '2018-10-13T13:00:00.000Z'
    }, {
      age: '284',
      timestamp: '2018-09-27T14:00:00.000Z'
    }, {
      age: '277',
      timestamp: '2018-10-21T13:00:00.000Z'
    }, {
      age: '347',
      timestamp: '2018-02-18T13:00:00.000Z'
    }, {
      age: '343',
      timestamp: '2018-02-24T13:00:00.000Z'
    }, {
      age: '366',
      timestamp: '2018-04-26T14:00:00.000Z'
    }, {
      age: '307',
      timestamp: '2018-09-01T14:00:00.000Z'
    }, {
      age: '329',
      timestamp: '2017-12-20T13:00:00.000Z'
    }, {
      age: '302',
      timestamp: '2018-09-07T14:00:00.000Z'
    }, {
      age: '403',
      timestamp: '2018-05-27T14:00:00.000Z'
    }, {
      age: '401',
      timestamp: '2018-06-03T14:00:00.000Z'
    }, {
      age: '407',
      timestamp: '2018-07-25T14:00:00.000Z'
    }, {
      age: '338',
      timestamp: '2017-12-29T13:00:00.000Z'
    }, {
      age: '353',
      timestamp: '2018-03-22T13:00:00.000Z'
    }, {
      age: '415',
      timestamp: '2018-08-02T14:00:00.000Z'
    }, {
      age: '358',
      timestamp: '2018-04-18T14:00:00.000Z'
    }, {
      age: '344',
      timestamp: '2018-01-21T13:00:00.000Z'
    }, {
      age: '317',
      timestamp: '2017-12-01T13:00:00.000Z'
    }, {
      age: '283',
      timestamp: '2018-10-09T13:00:00.000Z'
    }, {
      age: '410',
      timestamp: '2018-07-04T14:00:00.000Z'
    }, {
      age: '340',
      timestamp: '2017-12-31T13:00:00.000Z'
    }, {
      age: '343',
      timestamp: '2018-01-09T13:00:00.000Z'
    }, {
      age: '347',
      timestamp: '2018-01-31T13:00:00.000Z'
    }, {
      age: '275',
      timestamp: '2018-10-19T13:00:00.000Z'
    }, {
      age: '408',
      timestamp: '2018-07-11T14:00:00.000Z'
    }, {
      age: '279',
      timestamp: '2018-09-22T14:00:00.000Z'
    }, {
      age: '292',
      timestamp: '2017-11-09T13:00:00.000Z'
    }, {
      age: '363',
      timestamp: '2018-04-23T14:00:00.000Z'
    }, {
      age: '411',
      timestamp: '2018-07-29T14:00:00.000Z'
    }, {
      age: '303',
      timestamp: '2018-09-15T14:00:00.000Z'
    }, {
      age: '359',
      timestamp: '2018-04-19T14:00:00.000Z'
    }, {
      age: '344',
      timestamp: '2018-03-04T13:00:00.000Z'
    }, {
      age: '413',
      timestamp: '2018-07-31T14:00:00.000Z'
    }, {
      age: '304',
      timestamp: '2018-09-09T14:00:00.000Z'
    }, {
      age: '339',
      timestamp: '2017-12-30T13:00:00.000Z'
    }, {
      age: '403',
      timestamp: '2018-07-21T14:00:00.000Z'
    }, {
      age: '341',
      timestamp: '2018-01-07T13:00:00.000Z'
    }, {
      age: '288',
      timestamp: '2017-11-05T13:00:00.000Z'
    }, {
      age: '354',
      timestamp: '2018-03-23T13:00:00.000Z'
    }, {
      age: '275',
      timestamp: '2018-09-18T14:00:00.000Z'
    }, {
      age: '289',
      timestamp: '2018-10-15T13:00:00.000Z'
    }, {
      age: '274',
      timestamp: '2018-09-17T14:00:00.000Z'
    }, {
      age: '408',
      timestamp: '2018-07-26T14:00:00.000Z'
    }, {
      age: '361',
      timestamp: '2018-04-06T14:00:00.000Z'
    }, {
      age: '355',
      timestamp: '2018-03-15T13:00:00.000Z'
    }, {
      age: '332',
      timestamp: '2017-12-23T13:00:00.000Z'
    }, {
      age: '353',
      timestamp: '2018-03-13T13:00:00.000Z'
    }, {
      age: '313',
      timestamp: '2018-08-30T14:00:00.000Z'
    }, {
      age: '298',
      timestamp: '2017-11-16T13:00:00.000Z'
    }, {
      age: '347',
      timestamp: '2018-03-07T13:00:00.000Z'
    }, {
      age: '340',
      timestamp: '2018-02-21T13:00:00.000Z'
    }, {
      age: '397',
      timestamp: '2018-05-30T14:00:00.000Z'
    }, {
      age: '338',
      timestamp: '2018-01-04T13:00:00.000Z'
    }, {
      age: '332',
      timestamp: '2017-12-16T13:00:00.000Z'
    }, {
      age: '420',
      timestamp: '2018-08-07T14:00:00.000Z'
    }, {
      age: '343',
      timestamp: '2018-02-09T13:00:00.000Z'
    }, {
      age: '401',
      timestamp: '2018-06-25T14:00:00.000Z'
    }, {
      age: '318',
      timestamp: '2017-12-02T13:00:00.000Z'
    }, {
      age: '351',
      timestamp: '2018-03-11T13:00:00.000Z'
    }, {
      age: '365',
      timestamp: '2018-04-25T14:00:00.000Z'
    }, {
      age: '279',
      timestamp: '2018-09-29T14:00:00.000Z'
    }, {
      age: '346',
      timestamp: '2018-01-30T13:00:00.000Z'
    }, {
      age: '348',
      timestamp: '2018-01-14T13:00:00.000Z'
    }, {
      age: '344',
      timestamp: '2018-01-28T13:00:00.000Z'
    }, {
      age: '362',
      timestamp: '2018-03-31T13:00:00.000Z'
    }, {
      age: '345',
      timestamp: '2018-01-11T13:00:00.000Z'
    }, {
      age: '350',
      timestamp: '2018-04-16T14:00:00.000Z'
    }, {
      age: '348',
      timestamp: '2018-03-08T13:00:00.000Z'
    }, {
      age: '362',
      timestamp: '2018-04-07T14:00:00.000Z'
    }, {
      age: '278',
      timestamp: '2018-10-04T14:00:00.000Z'
    }, {
      age: '346',
      timestamp: '2018-03-16T13:00:00.000Z'
    }, {
      age: '282',
      timestamp: '2018-09-25T14:00:00.000Z'
    }, {
      age: '343',
      timestamp: '2018-02-14T13:00:00.000Z'
    }, {
      age: '395',
      timestamp: '2018-06-19T14:00:00.000Z'
    }, {
      age: '357',
      timestamp: '2018-03-26T13:00:00.000Z'
    }, {
      age: '281',
      timestamp: '2018-09-24T14:00:00.000Z'
    }, {
      age: '387',
      timestamp: '2018-05-19T14:00:00.000Z'
    }, {
      age: '357',
      timestamp: '2018-03-21T13:00:00.000Z'
    }, {
      age: '337',
      timestamp: '2018-08-19T14:00:00.000Z'
    }, {
      age: '389',
      timestamp: '2018-08-13T14:00:00.000Z'
    }, {
      age: '406',
      timestamp: '2018-06-30T14:00:00.000Z'
    }, {
      age: '404',
      timestamp: '2018-06-28T14:00:00.000Z'
    }, {
      age: '340',
      timestamp: '2018-01-06T13:00:00.000Z'
    }, {
      age: '334',
      timestamp: '2018-08-16T14:00:00.000Z'
    }, {
      age: '353',
      timestamp: '2018-02-06T13:00:00.000Z'
    }, {
      age: '342',
      timestamp: '2018-02-20T13:00:00.000Z'
    }, {
      age: '345',
      timestamp: '2018-03-05T13:00:00.000Z'
    }, {
      age: '349',
      timestamp: '2018-02-02T13:00:00.000Z'
    }, {
      age: '367',
      timestamp: '2018-04-27T14:00:00.000Z'
    }, {
      age: '301',
      timestamp: '2018-09-06T14:00:00.000Z'
    }, {
      age: '310',
      timestamp: '2018-09-04T14:00:00.000Z'
    }, {
      age: '297',
      timestamp: '2017-11-14T13:00:00.000Z'
    }, {
      age: '305',
      timestamp: '2018-09-10T14:00:00.000Z'
    }, {
      age: '329',
      timestamp: '2018-08-20T14:00:00.000Z'
    }, {
      age: '325',
      timestamp: '2017-12-09T13:00:00.000Z'
    }, {
      age: '377',
      timestamp: '2018-05-05T14:00:00.000Z'
    }, {
      age: '306',
      timestamp: '2018-08-31T14:00:00.000Z'
    }, {
      age: '346',
      timestamp: '2018-02-17T13:00:00.000Z'
    }, {
      age: '283',
      timestamp: '2017-10-31T13:00:00.000Z'
    }, {
      age: '348',
      timestamp: '2018-02-01T13:00:00.000Z'
    }, {
      age: '385',
      timestamp: '2018-06-09T14:00:00.000Z'
    }, {
      age: '281',
      timestamp: '2018-10-01T14:00:00.000Z'
    }, {
      age: '421',
      timestamp: '2018-08-08T14:00:00.000Z'
    }, {
      age: '364',
      timestamp: '2018-04-02T14:00:00.000Z'
    }, {
      age: '342',
      timestamp: '2018-01-08T13:00:00.000Z'
    }, {
      age: '303',
      timestamp: '2018-09-08T14:00:00.000Z'
    }, {
      age: '378',
      timestamp: '2018-05-06T14:00:00.000Z'
    }, {
      age: '297',
      timestamp: '2017-11-15T13:00:00.000Z'
    }, {
      age: '358',
      timestamp: '2018-03-27T13:00:00.000Z'
    }, {
      age: '383',
      timestamp: '2018-05-11T14:00:00.000Z'
    }, {
      age: '281',
      timestamp: '2018-10-07T13:00:00.000Z'
    }, {
      age: '392',
      timestamp: '2018-06-16T14:00:00.000Z'
    }, {
      age: '354',
      timestamp: '2018-02-07T13:00:00.000Z'
    }, {
      age: '280',
      timestamp: '2018-09-30T14:00:00.000Z'
    }, {
      age: '398',
      timestamp: '2018-06-22T14:00:00.000Z'
    }, {
      age: '304',
      timestamp: '2017-11-22T13:00:00.000Z'
    }, {
      age: '275',
      timestamp: '2017-10-23T13:00:00.000Z'
    }, {
      age: '316',
      timestamp: '2018-08-25T14:00:00.000Z'
    }, {
      age: '287',
      timestamp: '2017-11-04T13:00:00.000Z'
    }, {
      age: '349',
      timestamp: '2018-03-09T13:00:00.000Z'
    }, {
      age: '360',
      timestamp: '2018-04-20T14:00:00.000Z'
    }, {
      age: '335',
      timestamp: '2017-12-26T13:00:00.000Z'
    }, {
      age: '278',
      timestamp: '2018-10-22T13:00:00.000Z'
    }, {
      age: '295',
      timestamp: '2017-11-12T13:00:00.000Z'
    }, {
      age: '278',
      timestamp: '2017-10-26T13:00:00.000Z'
    }, {
      age: '329',
      timestamp: '2017-12-13T13:00:00.000Z'
    }, {
      age: '334',
      timestamp: '2017-12-18T13:00:00.000Z'
    }, {
      age: '384',
      timestamp: '2018-05-12T14:00:00.000Z'
    }, {
      age: '326',
      timestamp: '2017-12-10T13:00:00.000Z'
    }, {
      age: '400',
      timestamp: '2018-06-24T14:00:00.000Z'
    }, {
      age: '359',
      timestamp: '2018-03-28T13:00:00.000Z'
    }, {
      age: '306',
      timestamp: '2017-11-24T13:00:00.000Z'
    }, {
      age: '407',
      timestamp: '2018-07-10T14:00:00.000Z'
    }, {
      age: '308',
      timestamp: '2017-11-26T13:00:00.000Z'
    }, {
      age: '347',
      timestamp: '2018-03-17T13:00:00.000Z'
    }, {
      age: '407',
      timestamp: '2018-07-01T14:00:00.000Z'
    }, {
      age: '330',
      timestamp: '2017-12-14T13:00:00.000Z'
    }, {
      age: '383',
      timestamp: '2018-06-07T14:00:00.000Z'
    }, {
      age: '380',
      timestamp: '2018-05-08T14:00:00.000Z'
    }, {
      age: '338',
      timestamp: '2018-02-26T13:00:00.000Z'
    }, {
      age: '319',
      timestamp: '2017-12-03T13:00:00.000Z'
    }, {
      age: '282',
      timestamp: '2017-10-30T13:00:00.000Z'
    }, {
      age: '408',
      timestamp: '2018-07-02T14:00:00.000Z'
    }, {
      age: '354',
      timestamp: '2018-02-13T13:00:00.000Z'
    }, {
      age: '339',
      timestamp: '2018-01-23T13:00:00.000Z'
    }, {
      age: '286',
      timestamp: '2018-10-12T13:00:00.000Z'
    }, {
      age: '350',
      timestamp: '2018-02-03T13:00:00.000Z'
    }, {
      age: '410',
      timestamp: '2018-07-13T14:00:00.000Z'
    }, {
      age: '370',
      timestamp: '2018-04-30T14:00:00.000Z'
    }, {
      age: '333',
      timestamp: '2017-12-24T13:00:00.000Z'
    }, {
      age: '290',
      timestamp: '2017-11-07T13:00:00.000Z'
    }, {
      age: '352',
      timestamp: '2018-02-11T13:00:00.000Z'
    }, {
      age: '372',
      timestamp: '2018-05-02T14:00:00.000Z'
    }, {
      age: '410',
      timestamp: '2018-07-28T14:00:00.000Z'
    }, {
      age: '398',
      timestamp: '2018-05-22T14:00:00.000Z'
    }, {
      age: '349',
      timestamp: '2018-04-15T14:00:00.000Z'
    }, {
      age: '364',
      timestamp: '2018-04-24T14:00:00.000Z'
    }, {
      age: '339',
      timestamp: '2018-02-27T13:00:00.000Z'
    }, {
      age: '324',
      timestamp: '2017-12-08T13:00:00.000Z'
    }, {
      age: '340',
      timestamp: '2018-03-06T13:00:00.000Z'
    }, {
      age: '376',
      timestamp: '2018-05-04T14:00:00.000Z'
    }, {
      age: '344',
      timestamp: '2018-01-10T13:00:00.000Z'
    }, {
      age: '363',
      timestamp: '2018-04-08T14:00:00.000Z'
    }, {
      age: '282',
      timestamp: '2018-10-08T13:00:00.000Z'
    }, {
      age: '301',
      timestamp: '2017-11-19T13:00:00.000Z'
    }, {
      age: '277',
      timestamp: '2017-10-25T13:00:00.000Z'
    }, {
      age: '322',
      timestamp: '2017-12-06T13:00:00.000Z'
    }, {
      age: '342',
      timestamp: '2018-01-26T13:00:00.000Z'
    }, {
      age: '347',
      timestamp: '2018-01-13T13:00:00.000Z'
    }, {
      age: '307',
      timestamp: '2017-11-25T13:00:00.000Z'
    }, {
      age: '348',
      timestamp: '2018-01-18T13:00:00.000Z'
    }, {
      age: '402',
      timestamp: '2018-06-04T14:00:00.000Z'
    }, {
      age: '318',
      timestamp: '2018-08-27T14:00:00.000Z'
    }, {
      age: '413',
      timestamp: '2018-07-16T14:00:00.000Z'
    }, {
      age: '371',
      timestamp: '2018-05-01T14:00:00.000Z'
    }, {
      age: '400',
      timestamp: '2018-05-24T14:00:00.000Z'
    }, {
      age: '353',
      timestamp: '2018-02-12T13:00:00.000Z'
    }, {
      age: '406',
      timestamp: '2018-07-24T14:00:00.000Z'
    }, {
      age: '345',
      timestamp: '2018-02-16T13:00:00.000Z'
    }, {
      age: '274',
      timestamp: '2017-10-22T13:00:00.000Z'
    }, {
      age: '344',
      timestamp: '2018-02-10T13:00:00.000Z'
    }, {
      age: '356',
      timestamp: '2018-03-25T13:00:00.000Z'
    }, {
      age: '354',
      timestamp: '2018-03-14T13:00:00.000Z'
    }, {
      age: '340',
      timestamp: '2018-02-28T13:00:00.000Z'
    }, {
      age: '405',
      timestamp: '2018-07-08T14:00:00.000Z'
    }, {
      age: '405',
      timestamp: '2018-07-23T14:00:00.000Z'
    }, {
      age: '350',
      timestamp: '2018-03-20T13:00:00.000Z'
    }, {
      age: '347',
      timestamp: '2018-01-01T13:00:00.000Z'
    }, {
      age: '316',
      timestamp: '2017-11-30T13:00:00.000Z'
    }, {
      age: '411',
      timestamp: '2018-07-14T14:00:00.000Z'
    }, {
      age: '386',
      timestamp: '2018-06-10T14:00:00.000Z'
    }, {
      age: '391',
      timestamp: '2018-06-06T14:00:00.000Z'
    }, {
      age: '335',
      timestamp: '2018-08-17T14:00:00.000Z'
    }, {
      age: '337',
      timestamp: '2018-01-03T13:00:00.000Z'
    }, {
      age: '424',
      timestamp: '2018-08-11T14:00:00.000Z'
    }, {
      age: '349',
      timestamp: '2018-01-15T13:00:00.000Z'
    }, {
      age: '289',
      timestamp: '2017-11-06T13:00:00.000Z'
    }, {
      age: '382',
      timestamp: '2018-06-14T14:00:00.000Z'
    }, {
      age: '344',
      timestamp: '2018-02-25T13:00:00.000Z'
    }, {
      age: '391',
      timestamp: '2018-06-15T14:00:00.000Z'
    }, {
      age: '345',
      timestamp: '2018-01-22T13:00:00.000Z'
    }, {
      age: '425',
      timestamp: '2018-08-12T14:00:00.000Z'
    }, {
      age: '393',
      timestamp: '2018-06-17T14:00:00.000Z'
    }, {
      age: '362',
      timestamp: '2018-04-22T14:00:00.000Z'
    }, {
      age: '417',
      timestamp: '2018-08-04T14:00:00.000Z'
    }, {
      age: '344',
      timestamp: '2018-02-15T13:00:00.000Z'
    }, {
      age: '348',
      timestamp: '2018-03-18T13:00:00.000Z'
    }, {
      age: '343',
      timestamp: '2018-03-03T13:00:00.000Z'
    }, {
      age: '285',
      timestamp: '2018-10-11T13:00:00.000Z'
    }, {
      age: '368',
      timestamp: '2018-04-28T14:00:00.000Z'
    }, {
      age: '342',
      timestamp: '2018-03-02T13:00:00.000Z'
    }, {
      age: '393',
      timestamp: '2018-05-16T14:00:00.000Z'
    }, {
      age: '296',
      timestamp: '2017-11-13T13:00:00.000Z'
    }, {
      age: '308',
      timestamp: '2018-09-13T14:00:00.000Z'
    }, {
      age: '305',
      timestamp: '2017-11-23T13:00:00.000Z'
    }, {
      age: '360',
      timestamp: '2018-03-29T13:00:00.000Z'
    }, {
      age: '283',
      timestamp: '2018-10-03T14:00:00.000Z'
    }, {
      age: '348',
      timestamp: '2018-01-16T13:00:00.000Z'
    }, {
      age: '379',
      timestamp: '2018-05-07T14:00:00.000Z'
    }, {
      age: '409',
      timestamp: '2018-07-12T14:00:00.000Z'
    }, {
      age: '347',
      timestamp: '2018-04-13T14:00:00.000Z'
    }, {
      age: '348',
      timestamp: '2018-02-19T13:00:00.000Z'
    }, {
      age: '369',
      timestamp: '2018-04-29T14:00:00.000Z'
    }, {
      age: '307',
      timestamp: '2018-09-12T14:00:00.000Z'
    }, {
      age: '309',
      timestamp: '2018-09-03T14:00:00.000Z'
    }, {
      age: '388',
      timestamp: '2018-05-20T14:00:00.000Z'
    }, {
      age: '386',
      timestamp: '2018-05-14T14:00:00.000Z'
    }, {
      age: '330',
      timestamp: '2018-08-21T14:00:00.000Z'
    }, {
      age: '276',
      timestamp: '2017-10-24T13:00:00.000Z'
    }, {
      age: '346',
      timestamp: '2018-01-12T13:00:00.000Z'
    }, {
      age: '416',
      timestamp: '2018-08-03T14:00:00.000Z'
    }, {
      age: '340',
      timestamp: '2017-12-19T13:00:00.000Z'
    }, {
      age: '365',
      timestamp: '2018-04-10T14:00:00.000Z'
    }, {
      age: '342',
      timestamp: '2018-01-19T13:00:00.000Z'
    }, {
      age: '401',
      timestamp: '2018-05-25T14:00:00.000Z'
    }, {
      age: '406',
      timestamp: '2018-07-17T14:00:00.000Z'
    }, {
      age: '397',
      timestamp: '2018-06-21T14:00:00.000Z'
    }, {
      age: '340',
      timestamp: '2018-01-24T13:00:00.000Z'
    }, {
      age: '403',
      timestamp: '2018-07-06T14:00:00.000Z'
    }, {
      age: '359',
      timestamp: '2018-04-11T14:00:00.000Z'
    }, {
      age: '306',
      timestamp: '2018-09-11T14:00:00.000Z'
    }, {
      age: '402',
      timestamp: '2018-07-20T14:00:00.000Z'
    }, {
      age: '317',
      timestamp: '2018-08-26T14:00:00.000Z'
    }, {
      age: '315',
      timestamp: '2018-08-24T14:00:00.000Z'
    }, {
      age: '409',
      timestamp: '2018-07-27T14:00:00.000Z'
    }, {
      age: '277',
      timestamp: '2018-09-20T14:00:00.000Z'
    }, {
      age: '278',
      timestamp: '2018-09-21T14:00:00.000Z'
    }, {
      age: '387',
      timestamp: '2018-06-11T14:00:00.000Z'
    }, {
      age: '414',
      timestamp: '2018-08-01T14:00:00.000Z'
    }, {
      age: '343',
      timestamp: '2018-01-20T13:00:00.000Z'
    }, {
      age: '302',
      timestamp: '2018-09-14T14:00:00.000Z'
    }, {
      age: '360',
      timestamp: '2018-04-05T14:00:00.000Z'
    }, {
      age: '393',
      timestamp: '2018-07-05T14:00:00.000Z'
    }, {
      age: '348',
      timestamp: '2018-01-02T13:00:00.000Z'
    }, {
      age: '331',
      timestamp: '2017-12-22T13:00:00.000Z'
    }, {
      age: '345',
      timestamp: '2018-01-29T13:00:00.000Z'
    }, {
      age: '291',
      timestamp: '2017-11-08T13:00:00.000Z'
    }, {
      age: '308',
      timestamp: '2018-09-02T14:00:00.000Z'
    }, {
      age: '399',
      timestamp: '2018-06-01T14:00:00.000Z'
    }, {
      age: '396',
      timestamp: '2018-06-20T14:00:00.000Z'
    }, {
      age: '310',
      timestamp: '2017-11-28T13:00:00.000Z'
    }, {
      age: '280',
      timestamp: '2017-10-28T13:00:00.000Z'
    }, {
      age: '303',
      timestamp: '2017-11-21T13:00:00.000Z'
    }, {
      age: '283',
      timestamp: '2018-09-26T14:00:00.000Z'
    }, {
      age: '320',
      timestamp: '2017-12-04T13:00:00.000Z'
    }],
    averageBueno: '334'
  },
  closeAge: {
    delta: [{
      age: '146',
      timestamp: '2018-05-08T14:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-04-29T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-10-27T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-26T13:00:00.000Z'
    }, {
      age: '105',
      timestamp: '2018-01-13T13:00:00.000Z'
    }, {
      age: '190',
      timestamp: '2018-09-28T14:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-03-21T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-28T13:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-03-30T13:00:00.000Z'
    }, {
      age: '130',
      timestamp: '2018-03-07T13:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-08-25T14:00:00.000Z'
    }, {
      age: '158',
      timestamp: '2018-08-13T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-10-26T13:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-08-01T14:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-06-22T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2018-01-05T13:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-03-27T13:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-07-28T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2018-01-16T13:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-08-27T14:00:00.000Z'
    }, {
      age: '149',
      timestamp: '2018-07-16T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-11-05T13:00:00.000Z'
    }, {
      age: '132',
      timestamp: '2018-02-28T13:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-01-27T13:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-08-28T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-10-24T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-11-15T13:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-08-12T14:00:00.000Z'
    }, {
      age: '190',
      timestamp: '2018-09-23T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-11-19T13:00:00.000Z'
    }, {
      age: '192',
      timestamp: '2018-10-05T14:00:00.000Z'
    }, {
      age: '149',
      timestamp: '2018-07-11T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-14T13:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-08-20T14:00:00.000Z'
    }, {
      age: '105',
      timestamp: '2017-11-10T13:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-05-27T14:00:00.000Z'
    }, {
      age: '190',
      timestamp: '2018-09-25T14:00:00.000Z'
    }, {
      age: '149',
      timestamp: '2018-07-07T14:00:00.000Z'
    }, {
      age: '162',
      timestamp: '2018-08-14T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-11-24T13:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-03-23T13:00:00.000Z'
    }, {
      age: '182',
      timestamp: '2018-09-14T14:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-04-04T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-22T13:00:00.000Z'
    }, {
      age: '195',
      timestamp: '2018-10-21T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-27T13:00:00.000Z'
    }, {
      age: '149',
      timestamp: '2018-07-10T14:00:00.000Z'
    }, {
      age: '137',
      timestamp: '2018-03-20T13:00:00.000Z'
    }, {
      age: '192',
      timestamp: '2018-10-15T13:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-01-30T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-11-27T13:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2018-01-03T13:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-07-01T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-09T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-24T13:00:00.000Z'
    }, {
      age: '126',
      timestamp: '2018-02-18T13:00:00.000Z'
    }, {
      age: '105',
      timestamp: '2017-11-12T13:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-04-02T14:00:00.000Z'
    }, {
      age: '192',
      timestamp: '2018-10-07T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-11-17T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-11-20T13:00:00.000Z'
    }, {
      age: '150',
      timestamp: '2018-07-05T14:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-06-30T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-11-16T13:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-01-17T13:00:00.000Z'
    }, {
      age: '132',
      timestamp: '2018-02-23T13:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-06-27T14:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-05-30T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-11-26T13:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-08-29T14:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-04-20T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-06T13:00:00.000Z'
    }, {
      age: '105',
      timestamp: '2017-11-11T13:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-04-26T14:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-08-26T14:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-05-15T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2018-01-08T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-15T13:00:00.000Z'
    }, {
      age: '107',
      timestamp: '2018-01-01T13:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-04-19T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-13T13:00:00.000Z'
    }, {
      age: '132',
      timestamp: '2018-02-21T13:00:00.000Z'
    }, {
      age: '126',
      timestamp: '2018-02-19T13:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-08-18T14:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-06-09T14:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-08-03T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-25T13:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-06-16T14:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-08-08T14:00:00.000Z'
    }, {
      age: '109',
      timestamp: '2017-12-19T13:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-06-20T14:00:00.000Z'
    }, {
      age: '190',
      timestamp: '2018-09-18T14:00:00.000Z'
    }, {
      age: '130',
      timestamp: '2018-03-10T13:00:00.000Z'
    }, {
      age: '130',
      timestamp: '2018-03-14T13:00:00.000Z'
    }, {
      age: '105',
      timestamp: '2018-01-15T13:00:00.000Z'
    }, {
      age: '195',
      timestamp: '2018-10-18T13:00:00.000Z'
    }, {
      age: '184',
      timestamp: '2018-09-16T14:00:00.000Z'
    }, {
      age: '132',
      timestamp: '2018-03-05T13:00:00.000Z'
    }, {
      age: '149',
      timestamp: '2018-07-06T14:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-02-04T13:00:00.000Z'
    }, {
      age: '190',
      timestamp: '2018-09-29T14:00:00.000Z'
    }, {
      age: '137',
      timestamp: '2018-03-16T13:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-07-29T14:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-08-09T14:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-05-23T14:00:00.000Z'
    }, {
      age: '137',
      timestamp: '2018-03-18T13:00:00.000Z'
    }, {
      age: '130',
      timestamp: '2018-03-11T13:00:00.000Z'
    }, {
      age: '182',
      timestamp: '2018-09-11T14:00:00.000Z'
    }, {
      age: '190',
      timestamp: '2018-09-26T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-10-23T13:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-08-21T14:00:00.000Z'
    }, {
      age: '137',
      timestamp: '2018-03-19T13:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-02-03T13:00:00.000Z'
    }, {
      age: '192',
      timestamp: '2018-10-08T13:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-09-02T14:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-02-01T13:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-07-22T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-10-28T13:00:00.000Z'
    }, {
      age: '130',
      timestamp: '2018-03-08T13:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-08-17T14:00:00.000Z'
    }, {
      age: '126',
      timestamp: '2018-02-16T13:00:00.000Z'
    }, {
      age: '149',
      timestamp: '2018-07-09T14:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-04-15T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2018-01-07T13:00:00.000Z'
    }, {
      age: '182',
      timestamp: '2018-09-09T14:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-09-01T14:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-05-14T14:00:00.000Z'
    }, {
      age: '149',
      timestamp: '2018-05-19T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-11-30T13:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-07-18T14:00:00.000Z'
    }, {
      age: '127',
      timestamp: '2018-02-11T13:00:00.000Z'
    }, {
      age: '190',
      timestamp: '2018-09-19T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-11-07T13:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-07-27T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-10-31T13:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-01-21T13:00:00.000Z'
    }, {
      age: '195',
      timestamp: '2018-10-19T13:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-04-17T14:00:00.000Z'
    }, {
      age: '192',
      timestamp: '2018-10-11T13:00:00.000Z'
    }, {
      age: '190',
      timestamp: '2018-09-20T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-10-25T13:00:00.000Z'
    }, {
      age: '182',
      timestamp: '2018-09-07T14:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-03-28T13:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-04-21T14:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-04-10T14:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-08-07T14:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-06-19T14:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-08-16T14:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-08-19T14:00:00.000Z'
    }, {
      age: '192',
      timestamp: '2018-10-14T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-11-22T13:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-06-29T14:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-04-01T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-10-29T13:00:00.000Z'
    }, {
      age: '192',
      timestamp: '2018-10-06T14:00:00.000Z'
    }, {
      age: '190',
      timestamp: '2018-09-30T14:00:00.000Z'
    }, {
      age: '192',
      timestamp: '2018-10-09T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-12T13:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-05-22T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-31T13:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-04-25T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-16T13:00:00.000Z'
    }, {
      age: '130',
      timestamp: '2018-03-13T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-11-23T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-23T13:00:00.000Z'
    }, {
      age: '105',
      timestamp: '2017-11-13T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-11-25T13:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-03-24T13:00:00.000Z'
    }, {
      age: '105',
      timestamp: '2017-11-09T13:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-08-15T14:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-04-07T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2018-01-09T13:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-04-22T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2018-01-06T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-18T13:00:00.000Z'
    }, {
      age: '190',
      timestamp: '2018-10-01T14:00:00.000Z'
    }, {
      age: '127',
      timestamp: '2018-02-13T13:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-06-05T14:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-05-25T14:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-07-20T14:00:00.000Z'
    }, {
      age: '132',
      timestamp: '2018-02-25T13:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-01-28T13:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-06-02T14:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-03-31T13:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-07-31T14:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-06-13T14:00:00.000Z'
    }, {
      age: '195',
      timestamp: '2018-10-17T13:00:00.000Z'
    }, {
      age: '182',
      timestamp: '2018-09-08T14:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-06-25T14:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-07-04T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-11-06T13:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-01-18T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-07T13:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-06-07T14:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-09-04T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-11-02T13:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-11-08T13:00:00.000Z'
    }, {
      age: '127',
      timestamp: '2018-02-20T13:00:00.000Z'
    }, {
      age: '182',
      timestamp: '2018-09-13T14:00:00.000Z'
    }, {
      age: '190',
      timestamp: '2018-10-03T14:00:00.000Z'
    }, {
      age: '105',
      timestamp: '2018-01-14T13:00:00.000Z'
    }, {
      age: '130',
      timestamp: '2018-03-12T13:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-05-04T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-21T13:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-06-15T14:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-02-05T13:00:00.000Z'
    }, {
      age: '132',
      timestamp: '2018-02-22T13:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-04-18T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-11-18T13:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-04-23T14:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-02-08T13:00:00.000Z'
    }, {
      age: '105',
      timestamp: '2018-01-10T13:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-06-01T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-17T13:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-03-22T13:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-10-30T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-03T13:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-08-24T14:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-07-19T14:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-02-06T13:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-04-24T14:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-06-17T14:00:00.000Z'
    }, {
      age: '182',
      timestamp: '2018-09-05T14:00:00.000Z'
    }, {
      age: '137',
      timestamp: '2018-03-17T13:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-04-03T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-30T13:00:00.000Z'
    }, {
      age: '149',
      timestamp: '2018-07-13T14:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-06-18T14:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-04-09T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-11-04T13:00:00.000Z'
    }, {
      age: '192',
      timestamp: '2018-10-12T13:00:00.000Z'
    }, {
      age: '190',
      timestamp: '2018-09-24T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-10-22T13:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-04-13T14:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-03-25T13:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-08-11T14:00:00.000Z'
    }, {
      age: '132',
      timestamp: '2018-03-01T13:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-07-25T14:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-05-28T14:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-01-19T13:00:00.000Z'
    }, {
      age: '126',
      timestamp: '2018-02-14T13:00:00.000Z'
    }, {
      age: '149',
      timestamp: '2018-05-20T14:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-01-23T13:00:00.000Z'
    }, {
      age: '190',
      timestamp: '2018-09-27T14:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-02-10T13:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-06-28T14:00:00.000Z'
    }, {
      age: '182',
      timestamp: '2018-09-15T14:00:00.000Z'
    }, {
      age: '149',
      timestamp: '2018-05-18T14:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-04-30T14:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-02-02T13:00:00.000Z'
    }, {
      age: '149',
      timestamp: '2018-05-16T14:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-07-24T14:00:00.000Z'
    }, {
      age: '130',
      timestamp: '2018-03-09T13:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-04-28T14:00:00.000Z'
    }, {
      age: '190',
      timestamp: '2018-10-02T14:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-07-23T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2018-01-04T13:00:00.000Z'
    }, {
      age: '149',
      timestamp: '2018-07-14T14:00:00.000Z'
    }, {
      age: '149',
      timestamp: '2018-07-15T14:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-06-11T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-29T13:00:00.000Z'
    }, {
      age: '105',
      timestamp: '2018-01-11T13:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-02-09T13:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-04-12T14:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-05-26T14:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-04-27T14:00:00.000Z'
    }, {
      age: '131',
      timestamp: '2018-03-06T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-01T13:00:00.000Z'
    }, {
      age: '105',
      timestamp: '2018-01-12T13:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-04-06T14:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-06-24T14:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-07-26T14:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-04-16T14:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-05-03T14:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-05-05T14:00:00.000Z'
    }, {
      age: '149',
      timestamp: '2018-05-17T14:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-06-14T14:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-08-06T14:00:00.000Z'
    }, {
      age: '132',
      timestamp: '2018-03-03T13:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-08-30T14:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-08-22T14:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-08-02T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-05T13:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-05-29T14:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-11-03T13:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-06-21T14:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-05-10T14:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-09-03T14:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-04-08T14:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-04-11T14:00:00.000Z'
    }, {
      age: '126',
      timestamp: '2018-02-15T13:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-08-31T14:00:00.000Z'
    }, {
      age: '130',
      timestamp: '2018-03-15T13:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-01-20T13:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-03-26T13:00:00.000Z'
    }, {
      age: '149',
      timestamp: '2018-05-21T14:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-05-07T14:00:00.000Z'
    }, {
      age: '149',
      timestamp: '2018-07-08T14:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-08-10T14:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-07-03T14:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-06-03T14:00:00.000Z'
    }, {
      age: '182',
      timestamp: '2018-09-10T14:00:00.000Z'
    }, {
      age: '149',
      timestamp: '2018-07-12T14:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-06-08T14:00:00.000Z'
    }, {
      age: '178',
      timestamp: '2018-08-23T14:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-01-25T13:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-05-12T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-11T13:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-11-14T13:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-06-26T14:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-05-11T14:00:00.000Z'
    }, {
      age: '182',
      timestamp: '2018-09-06T14:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-01-29T13:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2017-11-01T13:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-06-23T14:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-02-07T13:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-01-24T13:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-08-05T14:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-01-26T13:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-05-02T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-20T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-02T13:00:00.000Z'
    }, {
      age: '126',
      timestamp: '2018-02-17T13:00:00.000Z'
    }, {
      age: '190',
      timestamp: '2018-09-21T14:00:00.000Z'
    }, {
      age: '182',
      timestamp: '2018-09-12T14:00:00.000Z'
    }, {
      age: '192',
      timestamp: '2018-10-13T13:00:00.000Z'
    }, {
      age: '132',
      timestamp: '2018-03-04T13:00:00.000Z'
    }, {
      age: '192',
      timestamp: '2018-10-04T14:00:00.000Z'
    }, {
      age: '127',
      timestamp: '2018-02-12T13:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-03-29T13:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-05-31T14:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-06-10T14:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-05-13T14:00:00.000Z'
    }, {
      age: '192',
      timestamp: '2018-10-10T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-10T13:00:00.000Z'
    }, {
      age: '190',
      timestamp: '2018-09-17T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-08T13:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-04-14T14:00:00.000Z'
    }, {
      age: '190',
      timestamp: '2018-09-22T14:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-05-01T14:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-05-09T14:00:00.000Z'
    }, {
      age: '147',
      timestamp: '2018-07-02T14:00:00.000Z'
    }, {
      age: '132',
      timestamp: '2018-03-02T13:00:00.000Z'
    }, {
      age: '195',
      timestamp: '2018-10-22T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-12-04T13:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-08-04T14:00:00.000Z'
    }, {
      age: '138',
      timestamp: '2018-04-05T14:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-11-21T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-11-28T13:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-06-06T14:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-06-12T14:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-01-31T13:00:00.000Z'
    }, {
      age: '132',
      timestamp: '2018-02-27T13:00:00.000Z'
    }, {
      age: '192',
      timestamp: '2018-10-16T13:00:00.000Z'
    }, {
      age: '108',
      timestamp: '2017-11-29T13:00:00.000Z'
    }, {
      age: '146',
      timestamp: '2018-05-06T14:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-07-21T14:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-05-24T14:00:00.000Z'
    }, {
      age: '155',
      timestamp: '2018-07-30T14:00:00.000Z'
    }, {
      age: '128',
      timestamp: '2018-01-22T13:00:00.000Z'
    }, {
      age: '132',
      timestamp: '2018-02-24T13:00:00.000Z'
    }, {
      age: '153',
      timestamp: '2018-07-17T14:00:00.000Z'
    }, {
      age: '195',
      timestamp: '2018-10-20T13:00:00.000Z'
    }, {
      age: '148',
      timestamp: '2018-06-04T14:00:00.000Z'
    }, {
      age: '132',
      timestamp: '2018-02-26T13:00:00.000Z'
    }, {
      age: '106',
      timestamp: '2018-01-02T13:00:00.000Z'
    }],
    averageBueno: '129'
  },
  optimisation: [{
    timestamp: '2018-10-30T13:00:00.000Z',
    total: '8'
  }, {
    timestamp: '2018-09-29T14:00:00.000Z',
    total: '9'
  }, {
    timestamp: '2018-08-30T14:00:00.000Z',
    total: '10'
  }, {
    timestamp: '2018-07-30T14:00:00.000Z',
    total: '11'
  }, {
    timestamp: '2018-06-29T14:00:00.000Z',
    total: '12'
  }, {
    timestamp: '2018-05-30T14:00:00.000Z',
    total: '12'
  }, {
    timestamp: '2018-04-29T14:00:00.000Z',
    total: '12'
  }, {
    timestamp: '2018-03-30T13:00:00.000Z',
    total: '13'
  }, {
    timestamp: '2018-02-27T13:00:00.000Z',
    total: '17'
  }, {
    timestamp: '2018-01-30T13:00:00.000Z',
    total: '18'
  }, {
    timestamp: '2017-12-30T13:00:00.000Z',
    total: '24'
  }, {
    timestamp: '2017-11-29T13:00:00.000Z',
    total: '24'
  }, {
    timestamp: '2017-10-30T13:00:00.000Z',
    total: '26'
  }],
  maintenance: [{
    timestamp: '2018-10-30T13:00:00.000Z',
    total: '25'
  }, {
    timestamp: '2018-09-29T14:00:00.000Z',
    total: '25'
  }, {
    timestamp: '2018-08-30T14:00:00.000Z',
    total: '27'
  }, {
    timestamp: '2018-07-30T14:00:00.000Z',
    total: '27'
  }, {
    timestamp: '2018-06-29T14:00:00.000Z',
    total: '30'
  }, {
    timestamp: '2018-05-30T14:00:00.000Z',
    total: '28'
  }, {
    timestamp: '2018-04-29T14:00:00.000Z',
    total: '31'
  }, {
    timestamp: '2018-03-30T13:00:00.000Z',
    total: '32'
  }, {
    timestamp: '2018-02-27T13:00:00.000Z',
    total: '32'
  }, {
    timestamp: '2018-01-30T13:00:00.000Z',
    total: '29'
  }, {
    timestamp: '2017-12-30T13:00:00.000Z',
    total: '29'
  }, {
    timestamp: '2017-11-29T13:00:00.000Z',
    total: '30'
  }, {
    timestamp: '2017-10-30T13:00:00.000Z',
    total: '32'
  }],
  dlp: [{
    timestamp: '2018-10-30T13:00:00.000Z',
    total: '1'
  }, {
    timestamp: '2018-09-29T14:00:00.000Z',
    total: '1'
  }, {
    timestamp: '2018-08-30T14:00:00.000Z',
    total: '2'
  }, {
    timestamp: '2018-07-30T14:00:00.000Z',
    total: '3'
  }, {
    timestamp: '2018-06-29T14:00:00.000Z',
    total: '3'
  }, {
    timestamp: '2018-05-30T14:00:00.000Z',
    total: '3'
  }, {
    timestamp: '2018-04-29T14:00:00.000Z',
    total: '3'
  }, {
    timestamp: '2018-03-30T13:00:00.000Z',
    total: '4'
  }, {
    timestamp: '2018-02-27T13:00:00.000Z',
    total: '4'
  }, {
    timestamp: '2018-01-30T13:00:00.000Z',
    total: '4'
  }, {
    timestamp: '2017-12-30T13:00:00.000Z',
    total: '7'
  }, {
    timestamp: '2017-11-29T13:00:00.000Z',
    total: '7'
  }, {
    timestamp: '2017-10-30T13:00:00.000Z',
    total: '7'
  }]
};
var _default = data;
exports.default = _default;
module.exports = exports.default;