"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.matchUnSearchable = exports.matchMDLinks = exports.matchUnsafeS3Chars = void 0;

/**
 * Match all characters that aren't safe for S3 filenames
 *
 * See [Object Key and Metadata](https://docs.aws.amazon.com/AmazonS3/latest/dev/UsingMetadata.html)
 * for complete list
 */
const matchUnsafeS3Chars = /[^a-z0-9!\-_.*'()]/gi;
/**
 * Match markdown links
 * Capturing Group 1: Link Text
 * Capturing Group 2: Link URL
 */

exports.matchUnsafeS3Chars = matchUnsafeS3Chars;
const matchMDLinks = /\[([^[\]]+)\]\(([^)]+)\)/g;
/**
 * Match any character that isn't a number, alphabet character, or space
 */

exports.matchMDLinks = matchMDLinks;
const matchUnSearchable = /[^a-z0-9_: -]/gi;
exports.matchUnSearchable = matchUnSearchable;