"use strict";

const environment = {
  development: {
    isProduction: false
  },
  production: {
    isProduction: true
  }
}[process.env.NODE_ENV || "development"];
module.exports = Object.assign({
  host: process.env.HOST || "localhost",
  port: process.env.PORT || 3000,
  apiHost: process.env.APIHOST || "localhost",
  apiPort: process.env.APIPORT || 1337,
  iwsHost: process.env.IWS_HOST || "localhost",
  iwsPort: process.env.IWS_PORT || 8099,
  iwsRouteBase: process.env.IWS_ROUTE_BASE || "/iws",
  iwsUrlPrefix: process.env.IWS_URL_PREFIX || "/api/v1/sites",
  ssoKeycloakAddressAuth: process.env.INSITE_SSO_KEYCLOAK_ADDRESS_AUTH
}, environment);