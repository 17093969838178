"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.valuePair = valuePair;
exports.selectChange = selectChange;
exports.selectBlur = selectBlur;
exports.handleGoBack = handleGoBack;

/**
 * Return a value pair from a label
 */
function valuePair(label) {
  return {
    label,
    value: label
  };
}
/**
 * onChange handler for `<Select />` components within Formik.
 */


function selectChange(key, setFieldValue) {
  // TODO: React-select has some funky type definitions and because it's not a
  // dependency of insite (it's a peer of @bueno/blocks components) we can't assume
  // that it's available to import
  return valPair => {
    // NOTE: valPair will be `null` when the field is cleared using
    // react-selects `isClearable` prop
    const value = valPair !== null ? valPair.value : "";
    setFieldValue(key, value);
  };
}
/**
 * onBlur handler for `<Select />` components within Formik. This tells Formik
 * the field was touched, but to not validate immediately.
 */


function selectBlur(key, setFieldTouched) {
  return () => {
    setFieldTouched(key, true, false);
  };
}
/**
 * A helper function to using the `window.history.back()` API
 */


function handleGoBack() {
  window.history.back();
}