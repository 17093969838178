"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.watchLoadReportData = watchLoadReportData;
exports.monthlySummarySagaFlow = monthlySummarySagaFlow;

var _effects = require("redux-saga/effects");

var _reduxPersist = require("redux-persist");

var _requestSchedule = require("../_BETA/requestSchedule");

var _actionTypes = require("../auth/actionTypes");

var _actionCreators = require("./actionCreators");

var _actionTypes2 = require("./actionTypes");

function* watchLoadReportData() {
  while (true) {
    const {
      shouldRequestMonthlySummary
    } = yield (0, _effects.select)(state => state.requestSchedule);

    if (shouldRequestMonthlySummary) {
      const {
        user
      } = yield (0, _effects.select)(state => state.auth);
      const {
        label: site
      } = yield (0, _effects.select)(state => state.betaSelections.activeSite); // NOTE: Uncomment to test against static, local data
      // yield put(loadSampleData(user, site))
      // NOTE: Comment when testing with above static data

      yield (0, _effects.put)((0, _actionCreators.loadReportData)(user, site));
      yield (0, _effects.take)(_actionTypes2.LOAD_REPORT_DATA_SUCCESS);
      yield (0, _effects.put)((0, _requestSchedule.setShouldRequestMonthlySummary)(false));
    }

    yield (0, _effects.take)(_actionTypes2.LOAD_REPORT_DATA_REQUEST);
  }
}

function* monthlySummarySagaFlow() {
  yield (0, _effects.take)(_reduxPersist.REHYDRATE);

  while (true) {
    yield (0, _effects.take)(_actionTypes2.LOAD_REPORT_DATA_REQUEST);
    const watchLoadReportDataTask = yield (0, _effects.fork)(watchLoadReportData);
    yield (0, _effects.take)(_actionTypes2.LOAD_REPORT_DATA_SUCCESS);
    yield (0, _effects.take)(_actionTypes.LOGOUT_SUCCESS);
    yield watchLoadReportDataTask.cancel();
  }
}