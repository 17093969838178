"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOGOUT_KEYCLOAK = exports.LOGIN_KEYCLOAK = exports.LOGOUT_FAIL = exports.LOGOUT_SUCCESS = exports.LOGOUT = exports.LOGIN_FAIL = exports.LOGIN_SUCCESS = exports.LOGIN = exports.LOAD_AUTH_USER_FAIL = exports.LOAD_AUTH_USER_SUCCESS = exports.LOAD_AUTH_USER = void 0;
// Action Types
const LOAD_AUTH_USER = "bonfire/auth/LOAD_AUTH_USER";
exports.LOAD_AUTH_USER = LOAD_AUTH_USER;
const LOAD_AUTH_USER_SUCCESS = "bonfire/auth/LOAD_AUTH_USER_SUCCESS";
exports.LOAD_AUTH_USER_SUCCESS = LOAD_AUTH_USER_SUCCESS;
const LOAD_AUTH_USER_FAIL = "bonfire/auth/LOAD_AUTH_USER_FAIL";
exports.LOAD_AUTH_USER_FAIL = LOAD_AUTH_USER_FAIL;
const LOGIN = "bonfire/auth/LOGIN";
exports.LOGIN = LOGIN;
const LOGIN_SUCCESS = "bonfire/auth/LOGIN_SUCCESS";
exports.LOGIN_SUCCESS = LOGIN_SUCCESS;
const LOGIN_FAIL = "bonfire/auth/LOGIN_FAIL";
exports.LOGIN_FAIL = LOGIN_FAIL;
const LOGOUT = "bonfire/auth/LOGOUT";
exports.LOGOUT = LOGOUT;
const LOGOUT_SUCCESS = "bonfire/auth/LOGOUT_SUCCESS";
exports.LOGOUT_SUCCESS = LOGOUT_SUCCESS;
const LOGOUT_FAIL = "bonfire/auth/LOGOUT_FAIL";
exports.LOGOUT_FAIL = LOGOUT_FAIL;
const LOGIN_KEYCLOAK = "bonfire/auth/LOGIN_KEYCLOAK";
exports.LOGIN_KEYCLOAK = LOGIN_KEYCLOAK;
const LOGOUT_KEYCLOAK = "bonfire/auth/LOGOUT_KEYCLOAK";
exports.LOGOUT_KEYCLOAK = LOGOUT_KEYCLOAK;