"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatEquipList = formatEquipList;

function formatEquip(equip) {
  return "- [".concat(equip.targetName, "](").concat(equip.reportUrl, ") \n\n ").concat(equip.ruleName);
}

function formatEquipList() {
  let equipList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return equipList.map(equip => formatEquip(equip)).join("\n");
}