"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMonthYearObj = getMonthYearObj;
exports.getPreviousMonths = getPreviousMonths;
exports.todayYesterday = todayYesterday;
exports.MONTHS = void 0;

var _moment = _interopRequireDefault(require("moment"));

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
exports.MONTHS = MONTHS;

/**
 * Create a `MonthYear` object given a moment object
 */
function getMonthYearObj(from) {
  const m = from || (0, _moment.default)(from);
  return {
    timestamp: m,
    month: MONTHS[m.month()],
    year: "".concat(m.year())
  };
}
/**
 * Return a list of previous `MonthYear`s
 */


function getPreviousMonths(delta) {
  let count = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  let months = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

  if (count <= delta - 1) {
    months.push(getMonthYearObj((0, _moment.default)().subtract(count, 'months')));
    return getPreviousMonths(delta, count + 1, months);
  }

  return months;
}

const todayUnix = (0, _moment.default)().endOf('day').unix();
const yesterdayUnix = (0, _moment.default)().subtract(1, 'day').endOf('day').unix();

function todayYesterday(m) {
  let today = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : todayUnix;
  let yesterday = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : yesterdayUnix;
  const date = (0, _moment.default)(m).endOf('day').unix();

  if (date === today) {
    return 'Today';
  }

  if (date === yesterday) {
    return 'Yesterday';
  }

  return m.fromNow();
}