"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ATTACHMENT_UPLOAD_FAIL = exports.ATTACHMENT_UPLOAD_SUCCESS = exports.ACTION_NOT_FOUND = exports.LOAD_RULES_REQUEST_FAIL = exports.LOAD_RULES_REQUEST_SUCCESS = exports.LOAD_RULES_REQUEST = exports.LOAD_RULES = exports.ADD_ACTION_REQUEST_FAIL = exports.ADD_ACTION_REQUEST_SUCCESS = exports.ADD_ACTION_REQUEST = exports.ADD_ACTION = exports.SAVE_ACTION_REQUEST_FAIL = exports.SAVE_ACTION_REQUEST_SUCCESS = exports.SAVE_ACTION_REQUEST = exports.SAVE_ACTION = exports.LOAD_ACTION_REQUEST_FAIL = exports.LOAD_ACTION_REQUEST_SUCCESS = exports.LOAD_ACTION_REQUEST = exports.LOAD_ACTION = exports.LOAD_COMMENTS_REQUEST_FAIL = exports.LOAD_COMMENTS_REQUEST_SUCCESS = exports.LOAD_COMMENTS_REQUEST = exports.LOAD_COMMENTS = exports.LOAD_ACTION_FOR_EDITING = void 0;
const LOAD_ACTION_FOR_EDITING = "bonfire/_BETA/action/LOAD_ACTION_FOR_EDITING";
exports.LOAD_ACTION_FOR_EDITING = LOAD_ACTION_FOR_EDITING;
const LOAD_COMMENTS = "bonfire/_BETA/action/LOAD_COMMENTS";
exports.LOAD_COMMENTS = LOAD_COMMENTS;
const LOAD_COMMENTS_REQUEST = "bonfire/_BETA/action/LOAD_COMMENTS_REQUEST";
exports.LOAD_COMMENTS_REQUEST = LOAD_COMMENTS_REQUEST;
const LOAD_COMMENTS_REQUEST_SUCCESS = "bonfire/_BETA/action/LOAD_COMMENTS_REQUEST_SUCCESS";
exports.LOAD_COMMENTS_REQUEST_SUCCESS = LOAD_COMMENTS_REQUEST_SUCCESS;
const LOAD_COMMENTS_REQUEST_FAIL = "bonfire/_BETA/action/LOAD_COMMENTS_REQUEST_FAIL";
exports.LOAD_COMMENTS_REQUEST_FAIL = LOAD_COMMENTS_REQUEST_FAIL;
const LOAD_ACTION = "bonfire/_BETA/action/LOAD_ACTION";
exports.LOAD_ACTION = LOAD_ACTION;
const LOAD_ACTION_REQUEST = "bonfire/_BETA/action/LOAD_ACTION_REQUEST";
exports.LOAD_ACTION_REQUEST = LOAD_ACTION_REQUEST;
const LOAD_ACTION_REQUEST_SUCCESS = "bonfire/_BETA/action/LOAD_ACTION_REQUEST_SUCCESS";
exports.LOAD_ACTION_REQUEST_SUCCESS = LOAD_ACTION_REQUEST_SUCCESS;
const LOAD_ACTION_REQUEST_FAIL = "bonfire/_BETA/action/LOAD_ACTION_REQUEST_FAIL";
exports.LOAD_ACTION_REQUEST_FAIL = LOAD_ACTION_REQUEST_FAIL;
const SAVE_ACTION = "bonfire/_BETA/action/SAVE_ACTION";
exports.SAVE_ACTION = SAVE_ACTION;
const SAVE_ACTION_REQUEST = "bonfire/_BETA/action/SAVE_ACTION_REQUEST";
exports.SAVE_ACTION_REQUEST = SAVE_ACTION_REQUEST;
const SAVE_ACTION_REQUEST_SUCCESS = "bonfire/_BETA/action/SAVE_ACTION_REQUEST_SUCCESS";
exports.SAVE_ACTION_REQUEST_SUCCESS = SAVE_ACTION_REQUEST_SUCCESS;
const SAVE_ACTION_REQUEST_FAIL = "bonfire/_BETA/action/SAVE_ACTION_REQUEST_FAIL";
exports.SAVE_ACTION_REQUEST_FAIL = SAVE_ACTION_REQUEST_FAIL;
const ADD_ACTION = "bonfire/_BETA/action/ADD_ACTION";
exports.ADD_ACTION = ADD_ACTION;
const ADD_ACTION_REQUEST = "bonfire/_BETA/action/ADD_ACTION_REQUEST";
exports.ADD_ACTION_REQUEST = ADD_ACTION_REQUEST;
const ADD_ACTION_REQUEST_SUCCESS = "bonfire/_BETA/action/ADD_ACTION_REQUEST_SUCCESS";
exports.ADD_ACTION_REQUEST_SUCCESS = ADD_ACTION_REQUEST_SUCCESS;
const ADD_ACTION_REQUEST_FAIL = "bonfire/_BETA/action/ADD_ACTION_REQUEST_FAIL";
exports.ADD_ACTION_REQUEST_FAIL = ADD_ACTION_REQUEST_FAIL;
const LOAD_RULES = "bonfire/_BETA/action/LOAD_RULES";
exports.LOAD_RULES = LOAD_RULES;
const LOAD_RULES_REQUEST = "bonfire/_BETA/action/LOAD_RULES_REQUEST";
exports.LOAD_RULES_REQUEST = LOAD_RULES_REQUEST;
const LOAD_RULES_REQUEST_SUCCESS = "bonfire/_BETA/action/LOAD_RULES_REQUEST_SUCCESS";
exports.LOAD_RULES_REQUEST_SUCCESS = LOAD_RULES_REQUEST_SUCCESS;
const LOAD_RULES_REQUEST_FAIL = "bonfire/_BETA/action/LOAD_RULES_REQUEST_FAIL";
exports.LOAD_RULES_REQUEST_FAIL = LOAD_RULES_REQUEST_FAIL;
const ACTION_NOT_FOUND = "bonfire/_BETA/action/ACTION_NOT_FOUND";
exports.ACTION_NOT_FOUND = ACTION_NOT_FOUND;
const ATTACHMENT_UPLOAD_SUCCESS = "bonfire/_BETA/action/ATTACHMENT_UPLOAD_SUCCESS";
exports.ATTACHMENT_UPLOAD_SUCCESS = ATTACHMENT_UPLOAD_SUCCESS;
const ATTACHMENT_UPLOAD_FAIL = "bonfire/_BETA/action/ATTACHMENT_UPLOAD_FAIL";
exports.ATTACHMENT_UPLOAD_FAIL = ATTACHMENT_UPLOAD_FAIL;