"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUniqIssues = getUniqIssues;
exports.getEveryDefinedIssue = getEveryDefinedIssue;
exports.sumRawHits = sumRawHits;
exports.sumHits = sumHits;
exports.getUniqEquip = getUniqEquip;
exports.getTicketCount = getTicketCount;
exports.composeInsights = composeInsights;
exports.composeOccurrences = composeOccurrences;
exports.groupByRule = groupByRule;
exports.groupBySite = groupBySite;
exports.createSiteGroups = createSiteGroups;
exports.SORT_BY_OPTIONS = exports.GROUP_BY_OPTIONS = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash.groupby"));

var _lodash2 = _interopRequireDefault(require("lodash.orderby"));

var _moment = _interopRequireDefault(require("moment"));

var _time = require("../../../utils/time");

var _sorting = require("../../../utils/sorting");

const _excluded = ["siteId", "reportUrl", "skysparkDomain", "severity", "targetName", "targetId", "occurredOn", "issueKey", "description", "ruleType", "ruleName", "snoozeEnd"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * @param {array} rules array of rule objects
 * @returns {array} array of unique issue ID strings
 */
function getUniqIssues(rules) {
  const issues = rules.map(rule => rule.issueKey);
  return (0, _sorting.uniq)(issues);
}
/**
 * @param {array} rules array of rule objects
 * @returns {array} array of every issue ID string, which could be duplicated
 */


function getEveryDefinedIssue(rules) {
  const issueKeys = rules.reduce((keys, rule) => {
    if (rule.issueKey !== undefined) {
      keys.push(rule.issueKey);
    }

    return keys;
  }, []);
  return issueKeys;
}
/**
 * Strips non-numerical characters from duration string
 * @param {string} dur duration string in the form of '1.08333333 h'
 * @returns {string} parsed string in the form of '1.08333333'
 */


function parseDuration(dur) {
  const re = /\d+\.?(\d+)?/;
  const parsed = dur.match(re);
  if (parsed !== null) return parsed[0];
  return '0';
}
/**
 *
 * @param {array} rules array of string float values
 * @returns {number} sum of all rule durations, to 2 decimal places
 */


function sumDuration() {
  let rules = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  const sum = rules.reduce((total, rule) => total + rule.duration, 0);
  return sum;
}
/**
 * @param {array} rules Array of occurrence objects
 * @returns {number} sum of all times a rule has been triggered within the 24 hour period
 */


function sumRawHits() {
  let rules = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return rules.reduce((hits, rule) => hits + rule.times.length, 0);
}

function sumHits() {
  let rules = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return rules.reduce((hits, rule) => hits + rule.activeHits, 0);
}

function getUniqEquip(composedRules) {
  const targetIds = composedRules.reduce((flat, rule) => {
    flat.push(...rule.targets);
    return flat;
  }, []).map(target => target.targetId);
  return targetIds;
}

function getGroupEquipCount(composedRules) {
  return composedRules.reduce((total, rule) => total + rule.targetCount, 0);
}

function getGroupUnassociatedEquipCount(composedRules) {
  return composedRules.reduce((total, rule) => total + (rule.targetCount - rule.actionsCount), 0);
}

function getTicketCount(composedRules) {
  return composedRules.map(rule => rule.actionsCount).reduce((sum, count) => sum + count, 0);
}
/**
 * @param {array} groupedInsights array of grouped insights
 * @param {boolean} uniqActionCount whether we want to return unique actions counts or not
 * @returns {array} array of componsed insights
 */


function composeInsights(groupedInsights, uniqActionCount) {
  return Object.entries(groupedInsights).map(_ref => {
    let [id, insight] = _ref;
    const targets = insight;
    const activeTargets = insight.filter(_ref2 => {
      let {
        isSnoozed
      } = _ref2;
      return !isSnoozed;
    });
    const issues = uniqActionCount ? getUniqIssues(targets) : getEveryDefinedIssue(targets);
    const activeIssues = uniqActionCount ? getUniqIssues(activeTargets) : getEveryDefinedIssue(activeTargets);
    const instance = insight.sort((a, b) => new Date(b.occurredOn).getTime() - new Date(a.occurredOn).getTime())[0];
    const {
      ruleName,
      severity,
      ruleType
    } = instance;
    const latest = instance.occurredOn;
    const latestUnix = new Date(instance.occurredOn).setHours(0, 0, 0, 0) / 1000;
    const hits = sumRawHits(targets);
    const activeHits = sumRawHits(activeTargets);
    const service = instance.ruleType || '';
    const rawDuration = sumDuration(targets);
    const activeRawDuration = sumDuration(activeTargets);
    const duration = Math.round(rawDuration * 100) / 100;
    const activeDuration = Math.round(activeRawDuration * 100) / 100;
    const averageDur = Math.round(rawDuration / hits * 100) / 100;
    const activeAverageDur = Math.round(activeRawDuration / hits * 100) / 100;
    const composed = {
      id,
      site: instance.site,
      project: instance.project,
      targets,
      activeTargets,
      targetCount: targets.length,
      activeTargetCount: activeTargets.length,
      isAllSnoozed: activeTargets.length === 0,
      ruleName,
      ruleType,
      ruleId: instance.ruleId,
      latest,
      latestUnix,
      latestLabel: instance.latestLabel,
      duration,
      activeDuration,
      averageDur,
      activeAverageDur,
      hits,
      activeHits,
      service,
      severity,
      issues,
      actionsCount: issues.length,
      activeActionsCount: activeIssues.length,
      priority: instance.priority
    };
    return _objectSpread(_objectSpread({}, composed), {}, {
      valueString: "".concat(ruleName || '', " ").concat(instance.ruleType || '', " ").concat(instance.latestLabel || '', " ").concat(severity || '')
    });
  });
}

const todayUnix = new Date().setHours(23, 59, 59, 0) / 1000;

function composeOccurrences(occurrences, sites, projects) {
  // @ts-ignore
  const result = occurrences.map(o => {
    const {
      siteId,
      reportUrl,
      skysparkDomain,
      severity,
      targetName,
      targetId,
      occurredOn,
      issueKey,
      description,
      ruleType,
      ruleName,
      snoozeEnd
    } = o,
          rest = (0, _objectWithoutProperties2.default)(o, _excluded);
    const times = o.times.split(',');
    const m = (0, _moment.default)(occurredOn);
    const latestLabel = (0, _time.todayYesterday)(m);
    const duration = Number(parseDuration(o.duration));
    const averageDur = Math.round(duration / times.length * 100) / 100; // @ts-ignore

    const isSnoozed = snoozeEnd !== null ? parseInt(snoozeEnd, 10) > todayUnix : false;
    const site = sites[siteId];
    const project = projects[site.projectId].project_name;
    return _objectSpread(_objectSpread({}, rest), {}, {
      site,
      project,
      siteId,
      snoozeEnd,
      isSnoozed,
      severity,
      ruleType,
      ruleName,
      targetName,
      targetId,
      skysparkId: '',
      reportURL: "".concat(skysparkDomain).concat(reportUrl),
      duration,
      description: typeof description === 'string' ? description : '',
      latestLabel,
      occurredOn,
      times,
      averageDur,
      issueKey,
      targetValueString: "".concat(targetName || '', " ").concat(site.label, " ").concat(site.state, " ").concat(project, " ").concat(severity || '', " ").concat(targetId, " ").concat(issueKey || '', " ").concat(description || '', " ").concat(ruleType || '', " ").concat(ruleName)
    });
  });
  return result;
}

function groupByRule() {
  let insights = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  if (insights.length < 1) return [];
  const ruleGroups = (0, _lodash.default)(insights, o => o.ruleId);
  return Object.values(ruleGroups).map(siteInsights => {
    const groupedInsights = (0, _lodash.default)(siteInsights, o => o.siteId);
    return composeInsights(groupedInsights);
  });
}

function groupBySite() {
  let insights = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  if (insights.length < 1) return [];
  const ruleGroups = (0, _lodash.default)(insights, o => o.siteId);
  return Object.values(ruleGroups).map(siteInsights => {
    const groupedInsights = (0, _lodash.default)(siteInsights, o => o.ruleId);
    return composeInsights(groupedInsights);
  });
}

function createSiteGroups() {
  let insights = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let sites = arguments.length > 1 ? arguments[1] : undefined;
  let projects = arguments.length > 2 ? arguments[2] : undefined;
  if (insights.length < 1) return {};
  const siteGroups = (0, _lodash.default)(insights, o => o.siteId);
  const result = Object.entries(siteGroups).reduce((combined, _ref3) => {
    let [key, siteInsights] = _ref3;
    const instance = siteInsights[0];
    const site = sites[instance.site.syncoId];
    const project = projects[site.projectId].project_name;
    const ruleGroups = (0, _lodash.default)(siteInsights, o => o.ruleId);
    const composed = {
      site,
      project,
      ruleGroups,
      insights: composeInsights(ruleGroups)
    };
    combined[key] = composed;
    return combined;
  }, {});
  return result;
}

const GROUP_BY_OPTIONS = {
  RULE: {
    label: 'Group by Rule',
    sub: 'Rule',
    key: 'RULE',
    value: 'rule',
    groupFunc: groupByRule
  },
  SITE: {
    label: 'Group by Site',
    sub: 'Site',
    key: 'SITE',
    value: 'site',
    groupFunc: groupBySite
  }
};
exports.GROUP_BY_OPTIONS = GROUP_BY_OPTIONS;
const SORT_BY_OPTIONS = {
  HITS: {
    label: 'Number of Hits',
    sub: 'Hits',
    key: 'HITS',
    value: 'Hits',
    sortFn: groups => {
      return (0, _lodash2.default)(groups, g => sumHits(g), 'desc');
    }
  },
  EQUIPS: {
    label: 'Number of Equips',
    value: 'Equips',
    sub: 'Equips',
    key: 'EQUIPS',
    sortFn: groups => (0, _lodash2.default)(groups, g => getGroupEquipCount(g), 'desc')
  },
  UNASSOCIATED_EQUIPS: {
    label: 'Number of Unassociated Equips',
    value: 'Unassociated Equips',
    sub: 'Equips',
    key: 'UNASSOCIATED_EQUIPS',
    sortFn: groups => (0, _lodash2.default)(groups, g => getGroupUnassociatedEquipCount(g), 'desc')
  },
  INSIGHTS_DESC: {
    label: 'Insight (High-Low)',
    sub: 'Insights',
    value: 'Insights Desc',
    key: 'INSIGHTS_DESC',
    sortFn: (groups, groupByOption) => {
      if (groupByOption === GROUP_BY_OPTIONS.RULE.key) {
        return (0, _lodash2.default)(groups, g => g.length, 'desc');
      }

      if (groupByOption === GROUP_BY_OPTIONS.SITE.key) {
        return (0, _lodash2.default)(groups, g => g.length, 'desc');
      }

      return groups;
    }
  },
  INSIGHTS_ASC: {
    label: 'Insight (Low-High)',
    sub: 'Insights',
    value: 'Insights ASC',
    key: 'INSIGHTS_ASC',
    sortFn: (groups, groupByOption) => {
      if (groupByOption === GROUP_BY_OPTIONS.RULE.key) {
        return (0, _lodash2.default)(groups, g => g.length, 'asc');
      }

      if (groupByOption === GROUP_BY_OPTIONS.SITE.key) {
        return (0, _lodash2.default)(groups, g => g.length, 'asc');
      }

      return groups;
    }
  },
  A_Z: {
    label: 'Alphabetical (A-Z)',
    sub: 'A-Z',
    value: 'A-Z',
    key: 'A_Z',
    sortFn: (groups, groupByOption) => {
      if (groupByOption === GROUP_BY_OPTIONS.RULE.key) {
        return (0, _lodash2.default)(groups, g => "".concat(g[0].ruleName).toLowerCase(), 'asc');
      }

      if (groupByOption === GROUP_BY_OPTIONS.SITE.key) {
        return (0, _lodash2.default)(groups, g => "".concat(g[0].site.label).toLowerCase(), 'asc');
      }

      return groups;
    }
  },
  Z_A: {
    label: 'Alphabetical (Z-A)',
    sub: 'Z-A',
    value: 'Z-A',
    key: 'Z_A',
    sortFn: (groups, groupByOption) => {
      if (groupByOption === GROUP_BY_OPTIONS.RULE.key) {
        return (0, _lodash2.default)(groups, g => "".concat(g[0].ruleName).toLowerCase(), 'desc');
      }

      if (groupByOption === GROUP_BY_OPTIONS.SITE.key) {
        return (0, _lodash2.default)(groups, g => "".concat(g[0].site.label).toLowerCase(), 'desc');
      }

      return groups;
    }
  }
};
exports.SORT_BY_OPTIONS = SORT_BY_OPTIONS;