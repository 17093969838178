"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.snoozeEquipRuleFlow = snoozeEquipRuleFlow;
exports.postSnoozeEquipRules = exports.snoozeEquipRules = exports.snoozeEquipRule = exports.SNOOZE_RULE_EQUIP_REQUEST_FAIL = exports.SNOOZE_RULE_EQUIP_REQUEST_SUCCESS = exports.SNOOZE_RULE_EQUIP_REQUEST = exports.SNOOZE_RULE_EQUIP = exports.SNOOZE_OPTIONS = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _effects = require("redux-saga/effects");

var _helpers = require("../helpers/helpers");

var _requestSchedule = require("./requestSchedule");

var _eventTracking = require("../../../utils/eventTracking");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const SNOOZE_OPTIONS = {
  DAYS_1: {
    value: 1,
    label: "1 Day"
  },
  DAYS_7: {
    value: 7,
    label: "7 Days"
  },
  DAYS_30: {
    value: 30,
    label: "30 Days"
  },
  MONTHS_6: {
    value: 183,
    label: "6 Months"
  },
  MONTHS_12: {
    value: 365,
    label: "12 Months"
  } // TODO: Add a mechanism for INDEFINITE within synco
  // INDEFINITE: { value: -1, label: 'Indefinitely', warning: '(not recommended)' }

};
exports.SNOOZE_OPTIONS = SNOOZE_OPTIONS;
const INITIAL_STATE = {
  pending: true,
  complete: false,
  error: undefined
};
const SNOOZE_RULE_EQUIP = "bonfire/_BETA/triage/SNOOZE_RULE_EQUIP";
exports.SNOOZE_RULE_EQUIP = SNOOZE_RULE_EQUIP;
const SNOOZE_RULE_EQUIP_REQUEST = "bonfire/_BETA/triage/SNOOZE_RULE_EQUIP_REQUEST";
exports.SNOOZE_RULE_EQUIP_REQUEST = SNOOZE_RULE_EQUIP_REQUEST;
const SNOOZE_RULE_EQUIP_REQUEST_SUCCESS = "bonfire/_BETA/triage/SNOOZE_RULE_EQUIP_REQUEST_SUCCESS";
exports.SNOOZE_RULE_EQUIP_REQUEST_SUCCESS = SNOOZE_RULE_EQUIP_REQUEST_SUCCESS;
const SNOOZE_RULE_EQUIP_REQUEST_FAIL = "bonfire/_BETA/triage/SNOOZE_RULE_EQUIP_REQUEST_FAIL";
exports.SNOOZE_RULE_EQUIP_REQUEST_FAIL = SNOOZE_RULE_EQUIP_REQUEST_FAIL;

function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case SNOOZE_RULE_EQUIP_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        pending: true,
        complete: false,
        error: undefined
      });

    case SNOOZE_RULE_EQUIP_REQUEST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        pending: false,
        complete: true,
        error: undefined
      });

    case SNOOZE_RULE_EQUIP_REQUEST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        pending: false,
        complete: true,
        error: action.error
      });

    default:
      {
        return state;
      }
  }
}

const snoozeEquipRule = _ref => {
  let {
    equipRuleId,
    duration
  } = _ref;
  return {
    type: SNOOZE_RULE_EQUIP,
    equipRuleId,
    duration
  };
};

exports.snoozeEquipRule = snoozeEquipRule;

const snoozeEquipRules = _ref2 => {
  let {
    equipRuleIds,
    duration
  } = _ref2;
  return {
    type: SNOOZE_RULE_EQUIP,
    equipRuleIds,
    duration
  };
};

exports.snoozeEquipRules = snoozeEquipRules;

const postSnoozeEquipRules = _ref3 => {
  let {
    equipRuleIds,
    duration,
    token
  } = _ref3;
  return {
    types: [SNOOZE_RULE_EQUIP_REQUEST, SNOOZE_RULE_EQUIP_REQUEST_SUCCESS, SNOOZE_RULE_EQUIP_REQUEST_FAIL],
    promise: client => client.post("/synco/equipRule/snooze", {
      headers: {
        Authorization: "Bearer ".concat(token)
      },
      data: {
        duration,
        equipRuleIds
      }
    })
  };
};

exports.postSnoozeEquipRules = postSnoozeEquipRules;

const getAuthUser = state => state.auth.user;

const getActiveSiteName = state => state.betaSelections.activeSite.label;

function* snoozeEquipRuleFlow() {
  while (true) {
    const {
      duration,
      equipRuleIds
    } = yield (0, _effects.take)(SNOOZE_RULE_EQUIP);

    try {
      const user = yield (0, _effects.select)(getAuthUser);
      const siteName = yield (0, _effects.select)(getActiveSiteName); // Check that user has necessary permissions to make request

      if (!user.rolesRestriction) throw Error("Unable to authenticate user"); // Validate that snooze duration has been given

      if (!duration) throw Error("No snooze duration given"); // validate that at least one equipRuleId has been given

      if (!equipRuleIds) throw Error("No equipRuleIds given");
      yield (0, _effects.fork)(_helpers.dispatchGeneratorFunc, postSnoozeEquipRules, {
        token: user.token,
        duration,
        equipRuleIds
      });
      const {
        success,
        failure
      } = yield (0, _effects.race)({
        success: (0, _effects.take)(SNOOZE_RULE_EQUIP_REQUEST_SUCCESS),
        failure: (0, _effects.take)(SNOOZE_RULE_EQUIP_REQUEST_FAIL)
      });

      if (failure) {
        throw new Error("Failed to snooze ruleEquips");
      }

      if (success) {
        (0, _eventTracking.snoozeEquipEvent)(siteName, duration, equipRuleIds, success.result);
        yield (0, _effects.put)((0, _requestSchedule.setShouldRequestInsights)(true));
        yield (0, _effects.put)((0, _requestSchedule.setShouldRequestMonitoring)(true));
      }
    } catch (err) {
      console.error("Something went wrong attempting to snooze rule equip: ".concat(err));
    }
  }
}