"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FooterLink = exports.Footer = exports.Wrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@conserveit/blocks");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

const Wrapper = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  position: relative;\n\n  .Wrapper-inner {\n    max-width: 568px;\n    margin: auto;\n    padding: ", ";\n  }\n"])), _blocks.spacing.ultraLoose);

exports.Wrapper = Wrapper;

const Footer = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  display: flex;\n  margin-top: ", ";\n  align-items: center;\n"])), _blocks.spacing.snug);

exports.Footer = Footer;
Footer.Section = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n  flex-basis: calc(100% / 3);\n"])));
Footer.Help = (0, _styledComponents.default)(Footer.Section)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n  text-align: center;\n"])));

const FooterLink = _styledComponents.default.a(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n  display: inline-block;\n  color: ", ";\n\n  &:hover {\n    color: ", ";\n    text-decoration: underline;\n  }\n"])), _blocks.color.theme.typography.ink, _blocks.color.theme.typography.ink);

exports.FooterLink = FooterLink;