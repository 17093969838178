"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGroupAssets = getGroupAssets;
exports.filterByCategory = filterByCategory;
exports.sortFilteredData = sortFilteredData;
exports.groupByGroupName = groupByGroupName;
exports.getHitsCount = getHitsCount;
exports.getEquipsCount = getEquipsCount;
exports.getUnassociatedEquipsCount = getUnassociatedEquipsCount;
exports.getInsightsCount = getInsightsCount;
exports.getSnoozedCount = getSnoozedCount;
exports.getMonitoringCounts = getMonitoringCounts;
exports.getGroupId = getGroupId;
exports.updateQueryString = updateQueryString;
exports.splitActioned = splitActioned;
exports.getActionsAssociatedState = getActionsAssociatedState;
exports.getInstantSearchQueryFromUrl = getInstantSearchQueryFromUrl;
exports.getCategoryFromQueryString = getCategoryFromQueryString;
exports.getAllInsightsExportData = getAllInsightsExportData;
exports.getGroupedInsightsExportData = getGroupedInsightsExportData;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _queryStringForAll = _interopRequireDefault(require("query-string-for-all"));

var _blocks = require("@conserveit/blocks");

var _SiteGroupHeading = _interopRequireDefault(require("../ExpandableGroup/components/SiteGroupHeading"));

var _SiteGroupHeader = _interopRequireDefault(require("../ExpandableGroup/components/SiteGroupHeader"));

var _SiteGroupRow = _interopRequireDefault(require("../ExpandableGroup/components/SiteGroupRow"));

var _RuleGroupHeading = _interopRequireDefault(require("../ExpandableGroup/components/RuleGroupHeading"));

var _RuleGroupHeader = _interopRequireDefault(require("../ExpandableGroup/components/RuleGroupHeader"));

var _RuleGroupRow = _interopRequireDefault(require("../ExpandableGroup/components/RuleGroupRow"));

var _PriorityGroupHeading = _interopRequireDefault(require("../ExpandableGroup/components/PriorityGroupHeading"));

var _PriorityGroupHeader = _interopRequireDefault(require("../ExpandableGroup/components/PriorityGroupHeader"));

var _PriorityGroupRow = _interopRequireDefault(require("../ExpandableGroup/components/PriorityGroupRow"));

var _GroupFooter = _interopRequireDefault(require("../ExpandableGroup/components/GroupFooter"));

var _types = require("./types");

var _GroupRow = require("../ExpandableGroup/GroupRow.styles");

var _browserHistory = require("../../../browserHistory");

var _constants = require("./constants");

var _groups = require("./groups");

var _sorts = require("./sorts");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function getGroupAssets(group) {
  switch (group) {
    case _types.GroupBy.Rule:
      return {
        Heading: _RuleGroupHeading.default,
        Header: _RuleGroupHeader.default,
        Row: _RuleGroupRow.default,
        Footer: _GroupFooter.default,
        grid: {
          columns: 5,
          group: [{
            start: 2,
            end: 3
          }, {
            start: 4,
            end: 5
          }],
          grow: [2],
          hide: []
        }
      };

    case _types.GroupBy.Priority:
      return {
        Heading: _PriorityGroupHeading.default,
        Header: _PriorityGroupHeader.default,
        Row: _PriorityGroupRow.default,
        Footer: _GroupFooter.default,
        grid: {
          columns: 9,
          group: [{
            start: 3,
            end: 4
          }, {
            start: 6,
            end: 7
          }, {
            start: 8,
            end: 9
          }],
          grow: [3, 6],
          hide: [2]
        }
      };

    case _types.GroupBy.Site:
    default:
      return {
        Heading: _SiteGroupHeading.default,
        Header: _SiteGroupHeader.default,
        Row: _SiteGroupRow.default,
        Footer: _GroupFooter.default,
        grid: {
          columns: 7,
          group: [{
            start: 4,
            end: 5
          }, {
            start: 6,
            end: 7
          }],
          grow: [4],
          hide: []
        }
      };
  }
}

function filterByCategory(category, group, data) {
  return Object.values(data[group.toLowerCase()]).map(hitIds => {
    const filteredHits = hitIds.filter(id => // TODO: Inefficient, pls optimise
    category === _types.Category.All || data["category"][category] && data["category"][category].includes(id) && data.hit[id]).map(id => data["hit"][id]).filter(occ => occ !== undefined);
    return filteredHits;
  }).filter(arr => arr.length > 0);
}

function sortFilteredData(data, groupBy, sortBy) {
  switch (sortBy) {
    case _types.SortBy.Equips:
      return (0, _sorts.sortByEquips)(data);

    case _types.SortBy.InsightsAsc:
      return (0, _sorts.sortByInsights)(data, "asc");

    case _types.SortBy.InsightsDesc:
      return (0, _sorts.sortByInsights)(data, "desc");

    case _types.SortBy.AlphaAsc:
      return (0, _sorts.sortByAlpha)(data, groupBy, "asc");

    case _types.SortBy.AlphaDesc:
      return (0, _sorts.sortByAlpha)(data, groupBy, "desc");

    case _types.SortBy.Hits:
    default:
      return (0, _sorts.sortByHits)(data);
  }
}

function groupByGroupName(group) {
  let occurrences = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

  switch (group) {
    case _types.GroupBy.Rule:
      return (0, _groups.groupByRule)(occurrences);

    case _types.GroupBy.Priority:
      return (0, _groups.groupByPriority)(occurrences, false);

    case _types.GroupBy.Site:
    default:
      return (0, _groups.groupBySite)(occurrences, false);
  }
}

function getHitsCount() {
  let insights = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let showSnoozed = arguments.length > 1 ? arguments[1] : undefined;
  const propAccessor = showSnoozed ? "hits" : "activeHits";
  return insights.reduce((count, insight) => count + insight[propAccessor], 0);
}

function getEquipsCount() {
  let insights = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let showSnoozed = arguments.length > 1 ? arguments[1] : undefined;
  const propAccessor = showSnoozed ? "targetCount" : "activeTargetCount";
  return insights.reduce((count, insight) => count + insight[propAccessor], 0);
}

function getUnassociatedEquipsCount() {
  let insights = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let showSnoozed = arguments.length > 1 ? arguments[1] : undefined;
  const targetAccessor = showSnoozed ? "targetCount" : "activeTargetCount";
  const actionAccessor = showSnoozed ? "actionsCount" : "activeActionsCount";
  return insights.reduce((count, insight) => count + (insight[targetAccessor] - insight[actionAccessor]), 0);
}

function getInsightsCount() {
  let insightGroups = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let showSnoozed = arguments.length > 1 ? arguments[1] : undefined;
  return showSnoozed ? insightGroups.reduce((count, insights) => count + insights.length, 0) : insightGroups.reduce((count, insights) => {
    return count + insights.filter(insight => !insight.isAllSnoozed).length;
  }, 0);
}

function getSnoozedCount() {
  let insightGroups = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return insightGroups.reduce((totalSnoozed, insightGroup) => {
    const insightGroupSnoozedCount = insightGroup.reduce((snoozedCount, insight) => snoozedCount + (insight.targetCount - insight.activeTargetCount), 0);
    return totalSnoozed + insightGroupSnoozedCount;
  }, 0);
}

function getMonitoringCounts(group, grouped, showSnoozed) {
  const siteCount = grouped.reduce((sites, occurrences) => {
    return sites.concat(occurrences.map(occurrence => occurrence.siteId));
  }, []);
  const count = new Set(siteCount);
  return {
    siteCount: count.size,
    equipCount: grouped.reduce((count, occurrences) => count + getEquipsCount(groupByGroupName(group, occurrences), showSnoozed), 0),
    analysisCount: grouped.reduce((count, occurrences) => count + getHitsCount(groupByGroupName(group, occurrences), showSnoozed), 0)
  };
}

function getGroupId(group, instance) {
  switch (group) {
    case _types.GroupBy.Priority:
      return "".concat(group, "-").concat(instance.priority);

    case _types.GroupBy.Rule:
      return "".concat(group, "-").concat(instance.ruleId);

    default:
      return "".concat(group, "-").concat(instance.site.syncoId);
  }
}

function updateQueryString(query, history, queryKey) {
  const qs = _queryStringForAll.default.parse(history.location.search);

  const val = query.length > 0 ? query : undefined;

  const stringified = _queryStringForAll.default.stringify(_objectSpread(_objectSpread({}, qs), {}, {
    [queryKey]: val
  }));

  history.replace({
    search: stringified
  });
}

function splitActioned(insights) {
  return insights.reduce((split, insight) => {
    if (insight.targets.length === insight.actionsCount) {
      split.actioned.push(insight);
    } else {
      split.unactioned.push(insight);
    }

    return split;
  }, {
    actioned: [],
    unactioned: []
  });
}
/**
 * Return different DataGridCell and Main style and wording on equips count based on whether actionsCount equals to targetCount
 * @param insight
 * @param showSnoozed
 */


function getActionsAssociatedState(insight, showSnoozed) {
  const {
    targetCount,
    activeTargetCount,
    actionsCount
  } = insight;
  const displayTargetCount = showSnoozed ? targetCount : activeTargetCount;
  return targetCount === actionsCount ? {
    Cell: _GroupRow.ActionedCell,
    MainText: _GroupRow.ActionedMain,
    allAssociated: true,
    displayTargetCount
  } : {
    Cell: _blocks.DataGridCell,
    MainText: _blocks.Main,
    allAssociated: false,
    displayTargetCount
  };
}

function getInstantSearchQueryFromUrl() {
  const qs = _react.default.useMemo(() => _queryStringForAll.default.parse(_browserHistory.history.location.search)[_constants.SEARCH_KEY], [_browserHistory.history.location.search]);

  return _react.default.useMemo(() => typeof qs === "string" ? qs : undefined, []);
}
/** Return the category in the query string, defaults to "connectivity" if not available */


function getCategoryFromQueryString(search) {
  const qs = _queryStringForAll.default.parse(search); // @ts-ignore


  if (qs.category && Object.values(_types.Category).includes(qs.category)) {
    return qs.category;
  }

  return _types.Category.All;
}
/**
 * Transform all insights gruops into a format that can be used
 * for the function getCsvString()
 * @param composed
 */


function getAllInsightsExportData(composed) {
  return composed.reduce((acc, items) => {
    const transformedItems = items.reduce((targetAcc, target) => {
      const transformed = target.targets.map(t => ({
        siteId: t.siteId,
        siteName: t.site.label,
        state: t.site.state,
        equipName: t.targetName,
        description: t.description,
        ruleName: t.ruleName,
        ruleCategory: t.ruleCategory ? t.ruleCategory : "",
        isSnoozed: t.isSnoozed.toString(),
        targetName: t.targetName,
        reportURL: t.reportURL,
        occurredOn: t.occurredOn,
        priority: t.priority !== undefined ? t.priority : "unprioritised",
        duration: t.duration
      }));
      return targetAcc.concat(transformed);
    }, []);
    return acc.concat(transformedItems);
  }, []);
}
/**
 * Transform single insight group for function getCsvString() to export
 * @param composed
 */


function getGroupedInsightsExportData(composed) {
  return composed.reduce((acc, item) => {
    const transformed = item.targets.map(t => ({
      siteId: t.siteId,
      siteName: t.site.label,
      state: t.site.state,
      equipName: t.targetName,
      description: t.description,
      ruleName: t.ruleName,
      ruleCategory: t.ruleCategory ? t.ruleCategory : "",
      isSnoozed: t.isSnoozed.toString(),
      targetName: t.targetName,
      reportURL: t.reportURL,
      occurredOn: t.occurredOn,
      priority: t.priority !== undefined ? t.priority : "unprioritised",
      duration: t.duration
    }));
    return [...acc, ...transformed];
  }, []);
}