"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSiteById = getSiteById;

/**
 * Get site object by siteId from redux store
 */
function getSiteById(id) {
  const store = require("../client").store;

  const sites = store.getState().userSites.sites;
  return sites[id];
}