"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/**
 * A helper function to wrap the Segment library in test-safe function calls
 */
const segment = {
  load: function load() {
    window.analytics !== undefined && window.analytics["load"](...arguments);
  },
  identify: function identify() {
    window.analytics !== undefined && window.analytics["identify"](...arguments);
  },
  track: function track() {
    window.analytics !== undefined && window.analytics["track"](...arguments);
  },
  page: function page() {
    window.analytics !== undefined && window.analytics["page"](...arguments);
  },
  group: function group() {
    window.analytics !== undefined && window.analytics["group"](...arguments);
  },
  alias: function alias() {
    window.analytics !== undefined && window.analytics["alias"](...arguments);
  },
  reset: function reset() {
    window.analytics !== undefined && window.analytics["reset"](...arguments);
  }
};
var _default = segment;
exports.default = _default;
module.exports = exports.default;