"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePortfolioEffects = usePortfolioEffects;

var React = _interopRequireWildcard(require("react"));

var _comfort = require("../../../../context/reports/comfort");

var _useActions = require("../../../../context/reports/comfort/useActions");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function usePortfolioEffects(_ref) {
  let {
    appReady,
    sites
  } = _ref;
  const state = (0, _comfort.useState)();
  const {
    getPortfolio
  } = (0, _useActions.useActions)();
  const didMountRef = React.useRef(false);
  const {
    current,
    compare,
    timeFrameOption,
    compareToOption
  } = state; // * If portfolio data has not yet been loaded, request it

  React.useEffect(() => {
    if (appReady && !state.portfolioLoading && !state.portfolioLoaded) {
      didMountRef.current = true;
      void getPortfolio({
        siteIds: sites,
        current,
        compare
      });
    }
  }, [appReady]); // * If sites list changes AFTER initial mount, refresh portfolio data

  React.useEffect(() => {
    // * Avoid repeat requests if portfolio data has already been requested/loaded
    // * when navigating back to the zones/portfolio views after initial load
    if (sites.join("") === state.activeSitesHash) return;

    if (appReady && !state.portfolioLoading && state.portfolioLoaded) {
      void getPortfolio({
        siteIds: sites,
        current,
        compare
      });
    }
  }, [sites]); // * If time frame changes AFTER initial mount, refresh portfolio data

  React.useEffect(() => {
    if (appReady && !state.portfolioLoading && state.portfolioLoaded && didMountRef.current) {
      void getPortfolio({
        siteIds: sites,
        current,
        compare
      });
    }

    didMountRef.current = true;
  }, [timeFrameOption, compareToOption]);
}